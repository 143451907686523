import { Modal, DraggableList, useDraggableList, Button, ButtonGroup } from 'alloy-foundation';
import { DraggableListItemProps } from 'alloy-foundation/es/components/DraggableList';
import { FormattedMessage } from 'react-intl';
import { DashboardSettingItem } from '../../models/ApplicationState/DashboardSettingItem';

interface DashboardSettingModalProps {
  initialList: DraggableListItemProps[];
  handleClose: any;
  saveDashboardSettings: (p: DashboardSettingItem[]) => void;
}
const DashboardSettingModal = (props: DashboardSettingModalProps) => {
  const [state, , toggleDisabled, setItemList] = useDraggableList(props.initialList ?? []);
  const handleSave = () => {
    const dashboardSettings = (state.itemList as DraggableListItemProps[])?.map((p) => {
      return {
        id: p.id,
        locked: p.locked,
        disabled: p.disabled,
      };
    });

    props.saveDashboardSettings(dashboardSettings);
    props.handleClose();
  };
  return (
    <Modal
      small
      closable={true}
      variant="default"
      onClose={props.handleClose}
      title={<FormattedMessage id="dashboard.setting.title" />}
      subtitle={<FormattedMessage id="dashboard.setting.subtitle" />}
      footer={
        <ButtonGroup endAlign={true}>
          <Button onClick={props.handleClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button onClick={handleSave} variant="primary">
            <FormattedMessage id="common.button.save" />
          </Button>
        </ButtonGroup>
      }
    >
      <DraggableList
        onToggle={toggleDisabled}
        itemList={state.itemList}
        setItemList={setItemList}
      />
    </Modal>
  );
};

export default DashboardSettingModal;
