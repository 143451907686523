import { DateTime } from 'luxon';
import * as Yup from 'yup';
import * as fieldNames from './fieldNames';

const ToDoListDashboardPanelValidationSchema = (formatMessage) =>
  Yup.object().shape(
    {
      [`${fieldNames.ACTION}`]: Yup.string()
        .trim()
        .required(formatMessage({ id: 'common.validation.required' })),
      [`${fieldNames.PRIORITY}`]: Yup.string()
        .trim()
        .required(formatMessage({ id: 'common.validation.required' })),
      [`${fieldNames.DAYS_FROM_TODAY}`]: Yup.string().when(`${fieldNames.DUE_DATE_TO}`, {
        is: (value) => !value,
        then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
        otherwise: Yup.string(),
      }),
      [`${fieldNames.DUE_DATE_FROM}`]: Yup.string()
        .nullable(true)
        .when(`${fieldNames.DUE_DATE_TO}`, {
          is: (value) => value,
          then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
          otherwise: Yup.string().nullable(true),
        })
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        }),
      [`${fieldNames.DUE_DATE_TO}`]: Yup.string()
        .nullable(true)
        .when(`${fieldNames.DUE_DATE_FROM}`, {
          is: (value) => value,
          then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
          otherwise: Yup.string().nullable(true),
        })
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        }),
      [`${fieldNames.INCLUDE_COMPLETE}`]: Yup.boolean(),
      [`${fieldNames.INCLUDE_INCOMPLETE}`]: Yup.boolean(),
    },
    [[fieldNames.DUE_DATE_FROM, fieldNames.DUE_DATE_TO]]
  );

export default ToDoListDashboardPanelValidationSchema;
