import { useQuery, UseQueryOptions, QueryKey } from 'react-query';
import GlobalSearchService from '../../services/globalSearch/GlobalSearchService';
import { SearchResult } from '../../models/SearchResult';

export const useGlobalSearchData = (
  searchTerm: string,
  option?: UseQueryOptions<SearchResult[], unknown, SearchResult[], QueryKey>
) => {
  const searchData = useQuery(
    ['search', searchTerm],
    () => GlobalSearchService.searchByAll(searchTerm),
    option
  );

  return searchData;
};
