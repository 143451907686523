import { Button, ButtonGroup, Modal, Typography } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onClose: () => void;
}

const NoPermissionToOpenWorkItemModal = ({ onClose }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      data-testid="no-permission-work-item"
      closable
      variant="default"
      footer={
        <ButtonGroup endAlign>
          <Button onClick={onClose} variant="primary">
            <FormattedMessage id="common.button.ok" />
          </Button>
        </ButtonGroup>
      }
      title={formatMessage({ id: 'commnon.accessDenied' })}
      onClose={onClose}
    >
      <Typography variant="body">
        <FormattedMessage id="drawer.workItem.noPermission" />
      </Typography>
    </Modal>
  );
};

export default NoPermissionToOpenWorkItemModal;
