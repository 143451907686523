import { TopNavButton, TopNavMenu } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDrawer } from '../drawer/DrawerProvider';
import RecentCustomersDrawer from '../drawer/recentCustomers';

const TopNavRecentCustomers = () => {
  const { showDrawer } = useDrawer();
  const { formatMessage } = useIntl();

  return (
    <TopNavMenu>
      <TopNavButton
        title={formatMessage({ id: 'drawer.recentCustomers.title' })}
        iconName="topNavEmployees"
        onClick={() => showDrawer(<RecentCustomersDrawer />)}
        data-testid="recent-customers"
      />
    </TopNavMenu>
  );
};

export default TopNavRecentCustomers;
