import { Button, Column, Flex, Row, SearchField, Spacer, Typography } from 'alloy-foundation';
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import WorkQueueDateFilter from './WorkQueueDateFilter';
import stylesFn from './styles';
import { useDrawer } from '../DrawerProvider';
import { DateFilterSettimgs } from './DateFilter';

interface Props {
  filterValue: string;
  onFilter: Dispatch<SetStateAction<string>>;
  dateFilter?: DateFilterSettimgs;
  setDateFilter?: (p: DateFilterSettimgs) => void;
}

const WorkQueueHeader = ({ filterValue, onFilter, dateFilter, setDateFilter }: Props) => {
  const { css, styles } = useStyles({ stylesFn });
  const { hideDrawer } = useDrawer();
  const { formatMessage } = useIntl();

  return (
    <div {...css(styles.header)}>
      <Row noGutters={true}>
        <Column xs={9} md={9} sm={9}>
          <Spacer pl="small" pt="small">
            <Typography variant="h2" color="white">
              <FormattedMessage id="drawer.workQueue.title" />
            </Typography>
          </Spacer>
        </Column>
        <Column>
          <Flex justifyContent="flex-end">
            <Spacer pt="xsmall">
              <Button
                icon="close"
                variant="linkWhite"
                onClick={() => hideDrawer()}
                data-testid="drawer-close"
              />
            </Spacer>
          </Flex>
        </Column>
      </Row>
      <Row noGutters={true}>
        <Column>
          <Spacer pt="small" pl="small" pr="small">
            <SearchField
              buttonAriaLabel="search-field"
              id="alloy-search-field"
              placeholder={formatMessage({ id: 'common.search.placeholder' })}
              value={filterValue}
              onChange={onFilter}
              inputDebouncingMs={500}
            />
          </Spacer>
        </Column>
      </Row>
      <Row noGutters={true}>
        <Column>
          <Spacer pt="small" pl="small" pr="small">
            <WorkQueueDateFilter dateFilter={dateFilter} setDateFilter={setDateFilter} />
          </Spacer>
        </Column>
      </Row>
    </div>
  );
};

export default WorkQueueHeader;
