import React, { useCallback, useEffect } from 'react';
import { AppShell, useDocumentTitle } from 'alloy-foundation';
import { usePingOnlyAuthContext } from 'ui-auth-client';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import TopNav from '../topnav/TopNav';
import MainWindow from './MainWindow';
import { EditProvider } from '../common/providers/EditProvider';
import { LockProvider } from '../common/providers/LockProvider';
import { CommunicationServiceProvider } from '../communicationService/CommunicationServiceProvider';
import { useLoginService } from './useLoginService';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';
import { ApplicationStateProvider } from '../common/providers/ApplicationStateProvider';
import { useLocaleContext } from '../localization/LocaleContext';
import productInfoService from '../../services/productInfo/productInfoService';
import AppLoading from '../loading/AppLoading';
import CookiesService from '../../services/cookies/CookiesService';

declare global {
  interface Window {
    stateCollection: string;
  }
}

const App = () => {
  const history = useHistory();
  const { setInitialLocale } = useLocaleContext();
  const { setProductInfo, prodInfoAvailable } = useProductInfoProvider();
  const { error, isAuthenticated, signinRedirect, isAuthLoading } = usePingOnlyAuthContext();
  const { formatMessage } = useIntl();
  useLoginService({
    enabled: isAuthenticated,
    onSuccess: (data: any) => {
      window.stateCollection = data?.StateCollection ?? '';
      setInitialLocale(productInfoService.getState()?.culture?.code);
      checkCookies();
    },
    refetchOnWindowFocus: false,
  });
  useDocumentTitle(formatMessage({ id: 'common.title' }), true);

  const checkCookies = useCallback(() => {
    if (CookiesService.get('Vertafore_MAH_AgencyNo')) setProductInfo();
    else history.push('/Login/SelectAgency');
  }, [history, setProductInfo]);

  useEffect(() => {
    if (!isAuthLoading && !isAuthenticated && !error) {
      signinRedirect();
    }
  }, [error, isAuthLoading, isAuthenticated, signinRedirect]);

  return !isAuthenticated || !prodInfoAvailable ? (
    <AppLoading />
  ) : (
    <AppShell name="MBH">
      <CommunicationServiceProvider>
        <ApplicationStateProvider>
          <TopNav />
          <LockProvider>
            <EditProvider>
              <MainWindow />
            </EditProvider>
          </LockProvider>
        </ApplicationStateProvider>
      </CommunicationServiceProvider>
    </AppShell>
  );
};

export default App;
