import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import * as fieldNames from '../fieldNames';
import { EmailValidationSchema } from './EmailValidationSchema';
import { PhoneValidationSchema } from './PhoneValidationSchema';
import { SecondaryContactValidationSchema } from './SecondaryContactValidationSchema';

const customerValidationSchema = (formatMessage, dropdowns: CustomerDropdowns) =>
  Yup.object().shape({
    [`${fieldNames.TYPE_OF_BUSINESS}`]: Yup.string()
      .trim()
      // Only present so that YUP doesn't throw an exception. The value can never be null. That why there is no text.
      .required(),
    [`${fieldNames.CUSTOMER_TYPE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.CUSTOMER_TYPE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.LANGUAGE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.DIVISION}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.DEPARTMENT}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.FIRST_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.LAST_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.MIDDLE_NAME}`]: Yup.string(),
    [`${fieldNames.PREFIX}`]: Yup.string(),
    [`${fieldNames.DOB}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string()
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        })
        .nullable(),
    }),
    [`${fieldNames.MARITAL_STATUS}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().nullable(),
    }),
    [`${fieldNames.FIRM_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'C',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.FIRM_DBA}`]: Yup.string(),
    [`${fieldNames.PERSONAL_INFORMATION_CONSENT}`]: Yup.boolean().nullable(true),
    [`${fieldNames.CONSENT_FOR_OTHER_USE}`]: Yup.boolean().nullable(true),
    [`${fieldNames.DATE_OF_CONSENT}`]: Yup.string()
      .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
        if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

        return true;
      })
      .when(`${fieldNames.PERSONAL_INFORMATION_CONSENT}`, {
        is: (value) => value !== 'null' && value !== null,
        then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
        otherwise: Yup.string(),
      }),
    [`${fieldNames.CUSTOMER_CLASS1}`]: dropdowns.MandatoryCustomerClass.includes(1)
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : // If CustomerClass1 is undefined, leave it as undefined instead of casting it to {}
        Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS2}`]: dropdowns.MandatoryCustomerClass.includes(2)
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : // If CustomerClass2 is undefined, leave it as undefined instead of casting it to {}
        Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS3}`]: dropdowns.MandatoryCustomerClass.includes(3)
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : // If CustomerClass3 is undefined, leave it as undefined instead of casting it to {}
        Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS4}`]: dropdowns.MandatoryCustomerClass.includes(4)
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : // If CustomerClass4 is undefined, leave it as undefined instead of casting it to {}
        Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.POSTAL_CODE}`]: Yup.string()
      .required(formatMessage({ id: 'common.validation.required' }))
      .matches(
        /^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/,
        formatMessage({ id: 'common.validation.invalidPostalCodeFormat' })
      ),
    [`${fieldNames.CITY}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.PROVINCE_CODE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ADDRESS_LINE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ADDRESS_LINE2}`]: Yup.string(),
    [`${fieldNames.ACCOUNTEXEC1}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ACCOUNTREP1}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ACCOUNTEXEC2}`]: Yup.string().nullable(true),
    [`${fieldNames.ACCOUNTREP2}`]: Yup.string().nullable(true),
    [`${fieldNames.EMAILS}`]: Yup.array().of(EmailValidationSchema(formatMessage)),
    [`${fieldNames.PHONES}`]: Yup.array().of(PhoneValidationSchema(formatMessage)),
    [`${fieldNames.PREFERED_CONTACT_METHOD}`]: Yup.string(),
    [`${fieldNames.SECONDARY_CUSTOMER_CONTACT}`]:
      SecondaryContactValidationSchema(formatMessage).nullable(true),
    [`${fieldNames.SUB_BROKER}`]: Yup.string().when(`${fieldNames.CUSTOMER_TYPE}`, {
      is: 'B',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
  });

export default customerValidationSchema;
