import { useQuery } from 'react-query';
import { ToDoListOptionsDomainData } from '../../../../models/ToDo';
import TodoListWorkgroupService from '../../../../services/todoListWorkgroup/TodoListWorkgroupService';

export const useToDoListWorkgroupOptionsDomainData = (
  onSuccess: (data: ToDoListOptionsDomainData) => void
) => {
  const toDoListWorkgroupOptionsDomainData = useQuery(
    'toDoListWorkgroupOptionsDomainData',
    () => TodoListWorkgroupService.getOptionsDomainData(),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );

  return toDoListWorkgroupOptionsDomainData;
};
