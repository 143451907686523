import { DataTableColumn } from '../../data-table/DataTableColumn';

// only derive your custom columns from this class if you will be overriding getValue
// because you will no longer be able to call the parent's getValue implementation
// since it's an arrow function and TS will not allow to call arrow functions on super.
// this will throw error --> TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
export abstract class DataTableColumnWithResolvableValue<T> extends DataTableColumn<T> {
  constructor({
    ...values
  }: Partial<DataTableColumnWithResolvableValue<T>> & { key: DataTableColumn<T>['key'] }) {
    super(values);
  }

  resolveValue(row: T): string | number {
    const key = this.key as keyof T;

    if (typeof row === 'object' && String(key) in row) {
      return row[key] as unknown as string | number;
    }

    return row as unknown as string | number;
  }
}
