import { Button, ButtonGroup, Modal, Typography } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onClose: () => void;
  onChangeStatus: () => void;
}

const ChangeStatusModal = ({ onClose, onChangeStatus }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      data-testid="change-status-confirmation"
      closable={true}
      variant="default"
      onClose={onClose}
      footer={
        <ButtonGroup endAlign={true}>
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onChangeStatus();
              onClose();
            }}
          >
            <FormattedMessage id="common.button.ok" />
          </Button>
        </ButtonGroup>
      }
      title={formatMessage({ id: 'dashboard.panel.common.changeSuspenseStatus.title' })}
    >
      <Typography variant="body">
        <FormattedMessage id="dashboard.panel.common.changeSuspenseStatus.body" />
      </Typography>
    </Modal>
  );
};

export default ChangeStatusModal;
