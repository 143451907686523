export const errors = {
  nullPayload: 'Smartflow: payload cannot be null when there is a required field',
  emptyPayload:
    'Smartflow: business objects on payload cannot be empty when launching form which needs data',
  itemMissing: 'Smartflow: unable to build launch xml; item missing from payload',
  missingItem: 'Smartflow: unable to build launch xml; item missing from payload: ',
  missingBOId: 'Smartflow: boId not returned from winform',
  missingOutput: 'Smartflow: winform missing requested output',
  incorrectDefinition: 'Smartflow: webform definiton is incorrect for requested output',
  missingUrl1: 'Smartflow: webform input paramters relative url:',
  missingUrl2: ' or url Input:',
  missingUrl3: ' is missing',
};
