import basePSSRequests from '../../api/basePSSRequests';
import { LogFormDropdowns } from '../../models/LogFormDropdowns';

// base url
const baseURL = '/logform/getdropdowns';

// exports
const LogFormDropdownsServices = {
  getDropdowns: (): Promise<LogFormDropdowns> =>
    basePSSRequests.get(baseURL).then((response) => response),
};

export default LogFormDropdownsServices;
