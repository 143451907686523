import { Flex, SearchField, TopNavSearchContainer } from 'alloy-foundation';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from '../common/hooks/useRouter';

const TopNavSearch = () => {
  const { history } = useRouter();
  const searchFieldRef = useRef(null);
  const [value, setValue] = useState('');
  const { formatMessage } = useIntl();

  const blurSearch = () => {
    return searchFieldRef.current?.blur();
  };

  return (
    <TopNavSearchContainer>
      <Flex width="314px">
        <SearchField
          ref={searchFieldRef}
          buttonAriaLabel="Search"
          id="alloy-search-field"
          placeholder={formatMessage({ id: 'customer.search.placeholder' })}
          onSubmit={() => {
            // % get convert twice to %2525 instead of %25 (once by the encodeURIComponent and once manually).
            // This is due to react-router-dom V5 expecting all URL to have decoded symbols,
            // even thought this is breaking some of its functionality (any symbols in the URL break the go back feature).
            // Solution is to update to react-router-dom but this isn't an easy fix (at least 2-3days of dev. + full retest of the app).
            // Here is the documented issue : https://github.com/remix-run/history/issues/505. It has been resolved in the history package 5.0+, but
            // our version of react-router-dom needs the history package V4.
            if (value) {
              history.push(`/search/${encodeURIComponent(value.replace('%', '%25'))}`);
              blurSearch();
              setValue(value);
            }
          }}
          value={value}
          onChange={(arg) => setValue(arg)}
          inputDebouncingMs={500}
        />
      </Flex>
    </TopNavSearchContainer>
  );
};

export default TopNavSearch;
