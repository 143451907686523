import { AxiosRequestConfig } from 'axios';
import baseRequests from './baseRequests';

const baseUrl = 'api/gateway/smartflow/'; // Duplicate this file and replate baseUrl with this one.

// baseRequests
const baseSmartflowRequests = {
  get: (url: string, config?: AxiosRequestConfig) => baseRequests.get(baseUrl + url, config),
  post: (url: string, body: any, config?: AxiosRequestConfig) =>
    baseRequests.post(baseUrl + url, body, config),
  put: (url: string, body: any, config?: AxiosRequestConfig<any>) =>
    baseRequests.put(baseUrl + url, body, config),
  delete: (url: string) => baseRequests.delete(baseUrl + url),
};

export default baseSmartflowRequests;
