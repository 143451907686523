import {
  Column,
  FormField,
  RadioButton,
  RadioButtonController,
  Row,
  SectionContainer,
  Spacer,
  Typography,
} from 'alloy-foundation';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Locale } from '../../localization/Locale';
import { useLocaleContext } from '../../localization/LocaleContext';
import ChangeLanguageModal from './ChangeLanguageModal';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

const MyProfileGeneralInfo = () => {
  const { locale, setCulture } = useLocaleContext();
  const { productInfo } = useProductInfoProvider();
  const { formatMessage } = useIntl();
  const [langModalVisible, setLangModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(locale);

  return (
    <SectionContainer headerText={formatMessage({ id: 'myProfile.generalInfo.header' })}>
      <>
        {langModalVisible ? (
          <ChangeLanguageModal
            onCancel={() => {
              setSelectedLanguage(locale);
              setLangModalVisible(false);
            }}
            onChangeLanguage={() => {
              setCulture(selectedLanguage);
              setLangModalVisible(false);
            }}
          />
        ) : null}
        <Row>
          <Column md={12}>
            <Spacer pb="medium">
              <Typography variant="h4">
                <FormattedMessage id="myProfile.basicInfo.header" />
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column xs={12} sm={12} md={6}>
            <Spacer mb="medium">
              <FormField label={formatMessage({ id: 'myProfile.generalInfo.displayName.header' })}>
                <Typography variant="body">{productInfo?.UserFullName}</Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column xs={12} sm={12} md={6}>
            <Spacer mb="medium">
              <FormField label={formatMessage({ id: 'myProfile.generalInfo.email.header' })}>
                <Typography variant="body">{productInfo?.UserEmail}</Typography>
              </FormField>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <Spacer pb="medium">
              <Typography variant="h4">
                <FormattedMessage id="myProfile.generalInfo.language.header" />
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={12}>
            <RadioButtonController
              value={selectedLanguage}
              name="language"
              label=""
              onChange={(value) => {
                setSelectedLanguage(value);
                setLangModalVisible(true);
              }}
            >
              <RadioButton
                data-testid="english-option"
                label={formatMessage({ id: 'myProfile.generalInfo.language.english' })}
                value={Locale.EnCa}
              />
              <RadioButton
                data-testid="french-option"
                label={formatMessage({ id: 'myProfile.generalInfo.language.french' })}
                value={Locale.FrCa}
              />
            </RadioButtonController>
          </Column>
        </Row>
      </>
    </SectionContainer>
  );
};

export default MyProfileGeneralInfo;
