import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Flex, Spacer, Typography } from 'alloy-foundation';

const CustomerBottomBar = ({
  canSave,
  hasErrors = false,
  handleSave,
  handleCancel,
  saveLabel,
}: {
  canSave: boolean;
  hasErrors?: boolean;
  handleSave: (data) => void;
  handleCancel: () => void;
  saveLabel: string;
}) => {
  return (
    <Flex justifyContent="space-between">
      <Button variant="tertiary" onClick={handleCancel}>
        <FormattedMessage id="common.button.cancel" />
      </Button>
      {hasErrors && (
        <Spacer m="small">
          <Typography align="center" color="error">
            <FormattedMessage id="customer.bottomBar.errorMessage" />
          </Typography>
        </Spacer>
      )}
      <Button id="save-button" variant="primary" onClick={handleSave} disabled={!canSave}>
        {saveLabel ?? <FormattedMessage id="common.button.save" />}
      </Button>
    </Flex>
  );
};

export default CustomerBottomBar;
