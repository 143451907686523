import baseRequests from '../../api/baseRequests';
import { FeatureFlagResponse } from '../../models/FeatureFlagResponse';
const baseURL = 'api/FeatureFlag';

const FeatureFlagService = {
  GetFlagValue: (flagName: string): Promise<FeatureFlagResponse> =>
    baseRequests.get(`${baseURL}/${flagName}`).then((response) => response),
};

export default FeatureFlagService;
