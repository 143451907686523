import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { Manager, AssignedEmployee } from '../../models/Manager';
import { ReassignEmployeeArr } from '../../models/smartflow/ReassignEmployees';
import { SelectedAssigneesModel, WorkItemRestModel } from '../../models/WorkItemRestModel';
import { ReassignWorkItemPayload } from '../../models/WorkQueue';
import { EmpCodeConverter } from './EmpCodeConverter';

const baseURL = 'sig';

const generatePayload = (
  selectedManager: Manager,
  assigningEmp: Manager[],
  agencyNumber: string
) => {
  const empList = [];
  assigningEmp.forEach((emp) => {
    empList.push({
      VimID: emp.VimId,
      Name: emp.DisplayName,
      EmpCode: emp.EmployeeCode,
      DeleteAccess: true,
      ReassignAccess: true,
      Active: true,
      ActiveStatus: 'A',
      AgencyNo: agencyNumber,
    });
  });
  return {
    employees: empList,
    VimID: selectedManager.VimId,
    Name: selectedManager.DisplayName,
    EmpCode: selectedManager.EmployeeCode,
    DeleteAccess: true,
    ReassignAccess: true,
    Active: true,
    ActiveStatus: 'A',
    AgencyNo: agencyNumber,
    LoggedInUserVimID: selectedManager.VimId,
    LoggedInUseName: selectedManager.DisplayName,
  };
};
const generateReassignPayload = (
  selectedWorkItems: WorkItemRestModel[],
  selectedAssignees: SelectedAssigneesModel[]
) => {
  const reassignList = [] as ReassignWorkItemPayload[];
  selectedWorkItems.forEach((item) => {
    const assignee = selectedAssignees.find((i) => i.itemId === item.Id);
    reassignList.push({
      WorkItemAssignee: {
        CustomerId: 0,
        CustomerName: null,
        DisplayName: assignee?.label ?? '',
        Id: assignee?.value,
        IsCustomerAdmin: false,
        IsVapAdmin: false,
        LongId: 0,
        Name: null,
        Type: 0,
      },
      WorkItemId: item.Id,
    });
  });
  return reassignList;
};
const ManagerService = {
  list: (): Promise<Manager[]> =>
    baseSmartflowRequests.get(`${baseURL}/allactivevssoemployees`).then((response) => response),
  listAssignedEmp: (vimId, empCode): Promise<AssignedEmployee[]> => {
    const decimalEmp = EmpCodeConverter(empCode);
    return baseSmartflowRequests
      .get(`configuration/manager/${vimId}/${decimalEmp}`)
      .then((response) => response);
  },
  assign: (selectedManager: Manager, assigningEmp: Manager[], agencyNumber: string) => {
    const payload = generatePayload(selectedManager, assigningEmp, agencyNumber);
    baseSmartflowRequests.post('configuration/manager', payload);
  },
  getWorkItemsForSupervisor(): Promise<WorkItemRestModel[]> {
    const result = baseSmartflowRequests.get('workitems/manager').then((response) => response);
    return result;
  },
  reassignWorkItem: (
    selectedWorkItems: WorkItemRestModel[],
    selectedAssignees: SelectedAssigneesModel[]
  ): Promise<WorkItemRestModel[]> => {
    const payload = generateReassignPayload(selectedWorkItems, selectedAssignees);
    const result = baseSmartflowRequests.post('process/assignees/update', payload);
    return result;
  },
  getReassignEmployees(customerId: string): Promise<ReassignEmployeeArr> {
    const result = baseSmartflowRequests
      .get(`sig/Customers/${customerId}/AssignedEmployees`)
      .then((response) => {
        return {
          CustomerId: customerId,
          ReassignEmpArr: response,
        } as ReassignEmployeeArr;
      });
    return result;
  },
};

export default ManagerService;
