import React, { memo, useState } from 'react';
import { Column, Flex, Link, Row, Typography } from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import { DashboardPanel } from '../DashboardPanel';
import { ExpiringPolicyItem } from '../../../../models/ExpiringPolicies';
import ExpiringPoliciesListDashboardOptions from './ExpiringPoliciesListDashboardOptions';
import { DollarColumn } from '../../../common/custom-datatable-columns/DollarColumn';
import { useExpiringPoliciesData } from './useExpiringPoliciesData';
import { Locale } from '../../../localization/Locale';
import { useRefetch } from '../../../common/hooks/useRefetch';
import { StringColumn } from '../../../common/custom-datatable-columns/StringColumn';
import { DateColumn } from '../../../common/custom-datatable-columns/DateColumn';
import { useApplicationState } from '../../../common/providers/ApplicationStateProvider';
import { ExpiringPoliciesSettings } from '../../../../models/ApplicationState/ExpiringPoliciesSettings';
import { useCommunicationService } from '../../../communicationService/CommunicationServiceProvider';
import InstanceSelection from '../../../quickNav/instanceSelection/InstanceSelection';
import { QuickNavAction } from '../../../../models/enums/QuickNavActions';
import { PolicyReduced } from '../../../../models/PolicySummary';
import { convertExpiringPolicyToPolicyReduced } from '../../../common/utils';
import { useRouter } from '../../../common/hooks/useRouter';
import { useProductInfoProvider } from '../../../productInfo/ProductInfoProvider';

interface Props {
  expiringPoliciesSettings: ExpiringPoliciesSettings;
  saveExpiringPoliciesSettings: (p: ExpiringPoliciesSettings) => void;
}

const ExpiringPoliciesDashboardPanel = () => {
  const { expiringPoliciesSettings, setExpiringPoliciesSettings } = useApplicationState();
  return (
    <InnerExpiringPoliciesDashboardPanel
      expiringPoliciesSettings={expiringPoliciesSettings}
      saveExpiringPoliciesSettings={setExpiringPoliciesSettings}
    />
  );
};

/** InnerExpiringPoliciesDashboardPanel is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerExpiringPoliciesDashboardPanel = memo(
  ({ expiringPoliciesSettings, saveExpiringPoliciesSettings }: Props) => {
    const { formatMessage, locale } = useIntl();
    const [instanceSelectionVisible, setInstanceSelectionVisible] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [selectedPolicy, setPolicy] = useState<PolicyReduced>(null);
    const { history } = useRouter();
    const { getEmpCode } = useProductInfoProvider();

    // expiring policies dashboard should default to 'Rep' view
    const defaultFilter = 'Rep';
    const mapFilterToLabel = (filter: string) => {
      switch (filter) {
        case 'Exec':
          return formatMessage({
            id: 'dashboard.panel.expiringPoliciesFilter.executive',
          });
        case 'Rep':
          return formatMessage({
            id: 'dashboard.panel.expiringPoliciesFilter.representative',
          });
        default:
          return '';
      }
    };

    const mapLabelToFilter = (label: string) => {
      switch (label) {
        case formatMessage({
          id: 'dashboard.panel.expiringPoliciesFilter.executive',
        }):
          return 'Exec';
        case formatMessage({
          id: 'dashboard.panel.expiringPoliciesFilter.representative',
        }):
          return 'Rep';
        default:
          return '';
      }
    };

    // filters
    // const { productInfo } = useProductInfoProvider();
    const empCode = getEmpCode();
    const filterOptions = [
      {
        label: mapFilterToLabel('Rep'),
        value: empCode,
      },
      {
        label: mapFilterToLabel('Exec'),
        value: empCode,
      },
    ];

    const [initialFilter] = useState(
      filterOptions.find(
        (p) => p.label === mapFilterToLabel(expiringPoliciesSettings?.filter ?? defaultFilter)
      ) ?? null
    );

    const { fetch, refetch } = useRefetch();
    const { showForm } = useCommunicationService();

    const [expiringPoliciesListData, setExpiringPoliciesListData] = useState<ExpiringPolicyItem[]>(
      []
    );

    const filterData = (filter: string, data: ExpiringPolicyItem[]) => {
      switch (filter) {
        case 'Exec':
          return data.filter((x) => x.ExecutiveCode === empCode) ?? [];
        case 'Rep':
          return data.filter((x) => x.RepresentativeCode === empCode) ?? [];
        default:
          return data;
      }
    };

    const expiringPoliciesList = useExpiringPoliciesData(
      expiringPoliciesSettings?.customFilter,
      fetch,
      (data: ExpiringPolicyItem[]) =>
        setExpiringPoliciesListData(
          filterData(expiringPoliciesSettings?.filter ?? defaultFilter, data)
        )
    );

    // columns
    const columns = [
      new StringColumn<ExpiringPolicyItem>({
        key: 'CustomerName',
        flex: 2,
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.customerName.header',
        }),
        Cell: function ExpiringPolicyCell({ row: expiringPolicy }) {
          // example usage - should open customer summary/test
          return (
            <>
              <Link
                small
                onClick={() => {
                  history.push(`/customers/${expiringPolicy.CustomerId}`);
                }}
              >
                {expiringPolicy.CustomerName}
              </Link>
            </>
          );
        },
      }),
      new StringColumn<ExpiringPolicyItem>({
        key: 'PolicyNo',
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.policyNo.header',
        }),
        getValue: (row) => row.PolicyNo ?? '',
        flex: 4,
        Cell: function RepExecCell({ row: expiringPolicy }) {
          return (
            <>
              <Row>
                <Column>
                  <Link
                    small
                    onClick={() =>
                      showForm('PolicyBrowse', {
                        PolId: expiringPolicy.PolicyId,
                        EndEffDate:
                          expiringPolicy.MaxEffectiveDatePolicyTransaction.toString().substring(
                            0,
                            19
                          ),
                      })
                    }
                  >
                    {expiringPolicy.PolicyNo}
                  </Link>
                  <Flex>
                    {expiringPolicy.PolicyTypeLineOfBusiness}:{' '}
                    {expiringPolicy.TypeOfBusinessDisplay}
                  </Flex>
                </Column>
              </Row>
            </>
          );
        },
      }),
      new DateColumn<ExpiringPolicyItem>({
        key: 'PolicyExpiryDate',
        flex: 2,
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.expirationDate.header',
        }),
      }),
      new StringColumn<ExpiringPolicyItem>({
        key: 'CompanyName',
        flex: 2,
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.carrierName.header',
        }),
      }),
      new DollarColumn<ExpiringPolicyItem>({
        key: 'TotalCost',
        flex: 2,
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.fullTermPremium.header',
        }),
        locale: locale === 'en-CA' ? Locale.EnCa : Locale.FrCa,
        getValue: (row) => row.TotalCost ?? '',
      }),
      new StringColumn<ExpiringPolicyItem>({
        key: 'RepresentativeName',
        header: formatMessage({
          id: 'dashboard.panel.expiringPoliciesList.columns.repExec.header',
        }),
        flex: 4,
        Cell: function RepExecCell({ row: expiringPolicy }) {
          return (
            <>
              <Row>
                <Column>
                  <Flex>{expiringPolicy.RepresentativeName}</Flex>
                  <Flex>{expiringPolicy.ExecutiveName}</Flex>
                </Column>
              </Row>
            </>
          );
        },
      }),
    ];

    // csvExport
    const csvColumnMapper = (item: ExpiringPolicyItem) => {
      return {
        'Customer Name': item.CustomerName,
        'Policy Number': item.PolicyNo,
        'Business Type': item.TypeOfBusinessDisplay,
        'Policy Type': item.PolicyTypeLineOfBusiness,
        Expiration: item.PolicyExpiryDate,
        'Parent Company': item.CompanyName,
        'Writing Company': item.WritingCompanyName,
        'Full Term Premium': item.TotalCost,
        Exec: item.ExecutiveName,
        Rep: item.RepresentativeName,
      };
    };

    const openQuicknavInstanceSelection = (row: ExpiringPolicyItem) => {
      setPolicy(convertExpiringPolicyToPolicyReduced(row));
      setCustomerName(row.CustomerName);
      setInstanceSelectionVisible(true);
    };

    // render
    return (
      <>
        {instanceSelectionVisible && (
          <InstanceSelection
            onClose={() => setInstanceSelectionVisible(false)}
            quickNavAction={QuickNavAction.PolicyRenewal}
            customerName={customerName}
            policy={selectedPolicy}
          />
        )}
        <DashboardPanel
          title={formatMessage({
            id: 'dashboard.panel.expiringPoliciesList.title',
          })}
          // href for panel - to scroll to panel when clicking on corresponding tile
          id="ExpiringPoliciesList"
          // //
          data-testid="expiringPolicieslist-panel"
          columns={columns}
          data={expiringPoliciesListData}
          isLoading={expiringPoliciesList.isLoading}
          initialSortedColumnKey="PolicyExpiryDate"
          initialSortDirection="asc"
          hasSelectedRowAction={true}
          unselectedRowActionLabel={formatMessage({
            id: 'dashboard.panel.expiringPoliciesList.renew',
          })}
          selectedRowActionLabelFn={(row) =>
            formatMessage({ id: 'dashboard.panel.expiringPoliciesList.renew' })
          }
          // example usage - use communication service to send message to SIG
          onSelectedRowActionClicked={openQuicknavInstanceSelection}
          //
          filterPlaceholder={formatMessage({
            id: 'dashboard.panel.expiringPoliciesFilter.placeholder',
          })}
          filterOptions={filterOptions}
          initialFilterOption={initialFilter}
          emptyMessage={
            <Typography variant="disclaimer">
              <FormattedMessage id="dashboard.panel.data.empty" />
            </Typography>
          }
          onFilterChange={(filter) => {
            const currentFilter = mapLabelToFilter(filter?.label);

            saveExpiringPoliciesSettings({
              filter: currentFilter,
              customFilter: expiringPoliciesSettings?.customFilter,
            });
            setExpiringPoliciesListData(filterData(currentFilter, expiringPoliciesList.data ?? []));
          }}
          options={ExpiringPoliciesListDashboardOptions}
          optionsData={expiringPoliciesSettings?.customFilter}
          onOptionsApply={(filter) =>
            saveExpiringPoliciesSettings({
              filter: expiringPoliciesSettings?.filter,
              customFilter: filter,
            })
          }
          onRefresh={() => refetch()}
          exportColumnMapper={csvColumnMapper}
          showClearCustomFilterOnApply={false}
          toolTipText={formatMessage({
            id: 'dashboard.panel.expiringPoliciesList.toolTipText',
          })}
        />
      </>
    );
  }
);

export default ExpiringPoliciesDashboardPanel;
