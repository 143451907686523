import {
  TopNavButtonWithDropdown,
  TopNavDropdown,
  TopNavMenu,
  TopNavMenuLink,
} from 'alloy-foundation';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { IGlobalNavItem } from '../../models/GlobalNavItem';
import { LaunchClientSetup } from './TopNavConstants';
import { useDrawer } from '../drawer/DrawerProvider';

type Props = {
  globalNav: IGlobalNavItem[];
  handleOpenCloseActivity: () => void;
  handleOpenCloseSuspense: () => void;
};

const TopNavActionsSection = ({
  globalNav,
  handleOpenCloseActivity,
  handleOpenCloseSuspense,
}: Props) => {
  const hasNewCustomer = globalNav.some((item) => {
    return item.SubItems.some(({ Context }) => Context.type === LaunchClientSetup);
  });

  const hasNewActivity = globalNav.some((item) =>
    item.SubItems.some(
      ({ Context }) => Context.type === 'ams360WinForm' && Context.reportName === 'Activity'
    )
  );
  const hasNewSuspense = globalNav.some((item) =>
    item.SubItems.some(
      ({ Context }) => Context.type === 'ams360WinForm' && Context.reportName === 'Suspense'
    )
  );
  const { formatMessage } = useIntl();
  const { hideDrawer } = useDrawer();

  return (
    <TopNavMenu>
      <TopNavButtonWithDropdown
        data-testid="actions"
        title={formatMessage({ id: 'dashboard.panel.actions.title' })}
        iconName="topNavCreateNew"
        aria-label="Actions"
        onClick={() => flushSync(() => hideDrawer())}
      />
      <TopNavDropdown alignment="right">
        {hasNewActivity && (
          <TopNavMenuLink data-testid="newActivity" onClick={handleOpenCloseActivity}>
            <FormattedMessage id="topnav.actions.newActivity" />
          </TopNavMenuLink>
        )}
        {hasNewSuspense && (
          <TopNavMenuLink data-testid="newSuspense" onClick={handleOpenCloseSuspense}>
            <FormattedMessage id="topnav.actions.newSuspense" />
          </TopNavMenuLink>
        )}
        {hasNewCustomer ? (
          <TopNavMenuLink data-testid="newCustomer" as={RouterLink} to="/customers/create">
            <FormattedMessage id="topnav.actions.newCustomer" />
          </TopNavMenuLink>
        ) : (
          <React.Fragment />
        )}
      </TopNavDropdown>
    </TopNavMenu>
  );
};

export default TopNavActionsSection;
