import React, { useState, useContext } from 'react';
import { CustomerLock } from '../../../models/CustomerLock';

// default Dashboard view is set here on the context, and the state below.
const LockContext = React.createContext({
  currentLock: null,
  setCurrentLock: (p) => p,
});

export const LockProvider = ({ children }) => {
  const [currentLock, setCurrentLock] = useState<CustomerLock>(null);

  return (
    <LockContext.Provider value={{ currentLock, setCurrentLock }}>{children}</LockContext.Provider>
  );
};

export const useLockProvider = () => {
  const context = useContext(LockContext);
  if (context === undefined) {
    throw new Error('useLockProvider cannot be called from outside of a LockProvider');
  }
  return context;
};
