import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import FeatureFlagService from './FeatureFlagService';
import { FeatureFlagResponse } from '../../models/FeatureFlagResponse';
const enabled = 'ENABLED';
const disabled = 'DISABLED';
export const useFeatureFlagService = (
  flagName: string,
  options?: UseQueryOptions<FeatureFlagResponse, unknown, FeatureFlagResponse, QueryKey>
) => {
  const res = useQuery<FeatureFlagResponse>(
    'FeatureFlagStatus',
    () => FeatureFlagService.GetFlagValue(flagName),
    options
  );
  if (!res.isLoading)
    switch (res?.data?.Status) {
      case enabled:
        return true;
      case disabled:
        return false;
      default:
        return false;
    }
};
