import * as Yup from 'yup';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import * as fieldNames from '../fieldNames';
import { EmailValidationSchema } from './EmailValidationSchema';
import { PhoneValidationSchema } from './PhoneValidationSchema';

const ContactInformationSchema = (formatMessage, dropdowns: CustomerDropdowns) =>
  Yup.object().shape({
    [`${fieldNames.POSTAL_CODE}`]: Yup.string()
      .required(formatMessage({ id: 'common.validation.required' }))
      .matches(
        /^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/,
        formatMessage({ id: 'common.validation.invalidPostalCodeFormat' })
      ),
    [`${fieldNames.CITY}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.PROVINCE_CODE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ADDRESS_LINE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ADDRESS_LINE2}`]: Yup.string().nullable(),
    [`${fieldNames.EMAILS}`]: Yup.array().of(
      EmailValidationSchema(formatMessage, false, dropdowns)
    ),
    [`${fieldNames.PHONES}`]: Yup.array().of(
      PhoneValidationSchema(formatMessage, false, dropdowns)
    ),
    [`${fieldNames.PREFERED_CONTACT_METHOD}`]: Yup.string().nullable(),
  });

export default ContactInformationSchema;
