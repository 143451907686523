import React, { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonGroup, Button, Modal, DropdownSingleSelectSearch } from 'alloy-foundation';
import { IncompleteWorkItemReduced } from '../../../models/WorkQueue';
import { availableAssignee, useReassignItem } from './useWorkQueue';

interface ReassignModuleProps {
  handleClose?: VoidFunction;
  handleToggle?: VoidFunction;
  data: IncompleteWorkItemReduced;
  options: availableAssignee;
  VimUserId?: string;
  setProcessId?: Dispatch<SetStateAction<string>>;
}

const ReassignWorkItemModal = ({
  handleClose,
  handleToggle,
  data,
  options,
  VimUserId,
  setProcessId,
}: ReassignModuleProps) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [buttonIsInvalid, setButtonInvalidity] = useState(true);
  const { formatMessage } = useIntl();
  const showFooter = true;
  const { mutate: reassignItem } = useReassignItem();

  const handleReassign = () => {
    const variables = {
      Id: data.Id,
      newAssignee: selectedItem.value,
      CustomerId: data.CustomerId,
    };
    reassignItem(variables);
    if (typeof setProcessId === 'function') {
      setProcessId(undefined);
    }
    handleClose();
  };

  return (
    <Modal
      data-testid="basic-modal"
      closable={true}
      small={false}
      large={false}
      variant="default"
      fluid={false}
      footer={
        showFooter ? (
          <ButtonGroup endAlign>
            <Button variant="tertiary" onClick={handleToggle}>
              {formatMessage({ id: 'common.button.cancel' })}
            </Button>
            <Button
              data-testid="pressReassign"
              onClick={handleReassign}
              variant="primary"
              disabled={buttonIsInvalid}
            >
              {formatMessage({ id: 'drawer.workQueue.actions.reassign' })}
            </Button>
          </ButtonGroup>
        ) : null
      }
      subtitle={
        showFooter ? formatMessage({ id: 'drawer.workQueue.actions.reassign.subtitle' }) : undefined
      }
      title={
        showFooter ? formatMessage({ id: 'drawer.workQueue.actions.reassign.title' }) : undefined
      }
      onClose={handleClose}
    >
      <div data-testid="SingleSelectSearch">
        <DropdownSingleSelectSearch
          label={formatMessage({ id: 'drawer.workQueue.actions.reassign.to' })}
          options={options?.data?.filter((assignee) => assignee.value.Id !== VimUserId)}
          placeholder={formatMessage({ id: 'common.search.placeholder' })}
          onChange={(item) => {
            setSelectedItem(item);
            item ? setButtonInvalidity(false) : setButtonInvalidity(true);
          }}
        />
      </div>
    </Modal>
  );
};

export default ReassignWorkItemModal;
