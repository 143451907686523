import React, { useContext, useMemo, useCallback } from 'react';
import { noop } from 'lodash';
import CommunicationService from '../../services/commSvc/CommunicationService';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';

interface ICommunicationServiceContext {
  /** Send message to display a specific SIG form, with arguments */
  showForm?: (formName: string, args: Record<string, unknown>) => void;
}

const CommunicationServiceContext = React.createContext<ICommunicationServiceContext>({
  showForm: noop,
});

export const CommunicationServiceProvider = ({ children }) => {
  const { getVimUserId, getAgencyNumber } = useProductInfoProvider();

  const getEndpointId = useCallback(() => {
    return `${getAgencyNumber()}-${getVimUserId()}`;
  }, [getVimUserId, getAgencyNumber]);

  const showForm = useCallback(
    (formName: string, args: Record<string, unknown>) => {
      CommunicationService.showForm(getEndpointId(), formName, args);
    },
    [getEndpointId]
  );

  const memoValue = useMemo(() => {
    return {
      showForm,
    };
  }, [showForm]);

  return (
    <CommunicationServiceContext.Provider value={memoValue}>
      {children}
    </CommunicationServiceContext.Provider>
  );
};

export const useCommunicationService = () => {
  const context = useContext(CommunicationServiceContext);
  if (context === undefined) {
    throw new Error(
      'useCommunicationService cannot be called from outside of a CommunicationServiceProvider'
    );
  }
  return context;
};
