import React from 'react';
import PropTypes from 'prop-types';
import { Spacer } from 'alloy-foundation';
import useStyles from 'react-with-styles/lib/hooks/useStyles';

const Divider = ({
  ml,
  mr,
  pb,
  pt,
  lineStyle = 'solid',
  variant = 'light',
  orientation = 'horizontal',
}) => {
  const isVertical = orientation === 'vertical';

  const stylesFn = ({ vertaforeAlloy: { color, spacing } }) => ({
    divmargin: {
      ...color,
      marginLeft: spacing[ml || 'large'],
      marginRight: spacing[mr || 'large'],
    },

    divhoriz: {
      borderBottom: `0.0625rem ${lineStyle} ${
        variant === 'dark' ? color.core.grayMedium : color.core.grayLight
      }`,
      borderTop: 'none',
    },

    divvert: {
      borderBottom: `0.0625rem ${lineStyle} ${
        variant === 'dark' ? color.core.grayMedium : color.core.grayLight
      }`,
      borderTop: 'none',
    },
  });

  const { css, styles } = useStyles({ stylesFn });

  return (
    <Spacer pt={pt || 'xsmall'} pb={pb || 'auto'}>
      <hr
        {...css(styles.divmargin, isVertical && styles.divvert, !isVertical && styles.divhoriz)}
        data-testid="layout-divider"
      />
    </Spacer>
  );
};

Divider.propTypes = {
  ml: PropTypes.string,
  mr: PropTypes.string,
  pb: PropTypes.string,
  pt: PropTypes.string,
  /** border/line style (default is solid) */
  lineStyle: PropTypes.oneOf(['solid', 'dotted', 'dashed']),
  /** enable variant (default is light) */
  variant: PropTypes.oneOf(['light', 'dark']),
  /** orientation of the line (default is horizontal) */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
};

export default Divider;
