import { Button, ButtonGroup, Input, Modal } from 'alloy-foundation';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  range: string;
  onClose: () => void;
  onSelect: (value: string) => void;
};

const CivicNumberModal = ({ range, onClose, onSelect }: Props) => {
  const [civic, setCivic] = useState('');
  const { formatMessage } = useIntl();

  return (
    <Modal
      small={true}
      closable={true}
      title={formatMessage({ id: 'customer.details.currentAddress.civicNumber' })}
      onClose={onClose}
      footer={
        <ButtonGroup endAlign={true}>
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button onClick={() => onSelect(civic)} variant="primary">
            <FormattedMessage id="common.button.ok" />
          </Button>
        </ButtonGroup>
      }
    >
      <Input
        value={civic}
        onChange={(value) => setCivic(value)}
        name="civic"
        label={`${formatMessage({
          id: 'customer.details.currentAddress.civicNumber.message',
        })} (${range})`}
      />
    </Modal>
  );
};

export default CivicNumberModal;
