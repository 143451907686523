import React from 'react';
import { useIntl } from 'react-intl';
import { Spacer } from 'alloy-foundation';
import { useFormikContext } from 'formik';
import Divider from '../../../common/divider/Divider';
import Header from '../../../common/header/Header';
import EmailForm from './EmailForms';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import * as FieldsName from '../../fieldNames';
import * as EmailFieldsName from './fieldNames';
import SingleLineFormListArray from '../../../common/forms/singleLineFormListArray/SingleLineFormListArray';

const EmailsInformations = ({
  parentNamespace,
  dropdowns,
  hideHeader,
  disableMandatoryContactMethod,
}: {
  parentNamespace?: string;
  dropdowns: CustomerDropdowns;
  hideHeader?: boolean;
  disableMandatoryContactMethod?: boolean;
}) => {
  const formikContext = useFormikContext();
  const { formatMessage } = useIntl();
  const prefix = parentNamespace ? `${parentNamespace}.` : '';

  const phrases = {
    addButton: `${formatMessage({ id: 'customer.contactMethods.addEmail' })}`,
    deleteAriaLabel: `${formatMessage({ id: 'customer.contactMethods.deleteEmail' })}`,
    confirmDeleteModal: {
      continueText: 'Delete',
      cancelText: 'Cancel',
      title: 'Are you sure you want to delete this item?',
      bodyText:
        'This action cannot be undone, and you will lose any information related to this item.',
    },
  };

  const canDelete = (index: number) => {
    const type = formikContext.getFieldProps(
      `${FieldsName.EMAILS}[${index}].${EmailFieldsName.EMAIL_TYPE}`
    ).value as string;
    return disableMandatoryContactMethod ?? !dropdowns.MandatoryContactMethods.includes(type);
  };

  return (
    <React.Fragment>
      {!hideHeader && (
        <Header title={formatMessage({ id: 'customer.contactMethods.emailHeader' })} />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <SingleLineFormListArray
          listItemDefault={{ type: '', emailAddress: '', notApplicable: false }}
          addButtonPosition="bottom"
          renderRow={({ namespace, index }) => {
            return (
              <EmailForm
                namespace={namespace}
                index={index}
                dropdowns={dropdowns}
                disableMandatory={disableMandatoryContactMethod}
              />
            );
          }}
          namespace={prefix + FieldsName.EMAILS}
          canDelete={canDelete}
          confirmDelete={false}
          phrases={phrases}
          maxItems={3}
        />
      </Spacer>
      {!hideHeader && <Divider pt="none" pb="none" ml="none" mr="none" />}
    </React.Fragment>
  );
};

export default EmailsInformations;
