import { Typography } from 'alloy-foundation';
import { DataTableColumn, CellProps, HighlightFilterValue } from '../../data-table/DataTableColumn';
import { DataTableColumnWithResolvableValue } from './DataTableColumnWithResolvableValue';

export type SortOptions = 'case-sensitive' | 'case-insensitive' | 'cs' | 'ci';

export class StringColumn<T> extends DataTableColumnWithResolvableValue<T> {
  sortMode?: SortOptions;
  noWrap?: boolean;

  constructor({
    noWrap = false,
    sortMode = 'case-insensitive',
    Cell,
    getSortValue,
    ...values
  }: Partial<StringColumn<T>> & { key: DataTableColumn<T>['key'] }) {
    super(values);
    Object.assign(this, { noWrap });
    Object.assign(this, { sortMode });

    if (Cell) Object.assign(this, { Cell });

    if (getSortValue) Object.assign(this, { getSortValue });
  }

  private removeAccents = (str) => {
    // normalize to unicode, which separates accents from characters, and remove accents
    // Weird thing with some getValue code where if the value is null, 0 is return thus causing ?? to fail due to (0 !== void 0) = true
    return (str === 0 ? '' : str ?? '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };

  getSortValue = (row: T) => {
    let value = (this.getValue ? this.getValue(row) : this.resolveValue(row)) as string;
    value = this.removeAccents(value);
    return this.sortMode === 'case-insensitive' || this.sortMode === 'ci'
      ? value?.toLowerCase()
      : value;
  };

  Cell = ({ row, isActive, width }: CellProps<T>) => {
    // If width is undefined and using noWrap = true, we will not render the value.
    // The reason is that, under certain circumstances, it can throw off the width of the whole table.
    // The value will be rendered in a subsequent call once all column widths have been properly computed.
    // However, this will break unit tests which contain a noWrap=true column.
    // This can be fixed by mocking useDimensions and returning a positive width (ie: greater than 0)
    if (width === undefined && this.noWrap) return <></>;

    const value = (this.getValue ? this.getValue(row) : this.resolveValue(row)) as string;
    const highlightedText = <HighlightFilterValue>{value}</HighlightFilterValue>;

    return (
      <>
        {this.noWrap ? (
          <Typography
            variant="bodySm"
            noWrap={true}
            fontWeight={isActive ? 'bold' : 'normal'}
            color={isActive ? 'blue' : 'grayDark'}
          >
            {highlightedText}
          </Typography>
        ) : (
          highlightedText
        )}
      </>
    );
  };
}
