import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Column,
  FormDropdownSingleSelect,
  LoadingIndicator,
  Modal,
  Row,
  Spacer,
  Typography,
  VertaFormik,
} from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import Divider from '../../../common/divider/Divider';
import { DashboardPanelOptionsProps } from '../DashboardPanelOptionsProps';
import { useExpiringPoliciesOptionsData } from './useExpiringPoliciesData';
import {
  ExpiringPolicyCustomFilter,
  DateRange,
  ExpiringPolicyOptionsData,
} from '../../../../models/ExpiringPolicies';
import * as fieldNames from './fieldNames';
import ExpiringPoliciesDashboardOptionsValidationSchema from './ExpiringPoliciesDashboardOptionsValidationSchema';
import LocalizedFormDatePickerInput from '../../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';
import { Handle } from '../../../common/handle';

interface Props {
  values: ExpiringPolicyCustomFilter;
  domainData: DateRange;
}

const USER_DEFINED = 'UD';

const ExpiringPoliciesListDashboardOptionsContent = forwardRef(
  ({ values, domainData }: Props, ref: Ref<{ SubmitForm }>) => {
    const { formatMessage } = useIntl();
    const formikContext = useFormikContext();
    const SubmitForm = () => formikContext.submitForm();

    useImperativeHandle(ref, () => ({ SubmitForm }));

    return (
      <>
        <Row>
          <Column>
            <Divider pt="none" pb="none" ml="none" mr="none" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer paddingTop="small" paddingBottom="small">
              <Typography noWrap={true} variant="h4" color="grayDark">
                <FormattedMessage id="dashboard.panel.expiringPoliciesList.options.showExpiringPolicies" />
                :
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormDropdownSingleSelect
              required
              name={fieldNames.CODE}
              label={formatMessage({
                id: 'dashboard.panel.expiringPoliciesList.options.preDefinedDateRange',
              })}
              onChange={async (innerValues, ...args: any[]) => {
                if (args[0]?.selectedItem) {
                  const optionsDateItem = domainData?.DateRange?.find(
                    (x) => x.Code === args[0]?.selectedItem.value
                  );
                  await formikContext.setFieldValue(
                    fieldNames.DATE_FROM,
                    DateTime.fromISO(optionsDateItem?.DateFrom).toFormat('MM/dd/yyyy')
                  );
                  await formikContext.setFieldValue(
                    fieldNames.DATE_TO,
                    DateTime.fromISO(optionsDateItem?.DateTo).toFormat('MM/dd/yyyy')
                  );
                } else {
                  await formikContext.setFieldValue(fieldNames.DATE_FROM, '');
                  await formikContext.setFieldValue(fieldNames.DATE_TO, '');
                }

                // re-validate field if already validating
                if (!formikContext.isValid) {
                  await formikContext.validateField(fieldNames.DATE_FROM);
                  await formikContext.validateField(fieldNames.DATE_TO);
                }
              }}
              options={
                domainData?.DateRange?.map((data) => {
                  return { value: data.Code, label: data.Description };
                }) ?? []
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer paddingTop="small" paddingBottom="small">
              <Typography noWrap={true} variant="h4" color="grayDark">
                <FormattedMessage id="dashboard.panel.expiringPoliciesList.options.or" />
              </Typography>
            </Spacer>
            <Spacer paddingTop="small" paddingBottom="small" color="grayDark">
              <Typography noWrap={true} variant="h4">
                <FormattedMessage id="dashboard.panel.expiringPoliciesList.options.expirationsBetween" />
                :
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              required
              name={fieldNames.DATE_FROM}
              label={formatMessage({
                id: 'dashboard.panel.expiringPoliciesList.options.dueDateFrom',
              })}
              onChange={async () =>
                await formikContext.setFieldValue(fieldNames.CODE, USER_DEFINED)
              }
            />
          </Column>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              required
              name={fieldNames.DATE_TO}
              label={formatMessage({
                id: 'dashboard.panel.expiringPoliciesList.options.dueDateTo',
              })}
              onChange={async () =>
                await formikContext.setFieldValue(fieldNames.CODE, USER_DEFINED)
              }
            />
          </Column>
        </Row>
      </>
    );
  }
);

const ExpiringPoliciesListDashboardOptions = ({
  onClose,
  onApply,
  data: customFilter,
}: DashboardPanelOptionsProps<ExpiringPolicyCustomFilter>) => {
  const { formatMessage } = useIntl();

  // hooks
  const [initialValues, setInitialValues] = useState<ExpiringPolicyCustomFilter>(
    {} as ExpiringPolicyCustomFilter
  );

  const optionsData = useExpiringPoliciesOptionsData((data: DateRange) => {
    // add user-defined option
    data.DateRange.push({
      Description: formatMessage({
        id: 'dashboard.panel.expiringPoliciesList.options.userDefined',
      }),
      Code: USER_DEFINED,
      SortNo: 1000,
    } as ExpiringPolicyOptionsData);

    const range = data?.DateRange[0] ?? ({} as ExpiringPolicyOptionsData);

    setInitialValues({
      Code: customFilter?.Code ?? range.Code,
      DateFrom: customFilter?.DateFrom ?? DateTime.fromISO(range.DateFrom).toFormat('MM/dd/yyyy'),
      DateTo: customFilter?.DateTo ?? DateTime.fromISO(range.DateTo).toFormat('MM/dd/yyyy'),
    });
  });

  // functions
  const handleApply = (data: ExpiringPolicyCustomFilter) => {
    onApply(data);
    onClose();
  };

  // validation schema
  const validationSchema = ExpiringPoliciesDashboardOptionsValidationSchema(formatMessage);
  let childHandle: Handle<typeof ExpiringPoliciesListDashboardOptionsContent>;

  return (
    <Modal
      title={formatMessage({
        id: 'dashboard.panel.expiringPoliciesList.title',
      })}
      onClose={onClose}
      footer={
        <ButtonGroup endAlign={true}>
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button variant="primary" onClick={() => childHandle.SubmitForm()}>
            <FormattedMessage id="common.button.apply" />
          </Button>
        </ButtonGroup>
      }
    >
      {optionsData.isFetching ? (
        <LoadingIndicator />
      ) : (
        <VertaFormik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleApply}
        >
          <ExpiringPoliciesListDashboardOptionsContent
            values={initialValues}
            domainData={optionsData.data}
            ref={(c) => (childHandle = c)}
          />
        </VertaFormik>
      )}
    </Modal>
  );
};

export default ExpiringPoliciesListDashboardOptions;
