import React, { useState, useContext } from 'react';

export enum DashboardView {
  MyWork = 'MyWork',
  ManagerDashboard = 'ManagerDashboard',
}

// default Dashboard view is set here on the context, and the state below.
const DashboardViewContext = React.createContext({
  dashboardView: DashboardView.MyWork,
  setDashboardView: (p) => p,
});

export const DashboardViewProvider = ({ children }) => {
  const [dashboardView, setDashboardView] = useState(DashboardView.MyWork);

  return (
    <DashboardViewContext.Provider value={{ dashboardView, setDashboardView }}>
      {children}
    </DashboardViewContext.Provider>
  );
};

export const useDashboardView = () => {
  const context = useContext(DashboardViewContext);
  if (context === undefined) {
    throw new Error('useDashboardView cannot be called from outside of a DashboardViewProvider');
  }
  return context;
};
