import baseRequests from '../../api/baseRequests';
import ExpiringPoliciesRequestPayload from '../../models/requestPayloads/ExpiringPoliciesRequestPayload';
import {
  ExpiringPoliciesCriteria,
  ExpiringPolicyItem,
  DateRange,
} from '../../models/ExpiringPolicies';

const baseURL = 'api/ExpiringPolicies';

const ExpiringPoliciesService = {
  GetDueTodayCount: (payload: ExpiringPoliciesRequestPayload): Promise<string> =>
    baseRequests
      .post(`${baseURL}/GetMyExpiringPoliciesCount`, payload)
      .then((response) => response),
  GetOptionsData: (): Promise<DateRange> =>
    baseRequests.get(`${baseURL}/OptionsData`).then((response) => response),
  list: (criteria: ExpiringPoliciesCriteria): Promise<ExpiringPolicyItem[]> =>
    baseRequests.post(`${baseURL}/GetMyExpiringPolicies`, criteria).then((response) => response),
};

export default ExpiringPoliciesService;
