import { Icon } from 'alloy-foundation';
import { IconName } from 'alloy-foundation/es/components/Icon';
import { DataTableColumn, CellProps } from '../../data-table/DataTableColumn';
import { DataTableColumnWithResolvableValue } from './DataTableColumnWithResolvableValue';

export class ImageColumn<T> extends DataTableColumnWithResolvableValue<T> {
  iconName?: IconName;
  size?: string | number;
  color?: string;
  visible?: (row) => boolean;

  constructor({
    iconName,
    size,
    color = 'black',
    visible,
    Cell,
    getSortValue,
    ...values
  }: Partial<ImageColumn<T>> & { key: DataTableColumn<T>['key'] }) {
    super(values);
    Object.assign(this, { iconName });
    Object.assign(this, { size });
    Object.assign(this, { color });
    Object.assign(this, { visible });

    if (Cell) Object.assign(this, { Cell });

    if (getSortValue) Object.assign(this, { getSortValue });
  }

  Cell = ({ row, isActive, width }: CellProps<T>) => {
    return (
      <>
        {!this.visible || this.visible(row) ? (
          <Icon name={this.iconName} size={this.size} color={this.color} />
        ) : null}
      </>
    );
  };
}
