import React from 'react';
import {
  Typography,
  LoadingIndicator,
  Container,
  Row,
  Column,
  Spacer,
  FormField,
} from 'alloy-foundation';
import { UseQueryResult } from 'react-query';
import { useIntl } from 'react-intl';
import { PssCustomer } from '../../../../models/PssCustomer';

const BusinessInformationViewOnly = ({
  customer,
}: {
  customer: UseQueryResult<PssCustomer, unknown>;
}) => {
  const { formatMessage } = useIntl();
  return customer.isLoading ? (
    <LoadingIndicator />
  ) : (
    <React.Fragment>
      <Container noPadding={true}>
        <Row>
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.businessInformation.typeOfBusiness' })}
              >
                <Typography
                  variant="body"
                  data-testid="customer.details.businessInformation.typeOfBusiness"
                >
                  {customer.data?.IsCommercialCustomer
                    ? formatMessage({
                        id: 'customer.details.businessInformation.typeOfBusiness.Commercial',
                      })
                    : formatMessage({
                        id: 'customer.details.businessInformation.typeOfBusiness.Personal',
                      })}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.businessInformation.division' })}
              >
                <Typography
                  variant="body"
                  data-testid="customer.details.businessInformation.division"
                >
                  {customer.data?.GLDivisionName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.businessInformation.department' })}
              >
                <Typography
                  variant="body"
                  data-testid="customer.details.businessInformation.department"
                >
                  {customer.data?.GLDepartmentName}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountExec1' })}
              >
                <Typography
                  variant="body"
                  data-testid="customer.details.brokeragePersonnel.AccountExec1"
                >
                  {customer.data?.AccountExecCodeDisplay}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column md={3}>
            <Spacer mb="none">
              <FormField
                label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountRep1' })}
              >
                <Typography
                  variant="body"
                  data-testid="customer.details.brokeragePersonnel.AccountRep1"
                >
                  {customer.data?.AccountRepCodeDisplay}
                </Typography>
              </FormField>
            </Spacer>
          </Column>
          {(customer.data?.AccountExec2CodeDisplay ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountExec2' })}
                >
                  <Typography
                    variant="body"
                    data-testid="customer.details.brokeragePersonnel.AccountExec2"
                  >
                    {customer.data?.AccountExec2CodeDisplay}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}
          {(customer.data?.AccountRep2CodeDisplay ?? '') !== '' && (
            <Column md={3}>
              <Spacer mb="none">
                <FormField
                  label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountRep2' })}
                >
                  <Typography
                    variant="body"
                    data-testid="customer.details.brokeragePersonnel.AccountRep2"
                  >
                    {customer.data?.AccountRep2CodeDisplay}
                  </Typography>
                </FormField>
              </Spacer>
            </Column>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default BusinessInformationViewOnly;
