import { Column, Flex, Row, Toggle, ToggleRadioButton } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DashboardView, useDashboardView } from './DashboardViewProvider';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';

const DashboardToggle = () => {
  const { getIsSmartflowAdmin } = useProductInfoProvider();
  const { dashboardView, setDashboardView } = useDashboardView();

  return getIsSmartflowAdmin() ? (
    <Row>
      <Column>
        <Flex flexDirection="row-reverse">
          <Toggle onChange={setDashboardView} value={dashboardView}>
            <ToggleRadioButton value={DashboardView.MyWork}>
              <FormattedMessage id="dashboard.myWork" />
            </ToggleRadioButton>
            <ToggleRadioButton value={DashboardView.ManagerDashboard}>
              <FormattedMessage id="dashboard.managerDashboard" />
            </ToggleRadioButton>
          </Toggle>
        </Flex>
      </Column>
    </Row>
  ) : null;
};

export default DashboardToggle;
