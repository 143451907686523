import React, { CSSProperties, useRef } from 'react';
import { ComponentType, ReactNode, useEffect, useMemo, useState } from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import isEqual from 'lodash/isEqual';
import { Theme } from 'alloy-foundation';
import {
  Flex,
  Link,
  LoadingIndicator,
  Pagination,
  SearchField,
  Spacer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableResponsive,
  TableRow,
  TableRowCountSelector,
  TableSortLabel,
  Typography,
  useDimensions,
  usePrevious,
} from 'alloy-foundation';
import { DataTableColumn, useVisibleColumns } from './DataTableColumn';
import { ExpandableRowColumn } from './ExpandableRowColum';
import { computeColumnWidths } from './util';
import { FilterContext } from './FilterContext';
import { AdvanceFilter } from './useDataTable';
import { MultiSelectRowColum } from './MultiSelectRowColum';

export type DataTablePhrases = {
  /** The text that comes before the row range dropdown. Defaults to `Currently displaying`. **/
  rowCountBeforeText: ReactNode;

  /** The text that comes after the row range dropdown. Defaults to `rows per page`. **/
  rowCountAfterText: ReactNode;

  /** Label for the next button of the Pagination. Defaults to `Next`. */
  paginationNextLabel: ReactNode;

  /** Label for the previous button of the Pagination. Defaults to `Previous`. */
  paginationPreviousLabel: ReactNode;
};

export interface ExpandableRowProps<Data> {
  row: Data;
}

export type DataTableMultiSelect<Data> = {
  /** The currently selected rows. */
  selectedRows?: Data[];

  /** Called when the selected row changes. If not supplied row selection will be disabled. Also disabled if multi select is enabled */
  onSelectRow(row?: Data): void;

  /** Called when the selected rows changes. If not supplied row selection will be disabled. Also disabled if multi select is enabled */
  onSelectAll(): void;
  isSelectAll: boolean;
  isPartialSelection: boolean;
  reset(): void;
};

export interface DataTableProps<Data> {
  /** Additional content to append above the table, next to the search box */
  actions?: ReactNode;

  /** Column definitions */
  columns: DataTableColumn<Data>[];

  /** The current page, zero based  */
  currentPage?: number;

  /** The data to render */
  data: Data[];

  /** The aria-label of the `SearchField` button. */
  filterButtonAriaLabel?: string;

  /** The value of the `SearchField` */
  filterValue?: string;

  /** The placeholder for the `SearchField` */
  filterPlaceholder?: string;
  advanceSearchPlaceholder?: string;
  advanceSearchClearButton?: string;

  advanceFilterDisplay?: ReactNode;
  advanceFilter?: AdvanceFilter<Data>;

  /** Set to true to show a loading indicator in place of rows*/
  isLoading?: boolean;

  /** Message shown next to loading indicator when loading */
  loadingMessage?: string | ReactNode;

  /** Message shown when there's no data */
  emptyMessage?: string | ReactNode;

  /** An object of phrases that can be overwritten in the UI. */
  phrases?: DataTablePhrases;

  /** How many rows to show at once. */
  pageSize?: number;

  /** The options for the page size selector. */
  pageSizeOptions?: number[];

  /** The currently selected row. */
  selectedRow?: Data;

  /** The key of the column the data is sorted by. */
  sortedColumnKey?: keyof Data | string;

  /** Direction of sorting for the sorted column */
  sortDirection?: SortDirection;

  /** Test id for the table */
  'data-testid'?: string;

  /** Total pages. Used for pagination */
  totalPages?: number;

  /** Override table width */
  width?: number;

  /** Called when the `SearchField` is typed into. If omitted the `SearchField` will be hidden. */
  onFilter?(query: string): void;

  /** Called when the pagination controls are used. If omitted pagination controls will be hidden. */
  onPageChange?(page: number): void;

  /** Called when the page size selector is changed. If omitted page size controls will be hidden */
  onPageSizeChange?(pageSize: number): void;

  /** Called when a sortable column's header is clicked. If omitted sorting will be disabled. */
  onSort?(sortedColumnKey: keyof Data | string, sortDirection: SortDirection): void;

  /** Called when the selected row changes. If not supplied row selection will be disabled. */
  onSelectRow?(row?: Data): void;

  /** Called when an expanded row changes. If not supplied expandable rows will be disabled. */
  onExpandedRowChange?(row?: Data): void;

  /** Called when advance filters are being cleared */
  onClearFilters?(): void;

  /** Template for expandable rows.  If omitted, expandable row support will be disabled. */
  expandableRowView?: ComponentType<ExpandableRowProps<Data>>;

  /** Rows that are currently expanded */
  expandedRows?: Data[];

  /** The height of the table. Defaults to 'auto'. */
  height?: string;

  /** Set to true to overflow with horizontal scrollbar*/
  responsive?: boolean;

  /** The key of the column used for equality compararison. */
  equatableRowKey?: keyof Data | string;

  multiSelect?: DataTableMultiSelect<Data>;

  /** Set the alignItems properpy for each row **/
  rowAlignItems?: CSSProperties['alignItems'];
  /** Sets the vertical align style of the row.  */
  rowVerticalAlign?: 'middle' | 'top' | 'bottom';

  inputComponentCell?: boolean;

  setFilterValue?: CallableFunction;
}

export function DataTable<Data>({
  actions = null,
  columns,
  currentPage = 0,
  data,
  filterButtonAriaLabel = 'Search',
  filterPlaceholder = 'Type to search...',
  advanceSearchPlaceholder = 'Advanced Search',
  advanceSearchClearButton = 'Clear',
  filterValue = '',
  advanceFilterDisplay = null,
  phrases = {
    rowCountAfterText: 'rows per page',
    rowCountBeforeText: 'Currently displaying',
    paginationNextLabel: 'Next',
    paginationPreviousLabel: 'Previous',
  },
  emptyMessage = (
    <Typography variant="disclaimer">
      {filterValue.trim().length
        ? `No results found for ${filterValue}`
        : "There's nothing here yet."}
    </Typography>
  ),
  isLoading = false,
  loadingMessage = <LoadingIndicator size="medium" />,
  onFilter,
  onPageSizeChange,
  onPageChange,
  onSelectRow,
  onSort,
  onExpandedRowChange,
  onClearFilters,
  pageSize = 10,
  pageSizeOptions = [10, 20, 60, 100],
  selectedRow,
  sortedColumnKey,
  sortDirection = 'desc',
  totalPages,
  'data-testid': testId,
  width: forcedWidth,
  expandableRowView,
  expandedRows = [],
  height = 'auto',
  responsive = true,
  equatableRowKey,
  multiSelect,
  rowAlignItems = 'baseline',
  rowVerticalAlign,
  inputComponentCell,
  setFilterValue,
}: DataTableProps<Data>) {
  const { css, styles } = useStyles({ stylesFn });
  const { theme } = useStyles({ stylesFn }) as any;
  const { border } = theme.vertaforeAlloy;
  const [measuredNode, dimensions] = useDimensions({ liveMeasure: true });
  const spaceForBorderPx = 4;
  const hasExpandableRows = typeof onExpandedRowChange === 'function';
  const width = (forcedWidth ? forcedWidth : dimensions.width) - spaceForBorderPx;
  const handleChange = (value: string) => {
    if (setFilterValue) {
      setFilterValue(value);
      onFilter(value);
    } else onFilter(value);
  };
  if (hasExpandableRows)
    columns = [
      new ExpandableRowColumn({
        key: 'DT_ER_CARET',
        header: '',
        onCaretClick: (row: Data) => onExpandedRowChange?.(row),
      }),
      ...columns,
    ];

  if (multiSelect)
    columns = [
      new MultiSelectRowColum({
        key: 'MultiSelect',
        onChange: (row: Data) => multiSelect.onSelectRow?.(row),
      }),
      ...columns,
    ];

  const visibleColumns = useVisibleColumns(columns);
  const [advanceSearchVisible, updateAdvanceSearchVisible] = useState(false);
  const columnWidths = useMemo(() => {
    return computeColumnWidths({
      columns: visibleColumns,
      width,
    });
  }, [visibleColumns, width]);
  const hasFiltering = typeof onFilter === 'function';
  const hasadvanceFilterDisplaying = advanceFilterDisplay;
  const columnsWithHeaders = visibleColumns.filter((visibleColumn) => !!visibleColumn.header);
  const searchFieldWidth = useMemo(() => {
    if (!width) return 0;
    const columnWidth = width / 12;
    return columnWidth * 2;
  }, [width]);
  const lastPageSize = usePrevious(pageSize);
  const minWidth = responsive ? dimensions.width : undefined;

  useEffect(
    function resetCurrentPageOnPageSizeChange() {
      if (lastPageSize && pageSize !== lastPageSize) {
        onPageChange?.(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageSize, onPageChange]
  );

  // clear selectedRow when data changes and selectedRow no longer included in data.
  // if equatableRowKey is provided and data includes a match with selectedRow, using the equatableRowKey, update selectedRow.
  useEffect(
    function updateSelectedRow() {
      if (selectedRow && equatableRowKey && !data.includes(selectedRow)) {
        const row = data.find(
          (x) => x[equatableRowKey as keyof Data] === selectedRow[equatableRowKey as keyof Data]
        );
        onSelectRow?.(row);
      }
    },
    [data, selectedRow, onSelectRow, equatableRowKey]
  );

  const table = (
    <Table role="table" data-testid={testId} ref={measuredNode} minWidth={minWidth}>
      {columnsWithHeaders.length > 0 ? (
        <TableHead>
          <TableRow
            border={false}
            display={inputComponentCell ? null : 'flex'}
            alignItems="baseline"
          >
            {visibleColumns.map((column) => {
              const isSortable = typeof onSort === 'function' && column.sortable !== false;
              const isSortedColumn = isSortable && sortedColumnKey === column.key;
              let header = column.header as ReactNode;

              if (typeof column.header === 'function') {
                const Header = column.header as ComponentType<any>;
                header = <Header multiSelect={multiSelect} />;
              }

              return (
                <TableCell
                  variant="head"
                  key={`head-${String(column.key)}`}
                  align={column.align}
                  width={columnWidths[column.key]}
                  padding={column.padding}
                  data-testid={column.dataTestId}
                  display={inputComponentCell ? null : 'block'}
                >
                  {isSortable ? (
                    <TableSortLabel
                      disabled={isLoading}
                      active={isSortedColumn}
                      direction={sortDirection}
                      onClick={() => {
                        const nextDirection = isSortedColumn
                          ? toggle[sortDirection]
                          : sortDirection;
                        onSort(column.key, nextDirection);
                      }}
                    >
                      <div
                        {...css(
                          styles.sortLabel,
                          column.align === 'right' && styles.rightAlignedHeaderContent
                        )}
                      >
                        {header}
                      </div>
                    </TableSortLabel>
                  ) : (
                    header
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
      ) : null}
      <TableBody>
        <FilterContext.Provider value={filterValue}>
          {isLoading ? (
            <TableRow rowColor="grayLighter">
              <TableCell colSpan={visibleColumns.length} align="center">
                <Spacer py="small">{loadingMessage}</Spacer>
              </TableCell>
            </TableRow>
          ) : data?.length ? (
            data.map((row, rowIndex) => {
              const isRowActive =
                isEqual(row, selectedRow) ||
                (multiSelect &&
                  (multiSelect.selectedRows?.includes(row) ||
                    (equatableRowKey &&
                      multiSelect.selectedRows?.some(
                        (x) =>
                          x[equatableRowKey as keyof Data] === row[equatableRowKey as keyof Data]
                      ))));
              const isRowExpanded =
                hasExpandableRows &&
                (expandedRows.includes(row) ||
                  (equatableRowKey &&
                    expandedRows.some(
                      (x) => x[equatableRowKey as keyof Data] === row[equatableRowKey as keyof Data]
                    )));
              const ExpandableRow = expandableRowView;

              return (
                <React.Fragment key={`row-${rowIndex}`}>
                  <TableRow
                    border={!!rowIndex}
                    rowColor={rowIndex % 2 ? 'white' : 'grayLighter'}
                    selected={isRowActive}
                    isInteractive={typeof onSelectRow === 'function'}
                    onClick={() => onSelectRow?.(row)}
                    display={inputComponentCell ? null : 'flex'}
                    alignItems={rowAlignItems}
                    verticalAlign={rowVerticalAlign}
                  >
                    {visibleColumns.map((column) => {
                      const Cell = column?.Cell;
                      if (!Cell) {
                        return <></>;
                      }
                      return (
                        <TableCell
                          variant="body"
                          align={column.align}
                          key={`row-${rowIndex}-${String(column.key)}`}
                          width={columnWidths[column.key]}
                          padding={column.padding}
                          data-testid={`row-${rowIndex}-${String(column.key)}`}
                          display={inputComponentCell ? null : 'block'}
                        >
                          <Cell
                            isActive={isRowActive}
                            isExpanded={isRowExpanded}
                            row={row}
                            column={column}
                            rowIndex={rowIndex}
                            value={column.getValue(row)}
                            width={columnWidths[column.key]}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {isRowExpanded && ExpandableRow !== undefined ? (
                    <TableRow>
                      <TableCell padding="none" colSpan={8}>
                        <div
                          data-testid="expandable-row-div"
                          id="expanded-row-div"
                          style={{
                            borderLeft: border.thickBlue,
                            // padding: spacing.large,
                          }}
                        >
                          <ExpandableRow row={row} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <TableRow rowColor="grayLighter">
              <TableCell colSpan={visibleColumns.length} align="center">
                <Spacer py="small">{emptyMessage}</Spacer>
              </TableCell>
            </TableRow>
          )}
        </FilterContext.Provider>
      </TableBody>
    </Table>
  );
  const inputElement = useRef<HTMLInputElement>(null);
  const clearIcon = inputElement?.current?.nextSibling;
  clearIcon?.addEventListener('click', () => {
    setFilterValue('');
    onFilter('');
  });

  return (
    <>
      <Flex>
        {hasFiltering ? (
          <Flex alignItems="flex-start" flexGrow={1}>
            <Flex>
              <div
                {...css(styles.searchFieldSizer, {
                  width: searchFieldWidth,
                })}
              >
                <SearchField
                  disabled={isLoading}
                  value={filterValue}
                  onChange={handleChange}
                  placeholder={filterPlaceholder}
                  buttonAriaLabel={filterButtonAriaLabel}
                  ref={inputElement}
                />
              </div>
            </Flex>
            <Flex flexGrow={1} />
            {hasadvanceFilterDisplaying && (
              <Flex height="38px" alignItems="center">
                <Link
                  small
                  iconAlign="right"
                  data-testid="advanceSearchButton"
                  icon={advanceSearchVisible ? 'caretUp' : 'caretDown'}
                  onClick={() => updateAdvanceSearchVisible(!advanceSearchVisible)}
                >
                  {advanceSearchPlaceholder}
                </Link>
              </Flex>
            )}
          </Flex>
        ) : null}
      </Flex>
      {advanceSearchVisible ? <Spacer mb="small">{advanceFilterDisplay} </Spacer> : null}
      {advanceSearchVisible && onClearFilters ? (
        <Flex justifyContent="flex-end">
          <Spacer mb="xlarge">
            <Link small={true} onClick={() => onClearFilters()}>
              {advanceSearchClearButton}
            </Link>
          </Spacer>
        </Flex>
      ) : null}
      {responsive ? <TableResponsive height={height}>{table}</TableResponsive> : table}
      <Spacer pt="small">
        <Flex justifyContent="space-between" alignItems="center">
          {typeof onPageSizeChange === 'function' ? (
            <TableRowCountSelector
              disabled={isLoading}
              rowRanges={pageSizeOptions}
              beforeDropdownText={phrases.rowCountBeforeText}
              afterDropdownText={phrases.rowCountAfterText}
              rowsToDisplay={pageSize}
              onChange={({ value }: { value: number }) => {
                onPageSizeChange(value);
              }}
            />
          ) : (
            <span />
          )}
          {totalPages > 1 ? (
            <Pagination
              disabled={isLoading}
              pageCount={totalPages}
              forcePage={currentPage}
              nextLabel={phrases.paginationNextLabel}
              previousLabel={phrases.paginationPreviousLabel}
              onPageChange={({ selected }) => onPageChange(selected)}
            />
          ) : null}
        </Flex>
      </Spacer>
    </>
  );
}

const stylesFn = ({ vertaforeAlloy: { spacing } }: Theme) => ({
  sortLabel: {
    paddingRight: spacing.tiny,
    userSelect: 'none',
  },

  searchFieldSizer: {
    minWidth: '14rem',
    maxWidth: '100%',
    paddingBottom: spacing.medium,
  },

  rightAlignedHeaderContent: {
    paddingLeft: spacing.xsmall,
  },
});

export type SortDirection = 'asc' | 'desc';
const toggle: Record<SortDirection, SortDirection> = {
  asc: 'desc',
  desc: 'asc',
};
