import { DateTime } from 'luxon';
import { Locale } from '../../localization/Locale';

export const useFormatting = () => {
  const formatPhoneNumber = (areaCode: string, phone: string, extension: string) => {
    let result = '';
    if ((areaCode ?? '') !== '' && areaCode.length === 3) {
      result += `(${areaCode})`;
    }

    if ((phone ?? '') !== '') {
      if (phone.length === 7) {
        result += `${phone.substring(0, 3)}-${phone.substring(3)}`;
      } else if (phone.length === 8) {
        result += `${phone.substring(0, 3)}-${phone.substring(4)}`;
      }
    }

    if ((extension ?? '') !== '') {
      result += ` x${extension}`;
    }

    return result;
  };
  const formatDate = (date: any) => {
    if (date) {
      return DateTime.fromISO(date, { zone: 'utc' }).toFormat('MM/dd/yyyy');
    } else return '';
  };

  const formatLocalDate = (date: any) => {
    if (date) {
      return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat('MM/dd/yyyy');
    } else return '';
  };

  const formatModifiedDate = (date: any) => {
    if (date) {
      return DateTime.fromISO(date).toFormat('MM/dd/yyyy hh:mm a');
    } else return '';
  };

  const formatCurrency = (value: number, decimal: number, locale: any) => {
    // We don't use the 'currency' style for consistency. Intl.NumberFormat make a space before $ in Fr-Ca (10 000 $)and all other currency in MBH dont have (10 000$)
    const options = {
      style: 'decimal',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    };

    const formatter = new Intl.NumberFormat(locale, options);

    const formatedValue2 =
      value !== 0
        ? locale === Locale.EnCa
          ? `$${formatter.format(value).toString()}`
          : `${formatter.format(value).toString()}$`
        : '';

    return formatedValue2;
  };

  return {
    formatPhoneNumber,
    formatDate,
    formatLocalDate,
    formatModifiedDate,
    formatCurrency,
  };
};
