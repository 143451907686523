import { Styles } from 'react-with-styles';

const stylesFn = ({ vertaforeAlloy: { color, border } }): Styles => ({
  tooltip: {
    // Row selector
    ':first-child>div>div:first-child > div': {
      alignItems: 'center',
    },
    // Title column selector
    ':first-child>div>div:first-child > div > div:nth-child(1)': {
      flexBasis: 'max-content',
      marginRight: '10px',
    },
    // Tooltip column selector
    ':first-child>div>div:first-child > div > div:nth-child(2)': {
      display: 'flex',
      marginBottom: '2px',
    },
    // icon selector
    ':first-child > div > div:first-child > div > div:nth-child(2) > div > span > div > i > svg': {
      overflow: 'visible',
    },
  },
});

export default stylesFn;
