import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { Culture } from '../../../models/ApplicationState/Culture';
import { ToDoListSettings } from '../../../models/ApplicationState/ToDoListSettings';
import { StateCollection } from '../../../models/ApplicationState/StateCollection';
import { ToDoListWorkgroupSettings } from '../../../models/ApplicationState/ToDoListWorkgroupSettings';
import { ExpiringPoliciesSettings } from '../../../models/ApplicationState/ExpiringPoliciesSettings';
import productInfoService from '../../../services/productInfo/productInfoService';
import applicationStateService from '../../../services/applicationState/applicationStateService';
import { DashboardSettingItem } from '../../../models/ApplicationState/DashboardSettingItem';
import { PolicySettings } from '../../../models/ApplicationState/PolicyFilter';
import { DateFilterSettimgs, defaultFilterSettings } from '../../drawer/workQueue/DateFilter';

export type ApplicationStateContextType = {
  /** Language Selection */
  culture: Culture;

  /** Set Language Selection */
  setCulture: (culture: Culture) => void;

  /** ToDoList Panel Settings */
  toDoListSettings: ToDoListSettings;

  /** Set ToDoList Panel Settings */
  setToDoListSettings: (toDoListSettings: ToDoListSettings) => void;

  /** ToDoListWorkgroup Panel Settings */
  toDoListWorkgroupSettings: ToDoListWorkgroupSettings;

  /** Set ToDoListWorkgroup Panel Settings */
  setToDoListWorkgroupSettings: (toDoListWorkgroupSettings: ToDoListWorkgroupSettings) => void;

  /** Expiring Policies Panel Settings */
  expiringPoliciesSettings: ExpiringPoliciesSettings;

  /** Set Expiring Policies Panel Settings */
  setExpiringPoliciesSettings: (expiringPoliciesSettings: ExpiringPoliciesSettings) => void;

  /** Dashboard Settings */
  dashboardSettings: DashboardSettingItem[];

  /** Set Dashboard Settings */
  setDashboardSettings: (dashboardSettings: DashboardSettingItem[]) => void;

  /** Policy Settings */
  policySettings: PolicySettings;

  /** Set Policy Settings */
  setPolicySettings: (policySettings: PolicySettings) => void;

  /** Work Queue Filter Settings */
  workQueueFilterSettings: DateFilterSettimgs;

  /** Set Woek Queue Filter Settings */
  setWorkQueueFilterSettings: (workQueueFilterSettings: DateFilterSettimgs) => void;
};

const ApplicationStateContext = React.createContext<ApplicationStateContextType>({
  culture: {} as Culture,
  setCulture: (p: Culture) => p,
  toDoListSettings: {} as ToDoListSettings,
  setToDoListSettings: (p: ToDoListSettings) => p,
  toDoListWorkgroupSettings: {} as ToDoListWorkgroupSettings,
  setToDoListWorkgroupSettings: (p: ToDoListWorkgroupSettings) => p,
  expiringPoliciesSettings: {} as ExpiringPoliciesSettings,
  setExpiringPoliciesSettings: (p: ExpiringPoliciesSettings) => p,
  dashboardSettings: {} as DashboardSettingItem[],
  setDashboardSettings: (p: DashboardSettingItem[]) => p,
  policySettings: {} as PolicySettings,
  setPolicySettings: (p: PolicySettings) => p,
  workQueueFilterSettings: {} as DateFilterSettimgs,
  setWorkQueueFilterSettings: (p: DateFilterSettimgs) => p,
});

export const ApplicationStateProvider = ({ children }) => {
  const stateCollection = useRef<StateCollection>(productInfoService.getState());
  const [culture, _setCulture] = useState<Culture>(stateCollection.current.culture);
  const [toDoListSettings, _setToDoListSettings] = useState<ToDoListSettings>(
    stateCollection.current.toDoListSettings
  );
  const [toDoListWorkgroupSettings, _setToDoListWorkgroupSettings] =
    useState<ToDoListWorkgroupSettings>(stateCollection.current.toDoListWorkgroupSettings);
  const [expiringPoliciesSettings, _setExpiringPoliciesSettings] =
    useState<ExpiringPoliciesSettings>(stateCollection.current.expiringPoliciesSettings);
  const [dashboardSettings, _setDashboardSettings] = useState<DashboardSettingItem[]>(
    stateCollection.current.dashboardSettings
  );
  const [policySettings, _setPolicySettings] = useState<PolicySettings>(
    stateCollection.current.policySettings
  );
  const [workQueueFilterSettings, _setWOrkQueuFilterSettings] =
    useState<DateFilterSettimgs>(defaultFilterSettings);

  useEffect(() => {
    if (stateCollection.current?.workQueueFilterSettings) {
      stateCollection.current.workQueueFilterSettings.fromDate = new Date(
        stateCollection.current.workQueueFilterSettings.fromDate
      );
      stateCollection.current.workQueueFilterSettings.toDate = new Date(
        stateCollection.current.workQueueFilterSettings.toDate
      );

      _setWOrkQueuFilterSettings(stateCollection.current.workQueueFilterSettings);
    }
  }, []);

  const applicationStateMutation = useMutation((body: any) =>
    applicationStateService.updateAppState(body)
  );

  const setApplicationState = useCallback(
    <T extends object>(setLocalState: (p: T) => void, stateValue: T, stateKey: string) => {
      setLocalState(stateValue);
      applicationStateMutation.mutateAsync({ [`${stateKey}`]: stateValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setCulture = useCallback(
    (p: Culture) => setApplicationState(_setCulture, p, 'culture'),
    [setApplicationState]
  );

  const setToDoListSettings = useCallback(
    (p: ToDoListSettings) => setApplicationState(_setToDoListSettings, p, 'toDoListSettings'),
    [setApplicationState]
  );

  const setToDoListWorkgroupSettings = useCallback(
    (p: ToDoListWorkgroupSettings) =>
      setApplicationState(_setToDoListWorkgroupSettings, p, 'toDoListWorkgroupSettings'),
    [setApplicationState]
  );

  const setExpiringPoliciesSettings = useCallback(
    (p: ExpiringPoliciesSettings) =>
      setApplicationState(_setExpiringPoliciesSettings, p, 'expiringPoliciesSettings'),
    [setApplicationState]
  );

  const setDashboardSettings = useCallback(
    (p: DashboardSettingItem[]) =>
      setApplicationState(_setDashboardSettings, p, 'dashboardSettings'),
    [setApplicationState]
  );

  const setPolicySettings = useCallback(
    (p: PolicySettings) => setApplicationState(_setPolicySettings, p, 'policySettings'),
    [setApplicationState]
  );

  const setWorkQueueFilterSettings = useCallback(
    (p: DateFilterSettimgs) =>
      setApplicationState(_setWOrkQueuFilterSettings, p, 'workQueueFilterSettings'),
    [setApplicationState]
  );

  const providerValue = React.useMemo(
    () => ({
      culture,
      setCulture,
      toDoListSettings,
      setToDoListSettings,
      toDoListWorkgroupSettings,
      setToDoListWorkgroupSettings,
      expiringPoliciesSettings,
      setExpiringPoliciesSettings,
      dashboardSettings,
      setDashboardSettings,
      policySettings,
      setPolicySettings,
      workQueueFilterSettings,
      setWorkQueueFilterSettings,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      culture,
      toDoListSettings,
      toDoListWorkgroupSettings,
      expiringPoliciesSettings,
      dashboardSettings,
      policySettings,
      workQueueFilterSettings,
    ]
  );

  return (
    <ApplicationStateContext.Provider value={providerValue}>
      {children}
    </ApplicationStateContext.Provider>
  );
};

export const useApplicationState = () => {
  const context = useContext(ApplicationStateContext);

  if (context === undefined) {
    throw new Error(
      'useApplicationState cannot be called from outside of an ApplicationStateProvider'
    );
  }
  return context;
};
