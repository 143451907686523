import React from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import stylesFn from './styles';

interface Props {
  children?: React.ReactNode;
}

const DrawerContent = ({ children }: Props) => {
  const { css, styles } = useStyles({ stylesFn });

  return (
    <div {...css(styles.container, { overflowY: 'overlay' })} data-testid="drawer-content">
      {children}
    </div>
  );
};

export default DrawerContent;
