import { TopNav as AlloyTopNav } from 'alloy-foundation';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Drawer from '../drawer';
import { useDrawer } from '../drawer/DrawerProvider';
import TopNavAgency from './TopNavAgency';
import TopNavSelectAgency from './TopNavSelectAgency';

const TopNav = () => {
  const { visible, content } = useDrawer();
  return (
    <React.Fragment>
      <AlloyTopNav variant="dark">
        <Switch>
          <Route path="/Login/SelectAgency" component={TopNavSelectAgency} />
          <Route path="/Login/UnauthorizedAccess" component={TopNavSelectAgency} />
          <Route component={TopNavAgency} />
        </Switch>
      </AlloyTopNav>
      {visible && <Drawer children={content} />}
    </React.Fragment>
  );
};

export default TopNav;
