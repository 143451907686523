import * as Yup from 'yup';
import * as fieldNames from './fieldNames';
import { StepValidationSchema } from './StepValidationSchema';
const instanceValidationSchema = (formatMessage) =>
  Yup.object().shape({
    [`${fieldNames.TITLE}`]: Yup.string()
      .trim()
      .required(formatMessage({ id: 'quickNav.configuration.instances.edit.title.error' }))
      .min(10, formatMessage({ id: 'quickNav.configuration.instances.edit.title.error' }))
      .max(160, formatMessage({ id: 'quickNav.configuration.instances.edit.step.max.error' })),
    [`${fieldNames.DESCRIPTION}`]: Yup.string()
      .max(160, formatMessage({ id: 'quickNav.configuration.instances.edit.step.max.error' }))
      .nullable(true),
    [`${fieldNames.STEPS}`]: Yup.array().of(StepValidationSchema(formatMessage)),
  });

export default instanceValidationSchema;
