import { Column, Flex, FormCheckBox, FormDropdownSingleSelect, FormInput } from 'alloy-foundation';
import React from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import * as FieldNames from '../../fieldNames';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { PhoneNumber } from '../../../../models/PhoneNumber';
import { KeyValuePair } from '../../../../models/KeyValuePair';

const PhoneNumbersForm = ({
  index,
  namespace,
  dropdowns,
  disableMandatory,
}: {
  index: number;
  namespace: string;
  dropdowns: CustomerDropdowns;
  disableMandatory?: boolean;
}) => {
  // Get context
  const formikContext = useFormikContext();
  const { formatMessage } = useIntl();

  // Setup variable/const to simplify component logic and reduce repetition
  const parentValue = formikContext.getFieldProps(namespace).value;
  const currentValue = formikContext.getFieldProps(`${namespace}[${index}]`).value as PhoneNumber;
  const currentPossiblePhoneTypes = parentValue
    .filter((x: PhoneNumber) => x.type !== currentValue.type)
    .map((x: PhoneNumber) => x.type);
  const isMandatory =
    dropdowns.MandatoryContactMethods.includes(currentValue?.type ?? '-1') && !disableMandatory;
  const fullNameSpace = `${namespace}[${index}]`;

  const options = dropdowns.TypeOfPhoneNumbers.filter(
    (listPhoneTypes: KeyValuePair) => !currentPossiblePhoneTypes.includes(listPhoneTypes.Key)
  ).map((data) => {
    return {
      value: data.Key,
      label: data.Value,
    };
  });

  const triggerValidation = async () => {
    // re-validate field if already validating
    if (!formikContext.isValid) {
      await formikContext.validateField(`${fullNameSpace}.${FieldNames.PHONE_NUMBER}`);
      await formikContext.validateField(`${fullNameSpace}.${FieldNames.PHONE_TYPE}`);
      await formikContext.validateField(`${fullNameSpace}.${FieldNames.PHONE_EXTENSION}`);
    }
  };

  async function resetPhoneProperties() {
    await formikContext.setFieldValue(`${fullNameSpace}.${FieldNames.PHONE_NUMBER}`, '');
    await formikContext.setFieldValue(`${fullNameSpace}.${FieldNames.PHONE_EXTENSION}`, '');
  }

  // Setup event handlers
  const handleNotApplicableChange = async () => {
    await formikContext.handleChange(`${fullNameSpace}.${FieldNames.NOT_APPLICABLE}`);

    const previousValue = formikContext.getFieldProps(
      `${fullNameSpace}.${FieldNames.NOT_APPLICABLE}`
    ).value as boolean;

    if (!previousValue) {
      await resetPhoneProperties();
    }

    await triggerValidation();
  };

  const handlePhoneTypeChange = async (values, args) => {
    await formikContext.handleChange(`${fullNameSpace}.${FieldNames.PHONE_TYPE}`);

    if (!values) {
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await resetPhoneProperties();
    }

    await triggerValidation();
  };

  return (
    <React.Fragment>
      <Column md={3}>
        <FormDropdownSingleSelect
          name={`${fullNameSpace}.${FieldNames.PHONE_TYPE}`}
          label={formatMessage({ id: 'customer.contactMethods.phoneType' })}
          placeholder={formatMessage({ id: 'customer.contactMethods.phoneType.placeholder' })}
          disabled={isMandatory}
          options={options}
          required={false}
          onChange={async (values, ...args: any[]) => {
            await handlePhoneTypeChange(values, args);
          }}
        />
      </Column>
      <Column md={3}>
        <FormInput
          name={`${fullNameSpace}.${FieldNames.PHONE_NUMBER}`}
          format="phone"
          placeholder="(555) 555-5555"
          label={formatMessage({ id: 'customer.contactMethods.phoneNumber' })}
          required={false}
          disabled={isMandatory && currentValue.notApplicable}
          onChange={triggerValidation}
        />
      </Column>
      <Column md={3}>
        <FormInput
          name={`${fullNameSpace}.${FieldNames.PHONE_EXTENSION}`}
          data-testid={`${fullNameSpace}.${FieldNames.PHONE_EXTENSION}`}
          format="numberNoCommas"
          placeholder="1234"
          maxLength={5}
          label={formatMessage({ id: 'customer.contactMethods.phoneNumberExtension' })}
          required={false}
          disabled={isMandatory && currentValue.notApplicable}
          onChange={triggerValidation}
        />
      </Column>
      {isMandatory && (
        <Column md={3}>
          <Flex alignItems="flex-end" height="5.125rem">
            <FormCheckBox
              height="4rem"
              label={formatMessage({ id: 'customer.contactMethods.notApplicable' })}
              name={`${fullNameSpace}.${FieldNames.NOT_APPLICABLE}`}
              onChange={handleNotApplicableChange}
            />
          </Flex>
        </Column>
      )}
    </React.Fragment>
  );
};

export default PhoneNumbersForm;
