import React from 'react';
import BrokeragePersonnel from '../../details/businessInformation/brokeragePersonnel/BrokeragePersonnel';
import { BusinessUnitForm } from '../../details/businessInformation/businessUnit/BusinessUnitForm';
import { SectionInfoProps } from '../SectionInfoProps';

export const BusinessInformationEditForm = (props: SectionInfoProps) => {
  return (
    <React.Fragment>
      <BusinessUnitForm
        dropdowns={props?.customerDropdown.data}
        showHeader={false}
        parentNamespace={props.namespace}
        customer={props?.customer.data}
      ></BusinessUnitForm>
      <BrokeragePersonnel
        dropdowns={props?.customerDropdown.data}
        showHeader={false}
        parentNamespace={props.namespace}
        customer={props?.customer.data}
      ></BrokeragePersonnel>
    </React.Fragment>
  );
};
