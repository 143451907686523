import { Column, Row, Typography, FormField, Icon, LoadingIndicator } from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormatting } from '../../common/hooks/useFormatting';
import { ActivityReduced } from '../../../models/ActivitySummary';
import { PssCustomer } from '../../../models/PssCustomer';
import LogFormViewSummary from './LogFormViewSummary';
import { useLogFormActivityExt } from './useLogFormActivityExt';
import { formatCustomerName } from '../../common/utils';

type Props = {
  onCancel: () => void;
  onChangeLogView: () => void;
  activity: ActivityReduced;
  customer: PssCustomer;
  logFormType: string;
  setLogFormViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogClose: () => void;
};

const LogFormViewModal = ({
  onCancel,
  onChangeLogView,
  activity,
  customer,
  logFormType,
  setLogFormViewVisible,
  handleLogClose,
}: Props) => {
  const { formatDate } = useFormatting();
  const { formatMessage } = useIntl();
  const [sysMan, setSysMan] = useState('logformview.manual');

  useEffect(() => {
    if (activity?.IsSystemActivity) {
      setSysMan('logformview.system');
    }
  }, [activity?.IsSystemActivity, setSysMan]);

  const logformextdata = useLogFormActivityExt(activity.ActivityId);

  return (
    <form>
      {logformextdata.isLoading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({ id: 'dashboard.panel.todoList.columns.status.header' })}
              >
                <Typography variant="body">
                  <FormattedMessage id="dashboard.panel.todoList.completed" />
                </Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'dashboard.panel.expiringPoliciesList.columns.customerName.header',
                })}
              >
                <Typography variant="body">{formatCustomerName(customer)}</Typography>
              </FormField>
            </Column>

            <Column xs={12} sm={12} md={4}>
              <FormField label={formatMessage({ id: 'logform.Processed.Date' })}>
                <Typography variant="body">{formatDate(activity.ActivityDate)}</Typography>
              </FormField>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logform.policy',
                })}
              >
                <Typography variant="body">{activity.PolicyNumber}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logform.transaction.date',
                })}
              >
                {logformextdata.data.LinkedActivity && (
                  <Typography data-testid="policyenddate" variant="body">
                    <Typography inline>{formatDate(activity.ActivityEndEffectiveDate)}</Typography>
                    {'   '}
                    <Icon name="successCheckMark" size={12} color="green" />{' '}
                    {formatMessage({ id: 'logformview.linked' })}
                  </Typography>
                )}
                {!logformextdata.data.LinkedActivity && (
                  <Typography data-testid="policyenddate" variant="body">
                    {formatDate(activity.ActivityEndEffectiveDate)}
                  </Typography>
                )}
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'drawer.workItem.header.term',
                })}
              >
                <Typography variant="body">
                  {formatDate(activity.PolicyEndDate)} - {formatDate(activity.PolicyExpdate)}
                </Typography>
              </FormField>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logform.action',
                })}
              >
                <Typography variant="body">{activity?.Action}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.duedate',
                })}
              >
                <Typography variant="body">
                  {activity?.DueDate
                    ? formatDate(activity?.DueDate)
                    : formatDate(activity.ActivityDate)}
                </Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.company',
                })}
              >
                <Typography variant="body">{activity.CompanyName}</Typography>
              </FormField>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'dashboard.panel.todoList.options.priority',
                })}
              >
                <Typography variant="body">{activity?.Priority}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.times.rescheduled',
                })}
              >
                <Typography variant="body">{activity?.ReschedNo}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.exec',
                })}
              >
                <Typography variant="body">{activity.ExecName}</Typography>
              </FormField>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.createdby',
                })}
              >
                <Typography variant="body">
                  {activity?.CreatedBy ? activity?.CreatedBy : activity.EmployeeName}
                </Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.completedby',
                })}
              >
                <Typography variant="body">{activity?.CompleteBy}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField
                label={formatMessage({
                  id: 'logformview.rep',
                })}
              >
                <Typography variant="body">{activity.CsrName}</Typography>
              </FormField>
            </Column>
          </Row>
          <Row>
            <Column xs={12} sm={12} md={4}>
              <FormField label={formatMessage({ id: 'logformview.method' })}>
                <Typography variant="body">{formatMessage({ id: sysMan })}</Typography>
              </FormField>
            </Column>
            <Column xs={12} sm={12} md={4}>
              <FormField label={formatMessage({ id: 'logformview.assignedto' })}>
                <Typography variant="body">{activity?.AssignTo}</Typography>
              </FormField>
            </Column>
          </Row>
          <LogFormViewSummary
            activity={activity}
            bCanEditActivity={logformextdata.data.CanEditActivity}
            logformactivityextdata={logformextdata.data?.ActivityExtData}
            setLogFormViewVisible={setLogFormViewVisible}
          />
        </React.Fragment>
      )}
    </form>
  );
};

export default LogFormViewModal;
