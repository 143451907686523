import {
  DropdownButtonProvider,
  DropdownLinkButton,
  DropdownList,
  DropdownListItem,
  Flex,
  Icon,
  Link,
  Spacer,
  Typography,
} from 'alloy-foundation';
import { Dispatch, SetStateAction, useEffect } from 'react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { DateTime } from 'luxon';
import { IncompleteWorkItemReduced } from '../../../models/WorkQueue';
import Divider from '../../common/divider/Divider';
import stylesFn from './styles';
import { HighlightFilterValue } from '../../data-table/DataTableColumn';
import { availableAssignee, useAvailableAssignee, useUpdateWorkItemPriority } from './useWorkQueue';
import ReassignWorkItemModal from './ReassignWorkItemModal';
import DeleteWorkItemModal from './DeleteWorkItemModal';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

const HIGH_PRIORITY = 1;
const NORMAL_PRIORITY = 3;

interface Props {
  index: number;
  data: IncompleteWorkItemReduced;
  onItemClick: Dispatch<SetStateAction<string>>;
}

export const getDueDateColor = (date: Date) => {
  const dueDate = new Date(date);
  const today = new Date();

  if (isDueToday(date)) return 'success';
  else if (dueDate?.getTime() < today.getTime()) return 'error';

  return 'grayDark';
};

export const isDueToday = (date: Date) => {
  const dueDate = DateTime.fromISO(date, { zone: 'utc' }).toLocal();
  const today = new Date();

  return (
    dueDate?.c?.day === today.getDate() &&
    dueDate?.c?.month === today.getMonth() + 1 &&
    dueDate?.c?.year === today.getFullYear()
  );
};

const WorkItemCard = ({ index, data, onItemClick }: Props) => {
  const { css, styles } = useStyles({ stylesFn });
  const [highPriority, setHighPriority] = useState<boolean>(false);
  const updateMutation = useUpdateWorkItemPriority();
  const { getVimUserId } = useProductInfoProvider();
  const { formatMessage } = useIntl();
  const [reassignVisibility, setReassignVisibility] = useState(false);
  const [deleteVisible, setDeleteVisibility] = useState(false);
  const options: availableAssignee = useAvailableAssignee(data.Id);
  useEffect(() => {
    setHighPriority(data.Priority < 3);
  }, [data.Priority]);

  const handleReassignToggle = () => setReassignVisibility((prevState) => !prevState);
  const handleDeleteToggle = () => setDeleteVisibility((prevState) => !prevState);
  const handleClose = () => {
    setReassignVisibility(false);
    setDeleteVisibility(false);
  };

  const handlePriorityFlagClick = () => {
    const newPriority = !highPriority;
    setHighPriority(newPriority);
    updateMutation.mutate({
      id: data.Id,
      priority: newPriority ? HIGH_PRIORITY : NORMAL_PRIORITY,
      customerId: data.CustomerId,
    });
  };

  return (
    <div {...css(styles.card)}>
      <div {...css(styles.priorityHeader)}>
        <Spacer pt="xsmall" pl="xsmall" pr="xsmall">
          <div {...css(styles.priorityFlag)} data-testid="priority-flag">
            <Flex justifyContent="center">
              <Spacer pt="xsmall">
                <Icon
                  name={highPriority ? 'flag' : 'flagOutline'}
                  color="orange"
                  onClick={handlePriorityFlagClick}
                  data-testid={highPriority ? 'flag-critical' : 'flag-normal'}
                />
              </Spacer>
            </Flex>
          </div>
        </Spacer>
      </div>
      <div
        {...css(styles.cardContent)}
        data-testid="card-content"
        role="button"
        tabIndex={index}
        onClick={() => onItemClick(data.Id)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onItemClick(data.Id);
        }}
      >
        <Spacer pt="small" pl="small">
          <Link block={true} truncated={true}>
            <HighlightFilterValue>{data.TaskTitle}</HighlightFilterValue>
          </Link>
          <Typography noWrap={true} variant="bodySm">
            <HighlightFilterValue>{data.ProcessTitle}</HighlightFilterValue>
          </Typography>
          <Divider pt="tiny" pb="tiny" ml="none" mr="none" />
          <div {...css(styles.workItemLabel)}>
            <Typography noWrap={true} variant="bodySm" fontWeight="semibold">
              <FormattedMessage id="drawer.workQueue.card.due" />
            </Typography>
          </div>
          <div {...css(styles.workItemValue)}>
            <Typography noWrap={true} variant="bodySm" color={getDueDateColor(data.DueDate)}>
              <HighlightFilterValue>
                {data.FormattedDueDate
                  ? isDueToday(data.DueDate)
                    ? formatMessage({ id: 'common.today' })
                    : data.FormattedDueDate
                  : ' - '}
              </HighlightFilterValue>
            </Typography>
          </div>
          <div {...css(styles.workItemLabel)}>
            <Typography noWrap={true} variant="bodySm" fontWeight="semibold">
              <FormattedMessage id="drawer.workQueue.card.customer" />
            </Typography>
          </div>
          <div {...css(styles.workItemValue)}>
            <Typography noWrap={true} variant="bodySm">
              <HighlightFilterValue>{data.CustomerName}</HighlightFilterValue>
            </Typography>
          </div>
        </Spacer>
      </div>
      <div {...css(styles.actionsHeader)}>
        <Spacer pl="tiny" pr="tiny" pt="tiny">
          <DropdownButtonProvider>
            <DropdownLinkButton size="small" data-testid="card-actions" aria-label="card-actions" />
            <DropdownList>
              <DropdownListItem data-testid="reassignButton" onSelect={handleReassignToggle}>
                <FormattedMessage id="drawer.workQueue.actions.reassign" />
              </DropdownListItem>
              <DropdownListItem data-testid="deleteButton" onSelect={handleDeleteToggle}>
                <FormattedMessage id="drawer.workQueue.actions.delete" />
              </DropdownListItem>
            </DropdownList>
          </DropdownButtonProvider>
        </Spacer>
        {reassignVisibility ? (
          <ReassignWorkItemModal
            handleClose={handleClose}
            handleToggle={handleReassignToggle}
            data={data}
            options={options}
            VimUserId={getVimUserId().toString()}
          />
        ) : null}
        {deleteVisible ? (
          <DeleteWorkItemModal
            handleClose={handleClose}
            handleToggle={handleDeleteToggle}
            data={data}
          />
        ) : null}
      </div>
    </div>
  );
};

export default WorkItemCard;
