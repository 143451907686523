import { Spacer, Typography } from 'alloy-foundation';
import React from 'react';

type Props = {
  title: string;
  topSpacer?: boolean;
};

const Header = ({ title, topSpacer }: Props) => {
  return (
    <>
      {topSpacer && <Spacer mb="medium" />}
      <Typography variant="h4" color="grayMedium" style={{ textTransform: 'uppercase' }}>
        {title.toLocaleUpperCase()}
      </Typography>
      <Spacer mb="medium" />
    </>
  );
};

Header.defaultProps = {
  topSpacer: true,
};

export default Header;
