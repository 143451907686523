import { Agency, ProductInfo, SelectAgencyInfo } from '../../models/ProductInfo';

export const getAgencies = (): Agency[] => {
  let productInfo: any = window.productInfo as SelectAgencyInfo;

  if (!productInfo) {
    productInfo = window.productInfo as ProductInfo;
  }

  return productInfo?.Agencies;
};

// Getting the error code from the URL is difficult or require code that can easily break.
// We need to update to react-router-dom 6 but that break lots of stuff in the app.
export const getErrorCode = (): number | undefined => {
  let productInfo: any = window.productInfo as SelectAgencyInfo;

  if (!productInfo) {
    productInfo = window.productInfo as ProductInfo;
  }

  return productInfo?.Error;
};
