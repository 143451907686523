import React from 'react';
import { Flex } from 'alloy-foundation';
import { DataTableColumn, CellProps, HighlightFilterValue } from '../../data-table/DataTableColumn';
import { Locale } from '../../localization/Locale';

export class NumberColumn<Data> extends DataTableColumn<Data> {
  decimals?: number;
  commas?: boolean;
  prefix?: string;
  suffix?: string;
  align: DataTableColumn<Data>['align'] = 'right';
  locale?: Locale;

  constructor({
    decimals = 2,
    commas = true,
    locale = Locale.EnCa,
    align = 'right',
    Cell,
    ...values
  }: Partial<NumberColumn<Data>> & { key: DataTableColumn<Data>['key'] }) {
    super(values);
    Object.assign(this, { decimals, commas, locale, align });

    if (Cell) Object.assign(this, { Cell });
  }

  format = (row: Data) => {
    if (this.getValue) {
      const value = this.getValue(row) as number;
      return new Intl.NumberFormat(this.locale, {
        useGrouping: this.commas,
        minimumFractionDigits: this.decimals,
      }).format(value);
    }
    return '';
  };

  get justifyContent() {
    switch (this.align) {
      case 'left':
        return 'flex-start';
      case 'center':
        return 'center';
      default:
        return 'flex-end';
    }
  }

  Cell = ({ row }: CellProps<Data>) => {
    return (
      <Flex justifyContent={this.justifyContent}>
        <HighlightFilterValue>{this.prefix ? this.prefix : null}</HighlightFilterValue>
        <HighlightFilterValue>{this.format(row)}</HighlightFilterValue>
        <HighlightFilterValue>{this.suffix ? this.suffix : null}</HighlightFilterValue>
      </Flex>
    );
  };
}
