import { TableBody, TableCell, TableHead, TableRow } from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { Instance } from '../../../models/QuickNavInstance';
import { ExpandableRowProps } from '../../data-table';
import { sortingLastModifiedDate } from './quickNavUtils';
import VersionCard from './VersionCard';
import stylesFn from './styles';

export const VERSION_WIDTH = 450;
export const LASTUPDATE_WIDTH = 200;
export const ROW_HEIGHT = 72;
const ExpandedVersionInfo = ({ row }: ExpandableRowProps<Instance>) => {
  const { css, styles } = useStyles({ stylesFn });
  const sortedVersions = sortingLastModifiedDate(row?.Versions);
  const [divWidth, setDivWidth] = useState(null);
  sortedVersions.sort((a) => (a.IsDraft ? -1 : 1));
  const { formatMessage } = useIntl();

  useEffect(() => {
    setDivWidth(document.getElementById('expanded-row-div').offsetWidth - 42);
  }, []);
  return (
    <React.Fragment>
      <TableHead border={false}>
        <TableRow display="flex" alignItems="baseline" border={false}>
          <TableCell width={VERSION_WIDTH} colSpan={2}>
            Versions
          </TableCell>
          <TableCell width={LASTUPDATE_WIDTH}>
            {formatMessage({ id: 'quickNav.configuration.instances.expanded.lastupdate' })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {divWidth && (
          <div {...css(styles.container, { width: divWidth })}>
            {sortedVersions?.map((version, rowIndex) => (
              <div key={rowIndex}>
                <VersionCard
                  version={version}
                  instanceId={row?.Id}
                  templateId={row?.IsVariation ? row?.ParentId.toString() : row?.Id.toString()}
                  rowIndex={rowIndex}
                ></VersionCard>
              </div>
            ))}
          </div>
        )}
      </TableBody>
    </React.Fragment>
  );
};

export default ExpandedVersionInfo;
