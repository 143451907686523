import React, { Dispatch, SetStateAction, useState } from 'react';
import { DateTime } from 'luxon';
import {
  BaseInput,
  DatePickerInput,
  FilterMenu,
  Flex,
  FormField,
  RadioButton,
  Spacer,
  Typography,
} from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';
import {
  DateFilter,
  DateFilterSettimgs,
  defaultDateRange,
  defaultDayRange,
  defaultFilterSettings,
} from './DateFilter';
import { useFormatting } from '../../common/hooks/useFormatting';

interface Props {
  dateFilter?: DateFilterSettimgs;
  setDateFilter?: Dispatch<SetStateAction<DateFilterSettimgs>>;
}

const WorkQueueDateFilter = ({
  dateFilter = defaultFilterSettings,
  setDateFilter = noop,
}: Props) => {
  const { formatMessage } = useIntl();

  const [selectedFilter, setSelectedFilter] = useState(dateFilter.filter);
  const [startDays, setStartDays] = useState(dateFilter.fromDays);
  const [endDays, setEndDays] = useState(dateFilter.toDays);

  const [startDate, setStartDate] = useState(dateFilter.fromDate);
  const [endDate, setEndDate] = useState(dateFilter.toDate);

  const isAmountOfDays = selectedFilter === DateFilter.AmountOfDays;
  const isDateRange = selectedFilter === DateFilter.DateRange;

  let modifiers;
  if (startDate && endDate) {
    modifiers = { start: startDate, end: endDate };
  } else if (startDate) {
    modifiers = { start: startDate, end: undefined };
  } else {
    modifiers = undefined;
  }

  const isAppliedAmountOfDays = dateFilter.filter === DateFilter.AmountOfDays;
  const isAppliedShowAll = dateFilter.filter === DateFilter.ShowAll;
  const { formatLocalDate } = useFormatting();
  const toggleText = isAppliedAmountOfDays
    ? formatMessage(
        { id: 'drawer.workQueue.filter.amountOfDays.toggleText' },
        { from: dateFilter.fromDays, to: dateFilter.toDays }
      )
    : isAppliedShowAll
    ? formatMessage({ id: 'drawer.workQueue.filter.showAll.toggleText' })
    : `${formatLocalDate(dateFilter.fromDate.toISOString())} - ${formatLocalDate(
        dateFilter.toDate?.toISOString()
      )}`;

  const amountOfDays = parseInt(endDays) - parseInt(startDays);
  const isAmountOfDaysValid =
    startDays !== '' &&
    endDays !== '' &&
    Number(startDays) <= Number(endDays) &&
    amountOfDays <= 365 &&
    amountOfDays > 0;

  const startDateTime = DateTime.fromJSDate(startDate);
  const endDateTime = DateTime.fromJSDate(endDate);
  const rangeOfDates = endDateTime.diff(startDateTime, 'days');
  const isDateRangeValid =
    startDate &&
    endDate &&
    startDate <= endDate &&
    rangeOfDates.days <= 365 &&
    rangeOfDates.days >= 0;

  const handleApply = () => {
    setDateFilter({
      filter: selectedFilter,
      fromDays: startDays,
      toDays: endDays,
      fromDate: startDate,
      toDate: endDate,
    });
  };

  const handleCancel = () => {
    setSelectedFilter(dateFilter.filter);
    setStartDays(dateFilter.fromDays);
    setEndDays(dateFilter.toDays);
    setStartDate(dateFilter.fromDate);
    setEndDate(dateFilter.toDate);
  };

  const resetFilter = (resetNumDays: boolean, resetDateRange: boolean) => {
    if (resetNumDays) {
      setStartDays(defaultDayRange.from);
      setEndDays(defaultDayRange.to);
    }

    if (resetDateRange) {
      setStartDate(defaultDateRange.from);
      setEndDate(defaultDateRange.to);
    }
  };

  return (
    <FilterMenu
      overflow="visible"
      onCancel={handleCancel}
      onApply={handleApply}
      toggleText={toggleText}
      buttonGroupProps={{
        cancelText: formatMessage({ id: 'common.button.cancel' }),
        saveText: formatMessage({ id: 'common.button.apply' }),
        disableSave: (!isAmountOfDaysValid && isAmountOfDays) || (!isDateRangeValid && isDateRange),
      }}
    >
      <RadioButton
        data-testid="amountOfDays"
        renderErrorMessage={false}
        compactSpacing
        label={formatMessage({ id: 'drawer.workQueue.filter.amountOfDays' })}
        value=""
        checked={selectedFilter === DateFilter.AmountOfDays}
        onChange={(checked) => {
          if (checked) {
            setSelectedFilter(DateFilter.AmountOfDays);
            // reset date range
            resetFilter(false, true);
          }
        }}
      />
      {isAmountOfDays ? (
        <Spacer ml="medium" mb="tiny">
          <FormField
            label=""
            hideLabel
            invalid={!isAmountOfDaysValid}
            errorMessage={formatMessage({ id: 'drawer.workQueue.filter.amountOfDays.error' })}
          >
            <Flex alignItems="center">
              <Flex width="40px">
                <BaseInput
                  data-testid="fromDays"
                  maxLength={3}
                  invalid={!isAmountOfDaysValid}
                  format="numberPositiveWholeNumber"
                  small
                  value={startDays}
                  onChange={setStartDays}
                />
              </Flex>
              <Spacer ml="xsmall" mr="xsmall">
                <Typography variant="bodySm">-</Typography>
              </Spacer>
              <Flex width="40px">
                <BaseInput
                  data-testid="toDays"
                  maxLength={3}
                  invalid={!isAmountOfDaysValid}
                  format="numberPositiveWholeNumber"
                  small
                  value={endDays}
                  onChange={setEndDays}
                />
              </Flex>
            </Flex>
          </FormField>
        </Spacer>
      ) : null}
      <RadioButton
        data-testid="dateRange"
        renderErrorMessage={false}
        compactSpacing
        label={formatMessage({ id: 'drawer.workQueue.filter.dateRange' })}
        value=""
        checked={selectedFilter === DateFilter.DateRange}
        onChange={(checked) => {
          if (checked) {
            setSelectedFilter(DateFilter.DateRange);
            // reset number of days
            resetFilter(true, false);
          }
        }}
      />
      {isDateRange ? (
        <Spacer ml="medium">
          <FormField
            label=""
            hideLabel
            invalid={!isDateRangeValid}
            errorMessage={formatMessage({ id: 'drawer.workQueue.filter.dateRange.error' })}
          >
            <Flex alignItems="center">
              <Flex width="120px">
                <DatePickerInput
                  data-testid="fromDate"
                  renderErrorMessage={false}
                  small
                  invalid={!isDateRangeValid}
                  hideLabel
                  label=""
                  onChange={(value, date, event) => {
                    setStartDate(date);
                  }}
                  value={startDate}
                  datePickerProps={{
                    disabledDays: endDate ? { after: endDate } : undefined,
                    modifiers,
                    month: new Date(),
                    toMonth: endDate || undefined,
                    selectedDays:
                      startDate && endDate
                        ? [endDate, startDate, { from: startDate, to: endDate }]
                        : undefined,
                  }}
                />
              </Flex>
              <Spacer ml="xsmall" mr="xsmall">
                <Typography variant="bodySm">-</Typography>
              </Spacer>
              <Flex width="120px">
                <DatePickerInput
                  data-testid="toDate"
                  renderErrorMessage={false}
                  small
                  hideLabel
                  invalid={!isDateRangeValid}
                  label=""
                  onChange={(value, date, event) => {
                    setEndDate(date);
                  }}
                  value={endDate}
                  datePickerProps={{
                    disabledDays: startDate ? { before: startDate } : undefined,
                    fromMonth: startDate || undefined,
                    modifiers,
                    month: startDate || undefined,
                    selectedDays:
                      startDate && endDate
                        ? [endDate, startDate, { from: startDate, to: endDate }]
                        : undefined,
                  }}
                />
              </Flex>
            </Flex>
          </FormField>
        </Spacer>
      ) : null}
      <RadioButton
        data-testid="showAll"
        renderErrorMessage={false}
        compactSpacing
        label={formatMessage({ id: 'drawer.workQueue.filter.showAll' })}
        value=""
        checked={selectedFilter === DateFilter.ShowAll}
        onChange={(checked) => {
          if (checked) {
            setSelectedFilter(DateFilter.ShowAll);
            // reset number of days and date range
            resetFilter(true, true);
          }
        }}
      />
    </FilterMenu>
  );
};

export default WorkQueueDateFilter;
