import { Column, FormDropdownSingleSelect, FormInput, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import BaseCustomerDetails from './BaseCustomerDetails';
import {
  PREFIX,
  FIRST_NAME,
  LAST_NAME,
  MIDDLE_NAME,
  DOB,
  MARITAL_STATUS,
  SECONDARY_CUSTOMER_CONTACT,
} from '../../fieldNames';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import LocalizedFormDatePickerInput from '../../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';

type PersonalCustomerDetailsProps = {
  parentNamespace?: string;
  dropdowns: CustomerDropdowns;
  hideBaseCustomerDetails?: boolean;
  showSecondaryFields?: boolean;
};

const PersonalCustomerDetails = (props: PersonalCustomerDetailsProps) => {
  const { formatMessage } = useIntl();
  const prefix = props.parentNamespace ? `${props.parentNamespace}.` : '';
  const secondaryPrefix = props.parentNamespace
    ? `${props.parentNamespace}.${SECONDARY_CUSTOMER_CONTACT}.`
    : '';
  return (
    <React.Fragment>
      <Spacer mr="xlarge" mb="tiny">
        {!props.hideBaseCustomerDetails && (
          <BaseCustomerDetails
            parentNamespace={props.parentNamespace ?? ''}
            dropdowns={props.dropdowns}
          />
        )}
        <Spacer mb="tiny">
          <Row data-testid="personal">
            <Column md={2}>
              <FormDropdownSingleSelect
                required={false}
                name={prefix + PREFIX}
                label={formatMessage({ id: 'customer.details.personal.prefix.label' })}
                options={props.dropdowns.Salutations.map((data) => {
                  return { value: data.Key, label: data.Value };
                })}
                placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                data-testid={prefix + PREFIX}
              />
            </Column>
            <Column md={3}>
              <FormInput
                name={prefix + FIRST_NAME}
                maxLength={27}
                label={formatMessage({ id: 'customer.details.personal.firstname.label' })}
                required={true}
                data-testid={prefix + FIRST_NAME}
              />
            </Column>
            <Column md={3}>
              <FormInput
                name={prefix + LAST_NAME}
                maxLength={120}
                label={formatMessage({ id: 'customer.details.personal.lastname.label' })}
                required={true}
                data-testid={prefix + LAST_NAME}
              />
            </Column>
            <Column md={3}>
              <FormInput
                name={prefix + MIDDLE_NAME}
                maxLength={11}
                label={formatMessage({ id: 'customer.details.personal.middlename.label' })}
                required={false}
                data-testid={prefix + MIDDLE_NAME}
              />
            </Column>
          </Row>
        </Spacer>
        <Row>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              name={prefix + DOB}
              label={formatMessage({ id: 'customer.details.personal.dob.label' })}
              errorMessage="rererer"
              required={false}
              data-testid={prefix + DOB}
            />
          </Column>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={false}
              name={prefix + MARITAL_STATUS}
              label={formatMessage({ id: 'customer.details.personal.maritalstatus.label' })}
              options={props.dropdowns.MaritalStatuses.map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
        </Row>
        {props.showSecondaryFields && (
          <React.Fragment>
            <Spacer mb="tiny">
              <Row data-testid="personal">
                <Column md={2}>
                  <FormDropdownSingleSelect
                    required={false}
                    name={secondaryPrefix + PREFIX}
                    label={formatMessage({ id: 'customer.details.personal.prefix.sc.label' })}
                    options={props.dropdowns.Salutations.map((data) => {
                      return { value: data.Key, label: data.Value };
                    })}
                    placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                    data-testid={secondaryPrefix + PREFIX}
                  />
                </Column>
                <Column md={3}>
                  <FormInput
                    name={secondaryPrefix + FIRST_NAME}
                    maxLength={27}
                    label={formatMessage({ id: 'customer.details.personal.firstname.sc.label' })}
                    required={false}
                    data-testid={secondaryPrefix + FIRST_NAME}
                  />
                </Column>
                <Column md={3}>
                  <FormInput
                    name={secondaryPrefix + LAST_NAME}
                    maxLength={120}
                    label={formatMessage({ id: 'customer.details.personal.lastname.sc.label' })}
                    required={false}
                    data-testid={secondaryPrefix + LAST_NAME}
                  />
                </Column>
                <Column md={3}>
                  <FormInput
                    name={secondaryPrefix + MIDDLE_NAME}
                    maxLength={11}
                    label={formatMessage({ id: 'customer.details.personal.middlename.sc.label' })}
                    required={false}
                    data-testid={secondaryPrefix + MIDDLE_NAME}
                  />
                </Column>
              </Row>
            </Spacer>
            <Row>
              <Column md={3}>
                <LocalizedFormDatePickerInput
                  name={secondaryPrefix + DOB}
                  label={formatMessage({ id: 'customer.details.personal.dob.label' })}
                  errorMessage="rererer"
                  required={false}
                  data-testid={prefix + DOB}
                />
              </Column>
              <Column md={3}>
                <FormDropdownSingleSelect
                  required={false}
                  name={secondaryPrefix + MARITAL_STATUS}
                  label={formatMessage({ id: 'customer.details.personal.maritalstatus.label' })}
                  options={props.dropdowns.MaritalStatuses.map((data) => {
                    return { value: data.Key, label: data.Value };
                  })}
                  placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                />
              </Column>
            </Row>
          </React.Fragment>
        )}
      </Spacer>
    </React.Fragment>
  );
};

export default PersonalCustomerDetails;
