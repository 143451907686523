import { DateTime } from 'luxon';
import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { SmartflowConstants } from '../../constants/smartflowConstant';
import { PolicyReduced } from '../../models/PolicySummary';
import { PublishedProcessRestModel } from '../../models/smartflow/PublishedProcessRestModel';

const processUrl = '/process/';
const dateFormat = 'MM/dd/yyyy';

const getClientBusinessObject = (entity, clientName, clientId) => {
  return {
    Index: 'Client',
    Source: SmartflowConstants.summary.services.ams360,
    Type: SmartflowConstants.summary.services.client,
    Id: clientId ? clientId : entity.AttributeFields.ClientId,
    UserFriendlyId: entity ? entity.UserFriendlyId : clientId,
    Description: clientName,
  };
};

const getPolicyExpDate = (policy: PolicyReduced) => {
  if (policy.ExpirationDate === null || policy.ExpirationDate === '') {
    return SmartflowConstants.summary.services.policyAction.continous;
  } else {
    return DateTime.fromISO(policy.ExpirationDate, { zone: 'utc' }).toFormat(dateFormat);
  }
};

const getPolicyEndDate = (policy: PolicyReduced) => {
  return DateTime.fromISO(policy.EndEffDate, { zone: 'utc' }).toFormat('yyyy-MM-dd HH:mm:ss.SSS');
};

const getPolicyEffDate = (policy: PolicyReduced) => {
  return DateTime.fromISO(policy.EffectiveDate, { zone: 'utc' }).toFormat(dateFormat);
};

const CNMRenewal = (
  policy: PolicyReduced,
  clientName: string,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: selectedQuickNavVariation.Id,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.expiringPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              PolicyNumber: policy.PolicyNumber,
              ExpirationDate: getPolicyExpDate(policy),
              EndEffDate: getPolicyEndDate(policy),
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const CMRenewal = (
  policy: PolicyReduced,
  clientName,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: selectedQuickNavVariation.Id,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.expiringPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              PolicyNumber: policy.PolicyNumber,
              ExpirationDate: getPolicyExpDate(policy),
              EndEffDate: getPolicyEndDate(policy),
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const DownloadRenewal = (
  policy: PolicyReduced,
  clientName,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: selectedQuickNavVariation.Id,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.expiringPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              PolicyNumber: policy.PolicyNumber,
              EndEffDate: getPolicyEndDate(policy),
              TermYear: `${getPolicyEffDate(policy)} - ${getPolicyExpDate(policy)}`,
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const NPCancellation = (
  policy: PolicyReduced,
  clientName,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: selectedQuickNavVariation.Id,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.expiringPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              PolicyNumber: policy.PolicyNumber,
              TermYear: `${getPolicyEffDate(policy)} - ${getPolicyExpDate(policy)}`,
              FullTermPremium: policy.Premium,
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const CICancellation = (
  policy: PolicyReduced,
  clientName,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: selectedQuickNavVariation.Id,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.expiringPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              PolicyNumber: policy.PolicyNumber,
              TermYear: `${getPolicyEffDate(policy)} - ${getPolicyExpDate(policy)}`,
              FullTermPremium: policy.Premium,
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const ReinstatementInitiateAction = (
  policy: PolicyReduced,
  clientName,
  processDefinitionId: number
) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: processDefinitionId,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.existingPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              ExpirationDate: getPolicyExpDate(policy),
              PolicyNumber: policy.PolicyNumber,
              PolicyTerm: `${getPolicyEffDate(policy)} - ${getPolicyExpDate(policy)}`,
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const InitiateAction = (policy: PolicyReduced, clientName, processDefinitionId: number) => {
  if (policy !== undefined && policy !== null) {
    return {
      Source: SmartflowConstants.summary.services.policyAction.smartflow,
      Type: SmartflowConstants.summary.services.policyAction.checklist,
      ProcessDefinitionId: processDefinitionId,
      Payload: {
        BusinessObjects: [
          getClientBusinessObject(policy, clientName, policy.CustId),
          {
            Index: SmartflowConstants.summary.services.policyAction.existingPolicy,
            Source: SmartflowConstants.summary.services.ams360,
            Type: SmartflowConstants.summary.services.policyAction.policy,
            Id: policy.Id,
            Attributes: {
              ExpirationDate: getPolicyExpDate(policy),
              EndEffDate: getPolicyEndDate(policy),
              PolicyNumber: policy.PolicyNumber,
              TermYear: `${getPolicyEffDate(policy)} - ${getPolicyExpDate(policy)}`,
              TypeOfBusiness: policy.PolicyType,
              LineOfBusiness: policy.LineOfBusiness,
            },
            UserFriendlyId: policy.UserFriendlyId,
            Description: policy.Description,
          },
        ],
        Values: {
          PolicyId: policy.Id,
        },
      },
    };
  }
};

const SubmitNewBusiness = (
  clientId,
  clientName,
  selectedQuickNavVariation: PublishedProcessRestModel
) => {
  return {
    Source: SmartflowConstants.summary.services.policyAction.smartflow,
    Type: SmartflowConstants.summary.services.policyAction.checklist,
    ProcessDefinitionId: selectedQuickNavVariation.Id,
    Payload: {
      BusinessObjects: [getClientBusinessObject(null, clientName, clientId)],
    },
  };
};

const publishNewWorkItem = (body: any) => {
  if (body) {
    return baseSmartflowRequests.post(processUrl, body);
  } else throw new Error('Body cannot be empty');
};

// Please keep following the standard set by getIsCustomerAdmin.
export const initiateExistingPolicyQuickNav = (
  policy: PolicyReduced,
  clientName,
  quickNav: PublishedProcessRestModel,
  selectedQuickNavVariation: PublishedProcessRestModel
): Promise<any> => {
  if (quickNav && quickNav.Name) {
    const processName = quickNav.Name;
    let body = null;
    switch (processName) {
      case 'CNMRenewal':
      case 'CLBPRenewal':
        body = CNMRenewal(policy, clientName, selectedQuickNavVariation);
        break;

      case 'CMRenewal':
      case 'RRenewal':
        body = CMRenewal(policy, clientName, selectedQuickNavVariation);
        break;

      case 'DownloadRenewal':
      case 'DownloadRenewalCompare':
        body = DownloadRenewal(policy, clientName, selectedQuickNavVariation);
        break;

      case 'NPCancellation':
        body = NPCancellation(policy, clientName, selectedQuickNavVariation);
        break;

      case 'CICancellation':
        body = CICancellation(policy, clientName, selectedQuickNavVariation);
        break;

      case 'ReinstatementAgencyBill':
        body = ReinstatementInitiateAction(policy, clientName, selectedQuickNavVariation.Id);
        break;

      case 'ReinstatementDirectBill':
        body = ReinstatementInitiateAction(policy, clientName, selectedQuickNavVariation.Id);
        break;

      case 'DownloadEndorsement':
        body = InitiateAction(policy, clientName, selectedQuickNavVariation.Id);
        break;

      case 'NonDownloadEndorsement':
      case 'BPPolicyEndorsement':
        body = InitiateAction(policy, clientName, selectedQuickNavVariation.Id);
        break;
    }

    return publishNewWorkItem(body);
  }
};

export const initiateNewPolicyQuickNav = (
  clientId,
  clientName,
  quickNav: PublishedProcessRestModel,
  selectedQuickNavVariation: PublishedProcessRestModel
): Promise<any> => {
  if (quickNav && selectedQuickNavVariation && quickNav.Name && selectedQuickNavVariation.Id) {
    const processName = quickNav.Name;

    if (
      processName === 'CLNewBusiness' ||
      processName === 'NewBusiness' ||
      processName === 'CLBPNewBusiness'
    ) {
      const body = SubmitNewBusiness(clientId, clientName, selectedQuickNavVariation);
      return publishNewWorkItem(body);
    }
  }
};
