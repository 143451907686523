import React from 'react';
import { useIntl } from 'react-intl';
import Card from '../common/card/Card';
import ContactInformationViewOnly from './ContactInformationViewOnly';
import { SectionInfoProps } from '../SectionInfoProps';
import { ContactInformationEditForm } from './ContactInformationEditForm';

const ContactInformation = (props: SectionInfoProps) => {
  const { formatMessage } = useIntl();

  return (
    <Card
      title={formatMessage({ id: 'customer.details.contactInfo.header' })}
      canEdit={props.canEdit}
      onEdit={props.onEdit}
      viewControl={<ContactInformationViewOnly customer={props.customer} />}
      formControl={<ContactInformationEditForm {...props} />}
      namespace="contactInformation"
    />
  );
};

export default ContactInformation;
