import {
  Button,
  Column,
  Flex,
  Group,
  LoadingIndicator,
  Row,
  Spacer,
  Typography,
} from 'alloy-foundation';
import React from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { FormattedMessage } from 'react-intl';
import { useRefetch } from '../../common/hooks/useRefetch';
import CustomerInfo from './CustomerInfo';
import DrawerHeader from '../DrawerHeader';
import DrawerContent from '../DrawerContent';
import { useDrawer } from '../DrawerProvider';
import { useRecentCustomers } from './useRecentCustomers';
import stylesFn from './styles';

export const RECENT_CUSTOMERS = 'recentcustomers';

const RecentCustomersDrawer = () => {
  const { css, styles } = useStyles({ stylesFn });
  const { fetch, refetch } = useRefetch();
  const { hideDrawer } = useDrawer();
  const recentCustomers = useRecentCustomers([RECENT_CUSTOMERS, fetch]);

  return (
    <>
      <DrawerHeader>
        <div {...css(styles.header)}>
          <Row noGutters={true}>
            <Column xs={9} md={9} sm={9}>
              <Spacer pl="small" pt="xlarge">
                <Typography variant="h2" color="white">
                  <FormattedMessage id="drawer.recentCustomers.title" />
                </Typography>
              </Spacer>
            </Column>
            <Column>
              <Flex justifyContent="flex-end">
                <Group spacing="tiny" stacked={true}>
                  <Button
                    icon="close"
                    variant="linkWhite"
                    onClick={() => hideDrawer()}
                    data-testid="drawer-close"
                  />
                  <Button
                    icon="refresh"
                    variant="linkWhite"
                    onClick={() => refetch()}
                    data-testid="drawer-refresh"
                  />
                </Group>
              </Flex>
            </Column>
          </Row>
        </div>
      </DrawerHeader>
      <DrawerContent>
        <div {...css(styles.container)}>
          {recentCustomers.isLoading || recentCustomers.isError ? (
            <Spacer pt="small">
              <Flex justifyContent="center">
                {recentCustomers.isLoading && <LoadingIndicator />}
                {recentCustomers.isError && (
                  <Typography color="white" variant="disclaimer">
                    <FormattedMessage id="common.error_loading_data" />
                  </Typography>
                )}
              </Flex>
            </Spacer>
          ) : (
            <>
              {(recentCustomers.data ?? []).map((p, i) => (
                <CustomerInfo key={i} data={p} />
              ))}
            </>
          )}
        </div>
      </DrawerContent>
    </>
  );
};

export default RecentCustomersDrawer;
