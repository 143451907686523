import {
  Button,
  Column,
  Flex,
  FormCheckBox,
  Link,
  Row,
  Spacer,
  TextArea,
  Typography,
} from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as fieldNames from './fieldNames';
import { LogFormSuspenseExtData } from '../../../models/LogFormData';

type LogFormSummaryProps = {
  suspenseId: string;
  logformsuspenseextdata: LogFormSuspenseExtData;
};

const LogFormSummary = ({ suspenseId, logformsuspenseextdata }: LogFormSummaryProps) => {
  const { formatMessage } = useIntl();
  const { setFieldValue } = useFormikContext();
  const [bSummaryEdit, setSummaryEdit] = useState(false);
  const [bCanEditSuspense, setCanEditSuspense] = useState(false);
  const [suspenseSummary, setSuspenseSummary] = useState('');
  const [extraSuspenseSummary, setextraSuspenseSummary] = useState('');
  const bCreating = suspenseId === '';

  const handleSummaryEdit = () => {
    if (bCanEditSuspense) {
      setSummaryEdit(!bSummaryEdit);
      setCanEditSuspense(false);
    }
  };
  const handleSummaryChange = (event) => {
    setextraSuspenseSummary(event);
    setFieldValue(fieldNames.SUMMARY, event);
  };

  const handleExtraSummaryChange = (event) => {
    setSuspenseSummary(event);
    setFieldValue(fieldNames.EXTRASUMMARY, event);
  };

  useEffect(() => {
    let temp = '';
    if (logformsuspenseextdata) {
      if (logformsuspenseextdata?.SuspenseExtData.length !== 0) {
        logformsuspenseextdata?.SuspenseExtData.forEach((ext) => {
          if (ext.Comment1.length !== 0) {
            if (logformsuspenseextdata.SuspenseExtData.length !== 1) {
              temp = `${temp} \n ${ext.ShortName}`;
              temp = `${temp} \n ${ext.Comment1}`;
              temp = `${temp} \n -------`;
            } else temp = `${ext.Comment1}`;
          }
        });
        setextraSuspenseSummary(temp);
        if (logformsuspenseextdata?.CanEditSuspense) {
          setCanEditSuspense(true);
        }
      }
    }
  }, [logformsuspenseextdata]);
  return (
    <>
      <>
        <Row>
          <Column>
            <Typography variant="h5">{formatMessage({ id: 'logform.attachdoc' })}</Typography>
          </Column>
        </Row>
        <Row>
          <Column>
            <Flex alignItems="center">
              <Button icon="addNew" disabled={true}>
                {formatMessage({ id: 'logform.uploaddoc' })}
              </Button>
              <Spacer pl="small" pt="medium">
                <FormCheckBox
                  name={fieldNames.SHARETOINSURLINK}
                  label={formatMessage({ id: 'logform.sharetoinsurlink' })}
                />
              </Spacer>
            </Flex>
          </Column>
        </Row>
        <Row>
          <Column xs={12} sm={12} md={10}>
            <Typography variant="h5" data-testid="summarylabeltest">
              {formatMessage({ id: 'logform.summary' })}
            </Typography>
          </Column>
          {!bCreating && (
            <Column xs={12} sm={12} md={2}>
              <div
                style={{
                  textAlign: 'right',
                  float: 'right',
                }}
              >
                <Link onClick={handleSummaryEdit} disabled={!bCanEditSuspense}>
                  {formatMessage({ id: 'logformview.EditSummary' })}
                </Link>
              </div>
            </Column>
          )}
        </Row>
        {bSummaryEdit && (
          <Row>
            <Column xs={12} sm={12} md={12}>
              <TextArea
                name={fieldNames.EXTRASUMMARY}
                label="extrasummary"
                value={suspenseSummary}
                hideLabel={true}
                placeholder={formatMessage({ id: 'logform.typesomething' })}
                autoResize={true}
                maxLength={8000}
                onChange={handleExtraSummaryChange}
                data-testid="detailsbox"
              />
            </Column>
          </Row>
        )}
        <Row>
          <Column xs={12} sm={12} md={12}>
            <TextArea
              name={fieldNames.SUMMARY}
              label={formatMessage({ id: 'logform.summary' })}
              value={extraSuspenseSummary}
              hideLabel={true}
              disabled={!bCreating}
              autoResize={true}
              maxLength={8000}
              onChange={handleSummaryChange}
              data-testid="summarybox"
            />
          </Column>
        </Row>
      </>
    </>
  );
};
export default LogFormSummary;
