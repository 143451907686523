import baseRequests from '../../api/baseRequests';
import { IGlobalNavItem } from '../../models/GlobalNavItem';
import CookiesService from '../cookies/CookiesService';

const urlWithCookies = () => {
  // base url
  let baseURL = 'api/GlobalNavItems?';
  // build cookies params
  const cookies = CookiesService.getCookiesNamesByValue('True');
  cookies.forEach(function (cookieName) {
    baseURL += `productGuids%5B%5D=${cookieName}&`;
  });
  return baseURL.slice(0, -1);
};

// exports
const GlobalNavService = {
  list: (): Promise<IGlobalNavItem[]> =>
    baseRequests.get(urlWithCookies()).then((response) => response),
};

export default GlobalNavService;
