import { useState } from 'react';

export const useVisible = (initialValue = false) => {
  const [visible, SetVisible] = useState<boolean>(initialValue);

  return {
    visible,
    SetVisible,
  };
};
