import {
  MutationOptions,
  QueryKey,
  useMutation,
  useQuery,
  QueryClient,
  UseQueryOptions,
} from 'react-query';
import { BusinessProcessTemplateRestModel } from '../../../models/smartflow/BusinessProcessTemplateRestModel';
import {
  getQuickNavTemplate,
  updateQuickNavTemplateStatus,
} from '../../../services/quicknav/configurationService';

export const useQuickNav = (
  options?: UseQueryOptions<
    BusinessProcessTemplateRestModel[],
    unknown,
    BusinessProcessTemplateRestModel[],
    QueryKey
  >
) => {
  const useCustomerQuery = useQuery<BusinessProcessTemplateRestModel[]>(
    'QuickNavTemplates',
    () => getQuickNavTemplate(),
    options
  );

  return useCustomerQuery;
};

export interface updateQuickNavTemplateStatusProp {
  template: BusinessProcessTemplateRestModel;
  newStatus: boolean;
}

export const useUpdateQuickNavTemplateStatus = (
  options: MutationOptions<
    updateQuickNavTemplateStatusProp,
    unknown,
    updateQuickNavTemplateStatusProp,
    any
  >
) => {
  const customerMutation = useMutation<
    updateQuickNavTemplateStatusProp,
    unknown,
    updateQuickNavTemplateStatusProp,
    any
  >(
    (data: updateQuickNavTemplateStatusProp) =>
      updateQuickNavTemplateStatus(data.template.Id, data.newStatus),
    options
  );

  return customerMutation;
};

// NOT A HOOK. This is a function to update a hook.
export const updateQuickNavTemplatesCache = (
  updatedCache: BusinessProcessTemplateRestModel[],
  queryClient: QueryClient
) => {
  queryClient.setQueryData('QuickNavTemplates', updatedCache);
};

// NOT A HOOK. This is a function to update a hook.
export const invalidateQuickNavTemplatesCache = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ['QuickNavTemplates'] });
  queryClient.invalidateQueries({ queryKey: ['quicknav-instance'] });
};
