import { Theme } from 'alloy-foundation';
import { Styles } from 'react-with-styles';

const stylesFn = ({ vertaforeAlloy: { color, radii, spacing } }: Theme): Styles => ({
  header: {
    height: '172px',
  },
  itemHeader: {
    height: '257px',
  },
  container: {
    height: 'calc(100vh - 15.75rem)',
  },
  itemContainer: {
    height: 'calc(100vh - 20.5rem)',
  },
  card: {
    height: '140px',
    backgroundColor: color.core.white,
    margin: spacing.small,
    borderRadius: radii.small,
    overflow: 'hidden',
    display: 'flex',
    border: 'none',
    outline: 'none',
  },
  priorityHeader: {
    backgroundColor: color.core.grayTint,
  },
  priorityFlag: {
    cursor: 'pointer',
  },
  cardContent: {
    backgroundColor: color.core.white,
    flexGrow: 1,
    minWidth: 0,
    cursor: 'pointer',
  },
  actionsHeader: {
    backgroundColor: color.core.white,
  },
  workItemLabel: {
    float: 'left',
    paddingRight: '5px',
  },
  workItemValue: {
    flexGrow: 1,
    minWidth: 0,
  },
  taskCard: {
    height: '52px',
  },
});

export default stylesFn;
