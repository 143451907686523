import React from 'react';
import { useEffectOnce } from 'alloy-foundation';
import Dashboard from './Dashboard';
import { DashboardViewProvider } from './DashboardViewProvider';
import { useDrawer } from '../drawer/DrawerProvider';
import { withLayoutShell } from '../common/hoc/withLayoutShell';

const DashboardContainer = () => {
  const { hideDrawer } = useDrawer();

  useEffectOnce(() => {
    hideDrawer();
  });

  return (
    // applying the following css (position: 'relative') fixes the issues with the double scrollbars on the
    // manager dashboard page.  ScrollWrapper should only be used with ScrollSection.
    <div style={{ position: 'relative' }}>
      <DashboardViewProvider>
        <Dashboard />
      </DashboardViewProvider>
    </div>
  );
};

export default withLayoutShell(DashboardContainer);
