import React, { useState } from 'react';
import {
  Button,
  Column,
  Container,
  DropdownButton,
  DropdownButtonProvider,
  DropdownFilterSelect,
  DropdownList,
  DropdownListItem,
  Flex,
  Icon,
  Link,
  Row,
  SectionContainer,
  Spacer,
  TableFilterArea,
  Tooltip,
  Typography,
} from 'alloy-foundation';
import { FormattedMessage } from 'react-intl';
import type { OptionType } from 'alloy-foundation';
import { noop } from 'lodash';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { DashboardPanelProps } from './DashboardPanelProps';
import { CsvDataService } from '../../../services/csvExport/CsvDataService';
import { useDataTable, useDataTableRows } from '../../data-table/useDataTable';
import LocalizedDataTable from '../../common/localizedDataTable/LocalizedDataTable';
import stylesFn from './styles';
import { useFeatureFlagService } from '../../../services/FeautureFlag/useFeautureFlagService';

export const DashboardPanel = <T extends object, K extends object>({
  title,
  id = '',
  columns,
  data,
  isLoading = false,
  options,
  optionsData,
  rowSelection = true,
  sorting = true,
  filtering = true,
  filterPlaceholder = '',
  filterOptions = [],
  initialFilterOption,
  onFilterChange = noop,
  initialSortDirection,
  initialSortedColumnKey,
  hasSelectedRowAction = true,
  selectedRowActionLabelFn = (row) => '',
  unselectedRowActionLabel = '',
  onSelectedRowActionClicked = noop,
  hasBulkActions = true,
  emptyMessage,
  onRefresh = noop,
  onOptionsApply = noop,
  exportColumnMapper,
  showClearCustomFilterOnApply = true,
  equatableRowKey,
  customFilterVisible = false,
  toolTipText,
  ...props
}: DashboardPanelProps<T, K>) => {
  const tableState = useDataTable({
    columns,
    data: data || [],
    filtering: false,
    rowSelection,
    sorting,
    initialSortedColumnKey,
    initialSortDirection,
    initialPageSize: 5,
  });
  const tableRows = useDataTableRows(tableState);

  const actionClickHandler = () => {
    if (tableState.selectedRow) onSelectedRowActionClicked(tableState.selectedRow);
  };

  const handleExport = () => {
    let items = data;
    if (exportColumnMapper) items = items.map(exportColumnMapper);

    CsvDataService.exportToCsv(`${title}.csv`, items);
  };

  const [optionsVisible, setOptionsVisible] = useState(false);
  const OptionsComponent = options;
  const dataTestId = props['data-testid'];

  const [selectedFilter, setSelectedFilter] = useState(initialFilterOption);

  const resetToFirstPage = () => tableState.onPageChange?.(0);

  const handleApply = (_data: K) => {
    onOptionsApply(_data);
    setOptionsVisible(false);
    resetToFirstPage();
  };

  const handleFilterChange = (filter: OptionType<string>) => {
    setSelectedFilter(filter);
    onFilterChange(filter);
    resetToFirstPage();
  };

  const handleClearCustomFilter = () => {
    onFilterChange(selectedFilter);
    resetToFirstPage();
  };

  const handleRefresh = () => {
    onRefresh();
    resetToFirstPage();
  };
  const flagName = 'mbh-ff-test';
  const { css, styles } = useStyles({ stylesFn });
  const isFeatureFlagEnabled = useFeatureFlagService(flagName);
  return (
    <div {...css(styles.SectionContainer)}>
      <SectionContainer
        headerContent={
          <Tooltip content={<Typography variant="body">{toolTipText}</Typography>}>
            <Icon
              testId="tooltip-icon"
              tabIndex={0}
              size={19}
              name="info"
              color={isFeatureFlagEnabled ? 'red' : 'blue'}
            />
          </Tooltip>
        }
        headerText={title}
        data-testid={dataTestId}
        id={id}
      >
        {OptionsComponent !== undefined && optionsVisible && (
          <OptionsComponent
            data={optionsData}
            onClose={() => setOptionsVisible(false)}
            onApply={handleApply}
          />
        )}

        <TableFilterArea>
          <Container fluid={true} noPadding={true}>
            <Row>
              <Column md={3}>
                {filtering && (
                  <DropdownFilterSelect
                    renderErrorMessage={false}
                    label=""
                    disabled={customFilterVisible}
                    options={filterOptions}
                    placeholder={filterPlaceholder}
                    onChange={handleFilterChange}
                    initialSelectedItem={initialFilterOption}
                  />
                )}
              </Column>
              {customFilterVisible && (
                <Column>
                  <Flex alignItems="center" height="2.5rem">
                    <Link icon="remove" onClick={() => handleClearCustomFilter()}>
                      <FormattedMessage id="dashboard.panel.custom_filter" />
                    </Link>
                  </Flex>
                </Column>
              )}
              <Column>
                <Flex justifyContent="flex-end">
                  {hasBulkActions && (
                    <DropdownButtonProvider>
                      <DropdownButton
                        data-testid="dashboard-panel-bulk-actions"
                        iconName="options"
                        variant="secondary"
                      >
                        <FormattedMessage id="dashboard.panel.actions.title" />
                      </DropdownButton>
                      <DropdownList>
                        <DropdownListItem onSelect={() => handleExport()}>
                          <FormattedMessage id="dashboard.panel.actions.export" />
                        </DropdownListItem>
                        {options !== undefined ? (
                          <DropdownListItem
                            data-testid="dashboard-panel-options"
                            onSelect={() => setOptionsVisible(true)}
                          >
                            <FormattedMessage id="dashboard.panel.actions.options" />
                          </DropdownListItem>
                        ) : (
                          <React.Fragment />
                        )}
                        <DropdownListItem onSelect={() => handleRefresh()}>
                          <FormattedMessage id="dashboard.panel.actions.refresh" />
                        </DropdownListItem>
                      </DropdownList>
                    </DropdownButtonProvider>
                  )}
                  {hasSelectedRowAction && <Spacer paddingLeft="small" />}
                  {hasSelectedRowAction && (
                    <Button
                      data-testid="dashboard-panel-selected-row-action"
                      variant="primary"
                      disabled={tableState.selectedRow === undefined}
                      onClick={() => actionClickHandler()}
                    >
                      {tableState.selectedRow === undefined
                        ? unselectedRowActionLabel
                        : selectedRowActionLabelFn(tableState.selectedRow)}
                    </Button>
                  )}
                </Flex>
              </Column>
            </Row>
          </Container>
        </TableFilterArea>
        <LocalizedDataTable
          {...tableState}
          {...tableRows}
          data-testid="dashboard-panel-datatable"
          isLoading={isLoading}
          pageSizeOptions={[5, 10, 25]}
          emptyMessage={emptyMessage}
          equatableRowKey={equatableRowKey}
        />
      </SectionContainer>
    </div>
  );
};
