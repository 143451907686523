import { TableCellProps } from 'alloy-foundation/es/components/Table/TableCell';
import { Theme } from 'alloy-foundation';
import { ComponentType, ReactNode, useMemo, useContext } from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { prettyPrint } from './util';
import { FilterContext } from './FilterContext';
import { DataTableMultiSelect } from '.';

export interface CellProps<Data> {
  value: Data[keyof Data] | string | number | boolean;
  row: Data;
  rowIndex: number;
  column: DataTableColumn<Data>;
  isActive: boolean;
  isExpanded: boolean;
  width?: number;
}

export class DataTableColumn<Data> {
  /** Set column alignment */
  align?: TableCellProps<HTMLTableCellElement>['align'] = 'left';

  /**  Component to render the contents of a row for this column. */
  Cell?: ComponentType<CellProps<Data>> = function DefaultCell({ value }) {
    return <HighlightFilterValue>{String(value)}</HighlightFilterValue>;
  };

  constructor(values: Partial<DataTableColumn<Data>> & { key: DataTableColumn<Data>['key'] }) {
    Object.assign(this, values);
  }

  /** If false this column's value will not be taken into account for filtering. */
  filterable? = true;

  /** The proportion of available space this column should occupy. */
  flex? = 1;

  /**
   * Returns the value for this column for a given row.
   * By default value will be row[column.key]
   *
   */
  getValue? = this.resolveValueFromKey;

  /**
   * Returns the value of a cell used for filtering.
   **/
  getFilterValue? = (row: Data) => this.getValue(row);

  /**
   * Returns the value of a cell used for sorting.
   */
  getSortValue? = (row: Data) => this.getValue(row);

  /** Allow to override the sort function if a multi column sort is needed. Unless specically needed, getSortValue should be used instead. */
  getSortFunction? = null;

  /**
   * Content of the header cell. By default will be inferred from key name.
   * e.g. given key 'userName' the inferred header wiill be 'User Name'
   **/
  header?: string | ReactNode | ComponentType = ({
    multiSelect = null,
  }: {
    multiSelect?: DataTableMultiSelect<Data>;
  }) => prettyPrint(String(this.key));

  /** If true the column won't be shown. A helper for conditionally rendering columns. */
  hide? = false;

  /**
   * Determines the value of the column for a given row unless overridden
   * by getValue. Also used as a unique identifier for the column.
   */
  key: keyof Data | string;

  /** Sets the minimum width of the column. */
  minWidth?: number;

  /** Padding for the cell. */
  padding?: TableCellProps<HTMLTableCellElement>['padding'] = 'default';

  /** If false the sorting icon will not be displayed for the column. */
  sortable? = true;

  /** Width in pixels for this column. */
  width?: number;

  /** A test ID to use with react testing library. This will be applied to the column header*/
  dataTestId?: string;

  private resolveValueFromKey?(row: Data): string | number | boolean {
    const key = this.key as keyof Data;

    if (typeof row === 'object' && String(key) in row) {
      return row[key] as unknown as string | number | boolean;
    }

    return row as unknown as string | number | boolean;
  }
}

export function useVisibleColumns<Data>(columns: DataTableColumn<Data>[]) {
  return useMemo<DataTableColumn<Data>[]>(() => {
    return columns.reduce((visible, column) => {
      if (column.hide) return visible;
      return visible.concat(new DataTableColumn(column));
    }, []);
  }, [columns]);
}

function HighlightedText({ query, children }: HighlightedTextProps) {
  const { css, styles } = useStyles({ stylesFn });

  if (!children) return <>{children}</>;

  const i = children?.toLowerCase().indexOf(query.toLowerCase()) ?? -1;
  if (i === -1) return <>{children}</>;

  const before = children.slice(0, i);
  const match = children.slice(i, i + query.length);
  const after = children.slice(i + query.length);

  return (
    <>
      {before}
      <span {...css(styles.highlight)}>{match}</span>
      {after}
    </>
  );
}

interface HighlightedTextProps {
  query: string;
  children: string;
}

const stylesFn = ({ vertaforeAlloy: { color } }: Theme) => ({
  highlight: {
    backgroundColor: color.core.blueFocus,
  },
});

export const HighlightFilterValue = ({
  children,
  query: queryOverride,
}: {
  children: string;
  query?: string;
}) => {
  const query = useContext(FilterContext);

  return <HighlightedText query={queryOverride || query}>{children}</HighlightedText>;
};
