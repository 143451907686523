import { MonEspaceCourtierSvg, MyBrokerHomeSvg, TopNavBrand } from 'alloy-foundation';
import React from 'react';
import { Locale } from '../localization/Locale';
import { useLocaleContext } from '../localization/LocaleContext';
import { getErrorCode } from '../../services/selectAgency/selectAgencyService';

const TopNavSelectAgency = () => {
  const { locale } = useLocaleContext();
  const errorCode = getErrorCode();
  return (
    <React.Fragment>
      <TopNavBrand
        // An url is required. Here I am keeping the url the same. The only scenario were there is navagation is when errorCode == 2,
        // which means that the user isn't properly configurated in SIG. In that case, we allow the user to select another brokerage.
        to={errorCode !== 1 ? '/Login/SelectAgency' : '/Login/UnauthorizedAccess?Error=1'}
        // w=168 for english logo and w=207 for french logo
        width={locale === Locale.EnCa ? 168 : 207}
        height={18}
        DarkSVGIcon={locale === Locale.EnCa ? MyBrokerHomeSvg : MonEspaceCourtierSvg}
        aria-label="MyBrokerHome Logo"
      />
    </React.Fragment>
  );
};

export default TopNavSelectAgency;
