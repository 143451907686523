import { useMemo, useState } from 'react';

export const useRefetch = () => {
  const [fetch, setFetch] = useState<number>(0);

  return useMemo(() => {
    const refetch = () => setFetch(Date.now());

    return {
      fetch,
      refetch,
    };
  }, [fetch]);
};
