import { DateTime } from 'luxon';
import * as Yup from 'yup';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import { SigPermissions } from '../../../models/SigPermissions';
import { EPermissions, HasPermissions, Permissions } from '../../../models/enums/EPermissions';
import * as fieldNames from '../fieldNames';

const GeneralInformationSchema = (
  formatMessage,
  dropdowns: CustomerDropdowns,
  sigPermissions: SigPermissions
) =>
  Yup.object().shape({
    [`${fieldNames.FIRST_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.LAST_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.MIDDLE_NAME}`]: Yup.string(),
    [`${fieldNames.PREFIX}`]: Yup.string(),
    [`${fieldNames.DOB}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string()
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        })
        .nullable(),
    }),
    [`${fieldNames.MARITAL_STATUS}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'P',
      then: Yup.string().nullable(),
    }),
    [`${fieldNames.FIRM_NAME}`]: Yup.string().when(`${fieldNames.TYPE_OF_BUSINESS}`, {
      is: 'C',
      then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
    }),
    [`${fieldNames.FIRM_DBA}`]: Yup.string(),
    [`${fieldNames.LANGUAGE}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.PERSONAL_INFORMATION_CONSENT}`]: Yup.string().nullable(true),
    [`${fieldNames.CONSENT_FOR_OTHER_USE}`]: Yup.string().nullable(true),
    [`${fieldNames.DATE_OF_CONSENT}`]: Yup.string()
      .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
        if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

        return true;
      })
      .when(`${fieldNames.PERSONAL_INFORMATION_CONSENT}`, {
        is: (value) => value !== 'null' && value !== null,
        then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
        otherwise: Yup.string(),
      }),
    [`${fieldNames.CUSTOMER_CLASS1}`]:
      HasPermissions(sigPermissions, Permissions.CanEditCustomerClass, EPermissions.EMODIFY) &&
      dropdowns.MandatoryCustomerClass.includes(1)
        ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
        : // If CustomerClass1 is undefined, leave it as undefined instead of casting it to {}
          Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS2}`]:
      HasPermissions(sigPermissions, Permissions.CanEditCustomerClass, EPermissions.EMODIFY) &&
      dropdowns.MandatoryCustomerClass.includes(2)
        ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
        : // If CustomerClass2 is undefined, leave it as undefined instead of casting it to {}
          Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS3}`]:
      HasPermissions(sigPermissions, Permissions.CanEditCustomerClass, EPermissions.EMODIFY) &&
      dropdowns.MandatoryCustomerClass.includes(3)
        ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
        : // If CustomerClass3 is undefined, leave it as undefined instead of casting it to {}
          Yup.string().notRequired().default(undefined).nullable(),
    [`${fieldNames.CUSTOMER_CLASS4}`]:
      HasPermissions(sigPermissions, Permissions.CanEditCustomerClass, EPermissions.EMODIFY) &&
      dropdowns.MandatoryCustomerClass.includes(4)
        ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
        : // If CustomerClass4 is undefined, leave it as undefined instead of casting it to {}
          Yup.string().notRequired().default(undefined).nullable(),
  });
export default GeneralInformationSchema;
