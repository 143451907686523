import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { CustomerDropdowns } from '../../../../../models/CustomerDropdowns';
import Department from '../../../../../models/Department';
import { DEPARTMENT, DIVISION } from '../../../fieldNames';
import Header from '../../../../common/header/Header';
import { PssCustomer } from '../../../../../models/PssCustomer';
import {
  HasPermissions,
  Permissions,
  EPermissions,
} from '../../../../../models/enums/EPermissions';
// hooks

export const BusinessUnitForm: React.FC<{
  dropdowns: CustomerDropdowns;
  showHeader?: boolean;
  parentNamespace?: string;
  customer?: PssCustomer;
}> = ({ dropdowns, showHeader = true, parentNamespace, customer }) => {
  const { formatMessage } = useIntl();
  // initial values
  const defaultDivision =
    parentNamespace === 'businessInformation'
      ? dropdowns.BusinessUnits.Divisions.find((x) => x.DivisionCode === customer.GLDivisionCode)
      : dropdowns.BusinessUnits.Divisions.find((x) => x.DivisionCode === dropdowns.DefaultDivision);
  const [departments, setDepartments] = useState<Department[]>(
    defaultDivision ? defaultDivision.Departments : []
  );
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const formik = useFormikContext();

  // functions
  const handleDivisionChange = (division) => {
    const currentDivision = dropdowns.BusinessUnits.Divisions.find(
      (x) => x.DivisionCode === division?.value
    );
    if (currentDivision) {
      setDepartments(currentDivision.Departments);
      formik.setFieldValue(prefix + DEPARTMENT, '');
    } else {
      setDepartments([]);
      formik.setFieldValue(prefix + DEPARTMENT, '');
    }
  };
  return (
    <React.Fragment>
      {showHeader && (
        <Header
          title={formatMessage({ id: 'customer.details.businessInformation.businessUnit' })}
        />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={true}
              name={prefix + DIVISION}
              preventClear={parentNamespace ? true : false}
              label={formatMessage({ id: 'customer.details.businessInformation.division' })}
              options={dropdowns.BusinessUnits.Divisions.map((data) => {
                return { value: data.DivisionCode, label: data.DivisionName };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
              onChange={(division) => handleDivisionChange(division)}
              disabled={
                parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditDiv,
                      EPermissions.EMODIFY
                    ) &&
                    HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditDept,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false
              }
            />
          </Column>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={true}
              name={prefix + DEPARTMENT}
              preventClear={parentNamespace ? true : false}
              disabled={
                (departments?.length ?? 0) === 0 ||
                (parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditDept,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false)
              }
              label={formatMessage({ id: 'customer.details.businessInformation.department' })}
              options={departments.map((data) => {
                return { value: data.DepartmentCode, label: data.DepartmentName };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
        </Row>
      </Spacer>
    </React.Fragment>
  );
};
