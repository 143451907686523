import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import productInfoService from '../../services/productInfo/productInfoService';
import { LocationInfo, ProductInfo } from '../../models/ProductInfo';
import LoginService from '../../services/loginService/LoginService';

interface IProductInfoContext {
  productInfo?: ProductInfo;
  errorCode?: number;
  handleError?: (p) => void;
  selectAgency?: (selectedAgencyId: string, selectedGroupId: number) => void;
  setProductInfo?: () => void;
  getIsCustomerAdmin?: () => boolean;
  getIsSmartflowAdmin?: () => boolean;
  getAgencyNumber?: () => string;
  getVimUserId?: () => number;
  getEmpCode?: () => string;
  getUserFullName?: () => string;
  getLocationInfo?: () => LocationInfo;
  prodInfoAvailable: boolean;
}
const ProductInfoContext = React.createContext<IProductInfoContext>({
  prodInfoAvailable: false,
});
export const ProductInfoProvider = ({ children }) => {
  const [prodInfoAvailable, setAvailable] = useState(false);
  const [productInfo, setProductInfoData] = useState<ProductInfo>();
  const history = useHistory();
  const [errorCode, setErrorCode] = useState<number>();

  const handleError = useCallback(
    (errCode) => {
      setErrorCode(errCode);
      history.push('/Login/UnauthorizedAccess');
    },
    [history]
  );
  const setProductInfo = useCallback(async () => {
    await productInfoService
      .retrieveProductInfo()
      .then((data) => {
        setProductInfoData(data);
        setAvailable(true);
      })
      .catch((err) => {
        handleError(parseInt(err['response'].data?.Message) ?? 1);
      });
  }, [handleError]);
  const selectAgency = useCallback(
    (selectedAgencyId: string, selectedGroupId: number) => {
      LoginService.SelectAgency(selectedAgencyId, selectedGroupId)
        .then(() => {
          setProductInfo();
          history.push('/dashboard');
        })
        .catch((err) => {
          handleError(parseInt(err['response'].data?.Message) ?? 1);
        });
    },
    [handleError, history, setProductInfo]
  );
  const getIsCustomerAdmin = (): boolean => {
    return productInfo?.IsCustomerAdmin;
  };
  const getIsSmartflowAdmin = (): boolean => {
    return productInfo?.ProductInfos.find((p) => p.Identifier === 'smartflow')?.Data?.IsManager;
  };
  const getAgencyNumber = (): string => {
    return productInfo?.AgencyNumber;
  };
  const getVimUserId = (): number => {
    return productInfo?.VimUserId;
  };

  const getEmpCode = (): string => {
    return productInfo?.ProductInfos[0]?.Data?.ECode;
  };
  const getUserFullName = (): string => {
    return productInfo?.UserFullName;
  };
  const getLocationInfo = (): LocationInfo => {
    return productInfo?.LocationInfo;
  };
  return (
    <ProductInfoContext.Provider
      value={{
        getIsCustomerAdmin,
        getIsSmartflowAdmin,
        getAgencyNumber,
        getVimUserId,
        getEmpCode,
        getUserFullName,
        getLocationInfo,
        errorCode,
        handleError,
        prodInfoAvailable,
        setProductInfo,
        productInfo,
        selectAgency,
      }}
    >
      {children}
    </ProductInfoContext.Provider>
  );
};

export const useProductInfoProvider = () => {
  const context = useContext(ProductInfoContext);
  if (context === undefined) {
    throw new Error(
      'useProductInfoProvider cannot be called from outside of a ProductInfoProvider'
    );
  }
  return context;
};
