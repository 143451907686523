import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, Column, Row, Typography } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useRouter } from '../common/hooks/useRouter';
import SearchPageLayout from '../common/page-layout/DefaultPageLayout';
import { useGlobalSearchData } from './useGlobalSearchData';
import { useDataTable, useDataTableRows } from '../data-table/useDataTable';
import { DataTableColumn, HighlightFilterValue } from '../data-table/DataTableColumn';
import { SearchResult } from '../../models/SearchResult';
import { StringColumn } from '../common/custom-datatable-columns/StringColumn';
import LocalizedDataTable from '../common/localizedDataTable/LocalizedDataTable';
import AdditionalInformation from './AdditionalInformation';
import GlobalSearchAdvanceFilter from './AdvanceFilter/GlobalSearchAdvanceFilter';
import { useCustomerDropdowns } from '../common/hooks/useCustomerDropdown';
import { withLayoutShell } from '../common/hoc/withLayoutShell';

type SearchParams = {
  term: string;
};

const GlobalSearchResults = () => {
  const { term } = useParams<SearchParams>();
  const { history } = useRouter();
  const searchData = useGlobalSearchData(term);
  const dropdowns = useCustomerDropdowns();
  const { formatMessage } = useIntl();

  let displayTerm = term;
  try {
    displayTerm = decodeURIComponent(term);
  } catch (e) {
    displayTerm = term;
  }

  useEffect(() => {
    if (!searchData.isLoading && searchData.data?.length === 1)
      history.push(`/customers/${searchData.data[0]?.ClientId}`);
  }, [searchData, history]);

  useEffect(() => {
    const getClientTypeDescription = (code: string) => {
      switch (code) {
        case 'P':
          return formatMessage({
            id: 'customer.details.businessInformation.typeOfBusiness.Personal',
          });
        case 'C':
          return formatMessage({
            id: 'customer.details.businessInformation.typeOfBusiness.Commercial',
          });
        default:
          return '';
      }
    };

    const getClientStatusDescription = (code: string) => {
      return dropdowns.data?.ClientTypes?.find((p) => p.Key === code)?.Value;
    };

    if (!searchData.isLoading && !dropdowns.isLoading) {
      searchData.data.forEach((item) => {
        item.ClientStatusDescription = getClientStatusDescription(item.ClientStatus);
        item.ClientTypeDescription = getClientTypeDescription(item.ClientType);
      });
    }
  }, [searchData, dropdowns, formatMessage]);

  const columns: DataTableColumn<SearchResult>[] = [
    new StringColumn<SearchResult>({
      key: 'ClientNum',
      header: formatMessage({ id: 'customer.search.customerNumber' }),
      flex: 2,
    }),
    new StringColumn<SearchResult>({
      key: 'Client',
      header: formatMessage({ id: 'customer.search.name' }),
      flex: 4,
      Cell: function ToDoNameCell({ row }) {
        // example usage - should open customer summary
        return (
          <>
            <Link small onClick={() => history.push(`/customers/${row.ClientId}`)} to="">
              <HighlightFilterValue>{row.Client}</HighlightFilterValue>
            </Link>
          </>
        );
      },
    }),
    new StringColumn<SearchResult>({
      key: 'Address',
      header: formatMessage({
        id: 'customer.search.address',
      }),
      // Overrided to allow filtering to work correctly
      getValue: (customer) =>
        customer
          ? `${customer.Address},${customer.Address2}, ${customer.City},${customer.State}`
          : '',
      flex: 4,
      Cell: function RepExecCell({ row }) {
        return (
          <>
            <Row>
              <Column>
                {(row.Address ?? '') !== '' && (
                  <React.Fragment>
                    <Typography variant="bodySm">
                      <HighlightFilterValue>{row.Address}</HighlightFilterValue>
                    </Typography>
                    {(row.Address2 ?? '') !== '' && (
                      <Typography variant="bodySm">
                        <HighlightFilterValue>{row.Address2}</HighlightFilterValue>
                      </Typography>
                    )}
                    <Typography variant="bodySm">
                      <HighlightFilterValue>{`${row.City}, ${row.State}`}</HighlightFilterValue>
                    </Typography>
                  </React.Fragment>
                )}
              </Column>
            </Row>
          </>
        );
      },
    }),
    new StringColumn<SearchResult>({
      key: 'Zip',
      header: formatMessage({ id: 'customer.search.postalCode' }),
      flex: 2,
    }),
    new StringColumn<SearchResult>({
      key: 'Email',
      header: formatMessage({
        id: 'customer.search.contactMethod',
      }),
      // Overrided to allow filtering to work correctly
      getValue: (customer) => `${customer.DisplayPhone}-,${customer.Email}`,
      flex: 4,
      Cell: function RepExecCell({ row: customer }) {
        return (
          <>
            <Row>
              <Column>
                {(customer.DisplayPhone ?? '') !== '' && (
                  <Typography variant="bodySm">
                    <HighlightFilterValue>{customer.DisplayPhone}</HighlightFilterValue>
                  </Typography>
                )}
                {(customer.Email ?? '') !== '' && (
                  <Typography variant="bodySm" noWrap={true}>
                    <HighlightFilterValue>{customer.Email}</HighlightFilterValue>
                  </Typography>
                )}
              </Column>
            </Row>
          </>
        );
      },
    }),
    new StringColumn<SearchResult>({
      key: 'Active',
      header: formatMessage({ id: 'customer.search.Active' }),
      getValue: (result: SearchResult) => {
        return result.Active
          ? formatMessage({ id: 'common.active' })
          : formatMessage({ id: 'common.inactive' });
      },
      flex: 2,
    }),
    new StringColumn<SearchResult>({
      key: 'Match',
      header: formatMessage({ id: 'customer.search.Match' }),
      flex: 2,
    }),
  ];

  const tableState = useDataTable({
    columns,
    data: searchData.data || [],
    filtering: true,
    advanceFilter: GlobalSearchAdvanceFilter(dropdowns),
    rowSelection: true,
    sorting: true,
    initialSortedColumnKey: 'Client',
    initialSortDirection: 'asc',
    expandingRows: true,
  });
  const tableRows = useDataTableRows(tableState);

  // Reset page when the search values changes as if the new set is smaller, it will display no result found.
  useEffect(() => {
    tableState.onPageChange?.(0);

    tableState.advanceFilter.clearFilter();
    tableState.onFilter('');

    // eslint is mark to ignore the previous line as tableState is changed too frequently and won't allow user to change the page or page size.
    // Please do not move this comments.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  return (
    <SearchPageLayout title={formatMessage({ id: 'customer.search.title' })}>
      <LocalizedDataTable
        {...tableState}
        {...tableRows}
        data-testid="dashboard-panel-datatable"
        isLoading={searchData.isLoading}
        pageSizeOptions={[5, 10, 25]}
        emptyMessage={formatMessage({ id: 'customer.search.noResults' }, { displayTerm })}
        expandableRowView={AdditionalInformation}
      />
    </SearchPageLayout>
  );
};

export default withLayoutShell(GlobalSearchResults);
