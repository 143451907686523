import * as Yup from 'yup';
import { DateTime } from 'luxon';
import * as fieldNames from '../fieldNames';
import * as EmailFieldNames from '../details/emailsInformation/fieldNames';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';

export const EmailValidationSchema = (
  formatMessage,
  requiredAll = true,
  dropdowns?: CustomerDropdowns
) =>
  Yup.object().shape(
    {
      [`${EmailFieldNames.EMAIL_ADDRESS}`]: requiredAll
        ? Yup.string()
            .when([`${EmailFieldNames.EMAIL_TYPE}`], {
              is: (type) => type !== '6',
              then: Yup.string().email(formatMessage({ id: 'common.validation.invalidEmail' })),
            })
            .when([`${EmailFieldNames.EMAIL_TYPE}`, `${fieldNames.NOT_APPLICABLE}`], {
              is: (type, notapplicable) => type && type.length > 0 && !notapplicable,
              then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
              otherwise: Yup.string(),
            })
        : Yup.string()
            .when([`${EmailFieldNames.EMAIL_TYPE}`], {
              is: (type) => type !== '6',
              then: Yup.string()
                .email(formatMessage({ id: 'common.validation.invalidEmail' }))
                .nullable(),
            })
            .when([`${EmailFieldNames.EMAIL_TYPE}`, `${fieldNames.NOT_APPLICABLE}`], {
              is: (type, notapplicable) =>
                type &&
                type.length > 0 &&
                dropdowns.MandatoryContactMethods.includes(type) &&
                !notapplicable,
              then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
              otherwise: Yup.string().nullable(),
            }),
      [`${fieldNames.NOT_APPLICABLE}`]: Yup.boolean(),
      [`${EmailFieldNames.CALS}`]: Yup.string().nullable(),
      [`${EmailFieldNames.EMAIL_TYPE}`]: Yup.string().when([`${EmailFieldNames.EMAIL_ADDRESS}`], {
        is: (emailAddress) => emailAddress && emailAddress.length > 0,
        then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
      }),
      [`${EmailFieldNames.CALS_DATE_OF_CONSENT}`]: Yup.string()
        .nullable()
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        }),
    },
    [[`${EmailFieldNames.EMAIL_ADDRESS}`, `${EmailFieldNames.EMAIL_TYPE}`]]
  );
