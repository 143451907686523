import { DateTime } from 'luxon';
import { Badge, Group, Icon } from 'alloy-foundation';
import { CellProps, DataTableColumn } from '../../data-table/DataTableColumn';
import { DataTableColumnWithResolvableValue } from './DataTableColumnWithResolvableValue';

export class DateColumn<T> extends DataTableColumnWithResolvableValue<T> {
  showBadgeView?: boolean;
  showFlag?: (data: T) => boolean;
  constructor({
    Cell,
    showBadgeView = true,
    showFlag = (data) => false,
    getSortValue,
    getValue,
    ...values
  }: Partial<DateColumn<T>> & { key: DataTableColumn<T>['key'] }) {
    super(values);
    Object.assign(this, { showBadgeView });
    Object.assign(this, { showFlag });
    if (getSortValue) Object.assign(this, { getSortValue });
    if (getValue) Object.assign(this, { getValue });
    if (Cell) Object.assign(this, Cell);
  }
  getValue = (row: T) =>
    new Date(this.resolveValue(row)).toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  getSortValue = (row: T) => new Date(this.resolveValue(row)).getTime();
  Cell = ({ row }: CellProps<T>) => {
    const value = (this.getValue ? this.getValue(row) : this.resolveValue(row)) as string;
    const date = DateTime.fromFormat(value, 'MM/dd/yyyy');
    const today = DateTime.now();
    const isPastDue = date.startOf('day') < today.startOf('day');
    const haveFlag = this.showFlag ? this.showFlag(row) : false;

    return (
      <>
        {this.showBadgeView ? (
          <Badge color={isPastDue ? 'red' : 'green'} inverse>
            {haveFlag ? (
              <Group spacing="tiny" stacked={false}>
                <Icon name="flag" size={12} color="red" />
                {value}
              </Group>
            ) : (
              value
            )}
          </Badge>
        ) : (
          value
        )}
      </>
    );
  };
}
