import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, ButtonGroup, Flex, Spacer, Typography } from 'alloy-foundation';

const InstanceEditBottomBar = ({
  canSave,
  preventSaveDraft,
  hasErrors = false,
  handleSave,
  handleSaveDraft,
  handleCancel,
  numberWorkSteps,
  numberDays,
}: {
  canSave: boolean;
  preventSaveDraft: boolean;
  hasErrors?: boolean;
  numberWorkSteps: number;
  numberDays: number;
  handleSave: (data) => void;
  handleSaveDraft: (data) => void;
  handleCancel: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Flex justifyContent="space-between">
      <Button variant="tertiary" onClick={handleCancel}>
        <FormattedMessage id="common.button.cancel" />
      </Button>
      {hasErrors && (
        <Spacer m="small">
          <Typography align="center" color="error">
            <FormattedMessage id="customer.bottomBar.errorMessage" />
          </Typography>
        </Spacer>
      )}
      <ButtonGroup>
        <Typography variant="disclaimer" data-testid="step-info">
          {numberWorkSteps}{' '}
          {formatMessage({ id: 'quickNav.configuration.instances.edit.workstep' })} | {numberDays}{' '}
          {formatMessage({ id: 'quickNav.configuration.instances.edit.days' })}
        </Typography>
        <Spacer mr="small" />
        <Button
          id="save-button"
          variant="secondary"
          onClick={handleSaveDraft}
          disabled={!canSave || preventSaveDraft}
        >
          <FormattedMessage id="quickNav.configuration.instances.edit.savedraft" />
        </Button>
        <Button
          id="save-button"
          data-testid="action-publish"
          variant="primary"
          onClick={handleSave}
          disabled={!canSave}
        >
          <FormattedMessage id="quickNav.configuration.instances.publish" />
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default InstanceEditBottomBar;
