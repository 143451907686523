import { Styles } from 'react-with-styles';

const stylesFn = ({ vertaforeAlloy: { color, border } }): Styles => ({
  drawer: {
    width: '500px',
  },
  header: {
    boxShadow: '0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.2)',
  },
  container: {
    overflowX: 'hidden',

    scrollbarWidth: '0.625rem',
    scrollbarColor: `${color.scrollbar} transparent`,
    scrollBehavior: 'smooth',

    '::-webkit-scrollbar': {
      width: '0.875rem',
      scrollBehavior: 'smooth',
    },

    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundClip: 'padding-box',
      backgroundColor: color.scrollbar,
      borderRadius: '0.625rem',
      border: border.extraThickTransparent,
    },
  },
});

export default stylesFn;
