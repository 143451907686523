import { Column, Link, MessageBar, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  numnberOfMatches: number;
  onClick: () => void;
};

const PotentialMatchesBar = ({ numnberOfMatches, onClick }: Props) => {
  return (
    <Row>
      <Column md={6}>
        <MessageBar title="" variant="info">
          {numnberOfMatches}{' '}
          <FormattedMessage id="customer.details.currentAddress.matches.header" />{' '}
          <Link onClick={onClick} data-testid="pmb-link">
            <FormattedMessage id="customer.details.currentAddress.matches.showmatches" />
          </Link>
        </MessageBar>
        <Spacer mb="small" />
      </Column>
    </Row>
  );
};

export default PotentialMatchesBar;
