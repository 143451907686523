module.exports = {
  'topnav.my_account.dashboard_settings': 'Dashboard Settings',
  'topnav.my_account.logout': 'Log Out',
  'topnav.my_account.location': 'Brokerage Location',
  'topnav.my_account.my_account': 'My Account',
  'topnav.my_account.my_profile': 'My Profile',
  'topnav.my_account.switch_language': 'Changer la langue à: Français',
  'topnav.manager.title': 'Admin Settings',
  'topnav.manager.config': 'Manager Configuration',
  'topnav.manager.quicknav': 'QuickNav Configuration',
  'common.button.cancel': 'Cancel',
  'common.button.save': 'Save',
  'common.button.ok': 'OK',
  'common.button.new': 'New',
  'common.button.back': 'Back',
  'common.button.next': 'Next',
  'common.button.apply': 'Apply',
  'common.validation.invalidDate': 'Please enter a valid date',
  'common.validation.invalidEmail': 'Please enter a valid email address',
  'common.validation.invalidPhone': 'Please enter a valid phone number',
  'common.validation.invalidURL': 'Please enter a valid URL',
  'common.validation.required': 'This field is required',
  'common.validation.invalidPostalCodeFormat': 'Please enter a valid postal code (ANA NAN)',
  'common.due_today': 'Due Today',
  'common.incomplete': 'Incomplete',
  'common.date_range_120': 'Next 120 days',
  'common.error_loading_data': 'An error occurred while retrieving the data',
  'common.actions.newActivity': 'New Activity',
  'common.actions.newSuspense': 'New Suspense',
  'common.date.nextMonth': 'Next Month',
  'common.date.previousMonth': 'Previous Month',
  'common.date.placeholder': 'MM/DD/YYYY',
  'common.active': 'Active',
  'common.inactive': 'Inactive',
  'common.advanceSearch': 'Advanced Search',
  'common.advanceSearch.clear': 'Clear',
  'common.english': 'English',
  'common.french': 'French',
  'common.select': 'Select',
  'common.search.placeholder': 'Type to search...',
  'common.today': 'Today',
  'common.today.reduced': 'Today',
  'commnon.accessDenied': 'Access Denied',
  'common.title': 'MyBrokerHome',
  'datatable.paging.next': 'Next',
  'datatable.paging.previous': 'Previous',
  'datatable.rowcount.beforeText': 'Currently displaying',
  'datatable.rowcount.afterText': 'rows per page',

  'customer.createCustomer.title': 'New Customer',
  'customer.validationError.field1': 'Please enter a valid field #1',
  'customer.validationError.field2': 'Please enter a valid field #2',
  'customer.createSuccess': 'Customer created successfully',
  'customer.saveSuccess': 'Customer updated successfully',
  'customer.create': 'Create',
  'customer.save': 'Save',
  'customer.createError': 'Something went wrong saving the customer.',
  'success.toasts.header': 'Success',
  'error.toasts.header': 'Error',
  'customer.search.customerNumber': 'ID#',
  'customer.search.name': 'Name',
  'customer.search.address': 'Address',
  'customer.search.postalCode': 'Postal Code',
  'customer.search.contactMethod': 'Phone / Email',
  'customer.search.Active': 'Status',
  'customer.search.selectStatus': 'Select Status(es)',
  'customer.search.selectAccountType': 'Select Account Type(s)',
  'customer.search.selectCustomerType': 'Select Customer Type(s)',
  'customer.search.Match': 'Match',
  'customer.details.title': 'Customer Details',
  'customer.details.businessInformation.businessUnit': 'Business Unit',
  'customer.details.businessInformation.title': 'Brokerage Information',
  'customer.details.currentAddress.title': 'Current Address',
  'customer.details.address.title': 'Address',
  'customer.details.currentAddress.postalCode.label': 'Postal Code',
  'customer.details.currentAddress.city.label': 'City',
  'customer.details.currentAddress.province.label': 'Province',
  'customer.details.currentAddress.province.placeholder': 'Select a Province...',
  'customer.details.currentAddress.addressLine.label': 'Street Address 1',
  'customer.details.currentAddress.addressLine2.label': 'Street Address 2',
  'customer.details.currentAddress.civicNumber': 'Civic Number',
  'customer.details.currentAddress.civicNumber.message': 'Please enter a Civic Number',
  'customer.details.currentAddress.matches.range': 'Range',
  'customer.details.currentAddress.matches.address': 'Address',
  'customer.details.currentAddress.matches.city': 'City',
  'customer.details.currentAddress.matches.province': 'Province',
  'customer.details.currentAddress.matches.postalCode': 'Postal Code',
  'customer.details.currentAddress.matches.header': 'Possible matches found.',
  'customer.details.currentAddress.matches.subheader': 'Select a matching address line',
  'customer.details.currentAddress.matches.showmatches': 'Show all possible matches.',
  'customer.details.currentAddress.matches.filter.empty': 'No address matches found',
  'customer.details.currentAddress.matches.filter.placeholder': 'Type here to search...',
  'topnav.actions.newCustomer': 'New Customer',
  'topnav.actions.newActivity': 'New Activity',
  'topnav.actions.newSuspense': 'New Suspense',
  'customer.details.businessInformation.typeOfBusiness': 'Account Type',
  'customer.details.businessInformation.typeOfBusiness.Commercial': 'Commercial',
  'customer.details.businessInformation.typeOfBusiness.Personal': 'Personal',
  'customer.details.businessInformation.customerClass': 'Customer Class',
  'customer.details.businessInformation.customerClasses.1': 'Customer Class 1',
  'customer.details.businessInformation.customerClasses.2': 'Customer Class 2',
  'customer.details.businessInformation.customerClasses.3': 'Customer Class 3',
  'customer.details.businessInformation.customerClasses.4': 'Customer Class 4',
  'customer.details.businessInformation.customerClasses.placeholder': 'Select a Customer Class...',
  'customer.details.businessInformation.division': 'Division',
  'customer.details.businessInformation.division.placeholder': 'Select a Division...',
  'customer.details.businessInformation.department': 'Department',
  'customer.details.businessInformation.department.placeholder': 'Select a Department...',

  'customer.details.generalInfo': 'General Information',
  'customer.details.base.type.label': 'Customer Type',
  'customer.details.base.type.placeholder': 'Select a Customer Type...',
  'customer.details.base.language.label': 'Language',
  'customer.details.base.language.placeholder': 'Select a Language...',
  'customer.details.base.subbroker.label': 'Sub-Broker',
  'customer.details.base.subbroker.placeholder': 'Select a Sub-Broker...',
  'customer.details.personal.prefix.label': 'Title',
  'customer.details.personal.prefix.sc.label': 'Secondary Title',
  'customer.details.personal.prefix.placeholder': 'Select a Title...',
  'customer.details.personal.firstname.label': 'First Name',
  'customer.details.personal.lastname.label': 'Last Name',
  'customer.details.personal.middlename.label': 'Middle Name',
  'customer.details.personal.firstname.sc.label': 'Secondary First Name',
  'customer.details.personal.lastname.sc.label': 'Secondary Last Name',
  'customer.details.personal.middlename.sc.label': 'Secondary Middle Name',
  'customer.details.personal.dob.label': 'Date of Birth',
  'customer.details.personal.maritalstatus.label': 'Marital Status',
  'customer.details.personal.maritalstatus.placeholder': 'Select a Marital Status...',
  'customer.details.commercial.firmname.label': 'Firm Name',
  'customer.details.commercial.firmdba.label': 'Firm DBA',
  'customer.details.brokeragePersonnel': 'Brokerage Personnel',
  'customer.details.brokeragePersonnel.AccountExec.placeholder': 'Select an Account Executive...',
  'customer.details.brokeragePersonnel.AccountExec1': 'Account Executive',
  'customer.details.brokeragePersonnel.AccountExec2': 'Account Executive 2',
  'customer.details.brokeragePersonnel.AccountRep.placeholder':
    'Select an Account Representative...',
  'customer.details.brokeragePersonnel.AccountRep1': 'Account Representative',
  'customer.details.brokeragePersonnel.AccountRep2': 'Account Representative 2',
  'customer.details.privacyAct': 'Privacy Act',
  'customer.details.personalInformationConsent': 'Personal Information Consent',
  'customer.details.consentForOtherUse': 'Consent for Other Use',
  'customer.details.dateOfConsent': 'Date of Consent',
  'customer.details.contactInfo.header': 'Contact Information',
  'customer.contactMethods.phoneNumber': 'Phone Number',
  'customer.contactMethods.phoneNumberExtension': 'Ext.',
  // Duplicated in English since the French header need to be plurals.
  'customer.contactMethods.phoneNumberHeader': 'Phone number',
  'customer.contactMethods.phoneType': 'Phone Type',
  'customer.contactMethods.phoneType.placeholder': 'Select a phone type...',
  'customer.contactMethods.CASLConsent': 'CASL Consent',
  'customer.contactMethods.CASLConsentDate': 'Date of Consent',
  'customer.contactMethods.addPhoneNumber': 'Add Phone Number',
  'customer.contactMethods.deletePhoneNumber': 'Delete',
  'customer.contactMethods.notApplicable': 'N/A',

  'customer.contactMethods.emailHeader': 'Email/Website',
  'customer.contactMethods.emailType': 'Email Type',
  'customer.contactMethods.emailType.placeholder': 'Select an email type...',
  'customer.contactMethods.emailAddress': 'Email Address',
  'customer.contactMethods.addEmail': 'Add Email',
  'customer.contactMethods.deleteEmail': 'Delete',
  'customer.contactMethods.website': 'Website',
  'customer.contactMethods.preferedContactMethod': 'Preferred Method of Contact',
  'customer.contactMethods.preferedContactMethod.header': 'Preferred Method of Contact',
  'customer.contactMethods.preferedContactMethod.placeholder':
    'Select a preferred method of contact...',
  'customer.secondaryContact.header': 'Secondary Contact',
  'customer.secondaryContact.AddButton': 'Add secondary contact',

  'customer.summary.title': 'Summary',
  'customer.summary.toolTipText': 'To edit, click the Pencil icon',
  'customer.summary.suspense': 'Suspense',

  'customer.search.title': 'Results',
  'customer.search.placeholder': 'Type to search...',
  'customer.search.multipleResults': 'We found multiple results for "{term}"',
  'customer.search.noResults': 'No results found for "{displayTerm}"',
  'customer.search.addtiontalInfo.title': 'Additional Information',
  'customer.search.addtiontalInfo.xref': 'X-Reference',
  'customer.unlock': 'Unlock',
  'customer.lock.message.title': 'Customer Locked',
  'customer.lock.message.subtitle': 'This customer is currently being updated by',
  'customer.lock.message.name': 'Name',
  'customer.lock.message.workstation': 'Workstation',
  'customer.lock.message.branch': 'Branch',
  'customer.lock.message.readonly': 'Customer information available to view in read-only.',

  'common.yes': 'Yes',
  'common.no': 'No',

  'common.dropdown.placeholder': 'Select from dropdown...',
  'dashboard.setting.title': 'Customize Layout',
  'dashboard.setting.subtitle':
    'Toggle a section on to include it in your dashboard. You can also click and drag sections to reorder them. Locked sections cannot be removed.',
  'dashboard.setting.sectionTiles': 'Section Tiles',
  'dashboard.setting.todoList': 'To-Do List',
  'dashboard.setting.suspense': 'Suspense Workgroups',
  'dashboard.setting.expiringPolicies': 'Expiring Policies',
  'dashboard.tiles.todo_list_title': 'To-Do List',
  'dashboard.tiles.suspense_workgroups_title': 'Suspense Workgroups',
  'dashboard.tiles.WorkQueue_title': 'Work Queue',
  'dashboard.tiles.expiring_policies_title': 'Expiring Policies',
  'dashboard.myWork': 'My Work',
  'dashboard.managerDashboard': 'Manager Dashboard',
  'dashboard.panel.dateRangeFilter.placeholder': 'Filter by date range',
  'dashboard.panel.dateRangeFilter.all': 'All',
  'dashboard.panel.dateRangeFilter.dueToday': 'Due Today',
  'dashboard.panel.dateRangeFilter.pastDue': 'Past Due',
  'dashboard.panel.data.empty': 'No data to display.',
  'dashboard.panel.custom_filter': 'Clear custom filter',
  'dashboard.panel.todoList.title': 'To-Do List',
  'dashboard.panel.todoList.complete': 'Complete',
  'dashboard.panel.todoList.completed': 'Completed',
  'dashboard.panel.todoList.reopen': 'Reopen',
  'dashboard.panel.todoList.incomplete': 'Incomplete',
  'dashboard.panel.todoList.toolTipText': 'Suspenses -  Click Calendar icon above for Work Queue',
  'dashboard.panel.actions.title': 'Actions',
  'dashboard.panel.actions.export': 'Export',
  'dashboard.panel.actions.options': 'Options',
  'dashboard.panel.actions.refresh': 'Refresh',
  'dashboard.panel.todoList.columns.name.header': 'Name',
  'dashboard.panel.todoList.columns.dueDate.header': 'Due Date',
  'dashboard.panel.todoList.columns.priority.header': 'Priority',
  'dashboard.panel.todoList.columns.reason.header': 'Reason',
  'dashboard.panel.todoList.columns.status.header': 'Status',
  'dashboard.panel.todoList.columns.policy.header': 'Policy',
  'dashboard.panel.todoList.columns.description.header': 'Description',
  'dashboard.panel.todoList.options.showToDoItems': 'Show To-Do Items:',
  'dashboard.panel.todoList.options.dueDaysFromNow': 'Due up to X days from now',
  'dashboard.panel.todoList.options.or': 'OR',
  'dashboard.panel.todoList.options.dueDateFrom': 'From',
  'dashboard.panel.todoList.options.dueDateTo': 'To',
  'dashboard.panel.todoList.options.and': 'AND',
  'dashboard.panel.todoList.options.action': 'Action',
  'dashboard.panel.todoList.options.priority': 'Priority',
  'dashboard.panel.todoList.options.completed': 'Completed',
  'dashboard.panel.todoList.options.notCompleted': 'Not Completed',

  'dashboard.panel.expiringPoliciesList.title': 'Expiring Policies',
  'dashboard.panel.expiringPoliciesList.toolTipText': 'Click Actions for advanced filters',
  'dashboard.panel.expiringPoliciesList.columns.customerName.header': 'Customer',
  'dashboard.panel.expiringPoliciesList.columns.policyNo.header': 'Policy',
  'dashboard.panel.expiringPoliciesList.columns.expirationDate.header': 'Expiration',
  'dashboard.panel.expiringPoliciesList.columns.carrierName.header': 'Carrier',
  'dashboard.panel.expiringPoliciesList.columns.fullTermPremium.header': 'Full Term Premium',
  'dashboard.panel.expiringPoliciesList.columns.description.header': 'Description',
  'dashboard.panel.expiringPoliciesList.columns.repExec.header': 'Rep / Exec',
  'dashboard.panel.expiringPoliciesFilter.placeholder': 'Filter by',
  'dashboard.panel.expiringPoliciesFilter.representative': 'Representative',
  'dashboard.panel.expiringPoliciesFilter.executive': 'Executive',
  'dashboard.panel.expiringPoliciesList.renew': 'Renew',
  'dashboard.panel.expiringPoliciesList.options.showExpiringPolicies': 'Show Expiring Policies',
  'dashboard.panel.expiringPoliciesList.options.preDefinedDateRange': 'Pre-defined Date Range',
  'dashboard.panel.expiringPoliciesList.options.expirationsBetween': 'With expirations between',
  'dashboard.panel.expiringPoliciesList.options.or': 'OR',
  'dashboard.panel.expiringPoliciesList.options.dueDateFrom': 'From',
  'dashboard.panel.expiringPoliciesList.options.dueDateTo': 'To',
  'dashboard.panel.expiringPoliciesList.options.userDefined': 'User Defined',

  'dashboard.panel.todoListWorkgroup.title': 'Suspense Workgroups',
  'dashboard.panel.todoListWorkgroup.toolTipText': 'Click Actions for advanced filters',
  'dashboard.panel.todoListWorkgroup.complete': 'Complete',
  'dashboard.panel.todoListWorkgroup.completed': 'Completed',
  'dashboard.panel.todoListWorkgroup.reopen': 'Reopen',
  'dashboard.panel.todoListWorkgroup.incomplete': 'Incomplete',
  'dashboard.panel.todoListWorkgroup.columns.name.header': 'Name',
  'dashboard.panel.todoListWorkgroup.columns.dueDate.header': 'Due Date',
  'dashboard.panel.todoListWorkgroup.columns.priority.header': 'Priority',
  'dashboard.panel.todoListWorkgroup.columns.to.header': 'Group',
  'dashboard.panel.todoListWorkgroup.columns.reason.header': 'Reason',
  'dashboard.panel.todoListWorkgroup.columns.status.header': 'Status',
  'dashboard.panel.todoListWorkgroup.columns.policy.header': 'Policy',
  'dashboard.panel.todoListWorkgroup.columns.description.header': 'Description',
  'dashboard.panel.todoListWorkgroup.options.showToDoItems': 'Show To-Do Items:',
  'dashboard.panel.todoListWorkgroup.options.dueDaysFromNow': 'Due up to X days from now',
  'dashboard.panel.todoListWorkgroup.options.or': 'OR',
  'dashboard.panel.todoListWorkgroup.options.dueDateFrom': 'From',
  'dashboard.panel.todoListWorkgroup.options.dueDateTo': 'To',
  'dashboard.panel.todoListWorkgroup.options.and': 'AND',
  'dashboard.panel.todoListWorkgroup.options.action': 'Action',
  'dashboard.panel.todoListWorkgroup.options.priority': 'Priority',
  'dashboard.panel.todoListWorkgroup.options.workgroup': 'Workgroup',
  'dashboard.panel.todoListWorkgroup.options.completed': 'Completed',
  'dashboard.panel.todoListWorkgroup.options.notCompleted': 'Not Completed',
  'dashboard.panel.common.changeSuspenseStatus.title': 'Change Status Confirmation',
  'dashboard.panel.common.changeSuspenseStatus.body':
    'Do you want to change the status for the selected item?',
  'dashboard.panel.common.changeSuspenseStatusError.body': 'Unable to change status',
  'dashboard.manager.panel.title': 'QuickNav Overview',
  'dashboard.manager.panel.pastDue': 'Past Due',
  'dashboard.manager.panel.currentDue': 'Current',
  'dashboard.manager.panel.filters.status': 'Status',
  'dashboard.manager.panel.filters.status.placeholder': 'Filter by status',
  'dashboard.manager.panel.filters.assignedto': 'Assigned to',
  'dashboard.manager.panel.filters.assignedto.placeholder': 'Filter by Assigned To',
  'dashboard.manager.panel.fitlers.typeofQuickNav': 'Type of QuickNav',
  'dashboard.manager.panel.filters.typeofQuickNav.placeholder': 'Filter by Type',

  'dashboard.manager.panel.columns.assignedto': 'Assigned to',
  'dashboard.manager.panel.columns.assignto': 'Assign to',
  'dashboard.manager.panel.columns.stepDueDate': 'Step Due Date',
  'dashboard.manager.panel.columns.currentStep': 'Current Step',
  'dashboard.manager.panel.columns.customer': 'Customer',
  'dashboard.manager.panel.columns.policyNo': 'Policy #',
  'dashboard.manager.panel.columns.type': 'Type',
  'dashboard.manager.panel.options.exportAll': 'Export All',
  'dashboard.manager.panel.options.reassign': 'Reassign',
  'dashboard.manager.panel.options': 'Options',

  'dashboard.manager.reassign.quicknav.title': 'Reassign QuickNav',
  'dashboard.manager.reassign.quicknav.placeholder': 'Type to Search or Select from Dropdown',
  'dashboard.manager.reassign.quicknav.modal.title': 'Are you sure you want to cancel?',
  'dashboard.manager.reassign.quicknav.modal.description':
    'You are about to leave this page with unsaved changes. Do you wish to continue reassigning work items or leave this page and discard all unsaved changes?',
  'dashboard.manager.reassign.quicknav.modal.continue': 'Continue Reassign',
  'dashboard.manager.reassign.quicknav.modal.leave': 'Leave and Discard',
  'dashboard.manager.reassign.quicknav.toast.success': 'Your reassignment was successful.',

  'drawer.recentCustomers.title': 'Recent Customers',
  'drawer.recentCustomers.customerId': 'Customer ID#',
  'drawer.workItem.title': 'Work Item',
  'drawer.workItem.header.initiated': 'Initiated',
  'drawer.workItem.header.due': 'Due',
  'drawer.workItem.header.term': 'Term',
  'drawer.workItem.header.policy': 'Policy',
  'drawer.workItem.header.effectiveDate': 'Transaction Date',
  'drawer.workItem.header.assignedTo': 'Assigned To',
  'drawer.workItem.noPermission':
    "Only 'Assigned To' user is allowed to open this work item. Please contact the 'Assigned To' user or your administrator.",
  'drawer.workItem.launchStep': 'Launch Step',
  'drawer.workItem.completeStep': 'Mark Step Complete',
  'drawer.workItem.makeStepNotApplicable': 'Make Step Not Applicable',
  'drawer.workQueue.title': 'Work Queue',
  'drawer.workQueue.back': 'Back to Work Queue',
  'drawer.workQueue.actions.reassign': 'Reassign',
  'drawer.workQueue.actions.reassign.to': 'Assign to: ',
  'drawer.workQueue.actions.delete': 'Delete',
  'drawer.workQueue.actions.delete.title': 'Delete Work Item',
  'drawer.workQueue.actions.delete.subtitle':
    'Deleting a work item permanently deletes it from your work queue',
  'drawer.workQueue.actions.deleteManager.subtitle':
    'Deleting a work item permanently deletes it from the work queue',
  'drawer.workQueue.actions.reassign.title': 'Reassign Work Item',
  'drawer.workQueue.actions.reassign.subtitle':
    'Use the search bar below to find the person you would like to reassign this work item to',
  'drawer.workQueue.card.customer': 'Customer:',
  'drawer.workQueue.card.due': 'Due:',
  'drawer.workQueue.empty': 'No items in queue',
  'drawer.workQueue.priority.critical': 'Critical',
  'drawer.workQueue.priority.normal': 'Normal',
  'drawer.workQueue.search.noResults_term': 'No results found for {term}',
  'drawer.workQueue.search.noResults': 'No results found',
  'drawer.workQueue.filter.amountOfDays': 'Amount of Days',
  'drawer.workQueue.filter.dateRange': 'Date Range',
  'drawer.workQueue.filter.showAll': 'Show All',
  'drawer.workQueue.filter.amountOfDays.error': 'Please select a valid amount of days',
  'drawer.workQueue.filter.dateRange.error': 'Please select a valid date range',
  'drawer.workQueue.filter.amountOfDays.toggleText': '{from} - {to} Days',
  'drawer.workQueue.filter.showAll.toggleText': 'All',
  'leaveWithoutSaveGuard.body':
    "You have not saved your progress. If you leave now, you'll lose all your changes.",
  'leaveWithoutSaveGuard.cancelNavigationButtonText': 'Stay on Page',
  'leaveWithoutSaveGuard.confirmNavigationButtonText': 'Leave Without Saving',
  'leaveWithoutSaveGuard.title': 'Are you sure you want to leave?',

  'contactMethods.PersonalEmail': 'Personal Email',
  'contactMethods.WorkEmail': 'Work Email',
  'contactMethods.Website': 'Website',

  'contactMethods.ResidentialPhone': 'Residential Phone',
  'contactMethods.BusinessPhone': 'Business Phone',
  'contactMethods.FaxNumber': 'Fax Number',
  'contactMethods.CellPhone': 'Cell Phone',
  'contactMethods.EmergencyPhone': 'Emergency Phone',
  'contactMethods.SeasonalNumber': 'Seasonal/Sec. Phone',
  'contactMethods.Text': 'Text',
  'contactMethods.Pager': 'Pager',

  'activityList.Employee': 'Employee',
  'activityList.Activity': 'Activity',
  'activityList.Date': 'Date',
  'activityList.Policy': 'Policy',
  'activityList.Description': 'Description',
  'activityList.ActivitySummary': 'Activity Summary',
  'activityList.title': 'Activities',
  'activityList.toolTipText': 'Display based on SIG Refine View',

  'workInProgress.Header': 'Work In Progress',
  'workInProgress.toolTipText': 'QuickNavs & Suspenses',
  'workInProgress.DueDate': 'Due Date',
  'workInProgress.Type': 'Type',
  'workInProgress.Description': 'Description',
  'workInProgress.Policy': 'Policy',
  'workInProgress.AssignedTo': 'Assigned To',
  'workInProgress.Priority': 'Priority',

  'customer.policies.Header': 'Policies',
  'customer.policies.toolTipText': 'Display based on SIG Refine View',
  'customer.policies.Policy': 'Policy',
  'customer.policies.Term': 'Term',
  'customer.policies.Status': 'Status',
  'customer.policies.Company': 'Company',
  'customer.policies.Premium': 'Full Term Premium',
  'customer.policies.option.CurrentFuture': 'Current/Future',
  'customer.policies.option.Cancelled': 'Cancelled',
  'customer.policies.option.All': 'All',
  'customer.policies.option.Lapsed': 'Lapsed',
  'customer.policies.filter.Endorse': 'Endorse',
  'customer.policies.filter.Renew': 'Renew',
  'customer.policies.filter.Cancel': 'Cancel',
  'customer.policies.filter.NewActivity': 'New Activity',
  'customer.policies.filter.NewSuspense': 'New Suspense',
  'customer.bottomBar.errorMessage':
    'There are errors in one or more area. Please review each area for details, where applicable.',
  'manager.configuration.title': 'Manager Configuration',
  'manager.configuration.info': 'Manager Information',
  'manager.configuration.info2': 'Active Employee Information',
  'manager.configuration.changemodal.title': 'Confirm Manager Change',
  'manager.configuration.changemodal.subtitle':
    'You are about to change managers with unsaved assignment changes. Do you wish to continue changing the manager, or go back and complete the assignments?',
  'manager.configuration.placerholder1': 'Type to search or select from dropdown',
  'manager.configuration.placerholder2': 'Type to search',
  'manager.configuration.savebutton': 'Save Assignment Changes',
  'manager.configuration.checkbox': 'Show only Employees assigned to me',
  'manager.configuration.subheader':
    'Select the employees who will be assigned to the manager above using the checkboxes below.',
  'manager.configuration.description':
    'Select a manager to view already assigned employees or to assign additional employees. This manager will be able to view team and individual metrics within MyBrokerHome.',
  'manager.configuration.info.title': 'Employee Title',
  'manager.configuration.info.email': 'Email Address',
  'manager.configuration.select': 'Select a manager',
  'manager.configuration.success': 'Employee Assignment successful',
  'myProfile.title': 'My Profile',
  'myProfile.basicInfo.header': 'Basic Information',
  'myProfile.brokerageInfo.header': 'Brokerage Information',
  'myProfile.brokerageInfo.agencyName.header': 'Name',
  'myProfile.brokerageInfo.address.header': 'Address',
  'myProfile.brokerageInfo.phoneNumbers.header': 'Phone Number(s)',
  'myProfile.brokerageInfo.brokerages.header': 'Brokerage(s)',
  'myProfile.brokerageInfo.changeBrokerage.button': 'Change Brokerage',
  'myProfile.brokerageInfo.changeBrokerage.header': 'Are you sure you want to change brokerages?',
  'myProfile.brokerageInfo.changeBrokerage.subHeader':
    'You will be taken to the Login page where you can choose another brokerage.',
  'myProfile.brokerageInfo.chooseBrokerage.header': 'Choose a Brokerage',
  'myProfile.brokerageInfo.changeBrokerage.continue': 'Continue',
  'myProfile.generalInfo.header': 'General Information',
  'myProfile.generalInfo.displayName.header': 'Display Name',
  'myProfile.generalInfo.email.header': 'Email',
  'myProfile.generalInfo.language.header': 'Language',
  'myProfile.updatePassword.header': 'Update Password',
  'myProfile.updatePassword.subHeader':
    "Updating your password will connect you to PingID's password service.",
  'myProfile.updatePassword.linkText': 'Update password',
  'myProfile.generalInfo.changeLanguage.header': 'Are you sure you want to switch languages?',
  'myProfile.generalInfo.changeLanguage.subHeader':
    'You will now be experiencing MyBrokerHome in French.',
  'myProfile.generalInfo.changeLanguage.continue': 'Continue in French',
  'myProfile.generalInfo.language.english': 'English',
  'myProfile.generalInfo.language.french': 'French',
  'dashboard.manager.panel.action.open': 'Open',
  'dashboard.manager.panel.action.delete': 'Delete',
  'communicationService.error.title': 'Communication Error',
  'communicationService.error.description':
    'Unable to load the Vertafore Communication Service. Please retry login again. If asked to allow/launch the Vertafore Communication Service please allow it.',
  'communicationService.download.title': 'Action Required Before Continuing',
  'communicationService.download.description1a': 'In order to coninue, please ',
  'communicationService.download.description1b': 'download and install',
  'communicationService.download.description1c':
    ' the Vertafore Communication Service. When Prompted, follow browser instructions to complete the installation. Upon completion, refresh your screen.',
  'communicationService.download.description2a': 'This service is required for MyBrokerHome.',
  'communicationService.download.description2b': 'You can ',
  'communicationService.download.description2c': 'continue without installing',
  'communicationService.download.description2d':
    ' but will have limited functionality, including no ability to enter data on screens that open directly in SIG.',
  'communicationService.download.description3':
    'Please note that you must have administrative access on your computer to install the service.',
  'communicationService.download.description4':
    'If you are seeing this alert, but already have the latest Vertafore Communication Service installed, please restart your computer and try accessing MyBrokerHome again.',
  'communicationService.download.action': 'Download and Install',
  'quickNav.configuration.header': 'QuickNav Configuration',
  'quickNav.configuration.columns.displayName.header': 'QuickNav Name',
  'quickNav.configuration.columns.publishedInstances.header': 'Published Instances',
  'quickNav.configuration.columns.actions.new': 'Add New Instance',
  'quickNav.configuration.columns.actions.view': 'View Instances',
  'quickNav.configuration.enablePopup.content':
    'To use this QuickNav, an instance will need to be manually published.',
  'quickNav.configuration.columns.enable.header': 'Enable/Disable',

  'quickNav.instanceSelection.begin': 'Begin QuickNav Process',
  'quickNav.instanceSelection.newSig': 'New Business via SIG',
  'quickNav.instanceSelection.renewSig': 'Renew via SIG',
  'quickNav.instanceSelection.cancelSig': 'Cancel Policy via SIG',
  'quickNav.instanceSelection.endorseSig': 'Endorse via SIG',
  'quickNav.instanceSelection.title': 'QuickNav',
  'quickNav.instanceSelection.subTitle1': 'To begin, please select a QuickNav type.',
  'quickNav.instanceSelection.subTitle2': 'Now, please select a {quickNav} instance.',

  'quickNav.configuration.instances.version': 'Version',
  'quickNav.configuration.instances.publisheddraft': 'Published / Draft',
  'quickNav.configuration.instances.published': 'Published',
  'quickNav.configuration.instances.unpublished': 'Unpublished',
  'quickNav.configuration.instances.unpublisheddraft': 'Unpublished / Draft',
  'quickNav.configuration.instances.draft': 'Draft',
  'quickNav.configuration.instances.copy': 'Copy',
  'quickNav.configuration.instances.delete.draft': 'Delete Draft',
  'quickNav.configuration.instances.publish.draft': 'Publish Draft',
  'quickNav.configuration.instances.delete': 'Delete',
  'quickNav.configuration.instances.publish': 'Publish',
  'quickNav.configuration.instances.unpublish': 'Unpublish',
  'quickNav.configuration.instances.unpublish.title': 'Unpublish Confirmation',
  'quickNav.configuration.instances.unpublish.subtitle.pt1':
    'This instance will now be unpublished and removed from user selection for all new ',
  'quickNav.configuration.instances.unpublish.subtitle.pt2':
    ' QuickNavs. QuickNavs that are in progress will not be affected.',
  'quickNav.configuration.instances.unpublish.button': 'Unpublish Now',
  'quickNav.configuration.instances.publish.title': 'Publish Confirmation',
  'quickNav.configuration.instances.publish.subtitle.pt1':
    'This configuration will now be published for all new ',
  'quickNav.configuration.instances.publish.subtitle.pt2':
    ' QuickNavs. QuickNavs that are in progress will not be affected.',
  'quickNav.configuration.instances.publish.button': 'Publish Now',
  'quickNav.configuration.instances.delete.title': 'Delete Instance',
  'quickNav.configuration.instances.delete.subtitle':
    'Deleting this instance from this QuickNav will permanently remove it from your Instance list. You will no longer have the ability to review drafts, versions or restore. This will also remove it from the list of available QuickNavs for user selection. QuickNavs that are in progress will not be affected.',
  'quickNav.configuration.instances.delete.button': 'Delete',
  'quickNav.configuration.instances.expanded.lastupdate': 'Last Update',
  'quickNav.configuration.instances.expanded.restore': 'Restore',
  'quickNav.configuration.instances.edit.name': 'Instance Name',
  'quickNav.configuration.instances.edit.savedraft': 'Save Draft',
  'quickNav.configuration.instances.edit.workstep': 'Work Steps',
  'quickNav.configuration.instances.edit.days': 'Days',
  'quickNav.configuration.instances.edit.days.tooltip':
    'The number inputted will represent the number of days from the workflow start date. It does not represent the number of days to complete the step.',
  'quickNav.configuration.instances.edit.title.error': 'Title must be at least 10 characters',
  'quickNav.configuration.instances.edit.step.error': 'Step titles must be at least 10 characters',
  'quickNav.configuration.instances.edit.step.max.error': 'Name cannot exceed 160 characters',
  'quickNav.configuration.instances.edit.step.warning.draft':
    'A draft already exists for this instance. A new draft cannot be saved. You must publish your edit or delete your existing draft.',
  'quickNav.configuration.instances.edit.duplicated':
    'This instance name is already in use. Select OK to confirm creating a new instance with the same name or Cancel to return and change the instance name before saving.',
  'quickNav.configuration.instances.edit.days.error': 'Not a valid number',
  'quickNav.configuration.instances.edit.step': 'Step',
  'quickNav.configuration.instances.edit.stepname': 'Step Name',
  'quickNav.configuration.instances.edit.link': 'Link',
  'quickNav.configuration.instances.edit.duedate': 'Days from beginning',
  'quickNav.configuration.instances.edit.active': 'Active',
  'quickNav.configuration.instances.edit.addStep': 'Add Step',
  'quickNav.configuration.instances.edit.linkUrl': 'Link URL',
  'errorPages.common.header': "Hang tight - something's not quite right.",
  'errorPages.403.error': 'Error 403',
  'errorPages.403.message':
    "Unfortunately you do not have the required permissions to access this product. Please reach out to your administrator if you'd like to continue.",
  'errorPages.403.license.message':
    'Unfortunately, you do not have access to this product. Please reach out to your administrator if you feel this is not correct.',
  'errorPages.403.sigpermission.message':
    'Unfortunately you do not have access to this brokerage, or are not configured correctly in SIG. Please reach out to your administrator for assistance.',
  'errorPages.403.area.message':
    'Unfortunately you do not have the required permissions to access this area. Please reach out to your administrator if you feel this is not correct.',
  'quicknav.keyInformation.key.policy': 'Policy',
  'quicknav.keyInformation.key.term': 'Term',
  'quicknav.keyInformation.key.endEffDate': 'Transaction Effective Date',
  'logform.title': 'New Log',
  'logform.title.update': 'Update Log',
  'logform.linkactivity': 'Link This Activity',
  'logform.duedate': 'Due Date',
  'logform.action': 'Suspense Action',
  'logform.policy': 'Policy',
  'logform.transaction.date': 'Transaction Date',
  'logform.activityType': 'Activity Type',
  'logform.descriptionType': 'Description Type',
  'logform.saleStage': 'Stage',
  'logform.closeDate': 'Close Date',
  'logform.premium': 'Premium',
  'logform.attachdoc': 'Attach Document',
  'logform.uploaddoc': 'Add Document',
  'logform.sharetoinsurlink': 'Share to InsurLink',
  'logform.summary': 'Summary',
  'logform.8000limit': '8000 Character Limit',
  'logform.logdate': 'Log Date',
  'logform.viewcustomer': 'View Customer',
  'logform.savelog': 'Save Log',
  'logform.typesomething': 'Type Something...',
  'logform.Processed.Date': 'Processed Date',
  'logformview.title': 'View Log',
  'logformview.company': 'Company',
  'logformview.times.rescheduled': 'Times Rescheduled',
  'logformview.exec': 'Exec',
  'logformview.createdby': 'Created By',
  'logformview.completedby': 'Completed By',
  'logformview.assignedto': 'Assigned To',
  'logformview.rep': 'Rep',
  'logformview.method': 'Method',
  'logformview.attachdoc': 'Attach Document',
  'logformview.uploaddocument': 'Add Document',
  'logformview.sharetoinsurlink': 'Share to InsurLink',
  'logformview.EditSummary': 'Edit Summary',
  'logformview.linked': 'Linked',
  'logformview.paperclip': 'This log has an attachment.  You can view it in SIG.',
  'logformview.system': 'System',
  'logformview.manual': 'Manual',
  'logformview.duedate': 'Log/Due Date',
  'logform.activityCreateSuccess': 'Activity created successfully',
  'logform.activityCreateError': 'Something went wrong saving the activity',
  'logform.suspenseCreateSuccess': 'Suspense created successfully',
  'logform.suspenseCreateError': 'Something went wrong saving the suspense',
  'logformviewsummary.activityextCreateSuccess': 'Activity details saved successfully',
  'logformviewsummary.activityextCreateError': 'Something went wrong saving activity details',
  'logformview.suspensecreatedby': 'Created By',
};
