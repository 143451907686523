import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import CustomerDropdownsServices from '../../../services/dropdowns/CustomerDropdownsServices';

export const useCustomerDropdowns = (
  options?: UseQueryOptions<CustomerDropdowns, unknown, CustomerDropdowns, QueryKey>
): UseQueryResult<CustomerDropdowns, any> => {
  const searchData = useQuery<CustomerDropdowns>(
    'dropdowns',
    CustomerDropdownsServices.getDropdowns,
    options
  );

  return searchData;
};
