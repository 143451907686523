import React, { Dispatch, SetStateAction, useEffect } from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { Divider, Flex, LoadingIndicator, Spacer, Typography } from 'alloy-foundation';
import { FormattedMessage } from 'react-intl';
import { useRouter } from '../../common/hooks/useRouter';
import stylesFn from './styles';
import DrawerContent from '../DrawerContent';
import DrawerHeader from '../DrawerHeader';
import WorkItemHeader from './WorkItemHeader';
import { availableAssignee, useAvailableAssignee, useProcess } from './useWorkQueue';
import WorkItemTaskCard from './WorkItemTaskCard';
import { ChecklistItemCompletionStatus } from '../../../models/Process';

interface Props {
  processId: string;
  setProcessId: Dispatch<SetStateAction<string>>;
}

const WorkItemView = ({ processId, setProcessId }: Props) => {
  const { css, styles } = useStyles({ stylesFn });
  const { history } = useRouter();
  const process = useProcess(processId);
  const options: availableAssignee = useAvailableAssignee(processId);
  useEffect(() => {
    if (process.isSuccess && !process.isFetching) {
      const customer = process.data?.BusinessObjects?.find((x) => x.Type === 1);
      if (customer) history.push(`/customers/${customer.Id}`);
    }
  }, [process.data, history, process.isSuccess, process.isFetching]);

  useEffect(() => {
    // when the process' current task has a status other than NotCompleted, this indicates
    // the workflow has been completed.  Therefore, we close the workitem drawer and go
    // back to thw work queue drawer.
    if (
      process.data?.CurrentTask?.Status === ChecklistItemCompletionStatus.Completed ||
      process.data?.CurrentTask?.Status === ChecklistItemCompletionStatus.NotApplicable
    )
      setProcessId(undefined);
  }, [process.data?.CurrentTask?.Status, setProcessId]);

  return (
    <>
      <DrawerHeader>
        <WorkItemHeader process={process.data} setProcessId={setProcessId} options={options} />
      </DrawerHeader>
      <DrawerContent>
        <div {...css(styles.itemContainer)}>
          {process.isLoading || process.isError ? (
            <Spacer pt="small">
              <Flex justifyContent="center">
                {process.isLoading && <LoadingIndicator />}
                {process.isError && (
                  <Typography color="white" variant="disclaimer">
                    <FormattedMessage id="common.error_loading_data" />
                  </Typography>
                )}
              </Flex>
            </Spacer>
          ) : (
            <>
              <Spacer mb="xsmall" />
              {process.data &&
                (process.data.Tasks ?? []).map((p, i) => (
                  <React.Fragment key={i}>
                    <WorkItemTaskCard
                      task={p}
                      processId={process.data.Id}
                      businessObjects={process.data.BusinessObjects}
                    />
                    {i < process.data.Tasks.length - 1 && (
                      <Divider ml="small" mr="medium" pt="none" pb="none" />
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
        </div>
      </DrawerContent>
    </>
  );
};

export default WorkItemView;
