import {
  Button,
  ButtonGroup,
  Modal,
  Row,
  Column,
  Spacer,
  Typography,
  VertaFormik,
  FormInput,
  FormDropdownSingleSelect,
  LoadingIndicator,
  FormCheckBox,
} from 'alloy-foundation';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { forwardRef, useImperativeHandle, Ref } from 'react';
import { useFormikContext } from 'formik';
import Divider from '../../../common/divider/Divider';
import { DashboardPanelOptionsProps } from '../DashboardPanelOptionsProps';
import ToDoListWorkgroupDashboardPanelValidationSchema from './ToDoListWorkgroupDashboardPanelValidationSchema';
import * as fieldNames from './fieldNames';
import { ToDoListCustomFilter, ToDoListOptionsDomainData } from '../../../../models/ToDo';
import { useToDoListWorkgroupOptionsDomainData } from './useToDoListWorkgroupOptionsDomainData';
import LocalizedFormDatePickerInput from '../../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';
import { Handle } from '../../../common/handle';

interface Props {
  values: ToDoListCustomFilter;
  domainData: ToDoListOptionsDomainData;
}

const ToDoListWorkgroupDashboardOptionsContent = forwardRef(
  ({ values, domainData }: Props, ref: Ref<{ SubmitForm }>) => {
    const { formatMessage } = useIntl();
    const formikContext = useFormikContext();
    const SubmitForm = () => formikContext.submitForm();

    useImperativeHandle(ref, () => ({ SubmitForm }));

    return (
      <React.Fragment>
        <Row>
          <Column>
            <Divider pt="none" pb="none" ml="none" mr="none" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer paddingTop="small" paddingBottom="small" color="grayDark">
              <Typography noWrap={true} variant="h4">
                <FormattedMessage id="dashboard.panel.todoListWorkgroup.options.showToDoItems" />
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormInput
              required={values.dueDateFrom || values.dueDateTo ? false : true}
              name={fieldNames.DAYS_FROM_TODAY}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.dueDaysFromNow',
              })}
              format="numberNoCommas"
              maxLength={4}
              onChange={async () => {
                await formikContext.setFieldValue(fieldNames.DUE_DATE_FROM, '');
                await formikContext.setFieldValue(fieldNames.DUE_DATE_TO, '');
              }}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer paddingTop="small" paddingBottom="small">
              <Typography noWrap={true} variant="h4" color="grayDark">
                <FormattedMessage id="dashboard.panel.todoListWorkgroup.options.or" />
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              required={values.dueDateFrom || values.dueDateTo ? true : false}
              name={fieldNames.DUE_DATE_FROM}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.dueDateFrom',
              })}
              onChange={() => formikContext.setFieldValue(fieldNames.DAYS_FROM_TODAY, '')}
            />
          </Column>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              required={values.dueDateFrom || values.dueDateTo ? true : false}
              name={fieldNames.DUE_DATE_TO}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.dueDateTo',
              })}
              onChange={() => formikContext.setFieldValue(fieldNames.DAYS_FROM_TODAY, '')}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Divider pt="none" pb="none" ml="none" mr="none" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer paddingTop="small" paddingBottom="small" color="grayDark">
              <Typography noWrap={true} variant="h4">
                <FormattedMessage id="dashboard.panel.todoListWorkgroup.options.and" />
              </Typography>
            </Spacer>
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormDropdownSingleSelect
              required={true}
              name={fieldNames.ACTION}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.action',
              })}
              options={
                domainData?.Actions.map((data) => {
                  return { value: data.Value, label: data.DisplayText };
                }) ?? []
              }
            />
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormDropdownSingleSelect
              required={true}
              name={fieldNames.PRIORITY}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.priority',
              })}
              options={
                domainData?.Priorities.map((data) => {
                  return { value: data.Value, label: data.DisplayText };
                }) ?? []
              }
            />
          </Column>
        </Row>
        <Row>
          <Column md={6}>
            <FormDropdownSingleSelect
              required={true}
              name={fieldNames.WORKGROUP}
              label={formatMessage({
                id: 'dashboard.panel.todoListWorkgroup.options.workgroup',
              })}
              options={
                domainData?.Workgroups.map((data) => {
                  return { value: data.Value, label: data.DisplayText };
                }) ?? []
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Divider pt="none" pb="none" ml="none" mr="none" />
          </Column>
        </Row>
        <Row>
          <Column>
            <Spacer pt="small" pb="small">
              <FormCheckBox
                renderErrorMessage={false}
                label={formatMessage({
                  id: 'dashboard.panel.todoListWorkgroup.options.completed',
                })}
                name={fieldNames.INCLUDE_COMPLETE}
                noSpacing={true}
              />
              <Spacer pt="xsmall">
                <FormCheckBox
                  renderErrorMessage={false}
                  label={formatMessage({
                    id: 'dashboard.panel.todoListWorkgroup.options.notCompleted',
                  })}
                  name={fieldNames.INCLUDE_INCOMPLETE}
                />
              </Spacer>
            </Spacer>
          </Column>
        </Row>
      </React.Fragment>
    );
  }
);

const ToDoListWorkgroupDashboardOptions = ({
  data: customFilter,
  onClose,
  onApply,
}: DashboardPanelOptionsProps<ToDoListCustomFilter>) => {
  const { formatMessage } = useIntl();
  const [initialValues, setInitialValues] = useState<ToDoListCustomFilter>({
    action: '',
    priority: '',
    daysFromToday: '',
    dueDateFrom: '',
    dueDateTo: '',
    includeCompleted: false,
    includeIncomplete: false,
  });
  const validationSchema = ToDoListWorkgroupDashboardPanelValidationSchema(formatMessage);
  let childHandle: Handle<typeof ToDoListWorkgroupDashboardOptionsContent>;

  const toDoListOptionsDomainData = useToDoListWorkgroupOptionsDomainData((data) => {
    const values = { filter: 'Custom' } as ToDoListCustomFilter;

    // use default values returned by the endpoint if custom filter settings have not been previously stored
    if ((customFilter?.filter ?? '') !== 'Custom') {
      values.action = data.Action;
      values.priority = data.Priority;
      values.daysFromToday = data.DaysFromToday?.toString();
      values.includeCompleted = data.IncludeCompleted;
      values.includeIncomplete = data.IncludeIncomplete;
      values.dueDateFrom = data.DueDateFrom;
      values.dueDateTo = data.DueDateTo;
      values.workgroup = data.Workgroup;
    } else {
      values.action = customFilter?.action;
      values.priority = customFilter?.priority;
      values.daysFromToday = customFilter?.daysFromToday;
      values.includeCompleted = customFilter?.includeCompleted ?? true;
      values.includeIncomplete = customFilter?.includeIncomplete ?? true;
      values.dueDateFrom = customFilter?.dueDateFrom;
      values.dueDateTo = customFilter?.dueDateTo;
      values.workgroup = customFilter?.workgroup;
    }

    setInitialValues(values);
  });

  return (
    <Modal
      title={formatMessage({ id: 'dashboard.panel.todoListWorkgroup.title' })}
      onClose={onClose}
      footer={
        <ButtonGroup endAlign={true}>
          <Button variant="tertiary" onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button variant="primary" onClick={() => childHandle.SubmitForm()}>
            <FormattedMessage id="common.button.apply" />
          </Button>
        </ButtonGroup>
      }
    >
      {toDoListOptionsDomainData.isFetching ? (
        <LoadingIndicator />
      ) : (
        <VertaFormik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onApply}
        >
          <ToDoListWorkgroupDashboardOptionsContent
            values={initialValues}
            domainData={toDoListOptionsDomainData.data}
            ref={(c) => (childHandle = c)}
          />
        </VertaFormik>
      )}
    </Modal>
  );
};

export default ToDoListWorkgroupDashboardOptions;
