import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useField, useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import * as fieldNames from '../../../fieldNames';
import Header from '../../../../common/header/Header';
import LocalizedFormDatePickerInput from '../../../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';

export const PrivacyAct = ({
  showHeader = true,
  parentNamespace,
}: {
  showHeader?: boolean;
  parentNamespace?: string;
}) => {
  // hooks
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext();
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const [picField] = useField(fieldNames.PERSONAL_INFORMATION_CONSENT);

  const consentOptions = [
    { value: 'true', label: formatMessage({ id: 'common.yes' }) },
    { value: 'false', label: formatMessage({ id: 'common.no' }) },
  ];

  // functions
  const handlePicChange = async (values, args) => {
    await formikContext.handleChange(fieldNames.PERSONAL_INFORMATION_CONSENT);

    if (values) {
      await formikContext.setFieldValue(
        prefix + fieldNames.DATE_OF_CONSENT,
        DateTime.now().toFormat('MM/dd/yyyy')
      );
      await formikContext.setTouched({
        ...formikContext.touched,
        [`${prefix} + ${fieldNames.DATE_OF_CONSENT}`]: true,
      });
    } else {
      // required because pressing X on the dropdown sets the value an as empty string...
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await formikContext.setFieldValue(prefix + fieldNames.PERSONAL_INFORMATION_CONSENT, null);
    }

    // re-validate field if already validating
    if (!formikContext.isValid) {
      await formikContext.validateField(prefix + fieldNames.DATE_OF_CONSENT);
    }
  };

  const handleCfouChange = async (values, args) => {
    await formikContext.handleChange(prefix + fieldNames.CONSENT_FOR_OTHER_USE);

    if (!values) {
      // required because pressing X on the dropdown sets the value an as empty string...
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await formikContext.setFieldValue(prefix + fieldNames.CONSENT_FOR_OTHER_USE, null);
    }
  };

  // rendering
  return (
    <>
      {showHeader && <Header title={formatMessage({ id: 'customer.details.privacyAct' })} />}
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={4}>
            <FormDropdownSingleSelect
              data-testid="joepro"
              name={prefix + fieldNames.PERSONAL_INFORMATION_CONSENT}
              label={formatMessage({ id: 'customer.details.personalInformationConsent' })}
              options={consentOptions}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
              onChange={async (values, ...args: any[]) => {
                await handlePicChange(values, args);
              }}
            />
          </Column>
          <Column md={4}>
            <FormDropdownSingleSelect
              name={prefix + fieldNames.CONSENT_FOR_OTHER_USE}
              label={formatMessage({ id: 'customer.details.consentForOtherUse' })}
              options={consentOptions}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
              onChange={async (values, ...args: any[]) => {
                await handleCfouChange(values, args);
              }}
            />
          </Column>
          <Column md={3}>
            <LocalizedFormDatePickerInput
              required={picField.value ? true : false}
              name={prefix + fieldNames.DATE_OF_CONSENT}
              label={formatMessage({ id: 'customer.details.dateOfConsent' })}
              value=""
              data-testid="DateOfConsent"
            />
          </Column>
        </Row>
      </Spacer>
    </>
  );
};
