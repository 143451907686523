import * as Yup from 'yup';
import { DateTime } from 'luxon';
import * as fieldNames from '../fieldNames';
import { EmailValidationSchema } from './EmailValidationSchema';
import { PhoneValidationSchema } from './PhoneValidationSchema';

export const SecondaryContactValidationSchema = (formatMessage) => {
  return Yup.object({
    [`${fieldNames.FIRST_NAME}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.LAST_NAME}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.MIDDLE_NAME}`]: Yup.string(),
    [`${fieldNames.PREFIX}`]: Yup.string(),
    [`${fieldNames.DOB}`]: Yup.string().test(
      'date',
      formatMessage({ id: 'common.validation.invalidDate' }),
      (val) => {
        if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

        return true;
      }
    ),
    [`${fieldNames.MARITAL_STATUS}`]: Yup.string(),
    [`${fieldNames.EMAILS}`]: Yup.array().of(EmailValidationSchema(formatMessage)),
    [`${fieldNames.PHONES}`]: Yup.array().of(PhoneValidationSchema(formatMessage)),
    [`${fieldNames.PREFERED_CONTACT_METHOD}`]: Yup.string(),
  });
};
