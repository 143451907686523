import { Button, TableRow, CheckBox, Flex, FormInput, TableCell } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useRef, useState } from 'react';
import { useDrop, useDrag } from 'react-dnd';

export const ItemTypes = {
  LIST_ITEM: 'list-item',
};

interface DragObject {
  id: string;
  index: number;
}
export class StepType {
  static readonly LinkingStep = 'LinkingStep';
  static readonly SigStep = 'SigStep';
  static readonly SmartflowStep = 'SmartflowStep';
}
export function DraggableStepItem({ moveItem, index, id, item, values, errors, setFieldValue }) {
  const ref = useRef(null);
  const [textFocused, setTextFocused] = useState(false);

  const { formatMessage } = useIntl();
  const getStepType = (i): StepType => {
    if (values.Steps[`${i}`]?.Type === 'ams.Winform') {
      if (values.Steps[`${i}`]?.IsExcludable) return StepType.SigStep;
      else return StepType.LinkingStep;
    } else {
      if (values.Steps[`${i}`]?.IsExcludable === false) return StepType.LinkingStep;
      else return StepType.SmartflowStep;
    }
  };
  const handleLinkChange = (val, i) => {
    const terms = ['://', 'http://', 'https://'];
    const value = val.target.value;
    if (value && !terms.some((term) => value.toLowerCase().includes(term)))
      setFieldValue(`Steps.${i}.Url`, `http://${value}`);
  };
  const draggableButton = (
    <Button icon="reorder" variant="link" data-testid={`action-${index}-drag`}></Button>
  );
  const [, drop] = useDrop({
    accept: ItemTypes.LIST_ITEM,
    hover(item2: DragObject, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item2.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item2.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.LIST_ITEM,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !textFocused,
  });

  drag(drop(ref));
  const isDeactivated = values.Steps[`${index}`]?.IsExcluded;
  const stepType = getStepType(index);
  return (
    <TableRow rowColor={isDragging ? 'grayLighter' : 'white'} ref={ref}>
      <TableCell>{index + 1}</TableCell>
      <TableCell width={300} data-testid={`row-${index}-StepName`}>
        <FormInput
          onMouseEnter={() => setTextFocused(true)}
          onMouseLeave={() => setTextFocused(false)}
          label="Label"
          placeholder={formatMessage({ id: 'quickNav.configuration.instances.edit.stepname' })}
          hideLabel
          required
          disabled={isDeactivated}
          name={`Steps.${index}.Title`}
          renderErrorMessage={Object.keys(errors).length > 0}
        />
      </TableCell>
      <TableCell width={300} data-testid={`row-${index}-Url`}>
        <FormInput
          onMouseEnter={() => setTextFocused(true)}
          onMouseLeave={() => setTextFocused(false)}
          onBlurCapture={(value) => handleLinkChange(value, index)}
          label="Label"
          hideLabel
          required
          placeholder={formatMessage({ id: 'quickNav.configuration.instances.edit.linkUrl' })}
          disabled={values.Steps[`${index}`]?.Type !== 'smartflow' || isDeactivated}
          name={
            values.Steps[`${index}`]?.Type === 'smartflow'
              ? `Steps.${index}.Url`
              : `Steps.${index}.UrlPlaceHolder`
          }
          renderErrorMessage={Object.keys(errors).length > 0}
          suffix={
            <Button
              icon="open"
              variant="link"
              disabled={
                values.Steps[`${index}`]?.Type !== 'smartflow' ||
                isDeactivated ||
                !values.Steps[`${index}`].Url
              }
              onClick={() => window.open(`${values.Steps[`${index}`].Url}`)}
            />
          }
        />
      </TableCell>
      <TableCell width={160} data-testid={`row-${index}-Days`}>
        <FormInput
          onMouseEnter={() => setTextFocused(true)}
          onMouseLeave={() => setTextFocused(false)}
          label="Label"
          required
          disabled={isDeactivated}
          name={`Steps.${index}.DueDateModifier`}
          hideLabel
          renderErrorMessage={Object.keys(errors).length > 0}
        />
      </TableCell>
      <TableCell width={150} data-testid={`row-${index}-Check`}>
        {stepType !== StepType.LinkingStep && (
          <CheckBox
            data-testid
            onChange={() => {
              setFieldValue(`Steps.${index}.IsExcluded`, !isDeactivated);
            }}
            checked={!isDeactivated}
            label="Label"
            hideLabel
            renderErrorMessage={false}
          ></CheckBox>
        )}
      </TableCell>
      <TableCell>
        <Flex justifyContent="flex-end">
          {stepType === StepType.SmartflowStep && (
            <Button
              data-testid={`row-${index}-delete`}
              icon="delete"
              variant="link"
              onClick={() => {
                setFieldValue(
                  'Steps',
                  values.Steps.filter((obj) => obj !== item)
                );
              }}
            />
          )}
          {draggableButton}
        </Flex>
      </TableCell>
    </TableRow>
  );
}
