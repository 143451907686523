export class CsvDataService {
  static exportToCsv(filename: string, rows: object[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent = `\ufeff${keys.join(separator)}\n${rows
      .map((row) => {
        return keys
          .map((k) => {
            let cell = row[k] === null || row[k] === undefined ? '' : row[k];
            if (cell instanceof Date) {
              cell = cell.toLocaleDateString();
            } else {
              cell = cell.toString().replace(/"/g, '""');
              if (cell.search(/[^A-Za-z0-9]/g) >= 0) {
                cell = `"${cell}"`;
                // Add = if the first char of the string is a symbol to disable excel trying to convert it to math formula.
                if (cell.search(/^"[^A-Za-z0-9].*"$/g) >= 0) {
                  cell = `=${cell}`;
                }
              }
            }
            return cell;
          })
          .join(separator);
      })
      .join('\n')}`;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
