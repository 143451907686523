import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { CUSTOMER_TYPE, LANGUAGE, SUB_BROKER } from '../../fieldNames';

const BaseCustomerDetails = ({
  dropdowns,
  parentNamespace,
}: {
  dropdowns: CustomerDropdowns;
  parentNamespace?: string;
}) => {
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext();
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const currentValue = formikContext.getFieldProps(prefix + CUSTOMER_TYPE).value as string;
  const subBrokerCustomerType = 'B';

  const triggerValidation = async () => {
    // re-validate field if already validating
    if (!formikContext.isValid) {
      await formikContext.validateField(prefix + CUSTOMER_TYPE);
      // Required because Formik throw an error since the control no longer exist.
      if (formikContext.registerField[prefix + SUB_BROKER]) {
        await formikContext.validateField(prefix + SUB_BROKER);
      }
    }
  };

  const handleCustomerTypeChange = async (values, args) => {
    await formikContext.handleChange(prefix + CUSTOMER_TYPE);

    if (values) {
      if (values !== subBrokerCustomerType) {
        await formikContext.setFieldValue(prefix + SUB_BROKER, '');
      }
    } else {
      // required because pressing X on the dropdown sets the value an as empty string...
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await formikContext.setFieldValue(prefix + SUB_BROKER, '');
    }

    await triggerValidation();
  };

  return (
    <Spacer mb="tiny">
      <Row data-testid="base">
        <Column md={3}>
          <FormDropdownSingleSelect
            required={true}
            disabled={parentNamespace ? true : false}
            name={prefix + CUSTOMER_TYPE}
            label={formatMessage({ id: 'customer.details.base.type.label' })}
            options={dropdowns.ClientTypes.map((data) => {
              return { value: data.Key, label: data.Value };
            })}
            placeholder={formatMessage({ id: 'customer.details.base.type.placeholder' })}
            onChange={async (values, ...args: any[]) => {
              await handleCustomerTypeChange(values, args);
            }}
          />
        </Column>
        {currentValue === 'B' && (
          <Column md={3}>
            <FormDropdownSingleSelect
              required={true}
              disabled={parentNamespace ? true : false}
              name={prefix + SUB_BROKER}
              label={formatMessage({ id: 'customer.details.base.subbroker.label' })}
              options={dropdowns.SubBrokers.map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'customer.details.base.subbroker.placeholder' })}
            />
          </Column>
        )}
        <Column md={3}>
          <FormDropdownSingleSelect
            required={true}
            name={prefix + LANGUAGE}
            label={formatMessage({ id: 'customer.details.base.language.label' })}
            options={dropdowns.Languages.map((data) => {
              return { value: data.Key, label: data.Value };
            })}
            placeholder={formatMessage({ id: 'customer.details.base.language.placeholder' })}
          />
        </Column>
      </Row>
    </Spacer>
  );
};

export default BaseCustomerDetails;
