import React from 'react';
import { useIntl } from 'react-intl';
import { Column, Flex, FormCheckBox, FormInput } from 'alloy-foundation';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { FormDropdownSingleSelect } from 'alloy-foundation';
import { Emails } from '../../../../models/Emails';
import * as EmailFieldsName from './fieldNames';
import * as FieldsName from '../../fieldNames';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import FormYesNoDropdown from '../../../common/forms/formYesNoDropdown/FormYesNoDropdown';
import { KeyValuePair } from '../../../../models/KeyValuePair';
import LocalizedFormDatePickerInput from '../../../common/localizedFormDatePickerInput/LocalizedFormDatePickerInput';

export interface EmailFormProps {
  parentNamespace?: string;
  namespace: string;
  index: number;
  dropdowns: CustomerDropdowns;
  disableMandatory?: boolean;
  hideType?: boolean;
}

const EmailForm = ({
  parentNamespace,
  namespace,
  index,
  dropdowns,
  disableMandatory,
  hideType = false,
}: EmailFormProps) => {
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext();

  const websiteType = '6';
  const fullNameSpace = `${(parentNamespace ? `${parentNamespace}.` : '') + namespace}[${index}]`;
  const calsDateOfConsentNamespace = `${fullNameSpace}.${EmailFieldsName.CALS_DATE_OF_CONSENT}`;

  const parentValue = formikContext.getFieldProps(namespace).value;
  const currentValue = formikContext.getFieldProps(`${namespace}[${index}]`).value as Emails;
  const currentPossibleEmailTypes = parentValue
    .filter((x: Emails) => x.type !== currentValue.type)
    .map((x: Emails) => x.type);
  const currentType = dropdowns.TypeOfEmails.find((x) => x.Key === currentValue.type)?.Value;

  const isMandatory =
    dropdowns.MandatoryContactMethods.includes(currentValue?.type ?? '-1') && !disableMandatory;

  // Website don't have CASL
  const showCASLFields =
    currentValue?.type !== websiteType &&
    currentValue?.emailAddress &&
    currentValue?.emailAddress.length > 0;

  const options = dropdowns.TypeOfEmails.filter(
    (listEmailTypes: KeyValuePair) => !currentPossibleEmailTypes.includes(listEmailTypes.Key)
  ).map((data) => {
    return {
      value: data.Key,
      label: data.Value,
    };
  });

  const triggerValidation = async () => {
    // re-validate field if already validating
    if (!formikContext.isValid) {
      await formikContext.validateField(`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`);
      await formikContext.validateField(`${fullNameSpace}.${EmailFieldsName.EMAIL_TYPE}`);
      // Required because Formik throw an error since the control no longer exist.
      if (formikContext.registerField[calsDateOfConsentNamespace]) {
        await formikContext.validateField(calsDateOfConsentNamespace);
      }
    }
  };

  const handleCASLChange = async (values, args) => {
    await formikContext.handleChange(`${fullNameSpace}.${EmailFieldsName.CALS}`);

    if (values) {
      await formikContext.setFieldValue(
        `${fullNameSpace}.${EmailFieldsName.CALS_DATE_OF_CONSENT}`,
        DateTime.now().toFormat('MM/dd/yyyy')
      );
      await formikContext.setTouched({
        ...formikContext.touched,
        [`${calsDateOfConsentNamespace}`]: true,
      });
    } else {
      // required because pressing X on the dropdown sets the value an as empty string...
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await formikContext.setFieldValue(calsDateOfConsentNamespace, '');
    }

    await triggerValidation();
  };

  const handleEmailTypeChange = async (values, args) => {
    await formikContext.handleChange(`${fullNameSpace}.${EmailFieldsName.EMAIL_TYPE}`);

    if (!values) {
      // required because pressing X on the dropdown sets the value an as empty string...
      // alloy bug - no longer only supports string values, but clear always sets value as empty string
      await formikContext.setFieldValue(`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`, '');
      await formikContext.setFieldValue(
        `${fullNameSpace}.${EmailFieldsName.CALS_DATE_OF_CONSENT}`,
        ''
      );
      await formikContext.setFieldValue(`${fullNameSpace}.${EmailFieldsName.CALS}`, '');
    }

    await triggerValidation();
  };

  const handleNotApplicableChange = async () => {
    await formikContext.handleChange(`${fullNameSpace}.${FieldsName.NOT_APPLICABLE}`);

    const previousValue = formikContext.getFieldProps(
      `${fullNameSpace}.${FieldsName.NOT_APPLICABLE}`
    ).value as boolean;

    if (!previousValue) {
      await formikContext.setFieldValue(`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`, '');
      await formikContext.setFieldValue(calsDateOfConsentNamespace, '');
      await formikContext.setFieldValue(`${fullNameSpace}.${EmailFieldsName.CALS}`, '');
    }

    await triggerValidation();
  };

  return (
    <React.Fragment>
      {!hideType && (
        <Column md={3}>
          <FormDropdownSingleSelect
            name={`${fullNameSpace}.${EmailFieldsName.EMAIL_TYPE}`}
            label={formatMessage({ id: 'customer.contactMethods.emailType' })}
            placeholder={formatMessage({ id: 'customer.contactMethods.emailType.placeholder' })}
            disabled={isMandatory}
            options={options}
            required={false}
            onChange={async (values, ...args: any[]) => {
              await handleEmailTypeChange(values, args);
            }}
          />
        </Column>
      )}
      <Column md={hideType ? 6 : 4}>
        {currentValue?.type !== websiteType ? (
          <FormInput
            label={
              hideType ? currentType : formatMessage({ id: 'customer.contactMethods.emailAddress' })
            }
            name={`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`}
            data-testid={`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`}
            required={hideType && isMandatory}
            disabled={currentValue?.notApplicable}
            placeholder="john.doe@example.com"
            onChange={triggerValidation}
            maxLength={80}
          />
        ) : (
          <FormInput
            label={formatMessage({ id: 'customer.contactMethods.website' })}
            name={`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`}
            data-testid={`${fullNameSpace}.${EmailFieldsName.EMAIL_ADDRESS}`}
            required={false}
            disabled={currentValue?.notApplicable}
            placeholder="https://www.example.com"
            onChange={triggerValidation}
            maxLength={80}
          />
        )}
      </Column>

      {showCASLFields && (
        <React.Fragment>
          <Column md={hideType ? 3 : 2}>
            <FormYesNoDropdown
              label={formatMessage({ id: 'customer.contactMethods.CASLConsent' })}
              name={`${fullNameSpace}.${EmailFieldsName.CALS}`}
              onChange={async (values, ...args: any[]) => {
                await handleCASLChange(values, args);
              }}
            />
          </Column>
          <Column md={hideType ? 3 : 2}>
            <LocalizedFormDatePickerInput
              name={`${fullNameSpace}.${EmailFieldsName.CALS_DATE_OF_CONSENT}`}
              label={formatMessage({ id: 'customer.contactMethods.CASLConsentDate' })}
              errorMessage="rererer"
            />
          </Column>
        </React.Fragment>
      )}
      {isMandatory && (
        <Column md={1}>
          <Flex alignItems="flex-end" height={hideType ? '3rem' : '5.125rem'}>
            <FormCheckBox
              label={formatMessage({ id: 'customer.contactMethods.notApplicable' })}
              name={`${fullNameSpace}.${FieldsName.NOT_APPLICABLE}`}
              onChange={handleNotApplicableChange}
            />
          </Flex>
        </Column>
      )}
    </React.Fragment>
  );
};

export default EmailForm;
