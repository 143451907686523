import {
  Column,
  Flex,
  FormDropdownSingleSelect,
  FormInput,
  LoadingIndicator,
  Row,
  Spacer,
} from 'alloy-foundation';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext, useField } from 'formik';
import Divider from '../../../common/divider/Divider';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { POSTAL_CODE, CITY, PROVINCE_CODE, ADDRESS_LINE, ADDRESS_LINE2 } from '../../fieldNames';
import AddressLookupService from '../../../../services/addressLookup/AddressLookupService';
import CivicNumberModal from './CivicNumberModal';
import PotentialMatchesModal from './PotentialMatchesModal';
import { Address } from '../../../../models/Address';
import PostalCodeSearchInput from './PostalCodeSearchInput';
import PotentialMatchesBar from './PotentialMatchesBar';
import Header from '../../../common/header/Header';

const CurrentAddress = ({
  dropdowns,
  showHeader = true,
  parentNamespace,
}: {
  dropdowns: CustomerDropdowns;
  showHeader?: boolean;
  parentNamespace?: string;
}) => {
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext();
  const [isLookingUp, setIsLookingUp] = useState(false);
  const [civicNumberVisible, setCivicNumberVisible] = useState(false);
  const [potentialMatchesVisible, setPotentialMatchesVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address>({
    Address1: '',
    City: '',
    Zipcode: '',
    State: '',
    Range: '',
  });
  const [potentialMatches, setPotentialMatches] = useState<Address[]>([]);
  const [postalCodeField] = useField(prefix + POSTAL_CODE);

  const provinces = dropdowns.States.filter((state) =>
    ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'].includes(
      state.Key
    )
  ).sort((a, b) => a.Value.localeCompare(b.Value));

  const resetAddressFields = async () => {
    await formikContext.setFieldValue(`${prefix}${CITY}`, '');
    await formikContext.setFieldValue(`${prefix}${PROVINCE_CODE}`, '');
    await formikContext.setFieldValue(`${prefix}${ADDRESS_LINE}`, '');
    await formikContext.setFieldValue(`${prefix}${ADDRESS_LINE2}`, '');

    setSelectedAddress({ Address1: '', City: '', Zipcode: '', State: '', Range: '' });
    setPotentialMatches([]);
  };

  const handlePostalCodeLookup = async () => {
    if (!formikContext.errors[`${POSTAL_CODE}`]) {
      await resetAddressFields();

      setIsLookingUp(true);
      const result = await AddressLookupService.listAddressMatches(postalCodeField.value);
      setIsLookingUp(false);
      if (result.Status === 'Success' && result.Addresses?.length === 1) {
        // we have a single match
        handleAddressMatchSelected(result.Addresses[0]);
      } else {
        // need to display list of matches
        setPotentialMatches(result.Addresses);
      }
    }
  };

  const validatePostalCodeFormat = async () => {
    // validate postal code
    await formikContext.validateField(`${prefix}${POSTAL_CODE}`);
  };

  const handleCivicNumberSelected = async (civicNumber) => {
    setCivicNumberVisible(false);
    await formikContext.setFieldValue(
      `${prefix}${ADDRESS_LINE}`,
      `${civicNumber} ${selectedAddress.Address1}`
    );
  };

  const handleAddressMatchSelected = async (address) => {
    setPotentialMatchesVisible(false);

    // we have a single match
    setSelectedAddress(address);
    setPotentialMatches([]);

    await formikContext.setFieldValue(`${prefix}${POSTAL_CODE}`, address.Zipcode);
    await formikContext.setFieldValue(`${prefix}${CITY}`, address.City);
    await formikContext.setFieldValue(`${prefix}${PROVINCE_CODE}`, address.State);
    await formikContext.setFieldValue(`${prefix}${ADDRESS_LINE}`, address.Address1);

    // re-validate if already validating
    if (!formikContext.isValid) {
      await formikContext.validateField(`${prefix}${CITY}`);
      await formikContext.validateField(`${prefix}${PROVINCE_CODE}`);
      await formikContext.validateField(`${prefix}${ADDRESS_LINE}`);
    }

    // need to display civic number modal popup if there's a range for this postal code
    if (address.UnitNumberFrom !== address.UnitNumberTo) setCivicNumberVisible(true);
    else {
      // push full street address if there's a signle civic number for this postal code
      await formikContext.setFieldValue(
        `${prefix}${ADDRESS_LINE}`,
        `${address.UnitNumberFrom} ${address.Address1}`
      );
    }
  };

  return (
    <React.Fragment>
      {civicNumberVisible && (
        <CivicNumberModal
          range={selectedAddress.Range}
          onClose={() => setCivicNumberVisible(false)}
          onSelect={(value) => handleCivicNumberSelected(value)}
        />
      )}
      {potentialMatchesVisible && (
        <PotentialMatchesModal
          addresses={potentialMatches}
          onSelect={(value) => handleAddressMatchSelected(value)}
          onClose={() => setPotentialMatchesVisible(false)}
        />
      )}
      {showHeader && (
        <Header title={formatMessage({ id: 'customer.details.currentAddress.title' })} />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <Spacer mb="tiny">
          <Row>
            <Column md={6}>
              <FormInput
                name={`${prefix}${ADDRESS_LINE}`}
                data-testid={`${prefix}${ADDRESS_LINE}`}
                maxLength={80}
                label={formatMessage({ id: 'customer.details.currentAddress.addressLine.label' })}
                required={true}
              />
            </Column>
            <Column md={6}>
              <FormInput
                name={`${prefix}${ADDRESS_LINE2}`}
                data-testid={`${prefix}${ADDRESS_LINE2}`}
                maxLength={80}
                label={formatMessage({ id: 'customer.details.currentAddress.addressLine2.label' })}
              />
            </Column>
          </Row>
        </Spacer>
        <Spacer mb="tiny">
          <Row>
            <Column md={3}>
              <FormInput
                name={`${prefix}${CITY}`}
                data-testid={`${prefix}${CITY}`}
                maxLength={19}
                label={formatMessage({ id: 'customer.details.currentAddress.city.label' })}
                required={true}
              />
            </Column>
            <Column md={3}>
              <FormDropdownSingleSelect
                required={true}
                name={`${prefix}${PROVINCE_CODE}`}
                label={formatMessage({ id: 'customer.details.currentAddress.province.label' })}
                options={provinces.map((data) => {
                  return { value: data.Key, label: data.Value };
                })}
                placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
              />
            </Column>
            <Column md={3}>
              <PostalCodeSearchInput
                name={`${prefix}${POSTAL_CODE}`}
                label={formatMessage({ id: 'customer.details.currentAddress.postalCode.label' })}
                required
                onValidate={async () => await validatePostalCodeFormat()}
                onClick={async () => await handlePostalCodeLookup()}
                data-testid="PostalCodeSearchInput"
              />
            </Column>
            <Column md={1}>
              {' '}
              {isLookingUp && (
                <Flex alignItems="flex-end" height="4rem" width="2rem">
                  <LoadingIndicator size="medium" />
                </Flex>
              )}
            </Column>
          </Row>
        </Spacer>
        {potentialMatches.length > 1 && (
          <PotentialMatchesBar
            numnberOfMatches={potentialMatches.length}
            onClick={() => setPotentialMatchesVisible(true)}
          />
        )}
      </Spacer>
      <Divider pt="none" pb="none" ml="none" mr="none" />
    </React.Fragment>
  );
};

export default CurrentAddress;
