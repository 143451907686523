import * as Yup from 'yup';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import * as fieldNames from '../fieldNames';

const PHONE_REG_EXP = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[-]?([0-9]{4})$|^$/;

export const PhoneValidationSchema = (
  formatMessage,
  requiredAll = true,
  dropdowns?: CustomerDropdowns
) =>
  Yup.object().shape(
    {
      [`${fieldNames.PHONE_NUMBER}`]: requiredAll
        ? Yup.string()
            .matches(PHONE_REG_EXP, formatMessage({ id: 'common.validation.invalidPhone' }))
            .when([`${fieldNames.PHONE_TYPE}`, `${fieldNames.NOT_APPLICABLE}`], {
              is: (phoneType, notapplicable) => phoneType && phoneType.length > 0 && !notapplicable,
              then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
            })
        : Yup.string()
            .matches(PHONE_REG_EXP, formatMessage({ id: 'common.validation.invalidPhone' }))
            .when([`${fieldNames.PHONE_TYPE}`, `${fieldNames.NOT_APPLICABLE}`], {
              is: (phoneType, notapplicable) =>
                phoneType &&
                phoneType.length > 0 &&
                dropdowns.MandatoryContactMethods.includes(phoneType) &&
                !notapplicable,
              then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
              otherwise: Yup.string().nullable(),
            }),
      [`${fieldNames.NOT_APPLICABLE}`]: Yup.boolean(),
      [`${fieldNames.PHONE_TYPE}`]: Yup.string().when([`${fieldNames.PHONE_NUMBER}`], {
        is: (phoneNumber) => phoneNumber && phoneNumber.length > 0,
        then: Yup.string().required(formatMessage({ id: 'common.validation.required' })),
      }),
      [`${fieldNames.PHONE_EXTENSION}`]: Yup.number().nullable(),
    },
    [[fieldNames.PHONE_TYPE, fieldNames.PHONE_NUMBER]]
  );
