import baseRequests from '../../api/baseRequests';
import TodoListDueTodayRequestPayload from '../../models/requestPayloads/TodoListDueTodayRequestPayload';
import {
  ToDoListCriteria,
  ToDoList,
  ToDoListOptionsDomainData,
  ToDoListCompleteStatusCriteria,
  ToDoListCompleteStatusResult,
} from '../../models/ToDo';

const baseURL = 'api/ToDoList';

const TodoListService = {
  GetDueTodayCount: (payload: TodoListDueTodayRequestPayload): Promise<string> =>
    baseRequests.post(`${baseURL}/GetDueToDayCount`, payload).then((response) => response),
  list: (criteria: ToDoListCriteria): Promise<ToDoList> =>
    baseRequests.post(`${baseURL}/GetDoToList`, criteria).then((response) => response),
  getOptionsDomainData: (): Promise<ToDoListOptionsDomainData> =>
    baseRequests.get(`${baseURL}/ViewOptionsDomainData`).then((response) => response),
  updateSuspenseStatus: (
    criteria: ToDoListCompleteStatusCriteria
  ): Promise<ToDoListCompleteStatusResult> =>
    baseRequests.post(`${baseURL}/CompleteSuspenseStatus`, [criteria]).then((response) => response),
};

export default TodoListService;
