import React, { useState, useContext, useCallback } from 'react';
import { ItemProps } from '../../topnav/LogForm/LogForm';
import { formatCustomerString, formatCustomerName } from '../utils';
import { WorkInProgressItem } from '../../../models/WorkInProgress';

export interface LogFormCustomer {
  CustomerId: string;
  CustomerNumber: string;
  AddressLine1: string;
  FirstName: string;
  LastName: string;
}

interface ILogFormContext {
  showLogForm?: (
    type: string,
    customer?: LogFormCustomer,
    policy?: string,
    transactiondate?: string,
    suspense?: WorkInProgressItem
  ) => void;

  hideLogForm?: () => void;
  setLogFormCustomer?: (p) => void;
  setLogFormSuspense?: (p) => void;
  logFormCustomer?: ItemProps;
  logFormVisible: boolean;
  logFormPolicy?: string;
  logFormTransactionDate?: string;
  logFormType: string;
  logFormSuspense?: WorkInProgressItem;
}

// default Dashboard view is set here on the context, and the state below.
const LogFormContext = React.createContext<ILogFormContext>({
  logFormVisible: false,
  logFormType: '',
});

export const LogFormProvider = ({ children }) => {
  const [logFormType, setLogFormType] = useState('');
  const [logFormVisible, setLogFormVisible] = useState(false);
  const [logFormCustomer, setLogFormCustomer] = useState<ItemProps>();
  const [logFormPolicy, setLogFormPolicy] = useState('');
  const [logFormTransactionDate, setLogFormTransactionDate] = useState('');
  const [logFormSuspense, setLogFormSuspense] = useState<WorkInProgressItem>();

  const showLogForm = useCallback(
    (
      type: string,
      customer: LogFormCustomer,
      policy: string,
      transactiondate: string,
      suspense: WorkInProgressItem
    ) => {
      setLogFormCustomer(
        customer
          ? {
              value: customer.CustomerId,
              label: formatCustomerString(
                formatCustomerName(customer),
                customer.CustomerNumber,
                customer.AddressLine1
              ),
            }
          : null
      );
      setLogFormType(type);
      setLogFormPolicy(policy);
      setLogFormTransactionDate(transactiondate);
      setLogFormSuspense(suspense);
      setLogFormVisible(true);
    },
    [
      setLogFormCustomer,
      setLogFormType,
      setLogFormPolicy,
      setLogFormTransactionDate,
      setLogFormVisible,
      setLogFormSuspense,
    ]
  );

  const hideLogForm = useCallback(() => {
    setLogFormCustomer(null);
    setLogFormPolicy(null);
    setLogFormTransactionDate(null);
    setLogFormVisible(false);
  }, [setLogFormVisible]);

  return (
    <LogFormContext.Provider
      value={{
        showLogForm,
        hideLogForm,
        logFormCustomer,
        logFormType,
        logFormPolicy,
        logFormTransactionDate,
        setLogFormCustomer,
        logFormVisible,
        logFormSuspense,
      }}
    >
      {children}
    </LogFormContext.Provider>
  );
};

export const useLogFormProvider = () => {
  const context = useContext(LogFormContext);
  if (context === undefined) {
    throw new Error('useLogFormProvider cannot be called from outside of a LogFormProvider');
  }
  return context;
};
