import { DateTime } from 'luxon';
import * as Yup from 'yup';
import * as fieldNames from './fieldNames';

const ExpiringPoliciesDashboardOptionsValidationSchema = (formatMessage) =>
  Yup.object().shape(
    {
      [`${fieldNames.CODE}`]: Yup.string()
        .trim()
        .required(formatMessage({ id: 'common.validation.required' })),
      [`${fieldNames.DATE_FROM}`]: Yup.string()
        .required(formatMessage({ id: 'common.validation.required' }))
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        }),
      [`${fieldNames.DATE_TO}`]: Yup.string()
        .required(formatMessage({ id: 'common.validation.required' }))
        .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
          if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

          return true;
        }),
    },
    [[fieldNames.DATE_FROM, fieldNames.DATE_TO]]
  );

export default ExpiringPoliciesDashboardOptionsValidationSchema;
