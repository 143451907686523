import { Button, ButtonGroup, Flex, Modal } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';

export interface DuplicatedModalProps {
  visible: boolean;
  hide(): void;
  handlePublishing(): void;
}

const DuplicatedModal = (props: DuplicatedModalProps) => {
  const { formatMessage } = useIntl();

  return props.visible ? (
    <Modal
      onClose={() => props.hide()}
      subtitle={formatMessage({
        id: 'quickNav.configuration.instances.edit.duplicated',
      })}
    >
      <Flex flexDirection="row-reverse">
        <ButtonGroup>
          <Button onClick={() => props.hide()}>
            {formatMessage({ id: 'common.button.cancel' })}
          </Button>
          <Button variant="primary" onClick={props.handlePublishing}>
            {formatMessage({ id: 'common.button.ok' })}
          </Button>
        </ButtonGroup>
      </Flex>
    </Modal>
  ) : null;
};

export default DuplicatedModal;
