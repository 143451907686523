import basePSSRequests from '../../api/basePSSRequests';
import { NewCustomer } from '../../models/NewCustomer';
import { PssCustomer } from '../../models/PssCustomer';
import { ContactModel } from '../../models/putPayloads/ContactModel';
import { Emails } from '../../models/Emails';
import { PhoneNumber } from '../../models/PhoneNumber';
import { ContactMethod } from '../../models/ContactMethod';
import { CustomerDropdowns } from '../../models/CustomerDropdowns';
import { PutCustomerModel } from '../../models/putPayloads/PutCustomerModel';
import { GeneralInfoModel } from '../../models/putPayloads/GeneralInfoModel';
import { BusinessInfoModel } from '../../models/putPayloads/BusinessInfoModel';
import { EPermissions, HasPermissions, Permissions } from '../../models/enums/EPermissions';
import { SigPermissions } from '../../models/SigPermissions';

// base url
const baseURL = '/clients/';

const createContactMethods = (emails: Emails[], phones: PhoneNumber[]): ContactMethod[] => {
  const contactMethods: ContactMethod[] = [];

  emails.forEach((email) => {
    const payload = {
      Details: email.emailAddress,
      IsNA: email.notApplicable,
      AreaCode: null,
      Extension: null,
      Type: email.type,
      CASLConsent: email.cals === 'Y' ? true : email.cals === 'N' ? false : null,
      CASLDateOfConsent: new Date(email.calsDateOfConsent),
    };
    contactMethods.push(payload);
  });

  phones.forEach((phone) => {
    const payload = {
      Details: phone.phoneNumber,
      IsNA: phone.notApplicable,
      Extension: phone.phoneExtension,
      Type: phone.type,
      // Deal at the backend as the we already have to code to parse the phone number there. FormInput return phone number as a 10-digit number without ()
      AreaCode: null,
      Cals: null,
      CalsDateOfConsent: null,
    } as ContactMethod;
    contactMethods.push(payload);
  });

  return contactMethods;
};

const createClassesModel = (updatedValues: GeneralInfoModel, dropdown: CustomerDropdowns) => {
  const result = [
    {
      name: 'customerClass1',
      key: updatedValues.customerClass1,
      value: !updatedValues.customerClass1
        ? ''
        : dropdown.CustomerClasses.find((i) => i.Key === updatedValues.customerClass1).Value,
    },
    {
      name: 'customerClass2',
      key: updatedValues.customerClass2,
      value: !updatedValues.customerClass2
        ? ''
        : dropdown.CustomerClasses.find((i) => i.Key === updatedValues.customerClass2).Value,
    },
    {
      name: 'customerClass3',
      key: updatedValues.customerClass3,
      value: !updatedValues.customerClass3
        ? ''
        : dropdown.CustomerClasses.find((i) => i.Key === updatedValues.customerClass3).Value,
    },
    {
      name: 'customerClass4',
      key: updatedValues.customerClass4,
      value: !updatedValues.customerClass4
        ? ''
        : dropdown.CustomerClasses.find((i) => i.Key === updatedValues.customerClass4).Value,
    },
  ];
  return result;
};

const generateContactModelPayLoad = (
  putPayloads: any,
  initialValues: ContactModel,
  updatedValues: ContactModel
) => {
  putPayloads.address1 = {
    Original: initialValues.addressLine,
    Modified: updatedValues.addressLine,
  };
  putPayloads.address2 = {
    Original: initialValues.addressLine2,
    Modified: updatedValues.addressLine2,
  };
  putPayloads.city = {
    Original: initialValues.city,
    Modified: updatedValues.city,
  };
  putPayloads.postalCode = {
    Original: initialValues.postalCode,
    Modified: updatedValues.postalCode,
  };
  putPayloads.province = {
    Original: initialValues.provinceCode,
    Modified: updatedValues.provinceCode,
  };
  putPayloads.preferedContactMethod = {
    Original: initialValues.preferedContactMethod ?? '',
    Modified: updatedValues.preferedContactMethod ?? '',
  };
  putPayloads.ContactMethods = createContactMethods(updatedValues.emails, updatedValues.phones);
};

const generateBusinessInfoModelPayload = (
  putPayloads: any,
  initialValues: BusinessInfoModel,
  updatedValues: BusinessInfoModel
) => {
  putPayloads.GLDivisionCode = {
    Original: initialValues.division,
    Modified: updatedValues.division,
  };
  putPayloads.GLDepartmentCode = {
    Original: initialValues.department,
    Modified: updatedValues.department,
  };
  putPayloads.AccountRepCode = {
    Original: initialValues.accountRepresentative1,
    Modified: updatedValues.accountRepresentative1,
  };
  putPayloads.AccountRep2Code = {
    Original: initialValues.accountRepresentative2,
    Modified: updatedValues.accountRepresentative2,
  };
  putPayloads.AccountExecCode = {
    Original: initialValues.accountExecutive1,
    Modified: updatedValues.accountExecutive1,
  };
  putPayloads.AccountExec2Code = {
    Original: initialValues.accountExecutive2,
    Modified: updatedValues.accountExecutive2,
  };
};
const generateGeneralInfoModelPayload = (
  putPayloads: any,
  initialValues: GeneralInfoModel,
  updatedValues: GeneralInfoModel
) => {
  // Shared fields
  putPayloads.dateOfConsent = {
    Original: initialValues.dateOfConsent,
    Modified: updatedValues.dateOfConsent,
  };
  putPayloads.language = {
    Original:
      initialValues.language === 'en-CA' ? 'e' : initialValues.language === 'fr-CA' ? 'f' : '',
    Modified:
      updatedValues.language === 'en-CA' ? 'e' : updatedValues.language === 'fr-CA' ? 'f' : '',
  };
  putPayloads.personalInformationConsent = {
    Original:
      String(initialValues.personalInformationConsent) === 'true'
        ? 'Y'
        : String(initialValues.personalInformationConsent) == null ||
          String(initialValues.personalInformationConsent) !== 'false'
        ? ''
        : 'N',
    Modified:
      String(updatedValues.personalInformationConsent) === 'true'
        ? 'Y'
        : String(updatedValues.personalInformationConsent) !== 'false' ||
          String(updatedValues.personalInformationConsent) == null
        ? ''
        : 'N',
  };
  putPayloads.consentOtherUse = {
    Original:
      String(initialValues.consentForOtherUse) === 'true'
        ? 'Y'
        : String(initialValues.consentForOtherUse) == null ||
          String(initialValues.consentForOtherUse) !== 'false'
        ? ''
        : 'N',
    Modified:
      String(updatedValues.consentForOtherUse) === 'true'
        ? 'Y'
        : String(updatedValues.consentForOtherUse) == null ||
          String(updatedValues.consentForOtherUse) !== 'false'
        ? ''
        : 'N',
  };

  // Type of business specific fields
  switch (updatedValues.typeOfBusiness) {
    // Commercial
    case 'C': {
      putPayloads.DoingBusinessAs = {
        Original: initialValues.firmDBA ?? '',
        Modified: updatedValues.firmDBA ?? '',
      };
      putPayloads.lastName = {
        Original: initialValues.firmName ?? '',
        Modified: updatedValues.firmName ?? '',
      };
      break;
    }
    // Personal
    case 'P': {
      putPayloads.firstName = {
        Original: initialValues.firstName,
        Modified: updatedValues.firstName,
      };
      putPayloads.lastName = {
        Original: initialValues.lastName,
        Modified: updatedValues.lastName,
      };
      putPayloads.middleName = {
        Original: initialValues.middleName,
        Modified: updatedValues.middleName,
      };
      putPayloads.primaryPrefix = {
        Original: typeof initialValues.prefix === 'string' ? 0 : initialValues.prefix,
        Modified: typeof updatedValues.prefix === 'string' ? 0 : updatedValues.prefix,
      };
      putPayloads.secondaryFirstName = {
        Original: initialValues.secondaryContact.firstName,
        Modified: updatedValues.secondaryContact.firstName,
      };
      putPayloads.secondaryLastName = {
        Original: initialValues.secondaryContact.lastName,
        Modified: updatedValues.secondaryContact.lastName,
      };
      putPayloads.secondaryMiddleName = {
        Original: initialValues.secondaryContact.middleName,
        Modified: updatedValues.secondaryContact.middleName,
      };
      putPayloads.secondaryPrefix = {
        Original:
          typeof initialValues.secondaryContact.prefix === 'string'
            ? 0
            : initialValues.secondaryContact.prefix,
        Modified:
          typeof updatedValues.secondaryContact.prefix === 'string'
            ? 0
            : updatedValues.secondaryContact.prefix,
      };
      putPayloads.maritalStatus = {
        Original: initialValues.maritalStatus,
        Modified: updatedValues.maritalStatus,
      };
      putPayloads.maritalStatus2 = {
        Original: initialValues.secondaryContact.maritalStatus,
        Modified: updatedValues.secondaryContact.maritalStatus,
      };

      putPayloads.dateOfBirth = {
        Original: initialValues.dateOfBirth,
        Modified: updatedValues.dateOfBirth,
      };
      putPayloads.dateOfBirth2 = {
        Original: initialValues.secondaryContact.dateOfBirth,
        Modified: updatedValues.secondaryContact.dateOfBirth,
      };
      break;
    }
    default: {
      throw new TypeError('Customer cannot be anything else that personal or commercial');
    }
  }
};

const generatePayload = (
  id: string,
  initialValues: PutCustomerModel,
  updatedValues: PutCustomerModel,
  dropdowns: CustomerDropdowns,
  sigPermissions: SigPermissions
) => {
  const putPayloads = {
    objectType: 'ams360.customer',
    ContactMethods: {},
    CustomerClasses: {},
  };

  generateContactModelPayLoad(
    putPayloads,
    initialValues.contactInformation,
    updatedValues.contactInformation
  );
  generateGeneralInfoModelPayload(putPayloads, initialValues.general, updatedValues.general);
  generateBusinessInfoModelPayload(
    putPayloads,
    initialValues.businessInformation,
    updatedValues.businessInformation
  );
  putPayloads.CustomerClasses = HasPermissions(
    sigPermissions,
    Permissions.CanEditCustomerClass,
    EPermissions.EMODIFY
  )
    ? createClassesModel(updatedValues.general, dropdowns)
    : // The real value should be null but that cause the backend to crash.
      // And fixing it will break more than this simple fix.
      '';
  return putPayloads;
};

// exports
const customerServices = {
  create: (newCustomer: NewCustomer): Promise<string> =>
    basePSSRequests.post(baseURL, newCustomer).then((response) => response),
  get: (id: string): Promise<PssCustomer> =>
    basePSSRequests.get(`${baseURL}sig/${id}`).then((response) => response),
  put: (
    id: string,
    initialValues: PutCustomerModel,
    updatedValues: PutCustomerModel,
    dropdowns: CustomerDropdowns,
    sigPermissions: SigPermissions
  ): Promise<any> => {
    const putPayloads = generatePayload(
      id,
      initialValues,
      updatedValues,
      dropdowns,
      sigPermissions
    );

    return basePSSRequests.put(baseURL + id, putPayloads).then((response) => response);
  },
};

export default customerServices;
