import React from 'react';
import PageLayout from './PageLayout';
import DashboardToggle from '../../dashboard/DashboardToggle';

export default function DashboardPageLayout(props) {
  return (
    <PageLayout
      minHeight="calc(100vh - 7.5rem)"
      hideContentHeader={true}
      topNode={<DashboardToggle />}
      {...props}
    />
  );
}
