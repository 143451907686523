import React from 'react';
import { useIntl } from 'react-intl';
import { DataTable, DataTableProps } from '../../data-table';

export default function LocalizedDataTable<Data>({ phrases, ...props }: DataTableProps<Data>) {
  const { formatMessage } = useIntl();
  const localizedPhrases = {
    rowCountAfterText: formatMessage({ id: 'datatable.rowcount.afterText' }),
    rowCountBeforeText: formatMessage({ id: 'datatable.rowcount.beforeText' }),
    paginationNextLabel: formatMessage({ id: 'datatable.paging.next' }),
    paginationPreviousLabel: formatMessage({ id: 'datatable.paging.previous' }),
  };

  return (
    <DataTable
      phrases={localizedPhrases}
      {...props}
      filterPlaceholder={formatMessage({ id: 'customer.search.placeholder' })}
      advanceSearchClearButton={formatMessage({ id: 'common.advanceSearch.clear' })}
      advanceSearchPlaceholder={formatMessage({ id: 'common.advanceSearch' })}
      setFilterValue={props.setFilterValue}
    />
  );
}
