import { Column, Container, FormField, Row, Spacer, Typography } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { Manager } from '../../../models/Manager';
export const ManagerInfoCard = (managerData: Manager) => {
  const { formatMessage } = useIntl();
  return (
    <div style={{ outline: '1px solid lightgray', marginBottom: '1em' }}>
      <Container fluid>
        <Row noGutters={true}>
          <Column>
            <Spacer pt="medium" pl="medium">
              <FormField label={formatMessage({ id: 'customer.details.personal.firstname.label' })}>
                <Typography variant="body">{managerData?.FirstName}</Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column>
            <Spacer pt="medium" pl="medium">
              <FormField label={formatMessage({ id: 'customer.details.personal.lastname.label' })}>
                <Typography variant="body">{managerData?.LastName}</Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column>
            <Spacer pt="medium" pl="medium">
              <FormField label={formatMessage({ id: 'manager.configuration.info.email' })}>
                <Typography variant="body">{managerData?.EMail}</Typography>
              </FormField>
            </Spacer>
          </Column>
          <Column>
            <Spacer pt="medium" pl="medium">
              <FormField label={formatMessage({ id: 'manager.configuration.info.title' })}>
                <Typography variant="body">{managerData?.EmployeeTitle}</Typography>
              </FormField>
            </Spacer>
          </Column>
        </Row>
      </Container>
    </div>
  );
};
