import { Link, Spacer, Typography } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RecentCustomer } from '../../../models/RecentCustomer';
import { useRouter } from '../../common/hooks/useRouter';
import { useDrawer } from '../DrawerProvider';

interface Props {
  data: RecentCustomer;
}

const CustomerInfo = ({ data }: Props) => {
  const { history } = useRouter();
  const { hideDrawer } = useDrawer();

  return (
    <Spacer pt="small" pl="small" pr="small" pb="tiny" data-testid="CustomerInfo">
      <Link
        underline="hover"
        variant="white"
        block
        onClick={() => {
          history.push(`/customers/${data.ClientId}`);
          hideDrawer();
        }}
      >
        <Typography color="white" variant="bodySm" fontWeight="semibold" noWrap>
          {data.DisplayName}
        </Typography>
        <Typography color="white" variant="bodySm">
          <FormattedMessage id="drawer.recentCustomers.customerId" />
          {data.AccountNo}
        </Typography>
      </Link>
    </Spacer>
  );
};

export default CustomerInfo;
