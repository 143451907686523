import { Button } from 'alloy-foundation';
import React from 'react';
import { CellProps, DataTableColumn } from './DataTableColumn';

const spaceForExandableRowCaretPx = 56;

export class ExpandableRowColumn<Data> extends DataTableColumn<Data> {
  onCaretClick?: (row: Data) => void;

  constructor({
    onCaretClick,
    width = spaceForExandableRowCaretPx,
    sortable = false,
    padding = 'filterIcon',
    dataTestId = 'expandable-row-header',
    ...values
  }: Partial<ExpandableRowColumn<Data>> & { key: DataTableColumn<Data>['key'] }) {
    super(values);
    Object.assign(this, { onCaretClick, width, sortable, padding, dataTestId });
  }

  Cell = ({ row, isExpanded }: CellProps<Data>) => {
    return (
      <Button
        onClick={(e) => {
          this.onCaretClick?.(row);
          e.stopPropagation();
        }}
        variant="link"
        data-testid="expandable-row-caret"
        icon={isExpanded ? 'caretDown' : 'caretRight'}
      />
    );
  };
}
