import { Spacer, Switch } from 'alloy-foundation';
import { noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { CellProps, DataTableColumn } from '../../data-table/DataTableColumn';

export class SwitchDataColumn<Data> extends DataTableColumn<Data> {
  onChange?: (row: Data, value: boolean) => void;

  constructor({
    onChange,
    padding = 'default',
    ...values
  }: Partial<SwitchDataColumn<Data>> & { key: DataTableColumn<Data>['key'] }) {
    super(values);
    Object.assign(this, { padding });

    if (onChange) {
      Object.assign(this, {
        onChange,
      });
    }
  }

  format = (row: Data) => {
    return this.getValue(row) as string;
  };

  Cell = ({ row, rowIndex }: CellProps<Data>) => {
    const handleOnChange = () => {
      if (this.onChange) {
        this.onChange(row, !(this.getValue(row) as unknown as boolean));
      }
    };
    return (
      <Spacer paddingLeft="medium">
        <Switch
          id={uuidv4()}
          onClick={noop}
          checked={this.getValue(row) as unknown as boolean}
          label=""
          onChange={handleOnChange}
          data-testid={`switch-Column-${this.key.toString()}-${rowIndex}`}
          hideLabel={true}
          noSpacing={true}
          renderErrorMessage={false}
        />
      </Spacer>
    );
  };
}
