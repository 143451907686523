import { ErrorPage403 } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';

export const UnauthorizedAccess = () => {
  const { formatMessage } = useIntl();
  let descriptionMessage = 'errorPages.403.message';
  const { errorCode } = useProductInfoProvider();
  if (errorCode) {
    switch (errorCode) {
      case 1:
        descriptionMessage = 'errorPages.403.license.message';
        break;
      case 2:
        descriptionMessage = 'errorPages.403.sigpermission.message';
        break;
    }
  }

  return (
    <React.Fragment>
      <ErrorPage403
        header={formatMessage({ id: 'errorPages.common.header' })}
        description={formatMessage({ id: descriptionMessage })}
        subHeading={formatMessage({ id: 'errorPages.403.error' })}
      />
    </React.Fragment>
  );
};
