import {
  Column,
  Container,
  DropdownSingleSelectTypeaheadSearch,
  LeaveWithoutSaveModal,
  LoadingIndicator,
  Row,
  SectionContainer,
  Typography,
} from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ManagerInfoCard } from './ManagerInfoCard';

export const ManagerSelectionStep = ({
  managerList,
  selectedManager,
  setSelectedManager,
  isDirty,
}) => {
  const { formatMessage } = useIntl();
  const [dropdown, setDropdown] = useState(null);
  const [tempManager, setTempManager] = useState(null);
  const [leaveWithoutSaveModalVisible, setLeaveWithoutSaveModalVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredManagers, setFilteredManagers] = useState([]);
  const onInputValueChange = (_inputValue) => {
    setInputValue(_inputValue);
  };

  useEffect(() => {
    const optionList = [];
    managerList?.data?.forEach((item) => {
      optionList.push({
        value: item.VimId.toString(),
        label: item.DisplayName,
      });
    });
    setDropdown(optionList);
    setTempManager(null);
    setFilteredManagers(optionList);
  }, [managerList?.data]);
  useEffect(() => {
    setFilteredManagers(
      dropdown?.filter((item) => {
        return item.label.toLowerCase().includes(inputValue.toLocaleLowerCase());
      })
    );
  }, [inputValue, dropdown]);

  const handleChange = (e) => {
    const vimId = parseInt(e?.value);
    const selManager = managerList?.data.find((x) => x.VimId === vimId);
    if (!isDirty || !selectedManager) setSelectedManager(selManager);
    else {
      setTempManager(selManager);
      setLeaveWithoutSaveModalVisible(true);
    }
  };

  const hanldeOnInputClear = () => {
    if (!isDirty || !selectedManager) setSelectedManager(null);
    else {
      setTempManager(null);
      setLeaveWithoutSaveModalVisible(true);
    }
  };

  return (
    <SectionContainer headerText={formatMessage({ id: 'manager.configuration.info' })}>
      {managerList.isLoading && <LoadingIndicator />}
      {dropdown && managerList.isSuccess && (
        <Container fluid noPadding>
          <Row>
            <Column sm={4}>
              <DropdownSingleSelectTypeaheadSearch
                label={formatMessage({ id: 'manager.configuration.select' })}
                data-testid="dropdownsearch"
                options={selectedManager ? dropdown : filteredManagers}
                placeholder={formatMessage({ id: 'manager.configuration.placerholder1' })}
                onChange={(e) => handleChange(e)}
                clearButtonAriaLabel="clearButton"
                selectedItem={
                  selectedManager
                    ? {
                        value: selectedManager?.VimId.toString(),
                        label: selectedManager?.DisplpayName,
                      }
                    : null
                }
                onInputClear={() => hanldeOnInputClear()}
                inputValue={inputValue}
                onInputValueChange={onInputValueChange}
              />
            </Column>
          </Row>
          {selectedManager && <ManagerInfoCard {...selectedManager}></ManagerInfoCard>}
          {leaveWithoutSaveModalVisible && (
            <LeaveWithoutSaveModal
              title={formatMessage({ id: 'manager.configuration.changemodal.title' })}
              subtitle={formatMessage({ id: 'manager.configuration.changemodal.subtitle' })}
              cancelNavigationButtonText={formatMessage({
                id: 'common.button.cancel',
              })}
              confirmNavigationButtonText={formatMessage({
                id: 'manager.configuration.changemodal.title',
              })}
              children={null}
              onConfirmNavigationClick={() => {
                setSelectedManager(tempManager);
                setTempManager(null);
                setLeaveWithoutSaveModalVisible(false);
              }}
              onClose={() => {
                setTempManager(null);
                setLeaveWithoutSaveModalVisible(false);
              }}
            />
          )}
        </Container>
      )}
      {managerList.isError && (
        <Typography variant="disclaimer" align="center">
          <FormattedMessage id="common.error_loading_data" />
        </Typography>
      )}
    </SectionContainer>
  );
};
