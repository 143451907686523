import { FormInput, Icon, Theme } from 'alloy-foundation';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { Styles } from 'react-with-styles';
import { FieldProps } from 'alloy-foundation/es/forms/hooks/useFormField';
import { InputProps } from 'alloy-foundation/es/components/Input';

const stylesFn = ({
  vertaforeAlloy: { color, spacing, border, borderWidth, inputs },
}: Theme): Styles => ({
  dropdownInputButton: {
    display: 'flex',
    minHeight: `calc(${inputs.sizing.medium} - 0.125rem)`,
    alignSelf: 'stretch',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    verticalAlign: 'middle',
    margin: 0,
    paddingLeft: spacing.xsmall,
    paddingRight: spacing.xsmall,
    border: 'none',
    borderLeft: border.thinGray,
    outline: 'none',
    borderRadius: '0 0.1875rem 0.1875rem 0',
    backgroundColor: color.core.grayLighter,
    transition: 'background 300ms ease 0s, border 300ms ease 0s, color 300ms ease 0s',

    ':hover': {
      backgroundColor: color.core.grayTint,
    },
  },

  dropdownInputButton_withBorder: {
    border: border.thinGray,
    borderLeft: 'none',
    height: '2.5rem',
  },

  dropdownInputButton_withBorder_small: {
    height: '2rem',
  },

  dropdownInputButton_disabled: {
    cursor: 'auto',
    ':hover': {
      backgroundColor: color.core.grayLighter,
    },
  },

  dropdownInputButton_error: {
    borderLeft: border.thinRed,
  },

  dropdownInputButton_focused: {
    border: border.thinBlue,
    borderLeft: 'none',
  },

  dropdownInputButton_small: {
    minHeight: `calc(${inputs.sizing.small} - ${borderWidth.thin} - ${borderWidth.thin})`,
    width: inputs.sizing.small,
    padding: spacing.none,
  },

  dropdownInputButtonIcon: {
    marginBottom: '-0.125rem',
  },

  filter: {
    borderLeft: 'none',
    backgroundColor: color.core.white,

    ':hover': {
      backgroundColor: color.core.white,
    },
  },

  filter_disabled: {
    backgroundColor: color.core.grayLighter,
    cursor: 'auto',

    ':hover': {
      backgroundColor: color.core.grayLighter,
    },
  },
});

type PostalCodeSearchInputProps = FieldProps<string, InputProps> & {
  onValidate: () => void;
  onClick: () => void;
};

const PostalCodeSearchInput = (props: PostalCodeSearchInputProps) => {
  const { css, styles } = useStyles({ stylesFn });
  const { onValidate, onClick, ...restProps } = props;

  return (
    <FormInput
      maxLength={7}
      {...restProps}
      suffix={
        <button
          data-testid="pcis-button"
          onFocus={() => onValidate()}
          onClick={() => onClick()}
          {...css(styles.dropdownInputButton, styles.visual, styles.dropdownInputButton_withBorder)}
        >
          <div {...css(styles.dropdownInputButtonIcon)}>
            <Icon flex={true} name="search" size="16" color="blue" />
          </div>
        </button>
      }
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          onValidate();
          onClick();
        }
      }}
    />
  );
};

export default PostalCodeSearchInput;
