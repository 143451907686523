import { Spacer } from 'alloy-foundation';
import React, { memo } from 'react';
import DashboardTiles from './dashboardTiles/DashboardTiles';
import ToDoListDashboardPanel from './dashboardPanels/todoList/ToDoListDashboardPanel';
import ToDoListWorkgroupDashboardPanel from './dashboardPanels/todoListWorkgroup/ToDoListWorkgroupDashboardPanel';
import ExpiringPoliciesDashboardPanel from './dashboardPanels/expiringPolicies/ExpiringPoliciesDashboardPanel';
import { useApplicationState } from '../common/providers/ApplicationStateProvider';
import { DashboardSettingItem } from '../../models/ApplicationState/DashboardSettingItem';
import {
  EXPIRING_POLICIES_ITEM_ID,
  initialItemList,
  TODO_LIST_ITEM_ID,
  TODO_LIST_WG_ITEM_ID,
} from '../topnav/TopNavMyAccountSection';

interface Props {
  dashboardSettings: DashboardSettingItem[];
}

const MyWorkDashboard = () => {
  const { dashboardSettings } = useApplicationState();
  return <InnerMyWorkDashboard dashboardSettings={dashboardSettings} />;
};

/** InnerMyWorkDashboard is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerMyWorkDashboard = memo(({ dashboardSettings }: Props) => {
  const dashboards = (dashboardSettings ?? initialItemList).filter((p) => !p.locked);

  const mapItemToDashboard = (id: string) => {
    switch (id) {
      case TODO_LIST_ITEM_ID:
        return <ToDoListDashboardPanel />;
      case TODO_LIST_WG_ITEM_ID:
        return <ToDoListWorkgroupDashboardPanel />;
      case EXPIRING_POLICIES_ITEM_ID:
        return <ExpiringPoliciesDashboardPanel />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <DashboardTiles dashboards={dashboards} />

      {dashboards
        .filter((item) => !item.disabled)
        .map((item) => (
          <Spacer key={item.id} paddingTop="large">
            {mapItemToDashboard(item.id)}
          </Spacer>
        ))}
    </>
  );
});

export default MyWorkDashboard;
