import React from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import stylesFn from './styles';

interface Props {
  children?: React.ReactNode;
}

const DrawerHeader = ({ children }: Props) => {
  const { css, styles } = useStyles({ stylesFn });

  return (
    <div {...css(styles.header)} data-testid="drawer-header">
      {children}
    </div>
  );
};

export default DrawerHeader;
