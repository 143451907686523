import { useState } from 'react';
import {
  SectionContainer,
  Button,
  TableFilterArea,
  Container,
  Row,
  Column,
  DropdownFilterSelect,
  Flex,
  SearchField,
  Tooltip,
  Typography,
  Icon,
} from 'alloy-foundation';
import { noop } from 'lodash';
import type { OptionType } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { useDataTable, useDataTableRows } from '../../../../data-table/useDataTable';
import LocalizedDataTable from '../../../../common/localizedDataTable/LocalizedDataTable';
import stylesFn from './styles';

export const SummaryPanel = ({
  title,
  id = '',
  columns,
  data,
  isLoading = false,
  sorting = true,
  filtering = false,
  filterPlaceholder = '',
  filterOptions = [],
  initialFilterOption = { value: '', label: '' },
  onFilterChange = noop,
  emptyMessage,
  initialSortedColumnKey,
  initialSortDirection,
  rowSelection = true,
  responsive = true,
  buttonText = 'default',
  buttonOnClick,
  searchField = false,
  toolTipText,
}) => {
  const tableState = useDataTable({
    columns,
    data: data || [],
    filtering: false,
    rowSelection,
    sorting,
    initialSortedColumnKey,
    initialSortDirection,
    initialPageSize: 5,
  });
  const [filterValue, onFilter] = useState('');
  tableState.filterValue = filterValue;
  const tableRows = useDataTableRows(tableState);
  const resetToFirstPage = () => tableState.onPageChange?.(0);
  const handleFilterChange = (filter: OptionType<string>) => {
    onFilterChange(filter);
    resetToFirstPage();
  };
  const { formatMessage } = useIntl();
  const { css, styles } = useStyles({ stylesFn });
  return (
    <div {...css(styles.tooltip)}>
      <SectionContainer
        headerContent={
          <Tooltip content={<Typography variant="body">{toolTipText}</Typography>}>
            <Icon testId="tooltip-icon" size={19} tabIndex={0} name="info" color="blue" />
          </Tooltip>
        }
        headerText={title}
        id={id}
      >
        {(filtering || searchField) && (
          <TableFilterArea>
            <Container fluid={true} noPadding={true}>
              <Row>
                {filtering && (
                  <Column md={3}>
                    <DropdownFilterSelect
                      renderErrorMessage={false}
                      label=""
                      options={filterOptions}
                      placeholder={filterPlaceholder}
                      onChange={handleFilterChange}
                      initialSelectedItem={initialFilterOption}
                    />
                  </Column>
                )}
                {searchField && (
                  <Column sm={4}>
                    <SearchField
                      buttonAriaLabel="search-field"
                      id="alloy-search-field"
                      placeholder={formatMessage({ id: 'common.search.placeholder' })}
                      value={filterValue}
                      onChange={(v) => {
                        onFilter(v);
                        tableState.onPageChange?.(0);
                      }}
                      inputDebouncingMs={500}
                    />
                  </Column>
                )}

                <Column>
                  <Flex flexDirection="row-reverse">
                    <Button variant="primary" onClick={buttonOnClick} data-testid="new-button">
                      {buttonText}
                    </Button>{' '}
                  </Flex>
                </Column>
              </Row>
            </Container>
          </TableFilterArea>
        )}
        <LocalizedDataTable
          {...tableState}
          {...tableRows}
          emptyMessage={emptyMessage}
          isLoading={isLoading}
          pageSizeOptions={[5, 10, 25]}
          responsive={responsive}
        />
      </SectionContainer>
    </div>
  );
};
