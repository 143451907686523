import { Flex, LoadingIndicator, Spacer, Typography } from 'alloy-foundation';
import React, { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { FilterContext } from '../../data-table/FilterContext';
import DrawerContent from '../DrawerContent';
import DrawerHeader from '../DrawerHeader';
import stylesFn from './styles';
import { useWorkQueue } from './useWorkQueue';
import WorkQueueContent from './WorkQueueContent';
import WorkQueueHeader from './WorkQueueHeader';
import { DateFilterSettimgs } from './DateFilter';
import { useApplicationState } from '../../common/providers/ApplicationStateProvider';

export interface Props {
  setProcessId: React.Dispatch<React.SetStateAction<string>>;
}

interface InnerProps {
  setProcessId: React.Dispatch<React.SetStateAction<string>>;
  workQueueFilterSettings: DateFilterSettimgs;
  setWorkQueueFilterSettings: (p: DateFilterSettimgs) => void;
}

const WorkQueueView = ({ setProcessId }: Props) => {
  const { workQueueFilterSettings, setWorkQueueFilterSettings } = useApplicationState();
  return (
    <InnerWorkQueueView
      setProcessId={setProcessId}
      workQueueFilterSettings={workQueueFilterSettings}
      setWorkQueueFilterSettings={setWorkQueueFilterSettings}
    />
  );
};

/** InnerWorkQueueView is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerWorkQueueView = memo(
  ({ setProcessId, workQueueFilterSettings, setWorkQueueFilterSettings }: InnerProps) => {
    const { css, styles } = useStyles({ stylesFn });
    const workQueue = useWorkQueue();
    const [filterValue, onFilter] = useState('');

    return (
      <>
        <DrawerHeader>
          <WorkQueueHeader
            filterValue={filterValue}
            onFilter={onFilter}
            dateFilter={workQueueFilterSettings}
            setDateFilter={setWorkQueueFilterSettings}
          />
        </DrawerHeader>
        <DrawerContent>
          <FilterContext.Provider value={filterValue}>
            <div {...css(styles.container)}>
              {workQueue.isLoading || workQueue.isError || workQueue.data?.length === 0 ? (
                <Spacer pt="small">
                  <Flex justifyContent="center">
                    {workQueue.isLoading && <LoadingIndicator />}
                    {workQueue.isError && (
                      <Typography color="white" variant="disclaimer">
                        <FormattedMessage id="common.error_loading_data" />
                      </Typography>
                    )}
                    {workQueue.data?.length === 0 && (
                      <Typography color="white" variant="disclaimer">
                        <FormattedMessage id="drawer.workQueue.empty" />
                      </Typography>
                    )}
                  </Flex>
                </Spacer>
              ) : (
                <>
                  <WorkQueueContent
                    data={workQueue.data}
                    filterValue={filterValue}
                    dateFilter={workQueueFilterSettings}
                    onItemClick={setProcessId}
                  />
                </>
              )}
            </div>
          </FilterContext.Provider>
        </DrawerContent>
      </>
    );
  }
);

export default WorkQueueView;
