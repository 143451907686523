/* eslint-disable @typescript-eslint/no-empty-interface */

export enum ChecklistItemCompletionStatus {
  NotCompleted,
  Completed,
  NotApplicable, // N/A.  This is different than Not Available, which is represented by a bool
}

export interface Definition {
  Name?: any;
  ShortTitle: string;
  Title: string;
  Description: string;
}

export interface Assignee {
  Type: number;
  Id: string;
  DisplayName: string;
}

export interface MetaAttribute {
  inKey: string;
  outKey: string;
}

export interface Attribute {
  inKey: string;
  outKey: string;
}

export interface RequiredBo {
  Index: string;
  MetaAttributes: MetaAttribute[];
  Attributes: Attribute[];
}

export interface AdditionalAttribs {
  Action: string;
  Mode: string;
  TranType: string;
}

export interface Attribute2 {
  inKey: string;
  outKey: string;
}

export interface OutputBo {
  Index: string;
  Id: string;
  Description: string;
  Type: string;
  LevelOfDetail: string;
  Attributes: Attribute2[];
}

export interface WinformInit {
  requiredBos: RequiredBo[];
  outputValues: any[];
  additionalAttribs: AdditionalAttribs;
  formName: string;
  outputBos: OutputBo[];
}

export interface IntegrationDefinition {
  winformInit: WinformInit;
}

export interface Definition2 {
  Name: string;
  ShortTitle: string;
  Title: string;
  Description: string;
  SequenceNumber: number;
  Url?: any;
  UrlType?: any;
  IntegrationDefinition: IntegrationDefinition;
  IntegrationViewClass: string;
  IsResetAllowed: boolean;
  IsNotApplicableAllowed: boolean;
  IsExcludable: boolean;
  IsAutoLaunched: boolean;
  IsAutoCompleted: boolean;
  IsAutoAdvanced: boolean;
}

export interface AttributeFields {
  PolicyNumber: string;
  ExpirationDate: string;
  EndEffDate: string;
  FormattedEndEffDate: string;
  TypeOfBusiness: string;
  LineOfBusiness: string;
}

export interface StructuredAttributes {}

export interface PolicyNumber {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface ExpirationDate {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface EndEffDate {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface TypeOfBusiness {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface LineOfBusiness {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface Attributes {
  PolicyNumber: PolicyNumber;
  ExpirationDate: ExpirationDate;
  EndEffDate: EndEffDate;
  TypeOfBusiness: TypeOfBusiness;
  LineOfBusiness: LineOfBusiness;
}

export interface BusinessObject {
  IntegrationKey?: any;
  Index: string;
  IsErrored: boolean;
  AttributeFields: AttributeFields;
  StructuredAttributes: StructuredAttributes;
  Children?: any;
  SecurityInformation?: any;
  Source: string;
  Type: number;
  Id: string;
  UserFriendlyId: string;
  Description: string;
  LevelOfDetail: number;
  Attributes: Attributes;
}

export interface Task {
  Definition: Definition2;
  Id: string;
  CreatedDate: Date;
  DueDate?: Date;
  FormattedDueDate?: string;
  CompletedDate?: any;
  FormattedCompletedDate?: string;
  CreatedDateLong: number;
  CompletedDateLong?: any;
  DueDateLong?: number;
  Status: ChecklistItemCompletionStatus;
  IsAvailable: boolean;
  BusinessObjects: BusinessObject[];
}

export interface MetaAttribute2 {
  inKey: string;
  outKey: string;
}

export interface Attribute3 {
  inKey: string;
  outKey: string;
}

export interface RequiredBo2 {
  Index: string;
  MetaAttributes: MetaAttribute2[];
  Attributes: Attribute3[];
}

export interface AdditionalAttribs2 {
  Action: string;
}

export interface WinformInit2 {
  requiredBos: RequiredBo2[];
  outputValues: any[];
  additionalAttribs: AdditionalAttribs2;
  formName: string;
}

export interface IntegrationDefinition2 {
  winformInit: WinformInit2;
}

export interface Definition3 {
  Name: string;
  ShortTitle: string;
  Title: string;
  Description: string;
  SequenceNumber: number;
  Url?: any;
  UrlType?: any;
  IntegrationDefinition: IntegrationDefinition2;
  IntegrationViewClass: string;
  IsResetAllowed: boolean;
  IsNotApplicableAllowed: boolean;
  IsExcludable: boolean;
  IsAutoLaunched: boolean;
  IsAutoCompleted: boolean;
  IsAutoAdvanced: boolean;
}

export interface AttributeFields2 {
  PolicyNumber: string;
  ExpirationDate: string;
  EndEffDate: string;
  FormattedEndEffDate: string;
  TypeOfBusiness: string;
  LineOfBusiness: string;
}

export interface StructuredAttributes2 {}

export interface PolicyNumber2 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface ExpirationDate2 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface EndEffDate2 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface TypeOfBusiness2 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface LineOfBusiness2 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface Attributes2 {
  PolicyNumber: PolicyNumber2;
  ExpirationDate: ExpirationDate2;
  EndEffDate: EndEffDate2;
  TypeOfBusiness: TypeOfBusiness2;
  LineOfBusiness: LineOfBusiness2;
}

export interface BusinessObject2 {
  IntegrationKey?: any;
  Index: string;
  IsErrored: boolean;
  AttributeFields: AttributeFields2;
  StructuredAttributes: StructuredAttributes2;
  Children?: any;
  SecurityInformation?: any;
  Source: string;
  Type: number;
  Id: string;
  UserFriendlyId: string;
  Description: string;
  LevelOfDetail: number;
  Attributes: Attributes2;
}

export interface CurrentTask {
  Definition: Definition3;
  Id: string;
  CreatedDate: Date;
  DueDate: Date;
  CompletedDate?: any;
  CreatedDateLong: number;
  CompletedDateLong?: any;
  DueDateLong: number;
  Status: ChecklistItemCompletionStatus;
  IsAvailable: boolean;
  BusinessObjects: BusinessObject2[];
}

export interface Value {
  Name: string;
  Description: string;
  Type: number;
  Value: string;
  IsExtra: boolean;
}

export interface KeyInformation {
  Key: string;
  Value: Value;
}

export interface AttributeFields3 {
  PolicyNumber: string;
  ExpirationDate: string;
  EndEffDate: string;
  FormattedEndEffDate: string;
  TypeOfBusiness: string;
  LineOfBusiness: string;
}

export interface StructuredAttributes3 {}

export interface PolicyNumber3 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface ExpirationDate3 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface EndEffDate3 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface TypeOfBusiness3 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface LineOfBusiness3 {
  Name: string;
  DisplayName?: any;
  Type: number;
  Value: string;
  CanEdit: boolean;
  HasError: boolean;
}

export interface Attributes3 {
  PolicyNumber: PolicyNumber3;
  ExpirationDate: ExpirationDate3;
  EndEffDate: EndEffDate3;
  TypeOfBusiness: TypeOfBusiness3;
  LineOfBusiness: LineOfBusiness3;
}

export interface BusinessObject3 {
  Index: string;
  IsErrored: boolean;
  AttributeFields: AttributeFields3;
  StructuredAttributes: StructuredAttributes3;
  Children?: any;
  SecurityInformation?: any;
  Source: string;
  Type: number;
  Id: string;
  UserFriendlyId: string;
  Description: string;
  LevelOfDetail: number;
  Attributes: Attributes3;
}

export interface Values {
  PolicyId: string;
}

export interface Process {
  Definition: Definition;
  Id: string;
  Assignee: Assignee;
  Priority?: number;
  CreatedDate: Date;
  CreatedDateLong?: number;
  FormattedCreatedDate: string;
  DueDate: Date;
  DueDateLong?: number;
  FormattedDueDate: string;
  Tasks?: Task[];
  CurrentTask?: CurrentTask;
  KeyInformation: KeyInformation[];
  BusinessObjects?: BusinessObject3[];
  Values?: Values;
}
