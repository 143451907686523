import Cookies from 'js-cookie';

// private functions
const getCookiesNamesByValue = (cookieValue: string) => {
  return Object.entries(Cookies.get()).reduce((result: string[], [key, value]) => {
    if (value === cookieValue) {
      result.push(key);
    }
    return result;
  }, []);
};

// CookiesService exports
const CookiesService = {
  list: (): any => Cookies.get(),
  get: (name: string) => Cookies.get(name),
  create: (name, value) => Cookies.set(name, value),
  getCookiesNamesByValue: (cookieValue: string) => getCookiesNamesByValue(cookieValue),
};

export default CookiesService;
