import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { PolicyList } from '../../models/PolicySummary';

const PolicyService = {
  list: (id: string): Promise<PolicyList> =>
    baseSmartflowRequests
      .get(`/object/ams360/client/${id}/children/policy?filter=all&json=true`)
      .then((response) => response.Children),
};

export default PolicyService;
