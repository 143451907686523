import { useQuery } from 'react-query';
import ManagerService from '../../../services/manager/ManagerService';

export const useAssignedEmpData = (vimId, empCode) => {
  const empList = useQuery(`assigned-employees-${vimId}`, () =>
    ManagerService.listAssignedEmp(vimId, empCode)
  );

  return empList;
};
