import React from 'react';
import DashboardPageLayout from '../common/page-layout/DashboardPageLayout';
import { DashboardView, useDashboardView } from './DashboardViewProvider';
import MyWorkDashboard from './MyWorkDashboard';
import ManagerDashboard from './managerDashboards/ManagerDashboard';

const Dashboard = () => {
  const { dashboardView } = useDashboardView();

  return (
    <DashboardPageLayout>
      {dashboardView === DashboardView.MyWork ? <MyWorkDashboard /> : <ManagerDashboard />}
    </DashboardPageLayout>
  );
};

export default Dashboard;
