import { useQuery, UseQueryResult, UseQueryOptions, QueryKey } from 'react-query';
import { ActivityList } from '../../../../models/ActivitySummary';
import ActivityListService from '../../../../services/activityList/ActivityListService';

export const useActivityData = (
  id: string,
  option?: UseQueryOptions<ActivityList, unknown, ActivityList, QueryKey>
): UseQueryResult<ActivityList, any> => {
  const toDoList = useQuery<ActivityList>(
    ['activity', id],
    () => ActivityListService.list(id),
    option
  );

  return toDoList;
};
