import React from 'react';
import { Column, HashLink, Metric, MetricCard, Row, Spacer } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import TodoListService from '../../../services/todoList/TodoListService';
import TodoListWorkgroupsService from '../../../services/todoListWorkgroup/TodoListWorkgroupService';
import WorkQueueService from '../../../services/workqueue/WorkQueueService';
import ExpiringPoliciesService from '../../../services/expiringPolicies/ExpiringPoliciesService';
import { formatMetricCardValue } from '../../common/utils';
import { TodoListPayload, ExpiringPoliciesPayload } from './DashboardTilesRequestPayload';
import { DashboardSettingItem } from '../../../models/ApplicationState/DashboardSettingItem';
import {
  EXPIRING_POLICIES_ITEM_ID,
  TODO_LIST_ITEM_ID,
  TODO_LIST_WG_ITEM_ID,
} from '../../topnav/TopNavMyAccountSection';

export const GET_TODO_LIST_DUE_TODAY_COUNT = 'GetTodoListDueTodayCount';
export const GET_TODO_LIST_WG_DUE_TODAY_COUNT = 'GetTodoListWorkgroupsDueTodayCount';

interface Props {
  dashboards: DashboardSettingItem[];
}

const DashboardTiles = ({ dashboards }: Props) => {
  const { formatMessage } = useIntl();

  const { data: TodoListDueTodayCount } = useQuery(GET_TODO_LIST_DUE_TODAY_COUNT, async () => {
    return await TodoListService.GetDueTodayCount(TodoListPayload);
  });

  const { data: TodoListWorkgroupsDueTodayCount } = useQuery(
    GET_TODO_LIST_WG_DUE_TODAY_COUNT,
    async () => {
      return await TodoListWorkgroupsService.GetDueTodayCount(TodoListPayload);
    }
  );

  const { data: WorkQueueIncompleteCount } = useQuery('GetWorkQueueIncompleteCount', async () => {
    return await WorkQueueService.GetIncompleteCount();
  });

  const { data: ExpiringPoliciesDueTodayCount } = useQuery(
    'GetExpiringPoliciesDueTodayCount',
    async () => {
      return await ExpiringPoliciesService.GetDueTodayCount(ExpiringPoliciesPayload);
    }
  );

  const isDashboardVisible = (id: string) => dashboards.find((p) => p.id === id && !p.disabled);

  return (
    <Row noGutters={true}>
      <Column>
        <div data-testid="todo_list_tile">
          <MetricCard>
            <Metric
              context={formatMessage({ id: 'common.due_today' })}
              value={formatMetricCardValue(TodoListDueTodayCount)}
              unit={formatMessage({ id: 'dashboard.tiles.todo_list_title' })}
              {...(isDashboardVisible(TODO_LIST_ITEM_ID) && {
                to: '#ToDoList',
                as: HashLink as any,
              })}
            />
          </MetricCard>
        </div>
      </Column>
      <Column>
        <Spacer pl="small">
          <div data-testid="suspense_workgroups_tile">
            <MetricCard>
              <Metric
                context={formatMessage({ id: 'common.due_today' })}
                value={formatMetricCardValue(TodoListWorkgroupsDueTodayCount)}
                unit={formatMessage({
                  id: 'dashboard.tiles.suspense_workgroups_title',
                })}
                {...(isDashboardVisible(TODO_LIST_WG_ITEM_ID) && {
                  to: '#ToDoListWorkgroup',
                  as: HashLink as any,
                })}
              />
            </MetricCard>
          </div>
        </Spacer>
      </Column>
      <Column>
        <Spacer pl="small">
          <div data-testid="WorkQueue_tile">
            <MetricCard>
              <Metric
                context={formatMessage({ id: 'common.incomplete' })}
                value={formatMetricCardValue(WorkQueueIncompleteCount)}
                unit={formatMessage({ id: 'dashboard.tiles.WorkQueue_title' })}
              />
            </MetricCard>
          </div>
        </Spacer>
      </Column>
      <Column>
        <Spacer pl="small">
          <div data-testid="expiring_policies_tile">
            <MetricCard>
              <Metric
                context={formatMessage({ id: 'common.date_range_120' })}
                value={formatMetricCardValue(ExpiringPoliciesDueTodayCount)}
                unit={formatMessage({
                  id: 'dashboard.tiles.expiring_policies_title',
                })}
                {...(isDashboardVisible(EXPIRING_POLICIES_ITEM_ID) && {
                  to: '#ExpiringPoliciesList',
                  as: HashLink as any,
                })}
              />
            </MetricCard>
          </div>
        </Spacer>
      </Column>
    </Row>
  );
};

export default DashboardTiles;
