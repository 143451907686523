import { Modal } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { ActivityReduced } from '../../../models/ActivitySummary';
import { PssCustomer } from '../../../models/PssCustomer';
import LogFormViewModal from './LogFormViewModal';

type Props = {
  onCancel: () => void;
  onChangeLogView: () => void;
  activity: ActivityReduced;
  customer: PssCustomer;
  logFormType: string;
  setLogFormViewVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogClose: () => void;
};

const LogFormView = ({
  onCancel,
  onChangeLogView,
  activity,
  customer,
  logFormType,
  setLogFormViewVisible,
}: Props) => {
  const { formatMessage } = useIntl();

  const handleClose = () => {
    setLogFormViewVisible(false);
  };

  return (
    <Modal
      onClose={() => handleClose()}
      scrollable
      large
      title={formatMessage({ id: 'logformview.title' })}
    >
      <LogFormViewModal
        onCancel={() => setLogFormViewVisible(false)}
        onChangeLogView={() => {
          setLogFormViewVisible(false);
        }}
        activity={activity}
        customer={customer}
        logFormType={logFormType}
        setLogFormViewVisible={setLogFormViewVisible}
        handleLogClose={handleClose}
      ></LogFormViewModal>
    </Modal>
  );
};
export default LogFormView;
