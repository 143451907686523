import { Column, FormInput, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import BaseCustomerDetails from './BaseCustomerDetails';
import { FIRM_NAME, FIRM_DBA } from '../../fieldNames';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';

type CommercialCustomerDetailsProps = {
  parentNamespace?: string;
  dropdowns: CustomerDropdowns;
};
const CommercialCustomerDetails = (props: CommercialCustomerDetailsProps) => {
  const { formatMessage } = useIntl();
  const prefix = props.parentNamespace ? `${props.parentNamespace}.` : '';
  return (
    <React.Fragment>
      <Spacer mr="xlarge" mb="tiny">
        <BaseCustomerDetails parentNamespace={props.parentNamespace} dropdowns={props.dropdowns} />
        <Row data-testid="commercial">
          <Column md={6}>
            <FormInput
              name={prefix + FIRM_NAME}
              maxLength={120}
              label={formatMessage({ id: 'customer.details.commercial.firmname.label' })}
              required={true}
              data-testid="CustomerFirmName"
            />
          </Column>
          <Column md={6}>
            <FormInput
              name={prefix + FIRM_DBA}
              maxLength={60}
              label={formatMessage({ id: 'customer.details.commercial.firmdba.label' })}
              required={false}
              data-testid="CustomerDBA"
            />
          </Column>
        </Row>
      </Spacer>
    </React.Fragment>
  );
};

export default CommercialCustomerDetails;
