import basePSSRequests from '../../api/basePSSRequests';
import { CustomerDropdowns } from '../../models/CustomerDropdowns';

// base url
const baseURL = '/clients/getdropdowns';

// exports
const CustomerDropdownsServices = {
  getDropdowns: (): Promise<CustomerDropdowns> =>
    basePSSRequests.get(baseURL).then((response) => response),
};

export default CustomerDropdownsServices;
