import React from 'react';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { BusinessUnitForm } from './businessUnit/BusinessUnitForm';
import { TypeOfBusiness } from './typeOfBusiness/TypeOfBusiness';
import { PrivacyAct } from './privacyAct/PrivacyAct';
import CustomerClasses from './customerClasses/CustomerClasses';
import Divider from '../../../common/divider/Divider';
import BrokeragePersonnel from './brokeragePersonnel/BrokeragePersonnel';

const BusinessInformation: React.FC<{ dropdowns: CustomerDropdowns }> = ({ dropdowns }) => {
  return (
    <>
      <TypeOfBusiness dropdowns={dropdowns} />
      <Divider pt="none" pb="none" ml="none" mr="none" />
      <PrivacyAct />
      <Divider pt="none" pb="none" ml="none" mr="none" />
      <CustomerClasses dropdowns={dropdowns} />
      <Divider pt="none" pb="none" ml="none" mr="none" />
      <BusinessUnitForm dropdowns={dropdowns} />
      <Divider pt="none" pb="none" ml="none" mr="none" />
      <BrokeragePersonnel dropdowns={dropdowns} />
      <Divider pt="none" pb="none" ml="none" mr="none" />
    </>
  );
};

export default BusinessInformation;
