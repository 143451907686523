import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { BusinessProcessTemplateRestModel } from '../../models/smartflow/BusinessProcessTemplateRestModel';
import { PublishedProcessRestModel } from '../../models/smartflow/PublishedProcessRestModel';

const processUrl = '/definition/process/';
const instanceUrl = '/definition/instances';

// Please keep following the standard set by getIsCustomerAdmin.
export const getQuickNavTemplate = (): Promise<BusinessProcessTemplateRestModel[]> =>
  baseSmartflowRequests.get(processUrl).then((response) => response);

// Please keep following the standard set by getIsCustomerAdmin.
export const updateQuickNavTemplateStatus = (id: number, status: boolean): Promise<any> =>
  baseSmartflowRequests
    .put(`${processUrl}${id}`, status, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => response);

// Please keep following the standard set by getIsCustomerAdmin.
export const getPublishedProcesses = async (): Promise<PublishedProcessRestModel[]> => {
  const query = await baseSmartflowRequests
    .get(`${instanceUrl}/published`)
    .then((response) => response as PublishedProcessRestModel[]);

  return query;
};
