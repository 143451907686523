import * as Yup from 'yup';
import * as fieldNames from './fieldNames';

export const StepValidationSchema = (formatMessage) =>
  Yup.object().shape({
    [`${fieldNames.TITLE}`]: Yup.string()
      .trim()
      .required(formatMessage({ id: 'quickNav.configuration.instances.edit.step.error' }))
      .min(10, formatMessage({ id: 'quickNav.configuration.instances.edit.step.error' }))
      .max(160, formatMessage({ id: 'quickNav.configuration.instances.edit.step.max.error' })),
    [`${fieldNames.URL}`]: Yup.string().nullable(),
    [`${fieldNames.EXCLUDED}`]: Yup.boolean().required(),
    [`${fieldNames.EXCLUDABLE}`]: Yup.boolean().required(),
    [`${fieldNames.TYPE}`]: Yup.string().required(),
    [`${fieldNames.DAYS}`]: Yup.number()
      .typeError(formatMessage({ id: 'quickNav.configuration.instances.edit.days.error' }))
      .required(formatMessage({ id: 'quickNav.configuration.instances.edit.days.error' }))
      .min(1, formatMessage({ id: 'quickNav.configuration.instances.edit.days.error' })),
  });
