import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Typography,
  Group,
  LoadingIndicator,
  VertaFormik,
  useAppContext,
  SectionContainer,
  LayoutShell,
  ScrollWrapper,
  ScrollSection,
  Tooltip,
  Icon,
  ClientMenuContainer,
} from 'alloy-foundation';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { useQueryClient } from 'react-query';
import SummaryPageLayout from '../../common/page-layout/DefaultPageLayout';
import stylesFn from './styles';
import { useCustomer, useUpdateCustomer } from '../../common/hooks/useCustomer';
import Header from './header/header';
import { useRecentCustomerUpdate } from '../../common/hooks/useRecentCustomerUpdate';
import { RECENT_CUSTOMERS } from '../../drawer/recentCustomers';
import ActivityListTable from './activityList/ActivityListTable';
import WorkInProgressGrid from './workInProgress/WorkInProgressGrid';
import { EPermissions, HasPermissions, Permissions } from '../../../models/enums/EPermissions';
import { useCustomerDropdowns } from '../../common/hooks/useCustomerDropdown';
import CustomerSummaryForm from './CustomerSummaryForm';
import { ContactMethod } from '../../../models/ContactMethod';
import { Emails } from '../../../models/Emails';
import { PhoneNumber } from '../../../models/PhoneNumber';
import { useEditProvider } from '../../common/providers/EditProvider';
import { customerSummaryValidationSchema } from '../customerValidation/customerSummaryValidationSchema';
import PoliciesGrid from './policies/PoliciesGrid';
import { useFormatting } from '../../common/hooks/useFormatting';
import CustomerLockService from '../../../services/customerLock/CustomerLockService';
import { useLockProvider } from '../../common/providers/LockProvider';
import CustomerSummarySideMenu from './CustomerSummarySideMenu';
import { CUSTOMER_SUMMARY_SCROLL_SECTIONS } from './CustomerSummaryScrollSections';
import { useBottomActionBarState } from '../../common/application-layout/BottomActionBarProvider';
import { TopNavHeight } from '../../topnav/TopNavConstants';
import { LocalizedLeaveWithoutSaveGuard } from '../../common/localizedLeaveWithoutSaveGuard/LocalizedLeaveWithoutSaveGuard';

type SummaryParams = {
  id: string;
};

const CustomerSummary = () => {
  const { id } = useParams<SummaryParams>();
  const { formatMessage } = useIntl();
  const { css, styles } = useStyles({ stylesFn });
  const queryClient = useQueryClient();
  const [canEdit, updateCanEdit] = useState<boolean>(false);
  const [canEditBusinessInfo, updateCanEditBusinessInfo] = useState<boolean>(false);
  const { addErrorToast, addSuccessToast } = useAppContext();
  const history = useHistory();
  const { formatDate } = useFormatting();
  const { editMode, setEditMode } = useEditProvider();
  const { setCurrentLock } = useLockProvider();
  const [activeSection, setActiveSection] = useState(CUSTOMER_SUMMARY_SCROLL_SECTIONS.SUMMARY);
  const { bottomActionBar } = useBottomActionBarState();
  const setEditCustomer = (customer) => {
    updateCanEdit(
      customer &&
        customer.Permissions &&
        HasPermissions(customer.Permissions, Permissions.CanEditCustomer, EPermissions.EMODIFY)
    );
  };
  const setEditBusinessInfo = (customer) => {
    updateCanEditBusinessInfo(
      customer &&
        customer.Permissions &&
        (HasPermissions(customer.Permissions, Permissions.CanEditDept, EPermissions.EMODIFY) ||
          HasPermissions(customer.Permissions, Permissions.CanEditDiv, EPermissions.EMODIFY) ||
          HasPermissions(customer.Permissions, Permissions.CanEditRep, EPermissions.EMODIFY) ||
          HasPermissions(customer.Permissions, Permissions.CanEditExec, EPermissions.EMODIFY))
    );
  };

  const customer = useCustomer(id, {
    onSuccess: (data) => {
      setEditCustomer(data);
      setEditBusinessInfo(data);
    },
  });

  const customerDropdown = useCustomerDropdowns();

  const shouldBlockNavigation = useCallback(
    (nextLocation) => {
      // eslint-disable-next-line
      if (location.pathname === nextLocation.pathname) {
        return false;
      }
      return editMode;
    },
    [editMode]
  );

  const navigate = useCallback((path) => history.push(path), [history]);

  const updateCustomerMutation = useUpdateCustomer(id, customerDropdown.data, {
    onError: (error, variables, context) => {
      // An error happened!
      addErrorToast(<FormattedMessage id="customer.createError" />, {
        title: <FormattedMessage id="error.toasts.header" />,
        withBottomActionBar: true,
      });
      setEditCustomer(customer.data);
      setEditBusinessInfo(customer.data);
    },
    onSuccess: (data, variables, context) => {
      addSuccessToast(<FormattedMessage id="customer.saveSuccess" />, {
        title: <FormattedMessage id="success.toasts.header" />,
        withBottomActionBar: true,
      });

      queryClient.setQueryData(id, data);
      setEditCustomer(data);
      setEditBusinessInfo(data);
    },
  });
  const onConfirmNavigationClick = () => {
    setEditMode(false);
    deleteLock(id);
  };
  const deleteLock = async (custId) => {
    return await CustomerLockService.delete(custId).then((res) => {
      setCurrentLock(null);
    });
  };
  const recentCustomersMutation = useRecentCustomerUpdate((result, params) => {
    // invalidate recent customers query to refresh drawer
    queryClient.invalidateQueries(RECENT_CUSTOMERS);
  });

  useEffect(() => {
    // update recent customers
    recentCustomersMutation.mutate(id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const createPhoneNumber = (contactMethod: ContactMethod) => {
    return {
      phoneNumber:
        (contactMethod?.Details ?? '') !== ''
          ? // Removing any dashes as they were causing issues with the save. I would have removed earlier in the pipeline but it might cause issues somewhere else where it was expected.
            contactMethod.AreaCode + contactMethod.Details.replace('-', '')
          : '',
      type: contactMethod?.Type.toString(),
      notApplicable: contactMethod?.IsNA,
      phoneExtension: contactMethod?.Extension,
    } as PhoneNumber;
  };

  const createEmails = (contactMethod: ContactMethod) => {
    return {
      type: contactMethod?.Type.toString(),
      emailAddress: contactMethod?.Details,
      cals: contactMethod?.CASLConsent ? 'Y' : contactMethod?.CASLConsent == null ? '' : 'N',
      calsDateOfConsent: contactMethod?.CASLDateOfConsent
        ? DateTime.fromISO(contactMethod.CASLDateOfConsent).toFormat('MM/dd/yyyy')
        : '',
      notApplicable: contactMethod?.IsNA,
    } as Emails;
  };

  const maritalStatusConverter = (maritalStatus: string) => {
    if (maritalStatus === 'Separated') return 'P';
    else if (maritalStatus === 'Partner, Same-sex') return '1';
    else if (!maritalStatus) return '';
    else return maritalStatus.charAt(0);
  };

  const generateInitialValues = () => {
    if (customer.data) {
      const init = {
        contactInformation: {
          postalCode: customer.data?.ZipCode ?? '',
          city: customer.data?.City ?? '',
          provinceCode: customer.data?.State ?? '',
          addressLine: customer.data?.AddressLine1 ?? '',
          addressLine2: customer.data?.AddressLine2 ?? '',
          phones: [],
          emails: [],
          preferedContactMethod: customer.data?.PreferredContactMethod,
        },
        general: {
          firstName: customer?.data?.FirstName ?? '',
          lastName: customer?.data?.LastName ?? '',
          middleName: customer?.data?.MiddleName ?? '',
          customerType: customer?.data?.CustomerType ?? '',
          subBroker: customer?.data?.BrokerCode ?? '',
          prefix: customer?.data?.PrimaryPrefix ?? '',
          dateOfBirth: formatDate(customer?.data?.DateOfBirth),
          firmName: customer?.data?.FirmName ?? '',
          firmDBA: customer?.data?.DoingBusinessAs ?? '',
          language:
            customer?.data?.Language.toLocaleUpperCase() === 'E'
              ? 'en-CA'
              : customer?.data?.Language.toLocaleUpperCase() === 'F'
              ? 'fr-CA'
              : '',
          maritalStatus: maritalStatusConverter(customer?.data?.MaritalStatus),
          customerClass1: customer?.data?.CustomerClasses['CustomerClass1']?.key ?? '',
          customerClass2: customer?.data?.CustomerClasses['CustomerClass2']?.key ?? '',
          customerClass3: customer?.data?.CustomerClasses['CustomerClass3']?.key ?? '',
          customerClass4: customer?.data?.CustomerClasses['CustomerClass4']?.key ?? '',
          dateOfConsent: formatDate(customer?.data?.ConsentDate),
          personalInformationConsent: String(customer?.data?.PersonalInformationConsent) ?? '',
          consentForOtherUse: String(customer?.data?.ConsentOtherUse) ?? '',
          typeOfBusiness: customer?.data?.IsPersonalCust ? 'P' : 'C',
          secondaryContact: {
            firstName: customer?.data?.SecondaryFirstName ?? '',
            lastName: customer?.data?.SecondaryLastName ?? '',
            middleName: customer?.data?.SecondaryMiddleName ?? '',
            prefix: customer?.data?.SecondaryPrefix ?? '',
            maritalStatus: maritalStatusConverter(customer?.data?.MaritalStatus2),
            dateOfBirth: formatDate(customer?.data?.DateOfBirth2),
          },
        },
        businessInformation: {
          division: customer?.data?.GLDivisionCode,
          department: customer?.data?.GLDepartmentCode,
          accountExecutive1: customer?.data?.IsAccountExecValid
            ? customer?.data?.AccountExecCode
            : '',
          accountExecutive2: customer?.data?.IsAccountExec2Valid
            ? customer?.data?.AccountExec2Code
            : '',
          accountRepresentative1: customer?.data?.IsAccountRepValid
            ? customer?.data.AccountRepCode
            : '',
          accountRepresentative2: customer?.data?.IsAccountRep2Valid
            ? customer?.data?.AccountRep2Code
            : '',
        },
      };

      // if (customer.data?.ContactMethods["ResidentialPhone"]?.Details ?? "" !== "")
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['HomePhone'])
      );
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['BusinessPhone'])
      );
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['CellPhone'])
      );
      init.contactInformation.phones.push(createPhoneNumber(customer.data?.ContactMethods['Fax']));
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['EmergencyPhone'])
      );
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['SeasonalPhone'])
      );
      init.contactInformation.phones.push(
        createPhoneNumber(customer.data?.ContactMethods['TextPhone'])
      );

      init.contactInformation.emails.push(
        createEmails(customer.data?.ContactMethods['PersonalEmail'])
      );
      init.contactInformation.emails.push(createEmails(customer.data?.ContactMethods['WorkEmail']));
      init.contactInformation.emails.push(createEmails(customer.data?.ContactMethods['Website']));
      return init;
    }
    return {};
  };

  return (
    <React.Fragment>
      {
        /* Check both customer and dropdowns. Dropdown is necessary as some displayed value are mapped from the dropdown (customer type, contact methods,...) */
        customer.isError || customerDropdown.isError ? (
          <Typography variant="disclaimer">
            <FormattedMessage id="common.error_loading_data" />
          </Typography>
        ) : (
          <LayoutShell
            disableScrollbarGutter
            bottomBar={bottomActionBar}
            contentMaxHeight={`calc(100vh - ${TopNavHeight})`}
            sideMenu={<CustomerSummarySideMenu activeSection={activeSection} />}
          >
            <ScrollWrapper onChangeActiveScrollSection={setActiveSection}>
              <ClientMenuContainer stickyTop>
                <Header customer={customer} updateCanEdit={updateCanEdit}></Header>
              </ClientMenuContainer>
              <SummaryPageLayout hideContentHeader={true}>
                <Group spacing="large" stacked={true}>
                  <ScrollSection id={CUSTOMER_SUMMARY_SCROLL_SECTIONS.SUMMARY} offset="3.5rem">
                    <div {...css(styles.tooltip)}>
                      <SectionContainer
                        headerContent={
                          <Tooltip
                            content={
                              <Typography variant="body">
                                {formatMessage({ id: 'customer.summary.toolTipText' })}
                              </Typography>
                            }
                          >
                            <Icon
                              testId="tooltip-icon"
                              size={19}
                              tabIndex={0}
                              name="info"
                              color="blue"
                            />
                          </Tooltip>
                        }
                        headerText={formatMessage({ id: 'customer.summary.title' })}
                      >
                        {customer.isLoading || customerDropdown.isLoading ? (
                          <LoadingIndicator size="medium" />
                        ) : (
                          <VertaFormik
                            initialValues={generateInitialValues()}
                            onSubmit={(data, { resetForm }) => {
                              updateCustomerMutation.mutateAsync({
                                original: generateInitialValues(),
                                updated: data,
                                sigPermissions: customer.data.Permissions,
                              });
                              // Disable editing until the form refresh
                              updateCanEdit(false);
                              setEditMode(false);
                              resetForm();
                              deleteLock(id);
                            }}
                            validationSchema={customerSummaryValidationSchema(
                              formatMessage,
                              customerDropdown.data,
                              customer.data
                            )}
                            // Refresh the model when not in edit mode, but once in edit, disable the refresh.
                            enableReinitialize={!editMode}
                          >
                            {(dirty, isSubmitting) => (
                              <>
                                <CustomerSummaryForm
                                  canEdit={canEdit}
                                  canEditBusinessInfo={canEditBusinessInfo}
                                  customer={customer}
                                  dropdowns={customerDropdown}
                                />
                                <LocalizedLeaveWithoutSaveGuard
                                  shouldBlockNavigation={shouldBlockNavigation}
                                  when={editMode && !isSubmitting}
                                  navigate={navigate}
                                  onConfirmNavigationClick={onConfirmNavigationClick}
                                />
                              </>
                            )}
                          </VertaFormik>
                        )}
                      </SectionContainer>
                    </div>
                  </ScrollSection>
                  <ScrollSection id={CUSTOMER_SUMMARY_SCROLL_SECTIONS.POLICIES} offset="3.5rem">
                    <PoliciesGrid customer={customer.data} />
                  </ScrollSection>
                  <ScrollSection
                    id={CUSTOMER_SUMMARY_SCROLL_SECTIONS.WORK_IN_PROGRESS}
                    offset="3.5rem"
                  >
                    <WorkInProgressGrid customer={customer.data} />
                  </ScrollSection>
                  <ScrollSection id={CUSTOMER_SUMMARY_SCROLL_SECTIONS.ACTIVITIES} offset="3.5rem">
                    <ActivityListTable customer={customer.data} />
                  </ScrollSection>
                </Group>
                {/* This div allows us to add extra spacing at the bottom to let the SideMenu/ScrollSection 
                    render correctly when scrolling to the bottom of the page.
                    This is how AgencyOne addresses the issue. */}
                <div style={{ height: '160px' }} />
              </SummaryPageLayout>
            </ScrollWrapper>
          </LayoutShell>
        )
      }
    </React.Fragment>
  );
};

export default CustomerSummary;
