import basePSSRequests from '../../api/basePSSRequests';
import { AddressLookup } from '../../models/AddressLookup';

// base url
const baseURL = '/search/address';

// exports
const AddressLookupService = {
  listAddressMatches: (postalCode: string): Promise<AddressLookup> =>
    basePSSRequests
      .post(baseURL, { Address: { ZipCode: postalCode } })
      .then((response) => response),
};

export default AddressLookupService;
