import { MutationOptions, QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { NameCheckPayload } from '../../../models/putPayloads/NameCheckPayload';
import { Instance, Version } from '../../../models/QuickNavInstance';
import {
  deleteInstance,
  deleteVersion,
  publishDraft,
  unpublishInstance,
  getQuickNavInstances,
  publishVersion,
  getVersionInfo,
  saveDraft,
  editDraft,
  publishNewInstance,
  saveNewDraft,
  checkDuplicatedName,
} from '../../../services/quicknavConfiguration/configurationService';

export interface deleteProps {
  id: string;
  isInstance: boolean;
}
export interface publishProps {
  version: Version;
  isNewInstance: boolean;
}
export interface saveDraftProps {
  draft: Version;
  isNewInstance: boolean;
}
export const useInstance = (
  id,
  option?: UseQueryOptions<Instance[], unknown, Instance[], QueryKey>
) => {
  const instanceList = useQuery<Instance[]>(
    ['quicknav-instance', id],
    () => getQuickNavInstances(id),
    option
  );

  return instanceList;
};
export const useVersion = (id, option?: UseQueryOptions<Version, unknown, Version, QueryKey>) => {
  const versionInfo = useQuery<Version>(['quicknav-version', id], () => getVersionInfo(id), option);

  return versionInfo;
};
export const useUnpublishInstance = (options: MutationOptions<string, unknown, string, any>) => {
  const unpublishMutation = useMutation<string, unknown, string, any>(
    (id: string) => unpublishInstance(id),
    options
  );
  return unpublishMutation;
};

export const useDeleteInstance = (
  options: MutationOptions<deleteProps, unknown, deleteProps, any>
) => {
  const deleteMutation = useMutation<deleteProps, unknown, deleteProps, any>(
    (data: deleteProps) => (data.isInstance ? deleteInstance(data.id) : deleteVersion(data.id)),
    options
  );
  return deleteMutation;
};
export const useCheckDuplicatedName = (
  options: MutationOptions<boolean, unknown, NameCheckPayload, any>
) => {
  const checkMutation = useMutation<boolean, unknown, NameCheckPayload, any>(
    (payload: NameCheckPayload) => checkDuplicatedName(payload),
    options
  );
  return checkMutation;
};
export const useSaveDraft = (
  options: MutationOptions<saveDraftProps, unknown, saveDraftProps, any>
) => {
  const saveDraftMutation = useMutation<saveDraftProps, unknown, saveDraftProps, any>(
    (data: saveDraftProps) =>
      data.isNewInstance
        ? saveNewDraft(data.draft)
        : data.draft.IsDraft
        ? editDraft(data.draft)
        : saveDraft(data.draft),
    options
  );
  return saveDraftMutation;
};

export const usePublishDraft = (options: MutationOptions<Version, unknown, Version, any>) => {
  const publishMutation = useMutation<Version, unknown, Version, any>(
    (draft: Version) => publishDraft(draft),
    options
  );
  return publishMutation;
};

export const usePublishVersion = (
  options: MutationOptions<publishProps, unknown, publishProps, any>
) => {
  const publishMutation = useMutation<publishProps, unknown, publishProps, any>(
    (data: publishProps) =>
      data.isNewInstance ? publishNewInstance(data.version) : publishVersion(data.version),
    options
  );
  return publishMutation;
};
