import { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { LeaveWithoutSaveModal } from 'alloy-foundation';
import { LeaveWithoutSaveGuardProps } from 'alloy-foundation/es/components/LeaveWithoutSaveGuard';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';

type LocalizedLeaveWithouSaveGuardProps = LeaveWithoutSaveGuardProps & {
  /** The event that is called when the confirm navigation button is clicked. I.E. `Leave Without Saving`. */
  onConfirmNavigationClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export function LocalizedLeaveWithoutSaveGuard({
  when,
  navigate,
  shouldBlockNavigation,
  onConfirmNavigationClick = noop,
}: LocalizedLeaveWithouSaveGuardProps) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [hasUserConfirmedNavigation, setUserHasConfirmedNavigation] = useState(false);

  const { formatMessage } = useIntl();

  const phrases = {
    body: formatMessage({ id: 'leaveWithoutSaveGuard.body' }),
    cancelNavigationButtonText: formatMessage({
      id: 'leaveWithoutSaveGuard.cancelNavigationButtonText',
    }),
    confirmNavigationButtonText: formatMessage({
      id: 'leaveWithoutSaveGuard.confirmNavigationButtonText',
    }),
    title: formatMessage({ id: 'leaveWithoutSaveGuard.title' }),
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    // need to reset last location and navigation state for repeated navigation prompts
    setLastLocation(null);
    setUserHasConfirmedNavigation(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!hasUserConfirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setIsModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = (event: MouseEvent<HTMLButtonElement>) => {
    setIsModalVisible(false);
    setUserHasConfirmedNavigation(true);

    // call code that should be executed when navigating away from page
    onConfirmNavigationClick(event);
  };

  useEffect(() => {
    if (hasUserConfirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      // need to reset last location and navigation state for repeated navigation prompts
      setLastLocation(null);
      setUserHasConfirmedNavigation(false);
    }
  }, [hasUserConfirmedNavigation, lastLocation, navigate]);

  useEffect(() => {
    // need to reset navigation state for repeated navigation prompts
    setUserHasConfirmedNavigation(false);
  }, [when]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {isModalVisible ? (
        <LeaveWithoutSaveModal
          cancelNavigationButtonText={phrases.cancelNavigationButtonText}
          confirmNavigationButtonText={phrases.confirmNavigationButtonText}
          onClose={handleModalClose}
          onConfirmNavigationClick={handleConfirmNavigationClick}
          title={phrases.title}
        >
          {phrases.body}
        </LeaveWithoutSaveModal>
      ) : null}
    </>
  );
}
