export const STATUS = 'status';
export const CUSTOMER = 'customer';
export const PRIORITY = 'priority';
export const ASSIGNEDTORADIO = 'assignedtoradio';
export const ASSIGNEDTODROPDN = 'assignedtodropdn';
export const ACTION = 'action';
export const DUEDATE = 'duedate';
export const LINKEDACTIVITY = 'linkedactivity';
export const TRANSACTIONDATE = 'transactiondate';
export const POLICY = 'policy';
export const SHARETOINSURLINK = 'sharetoinsurlink';
export const SUMMARY = 'summary';
export const ACTIVITYTYPE = 'activitytype';
export const DESCRIPTIONTYPE = 'descriptiontype';
export const STAGE = 'stage';
export const PREMIUM = 'premium';
export const CLOSEDATE = 'closedate';
export const DOCTYPE = 'doctype';
export const ASSIGNEDTONAME = 'assignedtoname';
export const RESCHEDNO = 'reschedno';
export const EXTRASUMMARY = 'extrasummary';
