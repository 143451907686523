import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { noop } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Column, Row, Typography, Link } from 'alloy-foundation';
import { WorkInProgressItem } from '../../../../models/WorkInProgress';
import { useWorkInProgressData } from './useWorkInProgressData';
import { DataTableColumn } from '../../../data-table/DataTableColumn';
import { DateColumn } from '../../../common/custom-datatable-columns/DateColumn';
import { StringColumn } from '../../../common/custom-datatable-columns/StringColumn';
import { SummaryPanel } from '../common/summaryPanel/SummaryPanel';
import { useDrawer } from '../../../drawer/DrawerProvider';
import WorkQueueDrawer from '../../../drawer/workQueue';
import NoPermissionToOpenWorkItemModal from '../../../drawer/workQueue/NoPermissionToOpenWorkItemModal';
import { PssCustomer } from '../../../../models/PssCustomer';
import { useProductInfoProvider } from '../../../productInfo/ProductInfoProvider';
import { useLogFormProvider } from '../../../common/providers/LogFormProvider';

type SummaryParams = {
  id: string;
};

type Props = {
  customer: PssCustomer;
};

const WorkInProgressGrid = ({ customer }: Props) => {
  const { formatMessage } = useIntl();
  const { id } = useParams<SummaryParams>();
  const workInProgressList = useWorkInProgressData(id);
  const { showDrawer } = useDrawer();
  const { getVimUserId } = useProductInfoProvider();
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const vimUserId = getVimUserId();

  const handleOpenWorkItem = (item: WorkInProgressItem) => {
    if (vimUserId === item.AssignedToId) showDrawer(<WorkQueueDrawer id={item.Id} />);
    else setPermissionModalVisible(true);
  };

  const logform = useLogFormProvider();
  const handleOpenSuspenseView = (Item) => {
    const endeffdate = Item.EndEffDate ? `${Item.EndEffDate}`.slice(0, -6) : '';
    logform.showLogForm('Suspense', customer, Item.PolicyId, endeffdate, Item);
  };

  const columns: DataTableColumn<WorkInProgressItem>[] = [
    new DateColumn<WorkInProgressItem>({
      key: 'DueDate',
      header: formatMessage({ id: 'workInProgress.DueDate' }),
      flex: 2,
    }),
    new StringColumn<WorkInProgressItem>({
      key: 'Type',
      header: formatMessage({ id: 'workInProgress.Type' }),
      flex: 2,
      getValue: (item) => (item ? `${item.SubType},${item.Type}` : ''),
      Cell: function RepExecCell({ row: item }) {
        return (
          <>
            <Row>
              <Column>
                {(item.SubType ?? '') !== '' && (
                  <Typography variant="bodySm" noWrap={true}>
                    {item.SubType}
                  </Typography>
                )}
                {(item.Type ?? '') !== '' && (
                  <Link
                    small
                    onClick={() =>
                      item.Type?.toUpperCase() === 'QUICKNAV'
                        ? handleOpenWorkItem(item)
                        : handleOpenSuspenseView(item)
                    }
                    truncated={true}
                  >
                    {item.Type?.toUpperCase() === 'QUICKNAV'
                      ? item.Type
                      : formatMessage({ id: 'customer.summary.suspense' })}
                  </Link>
                )}
              </Column>
            </Row>
          </>
        );
      },
    }),
    new StringColumn<WorkInProgressItem>({
      key: 'Description',
      header: formatMessage({ id: 'workInProgress.Description' }),
      flex: 2,
      noWrap: true,
    }),
    new StringColumn<WorkInProgressItem>({
      key: 'PolicyNumber',
      header: formatMessage({ id: 'workInProgress.Policy' }),
      flex: 2,
      getValue: (item) => (item ? `${item.PolicyNumber},${item.LineofBusiness}` : ''),
      Cell: function RepExecCell({ row: item }) {
        return (
          <>
            <Row>
              <Column>
                {(item.PolicyNumber ?? '') !== '' && (
                  <Typography variant="bodySm">{item.PolicyNumber}</Typography>
                )}
                {(item.LineofBusiness ?? '') !== '' && (
                  <Typography variant="bodySm">{item.LineofBusiness}</Typography>
                )}
              </Column>
            </Row>
          </>
        );
      },
    }),
    new StringColumn<WorkInProgressItem>({
      key: 'AssignedToName',
      header: formatMessage({ id: 'workInProgress.AssignedTo' }),
      flex: 2,
    }),
    new StringColumn<WorkInProgressItem>({
      key: 'PriorityDisplayName',
      header: formatMessage({ id: 'workInProgress.Priority' }),
      flex: 2,
    }),
  ];
  return (
    <React.Fragment>
      {permissionModalVisible && (
        <NoPermissionToOpenWorkItemModal
          onClose={() => setPermissionModalVisible(false)}
        ></NoPermissionToOpenWorkItemModal>
      )}
      <SummaryPanel
        title={formatMessage({ id: 'workInProgress.Header' })}
        id="WorkInProgress"
        columns={columns}
        data={workInProgressList.data || []}
        isLoading={workInProgressList.isLoading}
        initialSortDirection="asc"
        initialSortedColumnKey="DueDate"
        buttonOnClick={noop}
        rowSelection={false}
        emptyMessage={
          workInProgressList.isError ? (
            <Typography variant="disclaimer">
              <FormattedMessage id="common.error_loading_data" />
            </Typography>
          ) : (
            <Typography variant="disclaimer">
              <FormattedMessage id="dashboard.panel.data.empty" />
            </Typography>
          )
        }
        toolTipText={formatMessage({ id: 'workInProgress.toolTipText' })}
      />
    </React.Fragment>
  );
};

export default WorkInProgressGrid;
