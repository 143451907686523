import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { createMatcher } from './util';
import { useVisibleColumns } from './DataTableColumn';
import { DataTableColumn } from './DataTableColumn';
import { DataTableMultiSelect, DataTableProps, SortDirection } from '.';

export interface AdvanceFilter<Data> {
  /* Combination of all filtering parameters, This will be used by the memoized process */
  filteredParameters: string;

  filterData(data: Data[]): Data[];
  clearFilter(): void;

  display: ReactNode;
}

/**
 * Helper for managing <DataTable /> state and state setters.
 */
export function useDataTable<Data>({
  columns,
  filtering = true,
  advanceFilter = undefined,
  initialFilterValue = '',
  initialPageSize = 10,
  initialSortedColumnKey = columns.find((it) => it.sortable !== false)?.key,
  initialSortDirection = 'asc',
  initialSelectedRow,
  selectedRows = [],
  pageSizing = true,
  pagination = true,
  rowSelection = false,
  multipleRowSelection = false,
  sorting = true,
  expandingRows = false,
  data,
  equatableRowKey,
  multiSelect,
  ...rest
}: UseDataTableProps<Data>): UseDataTableReturnType<Data> {
  const [expandedRows, _onExpandedRowChange] = useState([] as Data[]);
  const [currentPage, onPageChange] = useState(0);
  const [filterValue, onFilter] = useState(initialFilterValue);
  const [pageSize, onPageSizeChange] = useState(initialPageSize);
  const [selectedRow, _onSelectRow] = useState(initialSelectedRow);
  // const [selectedRows, _onSelectedRows] = useState(selectedRows ?? []);
  const [sortedColumnKey, sortByColumnKey] = useState(initialSortedColumnKey);
  const [sortDirection, sortByDirection] = useState(initialSortDirection);

  const onSort = useCallback(
    (columnKey: keyof Data | string, direction: SortDirection) => {
      sortByColumnKey(columnKey);
      sortByDirection(direction);
    },
    [sortByDirection, sortByColumnKey]
  );
  const onSelectRow = useCallback(
    (nextSelectedRow: Data) => {
      _onSelectRow(function toggleOrChange(currentlySelectedRow) {
        if (nextSelectedRow === currentlySelectedRow) return undefined;
        return nextSelectedRow;
      });
    },
    [_onSelectRow]
  );
  const onExpandedRowChange = useCallback(
    (row: Data) => {
      _onExpandedRowChange(function toggleOrChange(currentlyExpandedRows) {
        if (
          currentlyExpandedRows.includes(row) ||
          (equatableRowKey &&
            currentlyExpandedRows.some(
              (x) => x[equatableRowKey as keyof Data] === row[equatableRowKey as keyof Data]
            ))
        )
          return currentlyExpandedRows.filter(
            (d) =>
              row !== d &&
              (!equatableRowKey ||
                d[equatableRowKey as keyof Data] !== row[equatableRowKey as keyof Data])
          );
        else return [...currentlyExpandedRows, row];
      });
    },
    [_onExpandedRowChange, equatableRowKey]
  );

  useEffect(() => {
    onPageChange(0);
  }, [onPageChange, filterValue, advanceFilter?.filteredParameters]);

  const onClearFilters = () => {
    advanceFilter.clearFilter();
  };

  return {
    columns,
    currentPage,
    data: data || [],
    filterValue,
    pageSize: pagination ? pageSize : Infinity,
    selectedRow,
    sortedColumnKey,
    sortDirection,
    expandedRows,

    advanceFilterDisplay: filtering && advanceFilter ? advanceFilter.display : undefined,
    advanceFilter: filtering ? advanceFilter : undefined,
    onFilter: filtering ? onFilter : undefined,
    onClearFilters: filtering ? onClearFilters : undefined,
    onPageChange: pagination ? onPageChange : undefined,
    onPageSizeChange: pageSizing ? onPageSizeChange : undefined,
    onSort: sorting ? onSort : undefined,
    onSelectRow: rowSelection ? onSelectRow : undefined,
    onExpandedRowChange: expandingRows ? onExpandedRowChange : undefined,
    multiSelect: multipleRowSelection ? multiSelect : null,
    equatableRowKey,
    ...rest,
  };
}

/**
 * Helper for managing <DataTable /> data manipulation.
 */
export function useDataTableRows<Data>({
  currentPage,
  filterValue,
  advanceFilter,
  pageSize,
  sortedColumnKey,
  sortDirection,
  data,
  columns: rawColumns,
}: TableState<Data>) {
  const columns = useVisibleColumns(rawColumns);

  const filteredData = useMemo(
    function filterData() {
      let result = data;

      if (filterValue?.trim().length) {
        const matchesFilter = createMatcher(filterValue);
        result = result.filter((row) => {
          return columns.some((column) => {
            if (!column.filterable) return false;
            const filterableValue = String(column.getFilterValue(row));
            return matchesFilter(filterableValue);
          });
        });
      }

      if (advanceFilter) {
        result = advanceFilter.filterData(result);
      }

      return result;
    },
    [data, columns, filterValue, advanceFilter]
  );

  const sortedData = useMemo(
    function sortData() {
      if (sortedColumnKey === undefined) return filteredData;
      const allColumns = rawColumns.reduce((item, column) => {
        return item.concat(new DataTableColumn(column));
      }, []);
      const sortColumn = allColumns?.find((it) => it.key === sortedColumnKey);

      if (!sortColumn) {
        throw new Error(`Couldn't find column with sortedColumnKey ${String(sortedColumnKey)}`);
      }

      const sorted = [...filteredData];

      sorted.sort((rowA, rowB) => {
        if (sortColumn.getSortFunction) {
          return sortColumn.getSortFunction(rowA, rowB);
        }

        const a = sortColumn.getSortValue(rowA);
        const b = sortColumn.getSortValue(rowB);

        if (typeof a === 'string') {
          return a < b ? -1 : 1;
        } else if (typeof a === 'number') {
          return a - (b as unknown as number);
        } else if (typeof a === 'boolean') {
          return a < b ? -1 : 1;
        } else if ('getFullYear' in a) {
          return Number(b) - Number(a);
        }

        throw new Error('Unable to infer sorting');
      });

      if (sortDirection !== 'asc') {
        sorted.reverse();
      }

      return sorted;
    },
    [filteredData, sortedColumnKey, sortDirection, rawColumns]
  );

  const paginatedData = useMemo(
    function paginateData() {
      const hasRequiredParams = [currentPage, pageSize].every(
        (requiredParam) => typeof requiredParam === 'number'
      );

      if (hasRequiredParams) {
        return sortedData.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
      }

      return sortedData;
    },
    [sortedData, currentPage, pageSize]
  );

  return {
    data: paginatedData,
    totalPages: Math.ceil(filteredData.length / pageSize),
  };
}

export interface UseDataTableProps<Data> {
  columns: DataTableProps<Data>['columns'];

  data?: DataTableProps<Data>['data'];

  /** Pass false to turn off filtering */
  filtering?: boolean;
  advanceFilter?: AdvanceFilter<Data>;

  /** Set an initial filter value */
  initialFilterValue?: DataTableProps<Data>['filterValue'];

  /** Set an initial page size */
  initialPageSize?: DataTableProps<Data>['pageSize'];

  /** Set the initial sort column */
  initialSortedColumnKey?: DataTableProps<Data>['sortedColumnKey'];

  /** Set the initial sort direction */
  initialSortDirection?: DataTableProps<Data>['sortDirection'];

  /** Set the initial selected row */
  initialSelectedRow?: DataTableProps<Data>['selectedRow'];

  /** Set the initial selected row */
  selectedRows?: DataTableProps<Data>['multiSelect']['selectedRows'];

  /** Pass false to turn off page sizing */
  pageSizing?: boolean;

  /** Pass false to turn off pagination */
  pagination?: boolean;

  /** Pass true to turn on row selection. Incompatible with multipe select row */
  rowSelection?: boolean;

  /** Pass true to turn on multiple row selection. Incompatible with single select row */
  multipleRowSelection?: boolean;

  /** Pass false to disable sorting */
  sorting?: boolean;

  /** Pass false to disable expandable rows */
  expandingRows?: boolean;

  /** The key of the column used for equality compararison. */
  equatableRowKey?: keyof Data | string;

  multiSelect?: DataTableMultiSelect<Data>;
}

export type UseDataTableReturnType<Data> = TableState<Data> & {
  advanceFilterDisplay?: ReactNode;
  advanceFilter?: AdvanceFilter<Data>;
  onFilter?(query: string): void;
  onClearFilters(): void;
  onPageChange?(nextPage: number): void;
  onPageSizeChange?(nextPageSize: number): void;
  onSort?(columnKey: keyof Data | string, direction: SortDirection): void;
  onSelectRow?(row: Data): void;
  onExpandedRowChange?(row: Data): void;
  multiSelect: DataTableMultiSelect<Data>;
  equatableRowKey?: keyof Data | string;
};

type TableStateKeys =
  | 'data'
  | 'columns'
  | 'currentPage'
  | 'filterValue'
  | 'pageSize'
  | 'sortedColumnKey'
  | 'sortDirection'
  | 'selectedRow'
  | 'expandedRows'
  | 'advanceFilter'
  | 'onClearFilters'
  | 'multiSelect'
  | 'equatableRowKey';

export type TableState<Data> = Pick<DataTableProps<Data>, TableStateKeys>;
