import {
  Button,
  ButtonGroup,
  CheckBox,
  Column,
  Flex,
  Row,
  SearchField,
  SectionContainer,
  Spacer,
  useAppContext,
} from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Manager } from '../../../models/Manager';
import ManagerService from '../../../services/manager/ManagerService';
import { StringColumn } from '../../common/custom-datatable-columns/StringColumn';
import LocalizedDataTable from '../../common/localizedDataTable/LocalizedDataTable';
import { DataTableColumn } from '../../data-table/DataTableColumn';
import { useDataTable, useDataTableRows } from '../../data-table/useDataTable';
import { useMultiSelect } from '../../data-table/useMultiSelect';
import { useAssignedEmpData } from './useAssignedEmpData';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';
export const EmployeeAssignmentStep = ({
  selectedManager,
  setSelectedManager,
  vssoEmpList,
  onChange,
  onSave,
}) => {
  const { formatMessage } = useIntl();
  const { getAgencyNumber } = useProductInfoProvider();
  const [filterValue, onFilter] = useState('');
  const [checked, onCheck] = useState(false);
  const [assignedEmp, setAssignedEmp] = useState([]);
  const [currentData, setCurrentData] = useState(vssoEmpList?.data);
  const [isFiltering, setIsFiltering] = useState(false);
  const assignedEmpData = useAssignedEmpData(selectedManager.VimId, selectedManager.EmployeeCode);
  const { addErrorToast, addSuccessToast } = useAppContext();
  useEffect(() => {
    const tempEmp = [];
    assignedEmpData?.data?.forEach((e) => {
      const temp = vssoEmpList?.data?.find((i) => i.VimId === parseInt(e.VIMId));
      if (temp) tempEmp.push(temp);
    });
    setAssignedEmp(tempEmp);
  }, [assignedEmpData?.data, vssoEmpList?.data]);

  const handleRowsUpdated = (rows) => {
    setAssignedEmp(rows);
    onChange?.();
  };
  const columns: DataTableColumn<Manager>[] = [
    new StringColumn<Manager>({
      key: 'FirstName',
      header: formatMessage({ id: 'customer.details.personal.firstname.label' }),
    }),
    new StringColumn<Manager>({
      key: 'LastName',
      header: formatMessage({ id: 'customer.details.personal.lastname.label' }),
    }),
    new StringColumn<Manager>({
      key: 'EMail',
      header: formatMessage({ id: 'manager.configuration.info.email' }),
    }),
    new StringColumn<Manager>({
      key: 'EmployeeTitle',
      header: formatMessage({ id: 'manager.configuration.info.title' }),
    }),
  ];
  const multiSelect = useMultiSelect(
    currentData || [],
    vssoEmpList?.data,
    assignedEmp,
    isFiltering,
    'VimId',
    handleRowsUpdated,
    true
  );

  const partialSelectionTableState = useDataTable({
    columns,
    data: vssoEmpList?.data || [],
    initialFilterValue: '',
    filtering: false,
    initialSortedColumnKey: 'LastName',
    initialSortDirection: 'asc',
    initialPageSize: 5,
    multipleRowSelection: true,
    selectedRows: assignedEmp,
    equatableRowKey: 'VimId',
    multiSelect,
  });
  partialSelectionTableState.filterValue = filterValue;
  if (checked) partialSelectionTableState.data = assignedEmp;
  const partialSelectionTableRows = useDataTableRows(partialSelectionTableState);

  useEffect(() => {
    partialSelectionTableState.onPageChange(0);
    setCurrentData(partialSelectionTableRows.data);
    setIsFiltering(filterValue !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const handleSaveAssignmentChanges = async () => {
    const agencyNumber = getAgencyNumber();
    await ManagerService.assign(selectedManager, assignedEmp, agencyNumber);
  };
  const mutation = useMutation(handleSaveAssignmentChanges, {
    onError: (error, variables, context) => {
      // An error happened!
      addErrorToast('Something went wrong', {
        title: <FormattedMessage id="error.toasts.header" />,
        withBottomActionBar: true,
      });
    },
    onSuccess: (data, variables, context) => {
      setSelectedManager(null);
      onSave();
      addSuccessToast(<FormattedMessage id="manager.configuration.success" />, {
        title: <FormattedMessage id="success.toasts.header" />,
        withBottomActionBar: true,
      });
    },
  });

  return (
    <SectionContainer
      headerText={formatMessage({ id: 'manager.configuration.info2' })}
      subheaderText={formatMessage({ id: 'manager.configuration.subheader' })}
    >
      <Row>
        <Column sm={4}>
          <SearchField
            buttonAriaLabel="search-field"
            id="alloy-search-field"
            placeholder={formatMessage({ id: 'manager.configuration.placerholder2' })}
            value={filterValue}
            onChange={(v) => {
              onFilter(v);
              partialSelectionTableState.onPageChange?.(0);
            }}
            inputDebouncingMs={500}
          />
        </Column>
        <Column>
          <Flex alignItems="center" height="62px">
            <CheckBox
              onChange={(v) => {
                onCheck(v);
                partialSelectionTableState.onPageChange?.(0);
              }}
              checked={checked}
              data-testid="show-all"
              label={formatMessage({ id: 'manager.configuration.checkbox' })}
            />
          </Flex>
        </Column>
      </Row>
      <LocalizedDataTable
        isLoading={assignedEmpData.isLoading}
        emptyMessage={formatMessage({ id: 'dashboard.panel.data.empty' })}
        {...partialSelectionTableState}
        {...partialSelectionTableRows}
        pageSizeOptions={[5, 10, 25]}
      />
      <Spacer pt="small" />
      <Flex flexDirection="row-reverse">
        <ButtonGroup>
          <Button onClick={() => setSelectedManager(null)}>
            {formatMessage({ id: 'common.button.cancel' })}
          </Button>
          <Button variant="primary" onClick={() => mutation.mutateAsync()}>
            {formatMessage({ id: 'manager.configuration.savebutton' })}
          </Button>
        </ButtonGroup>
      </Flex>
    </SectionContainer>
  );
};
