import { LayoutShell } from 'alloy-foundation';
import { useBottomActionBarState } from '../application-layout/BottomActionBarProvider';
import { TopNavHeight } from '../../topnav/TopNavConstants';

export const withLayoutShell = <Props extends object>(
  WrappedComponent: React.ComponentType<Props>
) => {
  return (props: Props) => {
    const { bottomActionBar } = useBottomActionBarState();

    return (
      <LayoutShell bottomBar={bottomActionBar} contentMaxHeight={`calc(100vh - ${TopNavHeight})`}>
        <WrappedComponent {...props} />
      </LayoutShell>
    );
  };
};
