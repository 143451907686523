import * as Yup from 'yup';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import { PssCustomer } from '../../../models/PssCustomer';
import BusinessInformation from './BusinessInformationSchema';
import ContactInformationSchema from './ContactInformationSchema';
import GeneralInformationSchema from './GeneralInformationSchema';

export const customerSummaryValidationSchema = (
  formatMessage,
  dropdowns: CustomerDropdowns,
  customer: PssCustomer
) =>
  Yup.object().shape({
    contactInformation: ContactInformationSchema(formatMessage, dropdowns),
    general: GeneralInformationSchema(formatMessage, dropdowns, customer.Permissions),
    businessInformation: BusinessInformation(formatMessage, customer),
  });
