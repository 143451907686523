import { LeaveWithoutSaveModalProps } from 'alloy-foundation/es/components/LeaveWithoutSaveModal';
import { LeaveWithoutSaveModal } from 'alloy-foundation';
import { useIntl } from 'react-intl';

export default function LocalizedLeaveWithoutSaveModal(
  props: Omit<
    LeaveWithoutSaveModalProps<
      'cancelNavigationButtonText' | 'confirmNavigationButtonText' | 'title' | 'children'
    >,
    'cancelNavigationButtonText' | 'confirmNavigationButtonText' | 'title' | 'children'
  >
) {
  const { formatMessage } = useIntl();

  return (
    <LeaveWithoutSaveModal
      cancelNavigationButtonText={formatMessage({
        id: 'leaveWithoutSaveGuard.cancelNavigationButtonText',
      })}
      confirmNavigationButtonText={formatMessage({
        id: 'leaveWithoutSaveGuard.confirmNavigationButtonText',
      })}
      title={formatMessage({ id: 'leaveWithoutSaveGuard.title' })}
      children={formatMessage({ id: 'leaveWithoutSaveGuard.body' })}
      {...props}
    />
  );
}
