import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Spacer, SectionContainer } from 'alloy-foundation';

export const PAGE_LAYOUT_ICON_SIZE = Object.freeze({
  DEFAULT: 48,
  SMALL: 30,
});

export const PAGE_LAYOUT_ICON_COLOR = Object.freeze({
  DEFAULT: '#364043',
});

const PageLayout = ({
  id,
  children,
  title = '',
  subtitle = '',
  topNode,
  fluid,
  minHeight,
  centerAlign,
  hideContentHeader = false,
}) => {
  const sectionContainer = !hideContentHeader ? (
    <SectionContainer headerText={title} subheaderText={subtitle}>
      {children}
    </SectionContainer>
  ) : (
    <div>{children}</div>
  );

  const top = topNode ? (
    <Spacer paddingLeft="xlarge" paddingRight="xsmall">
      {topNode}
    </Spacer>
  ) : (
    <Spacer paddingLeft="xlarge" paddingTop="xsmall" paddingRight="xsmall" />
  );

  return (
    <Layout
      id={id}
      minHeight={minHeight}
      fluid={fluid}
      marginTop="none"
      centerAlign={centerAlign}
      noPadding={true}
    >
      <Spacer paddingLeft="medium" paddingTop="medium" paddingRight="medium" paddingBottom="medium">
        {top}
        <Spacer
          paddingLeft="medium"
          paddingTop="medium"
          paddingRight="xsmall"
          paddingBottom="large"
        >
          {sectionContainer}
        </Spacer>
      </Spacer>
    </Layout>
  );
};

PageLayout.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  icon: PropTypes.string,
  description: PropTypes.node,
  topNode: PropTypes.node,
  buttonOne: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  scenarios: PropTypes.arrayOf(PropTypes.func),
};

export default PageLayout;
