import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Column, Row, Typography, Link, Icon } from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useActivityData } from './useActivityData';
import { ActivityReduced } from '../../../../models/ActivitySummary';
import { StringColumn } from '../../../common/custom-datatable-columns/StringColumn';
import { DataTableColumn, HighlightFilterValue } from '../../../data-table/DataTableColumn';
import { SummaryPanel } from '../common/summaryPanel/SummaryPanel';
import { useFormatting } from '../../../common/hooks/useFormatting';
import { PssCustomer } from '../../../../models/PssCustomer';
import { ImageColumn } from '../../../common/custom-datatable-columns/ImageColumn';
import LogFormView from '../../../topnav/LogForm/LogFormView';
import { useLogFormProvider } from '../../../common/providers/LogFormProvider';
type SummaryParams = {
  id: string;
};

type ActivityListTableParams = {
  initialColumnSort?;
  initialSortOrder?: string;
  customer: PssCustomer;
};

const ActivityListTable = ({
  initialColumnSort = 'ActivityDate',
  initialSortOrder = 'desc',
  customer,
}: ActivityListTableParams) => {
  const { formatMessage } = useIntl();
  const { id } = useParams<SummaryParams>();
  const [initialValue, updateInitialValues] = useState<ActivityReduced[]>([]);
  const { formatDate } = useFormatting();
  const [logFormViewlVisible, setLogFormViewVisible] = useState(false);
  const activityList = useActivityData(id, {
    onSuccess: (data) => {
      const updatedValues = [];
      data.Activities?.forEach((activity) => {
        updatedValues.push({
          EmployeeName: activity.AttributeFields.EmployeeName,
          ActivitySummary: activity.AttributeFields.ActivitySummary,
          Activity: activity.AttributeFields.Activity,
          Description: activity.AttributeFields.Description,
          ActivityDate: activity.AttributeFields.ActivityDate,
          PolicyID: activity.AttributeFields.PolicyId,
          PolicyNumber: activity.AttributeFields.PolicyNumber ?? '',
          PolicyEndDate: activity.AttributeFields.PolicyEndDate ?? '',
          PolicyExpdate: activity.AttributeFields.PolicyExpdate ?? '',
          ActivityEndEffectiveDate: activity.AttributeFields.ActivityEndEffectiveDate ?? '',
          ActivityId: activity.AttributeFields.ActivityId,
          HasDocument: activity.AttributeFields.HasDocument,
          CompanyName: activity.AttributeFields.CompanyName ?? '',
          CsrName: activity.AttributeFields.CsrName ?? '',
          ExecName: activity.AttributeFields.ExecName ?? '',
          StageId: activity.AttributeFields.StageId,
          Premium: activity.AttributeFields.Premium ?? '',
          CloseDate: activity.AttributeFields.CloseDate ?? '',
          IsSystemActivity: activity.AttributeFields.IsSystemActivity ?? '',
          Action: activity.AttributeFields.Action ?? '',
          Priority: activity.AttributeFields.Priority ?? '',
          ReschedNo: activity.AttributeFields.ReschedNo ?? '',
          CreatedBy: activity.AttributeFields.CreatedBy ?? '',
          CompleteBy: activity.AttributeFields.CompleteBy ?? '',
          AssignTo: activity.AttributeFields.AssignTo ?? '',
          DocType: activity.AttributeFields.DocType ?? '',
          LinkActivities: activity.AttributeFields.LinkActivities ?? '',
          SalesStage: activity.AttributeFields.SalesStage ?? '',
          IsShared: activity.AttributeFields.IsShared ?? '',
          DueDate: activity.AttributeFields.DueDate ?? '',
        });
      });
      updateInitialValues(updatedValues);
    },
  });
  useEffect(() => {
    return () => {
      updateInitialValues([]);
    };
  }, [id]);

  const [openActivity, setOpenActivity] = useState(null);
  const setOpenActivityView = (activity) => {
    setOpenActivity(activity);
    setLogFormViewVisible(true);
  };

  const columns: DataTableColumn<ActivityReduced>[] = [
    new ImageColumn<ActivityReduced>({
      key: 'HasDocument',
      header: <Icon name="paperclipVertical" size={14} color="black"></Icon>,
      flex: 0.75,
      iconName: 'paperclipVertical',
      size: 14,
      color: 'black',
      dataTestId: 'hasDocument-Header',
      visible: (row) => {
        return !!row.HasDocument;
      },
      getSortFunction: (rowA, rowB) => {
        // The sort is explicitly written this way else when compiled for release, it wasn't working correctly
        if (!!rowA.HasDocument === !!rowB.HasDocument) {
          const rowATime = new Date(rowA.ActivityDate).getTime();
          const rowBTime = new Date(rowB.ActivityDate).getTime();
          return rowATime < rowBTime ? -1 : 1;
        } else {
          return !!rowA.HasDocument < !!rowB.HasDocument ? -1 : 1;
        }
      },
    }),
    new StringColumn<ActivityReduced>({
      key: 'ActivityDate',
      header: formatMessage({ id: 'activityList.Date' }),
      flex: 2,
      getValue: (activity) => activity?.ActivityDate,
      Cell: function LinkCell({ row: activity }) {
        return (
          <>
            <Link small onClick={() => setOpenActivityView(activity)}>
              {formatDate(activity.ActivityDate)}
            </Link>
          </>
        );
      },
    }),
    new StringColumn<ActivityReduced>({
      key: 'Policy',
      header: formatMessage({ id: 'activityList.Policy' }),
      flex: 2,
      getValue: (activity) =>
        activity ? `${activity.PolicyNumber},${activity.ActivityEndEffectiveDate}` : '',
      Cell: function RepExecCell({ row: activity }) {
        return (
          <>
            <Row>
              <Column>
                {(activity.PolicyNumber ?? '') !== '' && (
                  <Typography variant="bodySm" fontWeight="bold">
                    <HighlightFilterValue>{activity.PolicyNumber}</HighlightFilterValue>
                  </Typography>
                )}
                {(activity.ActivityEndEffectiveDate ?? '') !== '' && (
                  <Typography variant="bodySm" noWrap={true}>
                    <HighlightFilterValue>
                      {formatDate(activity.ActivityEndEffectiveDate)}
                    </HighlightFilterValue>
                  </Typography>
                )}
              </Column>
            </Row>
          </>
        );
      },
    }),
    new StringColumn<ActivityReduced>({
      key: 'EmployeeName',
      header: formatMessage({ id: 'activityList.Employee' }),
      flex: 2,
    }),
    new StringColumn<ActivityReduced>({
      key: 'Activity',
      header: formatMessage({ id: 'activityList.Activity' }),
      flex: 2,
      dataTestId: 'activityheader-test',
    }),
    new StringColumn<ActivityReduced>({
      key: 'Description',
      header: formatMessage({ id: 'activityList.Description' }),
      flex: 2,
    }),
    new StringColumn<ActivityReduced>({
      key: 'ActivitySummary',
      header: formatMessage({ id: 'activityList.ActivitySummary' }),
      flex: 4,
      noWrap: true,
    }),
  ];

  const handleClose = () => {
    setLogFormViewVisible(false);
  };
  const logForm = useLogFormProvider();
  return (
    <React.Fragment>
      {logFormViewlVisible && (
        <LogFormView
          onCancel={() => setLogFormViewVisible(false)}
          onChangeLogView={() => {
            setLogFormViewVisible(false);
          }}
          logFormType="Activity"
          customer={customer}
          activity={openActivity}
          setLogFormViewVisible={setLogFormViewVisible}
          handleLogClose={handleClose}
        />
      )}
      <SummaryPanel
        title={formatMessage({ id: 'activityList.title' })}
        id="SummaryActivity"
        columns={columns}
        data={initialValue || []}
        isLoading={activityList.isLoading}
        initialSortDirection={initialSortOrder}
        initialSortedColumnKey={initialColumnSort}
        rowSelection={false}
        searchField={true}
        emptyMessage={
          activityList.isError ? (
            <Typography variant="disclaimer">
              <FormattedMessage id="common.error_loading_data" />
            </Typography>
          ) : (
            <Typography variant="disclaimer">
              <FormattedMessage id="dashboard.panel.data.empty" />
            </Typography>
          )
        }
        buttonText={formatMessage({ id: 'common.button.new' })}
        buttonOnClick={() => logForm.showLogForm('Activity', customer)}
        toolTipText={formatMessage({ id: 'activityList.toolTipText' })}
      />
    </React.Fragment>
  );
};

export default ActivityListTable;
