import { Switch, Route, Redirect } from 'react-router-dom';
import CreateCustomerContainer from '../customers/CreateCustomerContainer';
import DashboardContainer from '../dashboard/DashboardContainer';
import GlobalNavSearch from '../global-search/GlobalSearchResults';
import CustomerSummary from '../customers/summary/CustomerSummary';
import MyProfile from '../my-profile/MyProfile';
import SelectAgency from '../selectAgency/SelectAgency';
import ManagerConfiguration from '../configuration/manager/ManagerConfiguration';
import AdminRoute from './AdminRoute';
import QuickNavConfigurationMain from '../configuration/quicknav/QuickNavConfigurationMain';
import InstancesGrid from '../configuration/quicknav/InstancesGrid';
import InstanceEdit from '../configuration/quicknav/instanceForm/InstanceEdit';
import { UnauthorizedAccess } from './errorPages/UnauthorizedAccess';

const MainWindow = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/dashboard" component={DashboardContainer} />
      <Route path="/search/:term" component={GlobalNavSearch} />
      <Route path="/customers/create" component={CreateCustomerContainer} />
      <Route path="/customers/:id" component={CustomerSummary} />
      <Route path="/myprofile" component={MyProfile} />
      <Route path="/activities/create" />
      <Route path="/suspenses/create" />
      <AdminRoute exact path="/quicknav/configure" component={QuickNavConfigurationMain} />
      <AdminRoute exact path="/quicknav/configure/:id" component={InstancesGrid} />
      <AdminRoute
        exact
        path={['/quicknav/configure/instance/:id', '/quicknav/configure/:busId/instance/:id']}
        component={InstanceEdit}
      />
      <AdminRoute path="/manager/configure" component={ManagerConfiguration} />
      <Route path="/Login/SelectAgency" component={SelectAgency} />
      <Route path="/Login/UnauthorizedAccess" component={UnauthorizedAccess} />
    </Switch>
  );
};

export default MainWindow;
