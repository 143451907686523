import React from 'react';
import { SectionContainer, Link } from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import Configuration from '../../../Configuration';

const MyProfileUpdatePassword = () => {
  const { formatMessage } = useIntl();

  return (
    <div data-testid="my-account-password">
      <SectionContainer
        id="my-account-password"
        headerText={formatMessage({ id: 'myProfile.updatePassword.header' })}
        subheaderText={formatMessage({ id: 'myProfile.updatePassword.subHeader' })}
      >
        <Link
          data-testid="update-password"
          href={Configuration.idpChangePasswordUri}
          openInNewWindow={true}
          rel="noopener noreferrer"
          icon="open"
        >
          <FormattedMessage id="myProfile.updatePassword.linkText" />
        </Link>
      </SectionContainer>
    </div>
  );
};

export default MyProfileUpdatePassword;
