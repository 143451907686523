import { Button, Flex, Group, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import Header from '../../../common/header/Header';
import SecondaryContactForm from './SecondaryContactForm';
import { secondaryContact } from '../../../../models/NewCustomer';
import * as fieldNames from '../../fieldNames';

const SecondaryContact = ({ dropdowns }: { dropdowns: CustomerDropdowns }) => {
  const { formatMessage } = useIntl();
  const formikContext = useFormikContext();

  const hasSecondaryContact = formikContext.getFieldProps(
    fieldNames.SECONDARY_CUSTOMER_CONTACT
  ).value;

  const addSecondaryContact = async () => {
    const newSecondaryContact = {
      phones: [{ type: '', phoneNumber: '', notApplicable: false }],
      emails: [{ type: '', emailAddress: '', notApplicable: false }],
    } as secondaryContact;
    await formikContext.setFieldValue(fieldNames.SECONDARY_CUSTOMER_CONTACT, newSecondaryContact);
  };

  const removeSecondaryContact = async () => {
    await formikContext.setFieldValue(fieldNames.SECONDARY_CUSTOMER_CONTACT, null);
  };

  return (
    <React.Fragment>
      <Group spacing="small" stacked={false}>
        <Header title={formatMessage({ id: 'customer.secondaryContact.header' })} />
        {hasSecondaryContact && (
          <Flex alignItems="flex-end" height="2rem">
            <Button
              onClick={() => {
                removeSecondaryContact();
              }}
              size="small"
              icon="delete"
              aria-label=""
              data-testid="secondaryContact-delete"
              variant="link"
            />{' '}
          </Flex>
        )}
      </Group>
      <Spacer mr="xlarge" mb="tiny">
        {!hasSecondaryContact && (
          <Spacer mb="large">
            <Button onClick={() => addSecondaryContact()}>
              {formatMessage({ id: 'customer.secondaryContact.AddButton' })}
            </Button>
          </Spacer>
        )}
        {hasSecondaryContact && <SecondaryContactForm dropdowns={dropdowns} />}
      </Spacer>
    </React.Fragment>
  );
};

export default SecondaryContact;
