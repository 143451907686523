import React, { memo, useState } from 'react';
import { Link, Typography } from 'alloy-foundation';
import { useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataTableColumn } from '../../../data-table/DataTableColumn';
import {
  ToDoItem,
  ToDoList,
  ToDoListCompleteStatusCriteria,
  ToDoListCriteria,
  ToDoListCustomFilter,
} from '../../../../models/ToDo';
import { DashboardPanel } from '../DashboardPanel';
import ToDoListWorkgroupDashboardOptions from './ToDoListWorkgroupDashboardOptions';
import { DateColumn } from '../../../common/custom-datatable-columns/DateColumn';
import { useRefetch } from '../../../common/hooks/useRefetch';
import { StringColumn } from '../../../common/custom-datatable-columns/StringColumn';
import ChangeStatusModal from '../common/ChangeStatusModal';
import ChangeStatusErrorModal from '../common/ChangeStatusErrorModal';
import { GET_TODO_LIST_WG_DUE_TODAY_COUNT } from '../../dashboardTiles/DashboardTiles';
import { useToDoListWorkGroupData } from './useToDoListWorkGroupData';
import { useToDoListWorkGroupChangeStatus } from './useToDoListWorkGroupChangeStatus';
import { ToDoListWorkgroupSettings } from '../../../../models/ApplicationState/ToDoListWorkgroupSettings';
import { useApplicationState } from '../../../common/providers/ApplicationStateProvider';
import { useRouter } from '../../../common/hooks/useRouter';
import { useLogFormProvider } from '../../../common/providers/LogFormProvider';
import { WorkInProgressItem } from '../../../../models/WorkInProgress';
import { useCommunicationService } from '../../../communicationService/CommunicationServiceProvider';
export const TODO_LIST_WG = 'toDoListWorkgroup';
interface Props {
  toDoListWorkgroupSettings: ToDoListWorkgroupSettings;
  saveToDoListWorkgroupSettings: (p: ToDoListWorkgroupSettings) => void;
}
const ToDoListWorkgroupDashboardPanel = () => {
  const { toDoListWorkgroupSettings, setToDoListWorkgroupSettings } = useApplicationState();
  return (
    <InnerToDoListWorkgroupDashboardPanel
      toDoListWorkgroupSettings={toDoListWorkgroupSettings}
      saveToDoListWorkgroupSettings={setToDoListWorkgroupSettings}
    />
  );
};

/** InnerToDoListWorkgroupDashboardPanel is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerToDoListWorkgroupDashboardPanel = memo(
  ({ toDoListWorkgroupSettings, saveToDoListWorkgroupSettings }: Props) => {
    const { fetch, refetch } = useRefetch();
    const { formatMessage } = useIntl();
    const { history } = useRouter();
    const filterOptions = [
      {
        label: formatMessage({ id: 'dashboard.panel.dateRangeFilter.dueToday' }),
        value: 'Due Today',
      },
      {
        label: formatMessage({ id: 'dashboard.panel.dateRangeFilter.pastDue' }),
        value: 'Past Due',
      },
    ];
    const logform = useLogFormProvider();
    const { showForm } = useCommunicationService();

    const handleOpenSuspenseView = (susp: ToDoItem) => {
      const frmLogsusp = {
        Action: parseInt(susp.Displayaction),
        PolicyId: susp.PolicyId,
        EndEffDate: susp.EndEffDate,
        Description: susp.Description,
        CloseDate: susp.CloseDate,
        ReschedNo: parseInt(susp.ReschedNo),
        DueDate: susp.DueDate,
        Premium: susp.Premium,
        StageId: susp.StageId,
        PriorityId: susp.PriorityId,
        Id: susp.ItemId,
        Type: '',
        ProcessId: null,
        AssignedToCode: '',
        AssignedToName: '',
        AssignedToId: null,
        PolicyNumber: '',
        SubType: '',
        TypeofBusiness: '',
        LineofBusiness: '',
        PriorityDisplayName: '',
        CanOpen: true,
        IsCompleted: susp.IsCompleted,
        Activity: susp.Activity,
        SubActivity: susp.SubActivity,
        SuspGrpId: susp.SusGrpId,
      } as WorkInProgressItem;

      const endeffdate = frmLogsusp.EndEffDate ? `${frmLogsusp.EndEffDate}`.slice(0, -6) : '';
      logform.showLogForm('Suspense', susp, frmLogsusp.PolicyId, endeffdate, frmLogsusp);
    };

    // todo list workgroup dashboard should default to 'Due Today' view
    const defaultFilter = 'Due Today';
    const [initialFilter] = useState(
      filterOptions.find((p) => p.value === (toDoListWorkgroupSettings?.filter ?? defaultFilter)) ??
        null
    );

    const statusFormatter = (value) =>
      value
        ? formatMessage({ id: 'dashboard.panel.todoListWorkgroup.completed' })
        : formatMessage({ id: 'dashboard.panel.todoListWorkgroup.incomplete' });
    const actionStatusFormatter = (value) =>
      value
        ? formatMessage({ id: 'dashboard.panel.todoListWorkgroup.reopen' })
        : formatMessage({ id: 'dashboard.panel.todoListWorkgroup.complete' });

    const columns: DataTableColumn<ToDoItem>[] = [
      new StringColumn<ToDoItem>({
        key: 'EntityName',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.name.header' }),
        flex: 2,
        Cell: function ToDoNameCell({ row: toDoItem }) {
          // example usage - should open customer summary
          return (
            <>
              <Link
                small
                onClick={() =>
                  history.push(`/customers/${toDoItem.NavigationModel.routeParams.clientId}`)
                }
              >
                {toDoItem.EntityName}
              </Link>
            </>
          );
        },
      }),
      new DateColumn<ToDoItem>({
        key: 'DueDate',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.dueDate.header' }),
        flex: 2,
      }),
      new StringColumn<ToDoItem>({
        key: 'Priority',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.priority.header' }),
        flex: 2,
      }),
      new StringColumn<ToDoItem>({
        key: 'AssignedToEmpShortName',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.to.header' }),
        flex: 2,
      }),
      new StringColumn<ToDoItem>({
        key: 'Action',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.reason.header' }),
        flex: 2,
        Cell: function ToDoNameCell({ row: toDoItem }) {
          return (
            <>
              <Link
                small
                onClick={() => {
                  toDoItem?.CustomerNumber
                    ? handleOpenSuspenseView(toDoItem)
                    : showForm('Suspense', { Action: 'Edit', SuspId: toDoItem.EntityId });
                }}
                truncated={false}
              >
                {toDoItem.Action}
              </Link>
            </>
          );
        },
      }),
      new StringColumn<ToDoItem>({
        key: 'IsCompleted',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.status.header' }),
        flex: 2,
        getValue: (toDoItem) => statusFormatter(toDoItem.IsCompleted),
      }),
      new StringColumn<ToDoItem>({
        key: 'PolicyNo',
        header: formatMessage({ id: 'dashboard.panel.todoListWorkgroup.columns.policy.header' }),
        flex: 2,
        getValue: (toDoItem) => toDoItem.PolicyNo ?? '',
      }),
      new StringColumn<ToDoItem>({
        key: 'Description',
        header: formatMessage({
          id: 'dashboard.panel.todoListWorkgroup.columns.description.header',
        }),
        noWrap: true,
        flex: 4,
      }),
    ];
    // always default to true for suspense includes when there's no custom filter
    const mapCustomFilterToCriteria = (arg: ToDoListCustomFilter): ToDoListCriteria => {
      const tmp = {
        Action: arg?.action ?? '',
        DaysFromToday: arg?.daysFromToday ?? '',
        DueDateFrom: arg?.dueDateFrom ?? null,
        DueDateTo: arg?.dueDateTo ?? null,
        Filters: arg?.filter ?? defaultFilter,
        IncludeBackupSuspense: arg?.includeCompleted ?? true,
        IncludeCCSuspense: arg?.includeIncomplete ?? true,
        Limit: 50,
        MaxRowCount: 5000,
        Page: 1,
        Priority: arg?.priority ?? null,
        SortBy: [],
        Start: 0,
        Status: 1,
        Workgroup: arg?.workgroup,
      };

      return tmp;
    };

    // this query will re-execute when fetch/customFilter steate is updated
    const toDoListWorkgroup = useToDoListWorkGroupData(
      [TODO_LIST_WG, fetch, toDoListWorkgroupSettings?.customFilter],
      mapCustomFilterToCriteria(toDoListWorkgroupSettings?.customFilter)
    );
    const csvColumnMapper = (item: ToDoItem) => {
      return {
        Name: item.EntityName,
        DueDate: new Date(item.DueDate),
        Priority: item.Priority,
        Group: item.AssignedToEmpShortName,
        Reason: item.Action,
        Status: statusFormatter(item.IsCompleted),
        Policy: item.PolicyNo,
        Description: item.Description,
      };
    };

    const [changeStatusVisible, setChangeStatusVisible] = useState(false);
    const [susp, setSusp] = useState({} as ToDoItem);
    const queryClient = useQueryClient();

    const suspenseMutation = useToDoListWorkGroupChangeStatus((result, params) => {
      // invalidate tile query to refresh tile
      queryClient.invalidateQueries(GET_TODO_LIST_WG_DUE_TODAY_COUNT);

      // update suspense status in panel query data
      queryClient.setQueryData<ToDoList | undefined>(
        [TODO_LIST_WG, fetch, toDoListWorkgroupSettings?.customFilter],
        (old) => {
          const criteria = mapCustomFilterToCriteria(toDoListWorkgroupSettings?.customFilter);
          const item = old.ToDos.find((p) => p.ItemId === params.SuspId);

          if (old && item) {
            // 2 = COMPLETE, 1 = INCOMPLETE
            // remove item from list if it should not be displayed based on criteria options
            if (
              (params.Status === '2' && !criteria.IncludeBackupSuspense) ||
              (params.Status === '1' && !criteria.IncludeCCSuspense)
            ) {
              old.ToDos = old.ToDos.filter((p) => p !== item);
            } else {
              // update item in list
              old.ToDos = old.ToDos.map((p) =>
                p.ItemId === params.SuspId
                  ? { ...p, IsCompleted: params.Status === '2' ? true : false } // 2 = COMPLETE
                  : p
              );
            }

            return old;
          }
        }
      );
    });
    const handleChangeStatus = () => {
      // cancel pending panel queries
      queryClient.cancelQueries(TODO_LIST_WG);

      suspenseMutation.mutate({
        Status: susp.IsCompleted ? '1' : '2', // 2 = COMPLETE, 1 = INCOMPLETE
        SuspId: susp.ItemId,
      } as ToDoListCompleteStatusCriteria);
    };

    return (
      <>
        <DashboardPanel
          title={formatMessage({ id: 'dashboard.panel.todoListWorkgroup.title' })}
          // href for panel - to scroll to panel when clicking on corresponding tile
          id="ToDoListWorkgroup"
          // //
          data-testid="todolistwg-panel"
          columns={columns}
          data={toDoListWorkgroup.data?.ToDos || []}
          isLoading={toDoListWorkgroup.isLoading}
          initialSortedColumnKey="DueDate"
          initialSortDirection="asc"
          hasSelectedRowAction={true}
          unselectedRowActionLabel={formatMessage({
            id: 'dashboard.panel.todoListWorkgroup.complete',
          })}
          selectedRowActionLabelFn={(row) => actionStatusFormatter(row.IsCompleted)}
          onSelectedRowActionClicked={(row) => {
            setSusp(row);
            setChangeStatusVisible(true);
          }}
          filterPlaceholder={formatMessage({ id: 'dashboard.panel.dateRangeFilter.placeholder' })}
          filterOptions={filterOptions}
          initialFilterOption={initialFilter}
          emptyMessage={
            <Typography variant="disclaimer">
              <FormattedMessage id="dashboard.panel.data.empty" />
            </Typography>
          }
          onFilterChange={(selection) => {
            saveToDoListWorkgroupSettings({
              filter: selection ? selection.value : '',
              customFilter: { filter: selection ? selection.value : '' } as ToDoListCustomFilter,
            });
          }}
          options={ToDoListWorkgroupDashboardOptions}
          customFilterVisible={toDoListWorkgroupSettings?.customFilter?.filter === 'Custom'}
          optionsData={toDoListWorkgroupSettings?.customFilter}
          onRefresh={() => refetch()}
          exportColumnMapper={csvColumnMapper}
          onOptionsApply={(filter) =>
            saveToDoListWorkgroupSettings({
              filter: toDoListWorkgroupSettings?.filter,
              customFilter: filter,
            })
          }
          equatableRowKey="ItemId"
          toolTipText={formatMessage({ id: 'dashboard.panel.todoListWorkgroup.toolTipText' })}
        />
        {changeStatusVisible && (
          <ChangeStatusModal
            onClose={() => setChangeStatusVisible(false)}
            onChangeStatus={handleChangeStatus}
          />
        )}
        {suspenseMutation.isError && (
          <ChangeStatusErrorModal onClose={() => suspenseMutation.reset()} />
        )}
      </>
    );
  }
);

export default ToDoListWorkgroupDashboardPanel;
