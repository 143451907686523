export default class Configuration {
  static get apiBaseUri() {
    return `${window?.variables?.apiBaseUri}${window?.variables?.apiPath}`;
  }

  static get idpBaseUri() {
    return window?.variables?.idpBaseUri;
  }

  static get idpChangePasswordUri() {
    return (
      window?.variables?.idpBaseUri +
      window?.variables?.idpChangePasswordPath +
      window?.variables?.idpAdapterId
    );
  }

  static get idpRedirectUri() {
    return window?.variables?.idpBaseUri + window?.variables?.idpRedirectPath;
  }

  static get idpLogOutUri() {
    return window?.variables?.idpBaseUri + window?.variables?.idpLogOutPath;
  }

  static get idpForgotPasswordUri() {
    return (
      window?.variables?.idpBaseUri +
      window?.variables?.idpForgotPasswordPath +
      window?.variables?.idpAdapterId
    );
  }

  static get productId() {
    return window?.variables?.productId;
  }

  static get idpClientId() {
    return window?.variables?.idpClientId;
  }

  static get accessTokenExpiringNotificationTime() {
    return window?.variables?.accessTokenExpiringNotificationTimeInSeconds;
  }

  static get entitlementServiceId() {
    return window?.variables?.entitlementServiceId;
  }

  static get sigSessionProtocolHandlerName() {
    return window?.variables?.sigSessionProtocolHandlerName;
  }

  static get sigProtocolHandlerName() {
    return window?.variables?.sigProtocolHandlerName;
  }

  static get appBaseUri() {
    return window?.variables?.appBaseUri;
  }

  static get commSvcUri() {
    return window?.variables?.commSvcUri;
  }

  static get commSvcInstallerUri() {
    return window?.variables?.commSvcInstallerUri;
  }
  static get versionUri() {
    return window?.variables?.versionUri;
  }
}
