import { useIntl } from 'react-intl';
import { Version } from '../../../models/QuickNavInstance';

export enum Status {
  PublishedDraft,
  Published,
  UnpublishedDraft,
  Unpublished,
  Draft,
}
export const sortingLastModifiedDate = (versions: Version[]) => {
  return versions.sort((a, b) =>
    new Date(a.LastModifiedDate) > new Date(b.LastModifiedDate) ? -1 : 1
  );
};

export const useMappingStatus = (item: Status): string => {
  const { formatMessage } = useIntl();
  switch (item) {
    case Status.Published:
      return formatMessage({ id: 'quickNav.configuration.instances.published' });
    case Status.PublishedDraft:
      return formatMessage({ id: 'quickNav.configuration.instances.publisheddraft' });
    case Status.Unpublished:
      return formatMessage({ id: 'quickNav.configuration.instances.unpublished' });
    case Status.UnpublishedDraft:
      return formatMessage({ id: 'quickNav.configuration.instances.unpublisheddraft' });
    case Status.Draft:
      return formatMessage({ id: 'quickNav.configuration.instances.draft' });
  }
};
export const getVersionStatus = (versions: Version[]): Status => {
  const hasPublished = versions.some((item) => item.IsCurrentlyPublished === true);
  const hasDraft = versions.some((item) => item.IsDraft === true);
  const versionLength = versions.length;
  if (hasPublished && hasDraft) return Status.PublishedDraft;
  else if (hasPublished && !hasDraft) return Status.Published;
  else if (versionLength === 1 && hasDraft) return Status.Draft;
  else if (!hasPublished && versionLength > 1 && hasDraft) return Status.UnpublishedDraft;
  else return Status.Unpublished;
};
