import React, { useState } from 'react';
import { VertaFormik, LoadingIndicator } from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import customerValidationSchema from './customerValidation/CustomerValidationSchema';
import CreateCustomerForm from './CreateCustomerForm';
import CustomerPageLayout from '../common/page-layout/DefaultPageLayout';
import { CustomerDropdowns } from '../../models/CustomerDropdowns';
import { TypeOfBusinessProvider } from './TypeOfBusinessProvider';
import { useLocaleContext } from '../localization/LocaleContext';
import { NewCustomer } from '../../models/NewCustomer';
import { PhoneNumber } from '../../models/PhoneNumber';
import { Emails } from '../../models/Emails';
import { LocalizedLeaveWithoutSaveGuard } from '../common/localizedLeaveWithoutSaveGuard/LocalizedLeaveWithoutSaveGuard';
import { useCustomerDropdowns } from '../common/hooks/useCustomerDropdown';

const CreateCustomer = ({ createCustomer }) => {
  const [validationSchema, validationSchemaValues] = useState({});
  const { formatMessage } = useIntl();
  const { locale } = useLocaleContext();
  const history = useHistory();
  const [initialValues, updateInitialValues] = useState<NewCustomer>({
    typeOfBusiness: '',
    customerType: '',
    language: locale,
    division: '',
    department: '',
    personalInformationConsent: null,
    consentForOtherUse: null,
    postalCode: '',
    city: '',
    provinceCode: '',
    accountExecutive1: '',
    accountRepresentative1: '',
    phones: [],
    emails: [],
    secondaryContact: null,
  });

  const generateMandatoryContactMethod = (
    customerDropdowns: CustomerDropdowns,
    customer: NewCustomer
  ) => {
    customer.phones = customerDropdowns.TypeOfPhoneNumbers.filter((x) =>
      customerDropdowns.MandatoryContactMethods?.includes(x.Key)
    ).map((x) => {
      return {
        phoneNumber: '',
        type: x.Key,
        notApplicable: false,
      } as PhoneNumber;
    });

    customer.emails = customerDropdowns.TypeOfEmails.filter((x) =>
      customerDropdowns.MandatoryContactMethods?.includes(x.Key)
    ).map((x) => {
      return {
        emailAddress: '',
        type: x.Key,
        notApplicable: false,
        calsDateOfConsent: '',
        cals: '',
      } as Emails;
    });
  };

  const dropdowns = useCustomerDropdowns({
    onSuccess: (data) => {
      const updatedInitialValues = initialValues;
      updatedInitialValues.typeOfBusiness = data.DefaultCustomerType ?? 'P';
      updatedInitialValues.customerType = data.DefaultCustomerStatus ?? 'C';
      updatedInitialValues.division = data.DefaultDivision ?? '';
      updatedInitialValues.department = data.DefaultDepartment ?? '';
      updatedInitialValues.accountExecutive1 = data.DefaultAccountExec ?? '';
      updatedInitialValues.accountExecutive2 = data.DefaultAccountExec2;
      updatedInitialValues.accountRepresentative1 = data.DefaultAccountRep ?? '';
      updatedInitialValues.accountRepresentative2 = data.DefaultAccountRep2;
      generateMandatoryContactMethod(data, updatedInitialValues);

      updateInitialValues(updatedInitialValues);
      validationSchemaValues(customerValidationSchema(formatMessage, data));
    },
    refetchOnWindowFocus: false,
  });

  return dropdowns.isFetching ? (
    <LoadingIndicator />
  ) : (
    <CustomerPageLayout
      marginTop="0"
      title={<FormattedMessage id="customer.createCustomer.title" />}
    >
      <VertaFormik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={createCustomer}
      >
        {({ dirty, isSubmitting }) => (
          <React.Fragment>
            <TypeOfBusinessProvider defaultTypeOfBusiness={dropdowns.data?.DefaultCustomerType}>
              {dropdowns?.data && <CreateCustomerForm dropdowns={dropdowns.data} />}
            </TypeOfBusinessProvider>
            <LocalizedLeaveWithoutSaveGuard
              shouldBlockNavigation={() => {
                return dirty;
              }}
              when={dirty && !isSubmitting}
              navigate={(path) => {
                history.push(path);
              }}
            />
          </React.Fragment>
        )}
      </VertaFormik>
    </CustomerPageLayout>
  );
};

export default CreateCustomer;
