import escapeRegExp from 'lodash/escapeRegExp';
import capitalize from 'lodash/capitalize';
// import { Theme } from 'src/theme/model';
import { DataTableColumn } from './DataTableColumn';
// import { cellPresets } from 'alloy-foundation/types/components/Table/TableCell';

export function prettyPrint<Data>(key?: keyof Data) {
  if (!key) return '';
  let header = String(key);

  // "user-name" -> "user name"
  // "user_name" -> "user name"
  header = header.replace(/[-_]/, ' ');

  // "userName" -> "user name"
  header = header.replace(/(.+)([a-z])([A-Z])(.*)/g, (_match, before, lower, upper, after) => {
    return `${before}${lower} ${upper}${after}`;
  });

  // "user name" -> "User Name"
  header = header.split(' ').map(capitalize).join(' ');

  return header;
}

/**
 * Creates a lazy-initialized regexp that can be safely reused
 */
export function createMatcher(target: string) {
  let regex: RegExp;

  return (query: string) => {
    regex = regex || new RegExp(escapeRegExp(target), 'gi');
    regex.lastIndex = 0;
    return regex.test(query);
  };
}

export function styleValueInPixels(input: string | number) {
  if (typeof input === 'number') return input;

  const [, rems] = /([\d.]+)rem/g.exec(input);
  const inPixels = parseFloat(rems) * 16;
  return inPixels;
}

export function computeColumnWidths<Data>({
  columns,
  width,
}: {
  columns: DataTableColumn<Data>[];
  width: number;
}) {
  // JOE: Disabled presets - doesn't work without importing a lot of Alloy stuff and not really useful anyway.
  // const presets = cellPresets();
  const columnsWithPresetsApplied = columns.map((column) => {
    // const preset = presets[column.padding || 'default'];

    return {
      ...column,
      // explicit width wins:
      width: column.width, // || preset.width,
    };
  });
  const fixedWidthColumns = columnsWithPresetsApplied.filter(
    (it) => typeof it.width !== 'undefined'
  );
  const flexibleColumns = columnsWithPresetsApplied.filter(
    (it) => typeof it.width === 'undefined' && typeof it.minWidth !== 'number'
  );
  const possiblyFlexibleColumns = columnsWithPresetsApplied.filter(
    (it) => typeof it.width === 'undefined' && typeof it.minWidth === 'number'
  );
  const occupiedWidth = fixedWidthColumns.reduce((combinedWidth, column) => {
    return combinedWidth + styleValueInPixels(column.width);
  }, 0);
  let availableWidth = width - occupiedWidth;

  let flexCount = flexibleColumns.reduce((combinedFlex, column) => {
    return combinedFlex + column.flex;
  }, 0);

  possiblyFlexibleColumns.forEach((column) => {
    const potentialFlexCount = flexCount + column.flex;
    const potentialWidthPerFlex = availableWidth / potentialFlexCount;

    if (potentialWidthPerFlex * column.flex < column.minWidth) {
      column.flex = undefined;
      column.width = column.minWidth;
      availableWidth -= column.width;
      fixedWidthColumns.push(column);
      return;
    }

    flexCount = potentialFlexCount;
    flexibleColumns.push(column);
  });

  const widthPerFlex = availableWidth / flexCount;
  const widths: Partial<Record<keyof Data | string, number>> = {};

  if (width > 0) {
    flexibleColumns.forEach((flexibleColumn) => {
      widths[flexibleColumn.key] = Math.max(
        widthPerFlex * flexibleColumn.flex,
        flexibleColumn.minWidth || 0
      );
    });

    fixedWidthColumns.forEach((fixedColumn) => {
      widths[fixedColumn.key] = Math.max(
        styleValueInPixels(fixedColumn.width),
        fixedColumn.minWidth || 0
      );
    });
  }

  return widths;
}
