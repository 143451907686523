import { TableCell, TableHead, TableRow, TableSortLabel } from 'alloy-foundation';
import { KeyboardEvent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Order } from '../../common/tableUtilities';
import { ReassignSortableKeys } from './ReassignQuickNavGrid';

type ContactTableHeadProps = {
  order: Order;
  orderBy: string;
  onRequestSort: (event: MouseEvent | KeyboardEvent, property: keyof ReassignSortableKeys) => void;
};

interface ReassignQuickNavTableHeadCell {
  id: keyof ReassignSortableKeys;
  label: ReactNode;
}

const contactTableHeadCells: readonly ReassignQuickNavTableHeadCell[] = [
  {
    id: 'AssignedToName',
    label: <FormattedMessage id="dashboard.manager.panel.columns.assignedto" />,
  },
  {
    id: 'CurrentTaskDueDate',
    label: <FormattedMessage id="dashboard.manager.panel.columns.stepDueDate" />,
  },
  {
    id: 'CurrentTaskTitle',
    label: <FormattedMessage id="dashboard.manager.panel.columns.currentStep" />,
  },
  {
    id: 'ClientName',
    label: <FormattedMessage id="dashboard.manager.panel.columns.customer" />,
  },
  {
    id: 'Title',
    label: <FormattedMessage id="dashboard.manager.panel.columns.type" />,
  },
];

const ContactTableHead = ({ order, orderBy, onRequestSort }: ContactTableHeadProps) => {
  const createSortHandler = (property: keyof ReassignSortableKeys) => {
    return (event: MouseEvent | KeyboardEvent) => {
      onRequestSort(event, property);
    };
  };

  return (
    <TableHead>
      <TableRow>
        {contactTableHeadCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <TableSortLabel
              onClick={createSortHandler(headCell.id)}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>
          <FormattedMessage id="dashboard.manager.panel.columns.assignto" />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ContactTableHead;
