import { DateTime } from 'luxon';

export enum DateFilter {
  AmountOfDays,
  DateRange,
  ShowAll,
}

export interface DateFilterSettimgs {
  filter: DateFilter;
  fromDays?: string;
  toDays?: string;
  fromDate?: Date;
  toDate?: Date;
}

export const defaultDayRange = Object.freeze({
  from: '0',
  to: '7',
});

export const defaultDateRange = Object.freeze({
  from: DateTime.now().toJSDate(),
  to: DateTime.now().plus({ days: 7 }).toJSDate(),
});

export const defaultFilterSettings = {
  filter: DateFilter.AmountOfDays,
  fromDays: defaultDayRange.from,
  toDays: defaultDayRange.to,
  fromDate: defaultDateRange.from,
  toDate: defaultDateRange.to,
};
