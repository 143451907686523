import React from 'react';
import { useIntl } from 'react-intl';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import { PssCustomer } from '../../../../models/PssCustomer';
import Divider from '../../../common/divider/Divider';
import Header from '../../../common/header/Header';
import { useTypeOfBusiness } from '../../TypeOfBusinessProvider';
import CommercialCustomerDetails from './CommercialCustomerDetails';
import PersonalCustomerDetails from './PersonalCustomerDetails';

type CustomerDetailsProps = {
  parentNamespace?: string;
  dropdowns: CustomerDropdowns;
  customer?: PssCustomer;
};

const CustomerDetails = (props: CustomerDetailsProps) => {
  const { typeOfBusiness } = useTypeOfBusiness();
  const { formatMessage } = useIntl();

  return (
    <React.Fragment>
      {props.customer ? (
        <React.Fragment>
          {props.customer.IsPersonalCust ? (
            <PersonalCustomerDetails
              dropdowns={props.dropdowns}
              parentNamespace={props.parentNamespace ?? ''}
              showSecondaryFields={true}
            />
          ) : (
            <CommercialCustomerDetails
              dropdowns={props.dropdowns}
              parentNamespace={props.parentNamespace ?? ''}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header title={formatMessage({ id: 'customer.details.title' })} />
          {typeOfBusiness === 'P' ? (
            <PersonalCustomerDetails dropdowns={props.dropdowns} />
          ) : (
            <CommercialCustomerDetails dropdowns={props.dropdowns} />
          )}
        </React.Fragment>
      )}
      <Divider pt="none" pb="none" ml="none" mr="none" />
    </React.Fragment>
  );
};

export default CustomerDetails;
