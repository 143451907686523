import React from 'react';
import { ErrorPage } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { Route } from 'react-router-dom';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';

const AdminRoute = ({ component: Component, ...restOfProps }) => {
  const { getIsCustomerAdmin } = useProductInfoProvider();
  const isCustomerAdmin = getIsCustomerAdmin();
  const { formatMessage } = useIntl();
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isCustomerAdmin ? (
          <Component {...props} />
        ) : (
          <ErrorPage
            header={formatMessage({ id: 'errorPages.common.header' })}
            description={formatMessage({ id: 'errorPages.403.area.message' })}
            subHeading={formatMessage({ id: 'errorPages.403.error' })}
          />
        )
      }
    />
  );
};

export default AdminRoute;
