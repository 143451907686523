import { useQuery } from 'react-query';
import { ToDoListOptionsDomainData } from '../../../../models/ToDo';
import TodoListService from '../../../../services/todoList/TodoListService';

export const useToDoListOptionsDomainData = (
  onSuccess: (data: ToDoListOptionsDomainData) => void
) => {
  const toDoListOptionsDomainData = useQuery(
    'toDoListOptionsDomainData',
    () => TodoListService.getOptionsDomainData(),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );

  return toDoListOptionsDomainData;
};
