import baseRequests from '../../api/baseRequests';

const baseURL = 'api/State';
const appId = '4484DFEA-C621-477B-88CC-CE77B90A2B30';
const ApplicationStateService = {
  getAppSetting: (key: string): any =>
    baseRequests.get(`${baseURL}/GetApplicationState?appName=${appId}&key=${key}`),
  details: (id: string) => baseRequests.get(`${baseURL}/${id}`),
  updateAppState: (body: any) =>
    baseRequests.post(`${baseURL}/Set`, { appName: appId, state: body }),
};

export default ApplicationStateService;
