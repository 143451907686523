import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PingOnlyAuthProvider } from 'ui-auth-client';
import { ReactQueryDevtools } from 'react-query/devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './components/layout/index.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import { DefaultTheme } from 'alloy-foundation';
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';
import AphroditeInterface from 'react-with-styles-interface-aphrodite';
import { IntlProvider } from 'react-intl';
import { LocaleContextProvider, useLocaleContext } from './components/localization/LocaleContext';
import App from './components/layout/App';
import BottomActionBarProvider from './components/common/application-layout/BottomActionBarProvider';
import { DrawerProvider } from './components/drawer/DrawerProvider';
import { Locale } from './components/localization/Locale';
import en from './translations/en/lang';
import fr from './translations/fr/lang';
import { LogFormProvider } from './components/common/providers/LogFormProvider';
import { ProductInfoProvider } from './components/productInfo/ProductInfoProvider';

const queryClient = new QueryClient();
const InnerIntlProvider = ({ children }) => {
  const { locale } = useLocaleContext();
  const messages = {
    [`${Locale.EnCa}`]: en,
    [`${Locale.FrCa}`]: fr,
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]} defaultLocale="en-CA">
      {children}
    </IntlProvider>
  );
};

ReactDOM.render(
  <WithStylesContext.Provider
    value={{
      stylesInterface: AphroditeInterface,
      stylesTheme: DefaultTheme,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter basename="/">
          <PingOnlyAuthProvider>
            <LocaleContextProvider>
              <InnerIntlProvider>
                <BottomActionBarProvider>
                  <DrawerProvider>
                    <ProductInfoProvider>
                      <LogFormProvider>
                        <App />
                      </LogFormProvider>
                    </ProductInfoProvider>
                  </DrawerProvider>
                </BottomActionBarProvider>
              </InnerIntlProvider>
            </LocaleContextProvider>
          </PingOnlyAuthProvider>
        </BrowserRouter>
      </DndProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </WithStylesContext.Provider>,
  document.getElementById('root')
);
