import React, { createRef, Fragment, ReactNode, useEffect, useState } from 'react';
import { ErrorMessage, FieldArray, getIn, useFormikContext, ArrayHelpers } from 'formik';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import {
  Group,
  ButtonGroup,
  FormField,
  Button,
  Row,
  Column,
  FormActionButtons,
  Typography,
  Modal,
  Flex,
} from 'alloy-foundation';

export type FormListFieldArrayPhrases = {
  addButton: ReactNode;
  deleteAriaLabel: string;

  confirmDeleteModal: {
    continueText: ReactNode;
    cancelText: ReactNode;
    title: ReactNode;
    bodyText: ReactNode;
  };
};

export type RenderRowProps = {
  namespace: string;
  index: number;
  arrayHelpers: ArrayHelpers;
};

export type FormListFieldArrayProps = {
  /** When adding an item to the field array, this object populates the list with the initial object state. */
  listItemDefault: Record<string, unknown>;

  /** The render function that provides state from the FieldArray to render the row fields. */
  renderRow: (props: RenderRowProps) => ReactNode;

  /** The path to the array fields that will be updated. */
  namespace: string;

  /** If false, a modal does not appear when you delete a row item. */
  confirmDelete?: boolean;

  /** An object of phrases that can be overwritten in this component. */
  phrases?: Partial<FormListFieldArrayPhrases>;

  /** A function that is called when an item is deleted. */
  onListItemDelete?: (item: Record<string, unknown>) => void;

  addButtonPosition?: 'top' | 'bottom';

  canDelete?: (index: number) => boolean;

  maxItems?: number;
};

export default function SingleLineFormListArray({
  confirmDelete = true,
  listItemDefault,
  namespace,
  onListItemDelete = noop,
  canDelete,
  phrases = {
    addButton: 'Add Item',
    deleteAriaLabel: 'Delete item',
    confirmDeleteModal: {
      continueText: 'Delete',
      cancelText: 'Cancel',
      title: 'Are you sure you want to delete this item?',
      bodyText:
        'This action cannot be undone, and you will lose any information related to this item.',
    },
  },
  renderRow,
  addButtonPosition = 'top',
  maxItems,
}: FormListFieldArrayProps) {
  let openTimeout: number;
  let refocusTimeout: number;

  const { values, errors } = useFormikContext();
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    return () => {
      if (openTimeout) {
        window.clearTimeout(openTimeout);
      }

      if (refocusTimeout) {
        window.clearTimeout(refocusTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const numberOfRows = getIn(values, namespace).length;

  const rowRefs = React.useRef([]);
  const addButton = (arrayHelpers) => {
    return getIn(values, namespace).length < (maxItems ?? Number.MAX_SAFE_INTEGER) ? (
      <ButtonGroup>
        <FormField
          invalid={
            !isEmpty(getIn(errors, namespace)) &&
            getIn(errors, namespace) &&
            typeof getIn(errors, namespace) === 'string'
          }
          label=""
          hideLabel={true}
          errorMessage={<ErrorMessage name={namespace} />}
        >
          <Button
            disabled={false}
            variant="secondary"
            onClick={() => {
              if (addButtonPosition === 'top') {
                // Add item at the start of the list. Default behavior base on FormFieldListArray
                arrayHelpers.insert(0, listItemDefault);
              } else {
                // Add item at the end of list.
                arrayHelpers.push(listItemDefault);
              }
            }}
          >
            {phrases.addButton}
          </Button>
        </FormField>
      </ButtonGroup>
    ) : null;
  };

  if (rowRefs.current.length !== numberOfRows) {
    // add or remove refs
    rowRefs.current = Array(numberOfRows)
      .fill((_) => _)
      .map((_, i) => rowRefs.current[i] || createRef());
  }

  return (
    <FieldArray
      name={namespace}
      render={(arrayHelpers) => {
        return (
          <Group spacing="tiny">
            {addButtonPosition === 'top' && addButton(arrayHelpers)}
            {getIn(values, namespace).map((item, index) => {
              const confirmRemoveItem = (removedItem) => {
                setConfirmDeleteModalVisible(true);
                setItemToDelete(removedItem);
              };
              return (
                <Fragment key={index}>
                  <Row ref={rowRefs.current[index]}>
                    {renderRow({ namespace, index, arrayHelpers })}
                    {(!canDelete || canDelete(index)) && (
                      <Column md={1}>
                        <Flex alignItems="flex-end" height="4.375rem">
                          <Button
                            onClick={() => {
                              if (confirmDelete) {
                                confirmRemoveItem(index);
                              } else {
                                onListItemDelete(getIn(values, `${namespace}[${index}]`));
                                arrayHelpers.remove(index);
                              }
                            }}
                            size="small"
                            icon="delete"
                            aria-label={phrases.deleteAriaLabel}
                            data-testid={`${namespace}${index}-delete`}
                            variant="link"
                          />
                        </Flex>
                      </Column>
                    )}
                  </Row>
                  {isConfirmDeleteModalVisible && itemToDelete === index ? (
                    <Modal
                      onClose={() => {
                        setConfirmDeleteModalVisible(false);
                        setItemToDelete(null);
                      }}
                      footer={
                        <FormActionButtons
                          continueText={phrases.confirmDeleteModal.continueText}
                          cancelText={phrases.confirmDeleteModal.cancelText}
                          onContinue={() => {
                            onListItemDelete(getIn(values, `${namespace}[${index}]`));
                            arrayHelpers.remove(index);
                            setConfirmDeleteModalVisible(false);
                          }}
                          onCancel={() => {
                            setConfirmDeleteModalVisible(false);
                          }}
                          endAlign={true}
                        />
                      }
                      title={phrases.confirmDeleteModal.title}
                    >
                      <Typography variant="body">{phrases.confirmDeleteModal.bodyText}</Typography>
                    </Modal>
                  ) : null}
                </Fragment>
              );
            })}
            {addButtonPosition === 'bottom' && addButton(arrayHelpers)}
          </Group>
        );
      }}
    />
  );
}
