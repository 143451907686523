import { DateTime } from 'luxon';
import TodoListDueTodayRequestPayload from '../../../models/requestPayloads/TodoListDueTodayRequestPayload';
import ExpiringPoliciesRequestPayload from '../../../models/requestPayloads/ExpiringPoliciesRequestPayload';

export const TodoListPayload: TodoListDueTodayRequestPayload = {
  DaysFromToday: 0,
  DueDateFrom: '',
  DueDateTo: '',
  Status: 1,
  Action: '',
  Priority: null,
  IncludeBackupSuspense: true,
  IncludeCCSuspense: true,
  MaxRowCount: 5000,
  Filters: 'Due Today',
  Page: 1,
  Start: 0,
  Limit: 1,
  SortBy: [
    {
      property: 'DueDate',
      direction: 'ASC',
    },
  ],
};

export const ExpiringPoliciesPayload: ExpiringPoliciesRequestPayload = {
  DateFrom: new Date(
    DateTime.now().year,
    // Datetime.now().month returns the current month as one-based index, however the month argument in new Date() requires a zero-based index so we subtract 1
    DateTime.now().month - 1,
    DateTime.now().day
  ).toISOString(),
  // uses 121 days on purpose - will then remove 3 milliseconds to inckude all of the 120th day, to match panel
  DateTo: DateTime.utc(DateTime.now().year, DateTime.now().month, DateTime.now().day)
    .plus({ days: 121 })
    .minus({ milliseconds: 3 })
    .toString(),
  DisplayDownloadedPolicies: true,
  IncludeBackupPolicies: false,
  Stage: null,
  Status: null,
};
