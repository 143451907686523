import {
  Button,
  Column,
  Flex,
  FormInput,
  Group,
  Row,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from 'alloy-foundation';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useBottomActionBarDispatch } from '../../../common/application-layout/BottomActionBarProvider';
import { useVisible } from '../../../common/hooks/useVisible';
import LocalizedLeaveWithoutSaveModal from '../../../common/localizedLeaveWithoutSaveModal/LocalizedLeaveWithoutSaveModal';
import { DESCRIPTION, TITLE } from './fieldNames';
import InstanceEditBottomBar from './InstanceEditBottomBar';
import { Step, Version } from '../../../../models/QuickNavInstance';
import { DraggableStepItem } from './DraggableStepItem';

export class StepType {
  static readonly LinkingStep = 'LinkingStep';
  static readonly SigStep = 'SigStep';
  static readonly SmartflowStep = 'SmartflowStep';
}
const InstanceEditForm = ({
  instanceId,
  preventSaveDraft,
}: {
  instanceId: number;
  preventSaveDraft: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { handleSubmit, isSubmitting, resetForm, dirty, values, setFieldValue, errors } =
    useFormikContext<Version>();
  const { setBottomActionBar } = useBottomActionBarDispatch();

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragListItem = values.Steps[dragIndex];
      setFieldValue(
        'Steps',
        update(values.Steps, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragListItem],
          ],
        })
      );
    },
    // eslint-disable-next-line
    [values.Steps, setFieldValue]
  );
  const generateNewStep = useCallback(() => {
    const lastStep = {
      ...values.Steps[values.Steps.length - 1],
    };
    lastStep.Id = Math.max(...values.Steps.map((x) => x.Id)) + 1;
    lastStep.FormName = 'Check me!';
    lastStep.Title = '';
    lastStep.RefId = Math.max(...values.Steps.map((x) => x.RefId)) + 1;
    lastStep.SequenceNumber += 1;
    lastStep.IsExcludable = true;
    lastStep.IsExcluded = false;
    lastStep.IsUserDefined = false;
    lastStep.UrlPlaceHolder = '';
    lastStep.Type = 'smartflow';
    lastStep.Url = null;
    lastStep.DueDateModifier = 0;
    values.Steps.push(lastStep);
    setFieldValue('Steps', values.Steps);
  }, [setFieldValue, values]);
  const history = useHistory();
  const {
    visible: localizedLeaveWithouSaveGuardVisible,
    SetVisible: localizedLeaveWithouSaveGuardSetVisible,
  } = useVisible();

  const goBack = () => {
    history.push(`/quicknav/configure/${instanceId}`);
  };
  const getActiveSteps = (steps: Step[]): Step[] => {
    return steps.filter((item) => !item.IsExcluded);
  };
  const getDays = (version: Version) => {
    const dueDateModifier = Math.max(
      ...getActiveSteps(version?.Steps ?? []).map((i) => i.DueDateModifier)
    );
    return dueDateModifier;
  };
  useEffect(() => {
    setBottomActionBar(
      <InstanceEditBottomBar
        canSave={!isSubmitting}
        numberWorkSteps={getActiveSteps(values?.Steps ?? []).length}
        numberDays={getDays(values)}
        preventSaveDraft={preventSaveDraft || !dirty}
        handleCancel={() => {
          if (dirty) {
            localizedLeaveWithouSaveGuardSetVisible(true);
          } else {
            goBack();
          }
        }}
        handleSaveDraft={(formData) => {
          setFieldValue('DueDateModifier', getDays(values));
          setFieldValue('IsSaveDraft', true);
          handleSubmit(formData);
        }}
        handleSave={(formData) => {
          setFieldValue('DueDateModifier', getDays(values));
          setFieldValue('IsSaveDraft', false);
          handleSubmit(formData);
        }}
      />
    );
    return () => setBottomActionBar(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formatMessage,
    isSubmitting,
    localizedLeaveWithouSaveGuardSetVisible,
    setBottomActionBar,
    handleSubmit,
    dirty,
    values,
    values?.Steps.length,
    preventSaveDraft,
  ]);
  return (
    <div style={{ position: 'relative' }}>
      <Group spacing="small">
        <Row>
          <Column sm={3}>
            <FormInput
              label={formatMessage({ id: 'quickNav.configuration.instances.edit.name' })}
              required
              name={TITLE}
              data-testid="InstanceTitle"
            />
          </Column>
          <Column sm={9}>
            <FormInput label="Description" name={DESCRIPTION} />
          </Column>
        </Row>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {formatMessage({ id: 'quickNav.configuration.instances.edit.step' })}
              </TableCell>
              <TableCell>
                {formatMessage({ id: 'quickNav.configuration.instances.edit.stepname' })}
              </TableCell>
              <TableCell>
                {formatMessage({ id: 'quickNav.configuration.instances.edit.link' })}
              </TableCell>
              <TableCell>
                <Flex justifyContent="center">
                  {formatMessage({ id: 'quickNav.configuration.instances.edit.duedate' })}
                  <Tooltip
                    content={
                      <Typography variant="bodyTiny">
                        {formatMessage({
                          id: 'quickNav.configuration.instances.edit.days.tooltip',
                        })}
                      </Typography>
                    }
                  >
                    <Button data-testid="action-tooltip" icon="info" variant="link"></Button>
                  </Tooltip>
                </Flex>
              </TableCell>
              <TableCell>
                {formatMessage({ id: 'quickNav.configuration.instances.edit.active' })}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.Steps.map((item, index) => {
              return (
                <DraggableStepItem
                  moveItem={moveItem}
                  index={index}
                  key={item.Id}
                  item={item}
                  id={values.Steps[index]?.SequenceNumber}
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                ></DraggableStepItem>
              );
            })}
          </TableBody>
        </Table>
        <Button icon="addNew" data-testid="action-addstep" onClick={() => generateNewStep()}>
          {formatMessage({ id: 'quickNav.configuration.instances.edit.addStep' })}
        </Button>
        {localizedLeaveWithouSaveGuardVisible && (
          <LocalizedLeaveWithoutSaveModal
            onConfirmNavigationClick={async () => {
              await resetForm();
              goBack();
            }}
            onClose={() => {
              localizedLeaveWithouSaveGuardSetVisible(false);
            }}
          />
        )}
      </Group>
    </div>
  );
};

export default InstanceEditForm;
