import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  ContentHeader,
  DropdownButtonProvider,
  DropdownLinkButton,
  DropdownList,
  DropdownListItem,
  Flex,
  Link,
  Spacer,
  Typography,
} from 'alloy-foundation';
import { StringColumn } from '../../common/custom-datatable-columns/StringColumn';
import DefaultPageLayout from '../../common/page-layout/DefaultPageLayout';
import { DataTableColumn } from '../../data-table/DataTableColumn';
import {
  useCheckDuplicatedName,
  useDeleteInstance,
  useInstance,
  usePublishDraft,
  useUnpublishInstance,
} from './useInstance';
import { Instance, Version } from '../../../models/QuickNavInstance';
import { NumberColumn } from '../../common/custom-datatable-columns/NumberColumn';
import { DateColumn } from '../../common/custom-datatable-columns/DateColumn';
import QuickNavModal, { ModalProps } from './QuickNavModal';
import { useDataTable, useDataTableRows } from '../../data-table/useDataTable';
import LocalizedDataTable from '../../common/localizedDataTable/LocalizedDataTable';
import ExpandedVersionInfo from './ExpandedVersionInfo';
import {
  sortingLastModifiedDate,
  useMappingStatus,
  getVersionStatus,
  Status,
} from './quickNavUtils';
import DuplicatedModal from './DuplicatedModal';
import { NameCheckPayload } from '../../../models/putPayloads/NameCheckPayload';
import { withLayoutShell } from '../../common/hoc/withLayoutShell';
type InstancesParams = {
  id: string;
};

const InstancesGrid = () => {
  const { id } = useParams<InstancesParams>();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [duplicatedModalVisible, setDuplicatedModalVisible] = useState<boolean>();
  const instanceList = useInstance(id, {
    refetchOnWindowFocus: false,
  });
  const [modalData, setModalData] = useState<ModalProps>(null);
  const { formatMessage } = useIntl();
  const [draftRow, setDraftRow] = useState(null);

  const unpublishMutation = useUnpublishInstance({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['QuickNavTemplates'] });
      queryClient.invalidateQueries(['quicknav-instance', id]);
      setModalData(null);
    },
  });
  const deleteMutation = useDeleteInstance({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['QuickNavTemplates'] });
      queryClient.invalidateQueries(['quicknav-instance', id]);
      setModalData(null);
    },
  });
  const publishDraftMutation = usePublishDraft({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['QuickNavTemplates'] });
      queryClient.invalidateQueries(['quicknav-instance', id]);
      setModalData(null);
    },
  });
  const handleUnpublish = (instanceId: string, instanceName: string) => {
    setModalData({
      Name: instanceName ?? '',
      Title: formatMessage({ id: 'quickNav.configuration.instances.unpublish.title' }),
      SubTitle: formatMessage({ id: 'quickNav.configuration.instances.unpublish.subtitle.pt1' }),
      SubTitle2: formatMessage({ id: 'quickNav.configuration.instances.unpublish.subtitle.pt2' }),
      confirmText: formatMessage({ id: 'quickNav.configuration.instances.unpublish.button' }),
      onCancel: () => {
        setModalData(null);
      },
      onConfirm: () => {
        unpublishMutation.mutate(instanceId);
      },
    });
  };
  const handleDelete = (instanceId: string, isInstance: boolean) => {
    setModalData({
      Name: null,
      Title: formatMessage({ id: 'quickNav.configuration.instances.delete.title' }),
      SubTitle: formatMessage({ id: 'quickNav.configuration.instances.delete.subtitle' }),
      confirmText: formatMessage({ id: 'quickNav.configuration.instances.delete.button' }),
      onCancel: () => {
        setModalData(null);
      },
      onConfirm: () => {
        deleteMutation.mutate({ id: instanceId, isInstance });
      },
    });
  };

  const handlePublishDraft = (draft) => {
    setModalData({
      Name: draft.Title,
      Title: formatMessage({ id: 'quickNav.configuration.instances.publish.title' }),
      SubTitle: formatMessage({ id: 'quickNav.configuration.instances.publish.subtitle.pt1' }),
      SubTitle2: formatMessage({ id: 'quickNav.configuration.instances.publish.subtitle.pt2' }),
      confirmText: formatMessage({ id: 'quickNav.configuration.instances.publish.button' }),
      onCancel: () => {
        setModalData(null);
      },
      onConfirm: () => {
        publishDraftMutation.mutate(draft);
      },
    });
  };

  const checkDuplicateMutation = useCheckDuplicatedName({
    onSuccess(data) {
      if (data === false && draftRow) {
        handlePublishDraft(draftRow);
      } else if (draftRow) {
        setDuplicatedModalVisible(true);
      }
    },
  });

  const handlePublish = (row: Instance) => {
    const draft = row.Versions.find((element) => {
      return element.IsDraft === true;
    });
    setDraftRow(draft);
    const payload = {
      Type: draft.ProgrammaticName,
      ShortTitle: draft.ShortTitle,
      DisplayName: draft?.Title,
    } as NameCheckPayload;
    checkDuplicateMutation.mutate(payload);
  };

  const columns: DataTableColumn<Instance>[] = [
    new NumberColumn<Instance>({
      key: 'Version',
      header: formatMessage({ id: 'quickNav.configuration.instances.version' }),
      align: 'left',
      decimals: 0,
      getValue: (row) => row?.Versions.length,
      Cell: function DescCell({ row }) {
        return (
          <Typography variant="bodySm" noWrap={true} color="grayDark">
            {row?.Versions.length ?? ''}
          </Typography>
        );
      },
      flex: 1,
    }),
    new StringColumn<Instance>({
      key: 'Name',
      header: formatMessage({ id: 'customer.search.name' }),
      getValue: (row) => {
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        return sorted[0]?.IsDraft && sorted?.length > 1 ? sorted[1]?.Title : sorted[0]?.Title;
      },
      Cell: function DescCell({ row }) {
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        return (
          <>
            <Typography variant="bodySm" noWrap={true} fontWeight="bold" color="grayDark">
              {sorted[0]?.IsDraft && sorted?.length > 1 ? sorted[1]?.Title : sorted[0]?.Title ?? ''}
            </Typography>
            <Typography variant="bodySm" noWrap={true} fontWeight="normal" color="grayMedium">
              {useMappingStatus(getVersionStatus(row?.Versions))}
            </Typography>
          </>
        );
      },
      flex: 1,
    }),
    new StringColumn<Instance>({
      key: 'Description',
      header: formatMessage({ id: 'activityList.Description' }),
      getValue: (row) => {
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        return sorted[0]?.IsDraft && sorted?.length > 1
          ? sorted[1]?.Description
          : sorted[0]?.Description;
      },
      flex: 2,
    }),
    new DateColumn<Instance>({
      key: 'LastModified',
      header: 'LastModified',
      hide: true,
      getValue: (row) => {
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        return new Date(sorted[0]?.LastModifiedDate).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
      },
      getSortValue: (row) => {
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        return new Date(sorted[0]?.LastModifiedDate).getTime();
      },
      flex: 2,
    }),
    new StringColumn<Instance>({
      key: '',
      header: '',
      align: 'center',
      width: 100,
      padding: 'none',
      sortable: false,
      Cell: function ActionCell({ row, rowIndex }) {
        const status = getVersionStatus(row?.Versions ?? []);
        const sorted = sortingLastModifiedDate(row?.Versions ?? []);
        const instanceName =
          sorted[0]?.IsDraft && sorted?.length > 1 ? sorted[1]?.Title : sorted[0]?.Title;
        const copyId = sorted[0]?.IsDraft && sorted?.length > 1 ? sorted[1]?.Id : sorted[0]?.Id;
        const draft = row?.Versions.find((element) => {
          return element.IsDraft === true;
        });
        return (
          <Spacer paddingTop="small">
            <DropdownButtonProvider>
              <DropdownLinkButton
                data-testid={`actionButton-${rowIndex}`}
                aria-label={`actionButton-${rowIndex}`}
              />
              {renderOptions(status, row, draft, instanceName, copyId)}
            </DropdownButtonProvider>
          </Spacer>
        );
      },
    }),
  ];
  const renderOptions = (status: Status, row, draft, instanceName, copyId) => {
    switch (status) {
      case Status.PublishedDraft:
        return (
          <DropdownList>
            <DropdownListItem
              onSelect={() => {
                handlePublish(row);
              }}
            >
              {formatMessage({ id: 'quickNav.configuration.instances.publish.draft' })}
            </DropdownListItem>
            <DropdownListItem onSelect={() => handleDelete(draft?.Id.toString(), false)}>
              {formatMessage({ id: 'quickNav.configuration.instances.delete.draft' })}
            </DropdownListItem>
            <DropdownListItem onSelect={() => handleUnpublish(row?.Id.toString(), instanceName)}>
              {formatMessage({ id: 'quickNav.configuration.instances.unpublish' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => history.push(`/quicknav/configure/instance/${copyId}`)}
            >
              {formatMessage({ id: 'quickNav.configuration.instances.copy' })}
            </DropdownListItem>
            {row?.IsVariation && (
              <DropdownListItem onSelect={() => handleDelete(row?.Id.toString(), true)}>
                {formatMessage({ id: 'quickNav.configuration.instances.delete' })}
              </DropdownListItem>
            )}
          </DropdownList>
        );
      case Status.Published:
        return (
          <DropdownList>
            <DropdownListItem
              onSelect={() => history.push(`/quicknav/configure/instance/${copyId}`)}
            >
              {formatMessage({ id: 'quickNav.configuration.instances.copy' })}
            </DropdownListItem>
            <DropdownListItem onSelect={() => handleUnpublish(row?.Id.toString(), instanceName)}>
              {formatMessage({ id: 'quickNav.configuration.instances.unpublish' })}
            </DropdownListItem>
            {row?.IsVariation && (
              <DropdownListItem onSelect={() => handleDelete(row?.Id.toString(), true)}>
                {formatMessage({ id: 'quickNav.configuration.instances.delete' })}
              </DropdownListItem>
            )}
          </DropdownList>
        );
      case Status.Draft:
        return (
          <DropdownList>
            <DropdownListItem onSelect={() => handlePublish(row)}>
              {formatMessage({ id: 'quickNav.configuration.instances.publish' })}
            </DropdownListItem>
            <DropdownListItem onSelect={() => handleDelete(row?.Id.toString(), true)}>
              {formatMessage({ id: 'quickNav.configuration.instances.delete' })}
            </DropdownListItem>
          </DropdownList>
        );
      case Status.UnpublishedDraft:
        return (
          <DropdownList>
            <DropdownListItem onSelect={() => handlePublish(row)}>
              {formatMessage({ id: 'quickNav.configuration.instances.publish.draft' })}
            </DropdownListItem>
            <DropdownListItem onSelect={() => handleDelete(draft?.Id.toString(), false)}>
              {formatMessage({ id: 'quickNav.configuration.instances.delete.draft' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => history.push(`/quicknav/configure/instance/${copyId}`)}
            >
              {formatMessage({ id: 'quickNav.configuration.instances.copy' })}
            </DropdownListItem>
            {row?.IsVariation && (
              <DropdownListItem onSelect={() => handleDelete(row?.Id.toString(), true)}>
                {formatMessage({ id: 'quickNav.configuration.instances.delete' })}
              </DropdownListItem>
            )}
          </DropdownList>
        );
      default:
        return (
          <DropdownList>
            <DropdownListItem
              onSelect={() => history.push(`/quicknav/configure/instance/${copyId}`)}
            >
              {formatMessage({ id: 'quickNav.configuration.instances.copy' })}
            </DropdownListItem>
            {row?.IsVariation && (
              <DropdownListItem onSelect={() => handleDelete(row?.Id.toString(), true)}>
                {formatMessage({ id: 'quickNav.configuration.instances.delete' })}
              </DropdownListItem>
            )}
          </DropdownList>
        );
    }
  };
  const tableState = useDataTable({
    columns,
    data: instanceList?.data || [],
    filtering: false,
    rowSelection: false,
    expandingRows: true,
    sorting: true,
    initialPageSize: 10,
    initialSortDirection: 'desc',
    initialSortedColumnKey: 'LastModified',
  });
  const tableRows = useDataTableRows(tableState);

  const getTemplateVersion = (instances: Instance[]): Version => {
    const versArr = instances?.find((item) => item.IsVariation === false).Versions;
    const template = versArr?.find((item) => item.IsTemplate === true);
    return template;
  };
  return (
    <React.Fragment>
      <DuplicatedModal
        visible={duplicatedModalVisible}
        hide={() => setDuplicatedModalVisible(false)}
        handlePublishing={() => {
          setDuplicatedModalVisible(false);
          publishDraftMutation.mutate(draftRow);
        }}
      />
      <DefaultPageLayout hideContentHeader={true}>
        <Link onClick={() => history.push('/quicknav/configure')} icon="caretLeft">
          {formatMessage({ id: 'common.button.back' })}
        </Link>
        <Spacer mb="small" />
        {instanceList?.data && (
          <ContentHeader
            title={`Instances - ${getTemplateVersion(instanceList?.data)?.Title ?? ''}`}
          />
        )}
        <Flex flexDirection="row-reverse">
          <Button
            variant="primary"
            onClick={() =>
              history.push(
                `/quicknav/configure/instance/${getTemplateVersion(instanceList?.data)?.Id}`
              )
            }
          >
            {formatMessage({ id: 'common.button.new' })}
          </Button>
        </Flex>
        <Spacer mb="small" />
        <LocalizedDataTable
          {...tableState}
          {...tableRows}
          emptyMessage={formatMessage({ id: 'common.error_loading_data' })}
          isLoading={instanceList.isLoading}
          pageSizeOptions={[5, 10, 25]}
          responsive={false}
          expandableRowView={ExpandedVersionInfo}
        />
        {modalData && <QuickNavModal {...modalData}></QuickNavModal>}
      </DefaultPageLayout>
    </React.Fragment>
  );
};
export default withLayoutShell(InstancesGrid);
