import React, { ReactNode, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { ExpandableListContainer, Button, Group, Icon, Typography, Link } from 'alloy-foundation';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import stylesFn from './styles';
import { useEditProvider } from '../../../../common/providers/EditProvider';

export interface CardProps {
  title: string;
  viewControl: ReactNode;
  formControl: ReactNode;
  onEdit?: () => void;
  canEdit?: boolean;
  isClosed?: boolean;
  namespace?: string;
}

const Card = ({
  title,
  viewControl,
  formControl,
  canEdit = true,
  onEdit,
  isClosed = false,
  namespace,
}: CardProps) => {
  const [isClosedInternal, updateIsClosedInternal] = useState(isClosed);
  const [internalCanEdit, updateInternalCanEdit] = useState(canEdit);
  const [isEditing, updateIsEditing] = useState(false);
  const { editMode } = useEditProvider();
  const { css, styles } = useStyles({ stylesFn });
  const { errors } = useFormikContext();

  const hasErrors = namespace && errors[namespace] && Object.keys(errors[namespace]).length > 0;

  useEffect(() => {
    updateInternalCanEdit(canEdit);
  }, [canEdit]);

  useEffect(() => {
    if (!editMode) {
      updateIsEditing(false);
    }
  }, [editMode, isEditing]);

  // eslint-disable-next-line react/prop-types
  const renderHeader = () => {
    const isEditIconDisabled = isEditing || !internalCanEdit;

    return (
      <div {...css(styles.cardHeader)}>
        <div {...css(styles.cardHeader__headerRow)}>
          <Link
            onClick={() => updateIsClosedInternal(!isClosedInternal)}
            underline="none"
            data-testid="card-CollapsingLink"
          >
            <Group spacing="small" stacked={false}>
              <Icon
                name={isClosedInternal ? 'caretRight' : 'caretDown'}
                size={12}
                color="#018BFF"
              />
              <Typography variant="h5">{title}</Typography>
              {hasErrors && <Icon name="errorOutlined" size={16} color="red" />}
            </Group>
          </Link>
        </div>
        <div {...css(styles.cardHeader__btnColumn)}>
          <Button
            disabled={isEditIconDisabled}
            variant="link"
            size="small"
            icon="editSmall"
            aria-label="Edit"
            data-testid="Card-EditButton"
            onClick={() => {
              if (onEdit) onEdit();
              updateIsClosedInternal(false);
              updateIsEditing(true);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ExpandableListContainer
        header={renderHeader()}
        body={isEditing && editMode ? formControl : viewControl}
        isClosed={isClosedInternal}
        data-testid={`customer_card_${title}`}
        hasError={hasErrors}
      />
    </React.Fragment>
  );
};

export default Card;
