import basePSSRequests from '../../api/basePSSRequests';
import {
  LogFormData,
  LogFormActivityExtData,
  LogFormActivityExt,
  LogFormSuspenseExtData,
} from '../../models/LogFormData';

// base url
const baseURL = '/logform/CreateLogForm';
const baseextURL = '/logform/';

// exports
export const LogFormServices = {
  create: (logFormData: LogFormData): Promise<string> =>
    basePSSRequests.post(baseURL, logFormData).then((response) => response),
  getext: (actid: string): Promise<LogFormActivityExtData> =>
    basePSSRequests.get(`${baseextURL}/${actid}/getactivityext`).then((response) => response),
  createext: (logFormActivityExt: LogFormActivityExt): Promise<string> =>
    basePSSRequests
      .post(`${baseextURL}/createactivityext`, logFormActivityExt)
      .then((response) => response),
  getsuspext: (suspid: string): Promise<LogFormSuspenseExtData> =>
    basePSSRequests.get(`${baseextURL}/${suspid}/getsuspenseext`).then((response) => response),
};

export default LogFormServices;
