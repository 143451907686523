import React, { useState, useContext } from 'react';

// default Dashboard view is set here on the context, and the state below.
const EditContext = React.createContext({
  editMode: false,
  setEditMode: (p) => p,
});

export const EditProvider = ({ children }) => {
  const [editMode, setEditMode] = useState(false);

  return <EditContext.Provider value={{ editMode, setEditMode }}>{children}</EditContext.Provider>;
};

export const useEditProvider = () => {
  const context = useContext(EditContext);
  if (context === undefined) {
    throw new Error('useEditProvider cannot be called from outside of a EditProvider');
  }
  return context;
};
