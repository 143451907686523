import { Column, FormDropdownSingleSelect, Row, useEffectOnce } from 'alloy-foundation';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useLogFormDropdowns } from './useLogFormDropdown';
import * as fieldNames from './fieldNames';
import { PrStringDropdowns } from '../../../models/LogFormDropdowns';

type LogFormControlActivityProps = {
  logFormType: string;
};

const LogFormControlActivity = ({ logFormType }: LogFormControlActivityProps) => {
  const { formatMessage } = useIntl();

  const { setFieldValue, getFieldProps } = useFormikContext();

  const logformdropdowns = useLogFormDropdowns({
    refetchOnWindowFocus: false,
  });

  const handleActivityChange = (selected) => {
    const Activity = logformdropdowns?.data?.ActivityTypes.find(
      (x) => x.Key === parseInt(selected?.value)
    );
    if (Activity) {
      setdescriptionType(Activity.ChildPtrPrStrings);
      setFieldValue(
        fieldNames.STAGE,
        Activity && Activity.StageId ? Activity.StageId.toString() : ''
      );
      setFieldValue(fieldNames.SHARETOINSURLINK, Activity?.Shared === 'Y' ? true : false);
    } else {
      setdescriptionType([]);
      setFieldValue(fieldNames.STAGE, '');
      setFieldValue(fieldNames.SHARETOINSURLINK, false);
    }
    setFieldValue(fieldNames.DESCRIPTIONTYPE, '');
  };

  const [descriptionType, setdescriptionType] = useState<PrStringDropdowns[]>([]);

  // useEffectOnce is called here to populate Description dropdopwn since initial values do not set the childptr for the activity chosen
  useEffectOnce(() => {
    const defActvityType = getFieldProps(fieldNames.ACTIVITYTYPE).value;
    if (defActvityType) {
      const defActivity = logformdropdowns?.data?.ActivityTypes.find(
        (x) => x.Key === parseInt(defActvityType)
      );
      if (defActivity) setdescriptionType(defActivity.ChildPtrPrStrings);
    }
  });

  return (
    <React.Fragment>
      <Row>
        <Column>
          <FormDropdownSingleSelect
            label={formatMessage({ id: 'logform.activityType' })}
            data-testid="logformControlActivity-activityType"
            options={logformdropdowns?.data?.ActivityTypes.map((data) => {
              return {
                value: data.Key.toString(),
                label: data.Description,
              };
            })}
            name={fieldNames.ACTIVITYTYPE}
            required={
              logFormType === 'Activity'
                ? true
                : getFieldProps(fieldNames.STATUS).value === 2
                ? true
                : false
            }
            disabled={false}
            onChange={(activityType) => handleActivityChange(activityType)}
            placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
          ></FormDropdownSingleSelect>
        </Column>
        <Column>
          <FormDropdownSingleSelect
            label={formatMessage({ id: 'logform.descriptionType' })}
            data-testid="logformControlActivity-descriptionType"
            options={descriptionType.map((data) => {
              return {
                value: data.Key.toString(),
                label: data.Description,
              };
            })}
            name={fieldNames.DESCRIPTIONTYPE}
            disabled={(descriptionType?.length ?? 0) === 0}
            placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
          ></FormDropdownSingleSelect>
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default LogFormControlActivity;
