export const CUSTOMER_TYPE = 'customerType';
export const LANGUAGE = 'language';
export const PREFIX = 'prefix';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const MIDDLE_NAME = 'middleName';
export const DOB = 'dateOfBirth';
export const MARITAL_STATUS = 'maritalStatus';
export const FIRM_NAME = 'firmName';
export const FIRM_DBA = 'firmDBA';
export const PERSONAL_INFORMATION_CONSENT = 'personalInformationConsent';
export const CONSENT_FOR_OTHER_USE = 'consentForOtherUse';
export const TYPE_OF_BUSINESS = 'typeOfBusiness';
export const DATE_OF_CONSENT = 'dateOfConsent';
export const CUSTOMER_CLASS1 = 'customerClass1';
export const CUSTOMER_CLASS2 = 'customerClass2';
export const CUSTOMER_CLASS3 = 'customerClass3';
export const CUSTOMER_CLASS4 = 'customerClass4';
export const ACCOUNTEXEC1 = 'accountExecutive1';
export const ACCOUNTEXEC2 = 'accountExecutive2';
export const ACCOUNTREP1 = 'accountRepresentative1';
export const ACCOUNTREP2 = 'accountRepresentative2';
export const DIVISION = 'division';
export const DEPARTMENT = 'department';
export const POSTAL_CODE = 'postalCode';
export const CITY = 'city';
export const PROVINCE_CODE = 'provinceCode';
export const ADDRESS_LINE = 'addressLine';
export const ADDRESS_LINE2 = 'addressLine2';
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_EXTENSION = 'phoneExtension';
export const PHONE_TYPE = 'type';
export const NOT_APPLICABLE = 'notApplicable';
export const EMAILS = 'emails';
export const PHONES = 'phones';
export const PREFERED_CONTACT_METHOD = 'preferedContactMethod';
export const SECONDARY_CUSTOMER_CONTACT = 'secondaryContact';
export const SUB_BROKER = 'subBroker';
export const GENERAL_NAMESPACE = 'general';
