import { AxiosRequestConfig } from 'axios';
import baseRequests from './baseRequests';

const baseUrl = 'api/gateway/pss'; // Duplicate this file and replate baseUrl with this one.

// baseRequests
const basePSSRequests = {
  get: (url: string, config?: AxiosRequestConfig) => baseRequests.get(baseUrl + url, config),
  post: (url: string, body: any, config?: AxiosRequestConfig) =>
    baseRequests.post(baseUrl + url, body, config),
  put: (url: string, body: any) => baseRequests.put(baseUrl + url, body),
  delete: (url: string) => baseRequests.delete(baseUrl + url),
};

export default basePSSRequests;
