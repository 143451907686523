import { SigPermissions } from '../SigPermissions';

export class EPermissions {
  static readonly ENO = 0;
  static readonly EVIEW = 1;
  static readonly ECREATE = 2;
  static readonly EMODIFY = 4;
  static readonly EDELETE = 8;
  static readonly EAny = 15;
}

export class Permissions {
  static readonly CanEditCustomer = 'EditCustomer';
  static readonly CanEditDept = 'EditDept';
  static readonly CanEditDiv = 'EditDivision';
  static readonly CanEditRep = 'EditAccountRep';
  static readonly CanEditExec = 'EditAccountExec';
  static readonly CanEditCustomerClass = 'EditCustomerClasses';
}

export const HasPermissions = (
  permissions: SigPermissions,
  permissionName: string,
  permissionFlag: number
): boolean => {
  if (permissions[permissionName]) {
    return (permissions[permissionName] & permissionFlag) > 0;
  }
  return false;
};
