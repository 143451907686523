import {
  Column,
  FormDropdownSingleSelect,
  Row,
  useDebounce,
  TypeaheadSearch,
  LoadingIndicator,
  FormRadioButtonController,
  RadioButton,
  DisplayFormikState,
  Spacer,
  FormDatePickerInput,
  Input,
  CheckBox,
} from 'alloy-foundation';
import React, { Ref, forwardRef, useImperativeHandle } from 'react';
import { DateTime } from 'luxon';
import { Form, useFormikContext } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { noop } from 'lodash';
import { useGlobalSearchData } from '../../global-search/useGlobalSearchData';
import { useLogFormDropdowns } from './useLogFormDropdown';
import * as fieldNames from './fieldNames';
import { usePolicyData } from '../../customers/summary/policies/usePolicyData';
import { useFormatting } from '../../common/hooks/useFormatting';
import { DevOnlyComponent } from '../../common/devTools';
import LogFormControlActivity from './LogFormControlActivity';
import LogFormControlStage from './LogFormControlStage';
import Divider from '../../common/divider/Divider';
import LogFormSummary from './LogFormSummary';
import { ItemProps, LogFormEmployeeAssigned, LogFormGroupAssigned } from './LogForm';
import productInfoService from '../../../services/productInfo/productInfoService';
import { Locale } from '../../localization/Locale';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';
import { useLogFormSuspenseExt } from './useLogFormSuspenseExt';

type LogFormControlProps = {
  logFormType: string;
  selectedItem: ItemProps;
  setSelectedItem: React.Dispatch<React.SetStateAction<ItemProps>>;
  fromWorkItem: boolean;
  isCompleted: boolean;
  suspid: string;
};

const LogFormControl = forwardRef(
  (
    {
      logFormType,
      selectedItem,
      setSelectedItem,
      fromWorkItem,
      isCompleted,
      suspid,
    }: LogFormControlProps,
    ref: Ref<{ SubmitForm }>
  ) => {
    const { formatMessage } = useIntl();
    const [policySwitch, setPolicySwitch] = useState(selectedItem?.label ?? Option[0]);
    const [inputValue, setInputValue] = useState(selectedItem?.label ?? '');
    const debounceSearchKey = useDebounce({ value: inputValue, debounceMs: 500 });
    const searchData = useGlobalSearchData(debounceSearchKey, {
      enabled: debounceSearchKey.length > 2,
    });
    const { getEmpCode } = useProductInfoProvider();
    const { formatDate, formatLocalDate } = useFormatting();
    const { setFieldValue, getFieldProps, submitForm, errors } = useFormikContext();
    const SubmitForm = () => submitForm();
    useImperativeHandle(ref, () => ({ SubmitForm }));
    const [suspDueDate, setSuspDueDate] = useState<Date | null>(null);
    const [ReSchedNoUpdated, setReSchedNoUpdated] = useState(false);
    const [chkLinkActvity, setLinkActvity] = useState(true);
    const logformdropdowns = useLogFormDropdowns({
      refetchOnWindowFocus: false,
      onSuccess: () => {
        if (logformdropdowns.data?.LinkedActivity === 2) {
          setLinkActvity(false);
          setFieldValue(fieldNames.LINKEDACTIVITY, false);
        }
      },
    });

    const [createdBy, setCreatedBy] = useState('');
    const logformsuspenseextdata = useLogFormSuspenseExt(suspid, {
      enabled: suspid !== '',
      onSuccess: (data) => {
        if (data.CreatedBy) {
          setCreatedBy(data.CreatedBy);
        }
      },
    });

    const onSelectedItemChange = (item: ItemProps) => {
      if (item) {
        setSelectedItem(item);
        setInputValue(item.label);
        setFieldValue(fieldNames.CUSTOMER, item.value);
      } else {
        setSelectedItem(null);
        setInputValue('');
        setFieldValue(fieldNames.CUSTOMER, '');
      }
      setFieldValue(fieldNames.POLICY, '');
      setFieldValue(fieldNames.TRANSACTIONDATE, '');
      handleTransactionDateChange({}, []);
    };

    const onInputValueChange = (_inputValue: string) => {
      if (!selectedItem) {
        setInputValue(_inputValue);
      }
    };

    const handleTransactionDateChange = (values, polData) => {
      const selectedpolicy = polData?.Policies?.find(
        (x) => x.AttributeFields.EndEffDate === values?.value
      );
      if (selectedpolicy) {
        setFieldValue(fieldNames.PREMIUM, selectedpolicy.AttributeFields.Premium);
      } else {
        setFieldValue(fieldNames.PREMIUM, 0);
      }
    };

    const policies = usePolicyData(selectedItem?.value, {
      enabled: selectedItem?.value !== undefined,
      onSuccess: (data) => {
        const policyId = getFieldProps(fieldNames.POLICY);
        if (policyId) {
          setPolicySwitch(policyId);
          const polEndEffDate = getFieldProps(fieldNames.TRANSACTIONDATE).value;
          handleTransactionDateChange({ value: polEndEffDate, label: polEndEffDate }, data);
        }
      },
    });

    const handlechange = () => {
      setLinkActvity(!chkLinkActvity);
      setFieldValue(fieldNames.LINKEDACTIVITY, !chkLinkActvity);
    };

    const handlePolicyChange = (values) => {
      setPolicySwitch(values);
      if (
        policies?.data?.Policies?.filter((data) => data.AttributeFields.PolicyId === values?.value)
          .length === 1
      ) {
        const polEndEffDate = policies?.data?.Policies?.find(
          (x) => x.AttributeFields.PolicyId === values?.value
        ).AttributeFields.EndEffDate;
        setFieldValue(fieldNames.TRANSACTIONDATE, polEndEffDate);
        handleTransactionDateChange({ value: polEndEffDate, label: polEndEffDate }, policies?.data);
      } else {
        setFieldValue(fieldNames.TRANSACTIONDATE, '');
        handleTransactionDateChange({}, []);
      }
    };

    const stateCollection = productInfoService.getStateCollection();
    // default to en-CA if language isn't explicitely set to french
    const toLocaleEnum = (value: any): Locale =>
      value?.toLowerCase() === 'fr-ca' ? Locale.FrCa : Locale.EnCa;
    const currentLocale = toLocaleEnum(stateCollection?.culture?.code);
    const findSuspWkGrpIdByName = (WkGrpName) => {
      if (logformdropdowns.data.ActiveSuspenseWorkgroups) {
        for (const item of logformdropdowns.data.ActiveSuspenseWorkgroups) {
          if (item.Value === WkGrpName) {
            return item.Key;
          }
        }
      }
    };

    const handleActionChange = (value) => {
      const Action = logformdropdowns?.data?.Actions.find((x) => x.Key === parseInt(value?.value));

      if (Action) {
        const dueDate = DateTime.now()
          .plus({ days: Number(Action?.SupsDefaultDate) })
          .toJSDate()
          .toISOString();
        handleDatePickerChange(formatLocalDate(dueDate));

        if (Action.SuspDefaultUser) {
          setFieldValue(fieldNames.ASSIGNEDTORADIO, 'employee');
          setFieldValue(fieldNames.ASSIGNEDTODROPDN, Action.SuspDefaultUser);
        } else if (Action.SuspDefaultWkGrpEn) {
          setFieldValue(fieldNames.ASSIGNEDTORADIO, LogFormGroupAssigned);
          if (currentLocale === Locale.EnCa) {
            setFieldValue(
              fieldNames.ASSIGNEDTODROPDN,
              findSuspWkGrpIdByName(Action.SuspDefaultWkGrpEn)
            );
          } else {
            setFieldValue(
              fieldNames.ASSIGNEDTODROPDN,
              findSuspWkGrpIdByName(Action.SuspDefaultWkGrpFr)
            );
          }
        } else {
          // set back to default login employee if ther is no default user/wkgrp setting
          setFieldValue(fieldNames.ASSIGNEDTORADIO, LogFormEmployeeAssigned);
          setFieldValue(fieldNames.ASSIGNEDTODROPDN, getEmpCode());
        }
      } else {
        setSuspDueDate(null);
        setFieldValue(fieldNames.DUEDATE, '');
        // set back to default login employee if no susp action is selected
        setFieldValue(fieldNames.ASSIGNEDTORADIO, LogFormEmployeeAssigned);
        setFieldValue(fieldNames.ASSIGNEDTODROPDN, getEmpCode());
      }
    };

    const handleDatePickerChange = (values) => {
      const nowDue = getFieldProps(fieldNames.DUEDATE);
      setSuspDueDate(values);
      setFieldValue(fieldNames.DUEDATE, values);
      // only update reschedno once and not on create
      if (!ReSchedNoUpdated && nowDue.value !== '' && nowDue.value !== values) {
        const ReSchedNo = getFieldProps(fieldNames.RESCHEDNO);
        setFieldValue(fieldNames.RESCHEDNO, Number(ReSchedNo.value) + 1);
      }
      if (nowDue.value !== values) {
        setReSchedNoUpdated(true);
      }
    };

    return (
      <Form>
        {logformdropdowns.isLoading ? (
          <LoadingIndicator />
        ) : (
          <React.Fragment>
            <DevOnlyComponent>
              <DisplayFormikState />
            </DevOnlyComponent>
            <Row>
              <Column>
                <FormDropdownSingleSelect
                  label={formatMessage({ id: 'customer.search.Active' })}
                  data-testid="logform-state"
                  options={
                    logformdropdowns?.data?.Status.map((data) => {
                      return { value: data.Key, label: data.Value };
                    }).sort((a, b) => a.value - b.value) ?? []
                  }
                  name={fieldNames.STATUS}
                  required={true}
                  disabled={!fromWorkItem || (fromWorkItem && isCompleted)}
                ></FormDropdownSingleSelect>
              </Column>
              <Column>
                <TypeaheadSearch
                  invalid={
                    errors['customer'] && getFieldProps(fieldNames.CUSTOMER).value === ''
                      ? true
                      : false
                  }
                  label={formatMessage({ id: 'dashboard.manager.panel.columns.customer' })}
                  required={true}
                  inputValue={inputValue}
                  options={
                    searchData?.data
                      ?.sort((a, b) => {
                        return a.Client?.toLowerCase() < b.Client?.toLowerCase() ? -1 : 1;
                      })
                      .map((data) => {
                        return {
                          value: data?.ClientId,
                          label: `${data?.Client} (${data?.ClientNum}) ${
                            data?.Address ? `(${data.Address})` : ''
                          }`,
                        };
                      }) ?? []
                  }
                  onChange={onSelectedItemChange}
                  onInputValueChange={onInputValueChange}
                  selectedItem={selectedItem}
                  data-testid="globalsearch-test"
                  disabled={fromWorkItem}
                  placeholder={formatMessage({ id: 'common.search.placeholder' })}
                  errorMessage={
                    errors['customer'] ? formatMessage({ id: 'common.validation.required' }) : ''
                  }
                  renderErrorMessage={true}
                />
              </Column>
            </Row>
            <Row>
              <Column sm={6}>
                <FormDropdownSingleSelect
                  label={formatMessage({ id: 'logform.policy' })}
                  required={false}
                  initialSelectedItem={null}
                  options={
                    policies?.data?.Policies?.filter(
                      (val, index, arr) =>
                        arr.findIndex(
                          (pol) => pol.AttributeFields.PolicyId === val.AttributeFields.PolicyId
                        ) === index
                    ).map((data) => {
                      return {
                        value: data.AttributeFields.PolicyId,
                        label: `${data.AttributeFields.PolicyNumber} (${
                          data.AttributeFields.PolicyType
                        }) (${formatDate(data.AttributeFields.ExpirationDate)})`,
                      };
                    }) ?? []
                  }
                  name={fieldNames.POLICY}
                  selectedItem={selectedItem}
                  onChange={(values, ...args: any[]) => {
                    handlePolicyChange(values);
                  }}
                  placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                />
              </Column>
              <Column sm={4}>
                <FormDropdownSingleSelect
                  label={formatMessage({ id: 'logform.transaction.date' })}
                  required={false}
                  initialSelectedItem={null}
                  options={
                    policies?.data?.Policies?.filter(
                      (data) => data.AttributeFields.PolicyId === policySwitch?.value
                    ).map((data) => {
                      return {
                        value: data?.AttributeFields?.EndEffDate,
                        label: `${formatDate(data?.AttributeFields?.EndEffDate)}`,
                      };
                    }) ?? []
                  }
                  name={fieldNames.TRANSACTIONDATE}
                  onChange={(values, ...args: any[]) => {
                    handleTransactionDateChange(values, policies?.data);
                  }}
                  selectedItem={selectedItem}
                  placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                />
              </Column>
              {logformdropdowns.data.LinkedActivity !== null &&
                logformdropdowns.data.LinkedActivity > 0 && (
                  <Column>
                    <div
                      style={{
                        position: 'absolute',
                        left: '60%',
                        top: '65%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <CheckBox
                        label={formatMessage({ id: 'logform.linkactivity' })}
                        checked={chkLinkActvity}
                        onChange={handlechange}
                        name={fieldNames.LINKEDACTIVITY}
                      />
                    </div>
                  </Column>
                )}
            </Row>
            <Row>
              <Column sm={6}>
                <FormDropdownSingleSelect
                  label={formatMessage({ id: 'logform.action' })}
                  data-testid="logform-state"
                  options={logformdropdowns?.data?.Actions.map((data) => {
                    return {
                      value: data.Key.toString(),
                      label: `${data.Description} (${
                        data?.SupsDefaultDate ? data.SupsDefaultDate.toString() : '0'
                      })`,
                    };
                  }).sort((a, b) => a.label.localeCompare(b.label))}
                  name={fieldNames.ACTION}
                  required={logFormType === 'Activity' ? false : true}
                  disabled={logFormType === 'Activity' ? true : false}
                  onChange={(Actionsusp) => handleActionChange(Actionsusp)}
                  placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                ></FormDropdownSingleSelect>
              </Column>
              <Column sm={3}>
                <FormDatePickerInput
                  data-testid="duedate-testid"
                  required={true}
                  name={fieldNames.DUEDATE}
                  label={
                    logFormType === 'Activity'
                      ? formatMessage({ id: 'logform.logdate' })
                      : formatMessage({ id: 'logform.duedate' })
                  }
                  onChange={(value, date, event) => {
                    handleDatePickerChange(value);
                  }}
                  value={suspDueDate}
                />
              </Column>
              {fromWorkItem && (
                <Column>
                  <Input
                    data-testid="reschedno-testid"
                    name={fieldNames.RESCHEDNO}
                    label={formatMessage({ id: 'logformview.times.rescheduled' })}
                    value={getFieldProps(fieldNames.RESCHEDNO).value}
                    onChange={noop}
                    disabled={true}
                  />
                </Column>
              )}
            </Row>
            <Row>
              <Column>
                <FormDropdownSingleSelect
                  options={
                    logformdropdowns?.data?.Priority.map((data) => {
                      return { value: data.Key, label: data.Value };
                    }).sort((a, b) => a.value - b.value) ?? []
                  }
                  label={formatMessage({ id: 'workInProgress.Priority' })}
                  name={fieldNames.PRIORITY}
                  required={logFormType === 'Activity' ? false : true}
                  placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                  disabled={logFormType === 'Activity' ? true : false}
                ></FormDropdownSingleSelect>
              </Column>
              <Column>
                <Row>
                  <Column md={3}>
                    <FormRadioButtonController
                      name={fieldNames.ASSIGNEDTORADIO}
                      label={formatMessage({ id: 'drawer.workItem.header.assignedTo' })}
                      direction="row"
                      required={logFormType === 'Activity' ? false : true}
                      noSpacing={true}
                      renderErrorMessage={false}
                      onChange={() => setFieldValue(fieldNames.ASSIGNEDTODROPDN, '')}
                      disabled={logFormType === 'Activity' ? true : false}
                    >
                      <RadioButton
                        data-testid="assignedto-employee"
                        label={formatMessage({ id: 'activityList.Employee' })}
                        value="employee"
                      />
                      <RadioButton
                        data-testid="assignedto-group"
                        label={formatMessage({
                          id: 'dashboard.panel.todoListWorkgroup.columns.to.header',
                        })}
                        value="group"
                      />
                    </FormRadioButtonController>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Spacer pt="xsmall">
                      <FormDropdownSingleSelect
                        label="AssignedToDropDn"
                        hideLabel={true}
                        name={fieldNames.ASSIGNEDTODROPDN}
                        options={
                          getFieldProps(fieldNames.ASSIGNEDTORADIO).value === 'employee'
                            ? logformdropdowns?.data?.ActiveEmployees.map((data) => {
                                return { value: data.Key, label: data.Value };
                              }).sort((a, b) => a.value - b.value) ?? []
                            : logformdropdowns?.data?.ActiveSuspenseWorkgroups.map((data) => {
                                return {
                                  value: data.Key,
                                  label: data.Value,
                                };
                              }).sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
                        }
                        placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
                        disabled={logFormType === 'Activity' ? true : false}
                      ></FormDropdownSingleSelect>
                    </Spacer>
                  </Column>
                </Row>
              </Column>
            </Row>
            {suspid && (
              <Row>
                <Column xs={6} sm={6} md={6}>
                  <Input
                    name={fieldNames.PREMIUM}
                    data-testid="logformControl-createbdy"
                    label={formatMessage({ id: 'logformview.suspensecreatedby' })}
                    value={createdBy}
                    onChange={noop}
                    disabled={true}
                  />
                </Column>
              </Row>
            )}
            <Row>
              <Column>
                <LogFormControlActivity logFormType={logFormType} />
              </Column>
            </Row>
            <Row>
              <Column>
                <LogFormControlStage />
              </Column>
            </Row>
            <Row>
              <Column>
                <Divider pt="none" pb="small" ml="none" mr="none" />
              </Column>
            </Row>
            <LogFormSummary
              suspenseId={suspid}
              logformsuspenseextdata={logformsuspenseextdata.data}
            />
            <Row>
              <Column>
                <Divider pt="small" pb="small" ml="none" mr="none" />
              </Column>
            </Row>
          </React.Fragment>
        )}
      </Form>
    );
  }
);

export default LogFormControl;
