import basePSSRequests from '../../api/basePSSRequests';
import { CustomerLock } from '../../models/CustomerLock';

const CustomerLockService = {
  get: (id: string): Promise<CustomerLock> =>
    basePSSRequests.get(`/getlock/${id}`).then((response) => {
      return response;
    }),
  post: (id: string): Promise<CustomerLock> =>
    basePSSRequests.post(`/lockcustomer?clientId=${id}`, '').then((response) => {
      return response;
    }),
  delete: (id: string): Promise<string> =>
    basePSSRequests.delete(`/unlockcustomer/${id}`).then((response) => {
      return response;
    }),
};

export default CustomerLockService;
