import React, { useState, useContext } from 'react';
import noop from 'lodash/noop';

type DrawerContextType = {
  visible: boolean;
  hideDrawer: () => void;
  showDrawer: (p: React.ReactNode) => void;
  content: React.ReactNode;
};

const DrawerContext = React.createContext<DrawerContextType>({
  visible: false,
  hideDrawer: noop,
  showDrawer: noop,
  content: React.Fragment,
});

export const DrawerProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(React.Fragment);

  const hideDrawer = () => setVisible(false);
  const showDrawer = (p: React.ReactNode) => {
    setContent(p);
    setVisible(true);
  };

  return (
    <DrawerContext.Provider value={{ visible, hideDrawer, showDrawer, content }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawer cannot be called from outside of a DrawerProvider');
  }
  return context;
};
