import { Theme } from 'alloy-foundation';
import { Styles } from 'react-with-styles';

const stylesFn = ({ vertaforeAlloy: { spacing } }: Theme): Styles => ({
  cardHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  cardHeader__headerRow: {
    width: '90%',
  },

  cardHeader__btnColumn: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
});

export default stylesFn;
