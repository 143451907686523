import { Column, FormRadioButtonController, RadioButton, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { CustomerDropdowns } from '../../../../../models/CustomerDropdowns';
import { useTypeOfBusiness } from '../../../TypeOfBusinessProvider';
import { TYPE_OF_BUSINESS } from '../../../fieldNames';
import Header from '../../../../common/header/Header';

export const TypeOfBusiness: React.FC<{ dropdowns: CustomerDropdowns }> = ({ dropdowns }) => {
  const { formatMessage } = useIntl();
  const { setTypeOfBusiness } = useTypeOfBusiness();
  return (
    <>
      <Header
        title={formatMessage({ id: 'customer.details.businessInformation.typeOfBusiness' })}
        topSpacer={false}
      />
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={2}>
            <FormRadioButtonController
              name={TYPE_OF_BUSINESS}
              label=""
              onChange={(value) => setTypeOfBusiness(value)}
              direction="row"
            >
              <RadioButton
                data-testid="TypeofBusiness-Commercial"
                label={formatMessage({
                  id: 'customer.details.businessInformation.typeOfBusiness.Commercial',
                })}
                value="C"
              />
              <RadioButton
                data-testid="TypeofBusiness-Personal"
                label={formatMessage({
                  id: 'customer.details.businessInformation.typeOfBusiness.Personal',
                })}
                value="P"
              />
            </FormRadioButtonController>
          </Column>
        </Row>
      </Spacer>
    </>
  );
};
