import { useCallback, useEffect, useState } from 'react';
import { DataTableMultiSelect } from '.';

/**
 * Helper for managing <DataTable /> state and state setters.
 */
export function useMultiSelect<Data>(
  data: Data[],
  initialData: Data[],
  initialSelection: Data[] = [],
  isFiltering: boolean,
  equatableRowKey?: keyof Data | string,
  onSelectionChange?: (rows: Data[]) => void,
  isExternalyControlled = false
): DataTableMultiSelect<Data> {
  const [selectedRows, updateSelectedRows] = useState<Data[]>(initialSelection);
  const [isPartialSelection, updateIsPartialSelection] = useState(false);
  const [isSelectAll, updateIsSelectAll] = useState(false);

  useEffect(() => {
    updateIsPartialSelection(
      selectedRows && selectedRows.length > 0 && selectedRows.length !== data?.length
    );
    updateIsSelectAll(selectedRows && selectedRows.length === data?.length);
  }, [selectedRows, data, updateIsPartialSelection, updateIsSelectAll]);

  useEffect(() => {
    if (isExternalyControlled) {
      updateSelectedRows(initialSelection);
    }
  }, [initialSelection, selectedRows, isExternalyControlled]);

  const onSelectRow = useCallback(
    (row: Data) => {
      let updatedSelectedRows = [] as Data[];

      if (
        selectedRows.includes(row) ||
        (equatableRowKey &&
          selectedRows.some(
            (x) => x[equatableRowKey as keyof Data] === row[equatableRowKey as keyof Data]
          ))
      )
        updatedSelectedRows = selectedRows.filter(
          (d) =>
            row !== d &&
            (!equatableRowKey ||
              d[equatableRowKey as keyof Data] !== row[equatableRowKey as keyof Data])
        );
      else updatedSelectedRows = [...selectedRows, row];

      if (!isExternalyControlled) {
        updateSelectedRows(updatedSelectedRows);
      }
      if (onSelectionChange) onSelectionChange(updatedSelectedRows);
    },
    [updateSelectedRows, selectedRows, equatableRowKey, onSelectionChange, isExternalyControlled]
  );

  const onSelectAll = useCallback(() => {
    switch (isFiltering) {
      case false:
        // If nothing is selected, select all
        if (selectedRows.length === 0) {
          if (!isExternalyControlled) updateSelectedRows([...initialData]);
          if (onSelectionChange) onSelectionChange([...initialData]);
          // If everything is selected, deselect all
        } else {
          if (!isExternalyControlled) updateSelectedRows([]);
          if (onSelectionChange) onSelectionChange([]);
        }
        break;
      case true:
        // If the filtered rows are selected, unselect them
        if (data.filter((x) => selectedRows.includes(x)).length > 0 && selectedRows.length > 0) {
          if (!isExternalyControlled)
            updateSelectedRows([...selectedRows.filter((x) => !data.includes(x))]);
          if (onSelectionChange)
            onSelectionChange([...selectedRows.filter((x) => !data.includes(x))]);
          // Else select the filtered rows
        } else {
          if (!isExternalyControlled)
            updateSelectedRows([...selectedRows, ...data.filter((x) => !selectedRows.includes(x))]);
          if (onSelectionChange)
            onSelectionChange([...selectedRows, ...data.filter((x) => !selectedRows.includes(x))]);
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, updateSelectedRows, selectedRows, onSelectionChange, isExternalyControlled]);

  const reset = () => {
    updateIsPartialSelection(false);
    updateIsSelectAll(false);
    updateSelectedRows([]);
  };
  return {
    onSelectAll,
    onSelectRow,
    isPartialSelection,
    isSelectAll,
    selectedRows,
    reset,
  };
}
