import { DateTime } from 'luxon';
import * as Yup from 'yup';
import * as fieldNames from './fieldNames';

const logFormValidationSchema = (formatMessage, logFormType) =>
  Yup.object().shape({
    [`${fieldNames.STATUS}`]: Yup.string()
      .required(formatMessage({ id: 'common.validation.required' }))
      .nullable(true),
    [`${fieldNames.CUSTOMER}`]: Yup.string()
      .required(formatMessage({ id: 'common.validation.required' }))
      .nullable(true),
    [`${fieldNames.ACTION}`]: Yup.number().when([], (schema) => {
      if (logFormType === 'Suspense')
        return schema.required(formatMessage({ id: 'common.validation.required' })).nullable(true);
      else return schema;
    }),
    [`${fieldNames.DUEDATE}`]: Yup.string()
      .test('date', formatMessage({ id: 'common.validation.invalidDate' }), (val) => {
        if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

        return true;
      })
      .required(formatMessage({ id: 'common.validation.required' }))
      .nullable(true),
    [`${fieldNames.PRIORITY}`]: Yup.string().when([], (schema) => {
      if (logFormType === 'Suspense')
        return schema.required(formatMessage({ id: 'common.validation.required' })).nullable(true);
      else return schema;
    }),
    [`${fieldNames.ASSIGNEDTODROPDN}`]: Yup.string().when([], (schema) => {
      if (logFormType === 'Suspense')
        return schema.required(formatMessage({ id: 'common.validation.required' })).nullable(true);
      else return schema;
    }),
    [`${fieldNames.ASSIGNEDTORADIO}`]: Yup.string().when([], (schema) => {
      if (logFormType === 'Suspense')
        return schema.required(formatMessage({ id: 'common.validation.required' })).nullable(true);
      else return schema;
    }),
    [`${fieldNames.ACTIVITYTYPE}`]: Yup.string().when(`${fieldNames.STATUS}`, {
      is: '2',
      then: Yup.string().test(
        'Completed',
        formatMessage({ id: 'common.validation.required' }),
        (val) => {
          if (typeof val === 'undefined' || val === '-1' || val === '') return false;
          return true;
        }
      ),
    }),
    [`${fieldNames.CLOSEDATE}`]: Yup.string().test(
      'date',
      formatMessage({ id: 'common.validation.invalidDate' }),
      (val) => {
        if (val) return DateTime.fromFormat(val, 'MM/dd/yyyy').isValid;

        return true;
      }
    ),
  });

export default logFormValidationSchema;
