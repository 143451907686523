import { Button, Column, Flex, Group, Link, Row, Spacer, Typography } from 'alloy-foundation';
import React, { Dispatch, SetStateAction, useState } from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { FormattedMessage, useIntl } from 'react-intl';
import stylesFn from './styles';
import { useDrawer } from '../DrawerProvider';
import { Process } from '../../../models/Process';
import { IncompleteWorkItemReduced } from '../../../models/WorkQueue';
import ReassignWorkItemModal from './ReassignWorkItemModal';
import { useProductInfoProvider } from '../../productInfo/ProductInfoProvider';
import { availableAssignee } from './useWorkQueue';

interface Props {
  process: Process;
  setProcessId: Dispatch<SetStateAction<string>>;
  options?: availableAssignee;
}

const WorkItemHeader = ({ process, setProcessId, options }: Props) => {
  const { css, styles } = useStyles({ stylesFn });
  const { formatMessage } = useIntl();
  const { hideDrawer } = useDrawer();
  const { getVimUserId } = useProductInfoProvider();

  const getKeyInfo = (name: string) => process?.KeyInformation?.find((x) => x.Key === name);
  const policyKey = getKeyInfo(formatMessage({ id: 'quicknav.keyInformation.key.policy' }));
  const termKey = getKeyInfo(formatMessage({ id: 'quicknav.keyInformation.key.term' }));
  const endEffDateKey = getKeyInfo(formatMessage({ id: 'quicknav.keyInformation.key.endEffDate' }));
  const [reassignVisibility, setReassignVisibility] = useState(false);
  const handleReassignToggle = () => setReassignVisibility((prevState) => !prevState);
  const handleClose = () => {
    setReassignVisibility(false);
  };
  const data: IncompleteWorkItemReduced = {
    Id: process?.Id,
    Type: process?.CurrentTask?.BusinessObjects.find((x) => x.Index === 'Client').Description,
    TaskTitle: process?.CurrentTask?.Definition.Title,
    ProcessTitle: process?.Definition?.Description,
    DueDate: process?.DueDate,
    FormattedDueDate: process?.FormattedDueDate,
    CustomerName: process?.BusinessObjects.find((x) => x.Index === 'Client').Description,
    CustomerId: process?.BusinessObjects.find((x) => x.Index === 'Client').Id,
    Priority: process?.Priority,
  };
  return (
    <div {...css(styles.itemHeader)}>
      <Row noGutters={true}>
        <Column xs={9} md={9} sm={9}>
          <Spacer pl="small" pt="small">
            <Link
              onClick={() => setProcessId(undefined)}
              icon="caretLeft"
              variant="white"
              iconAlign="left"
            >
              {formatMessage({ id: 'drawer.workQueue.back' })}
            </Link>
          </Spacer>
        </Column>
        <Column>
          <Flex justifyContent="flex-end">
            <Spacer pt="xsmall">
              <Button
                icon="close"
                variant="linkWhite"
                onClick={() => hideDrawer()}
                data-testid="drawer-close"
              />
            </Spacer>
          </Flex>
        </Column>
      </Row>
      <Row noGutters={true}>
        <Column xs={12} md={12} sm={12}>
          <Spacer pl="small" pt="medium" pr="small">
            <Typography variant="h2" color="white">
              <FormattedMessage id="drawer.workItem.title" />
            </Typography>
            <Typography variant="h4" color="white" noWrap={true}>
              {process?.Definition?.ShortTitle || process?.Definition?.Title
                ? `${process.Definition?.ShortTitle} - ${process.Definition?.Title}`
                : '--'}
            </Typography>
          </Spacer>
        </Column>
      </Row>
      <Row noGutters={true}>
        <Column xs={5} md={5} sm={5}>
          <Spacer pl="small" pt="large">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.assignedTo" />
            </Typography>
            <Flex flexDirection="row" alignItems="center">
              <Group spacing="xsmall" stacked={false}>
                <div style={{ maxWidth: 175 }}>
                  <Typography variant="body" color="white" noWrap={true}>
                    {process?.Assignee?.DisplayName ?? '--'}
                  </Typography>
                </div>
                {process?.Assignee ? (
                  <Link
                    variant="blueWaters"
                    data-testid="reassign-icon"
                    icon="edit"
                    onClick={() => handleReassignToggle()}
                  />
                ) : (
                  <></>
                )}
              </Group>
            </Flex>
          </Spacer>
          <Spacer pl="small" pt="xsmall">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.term" />
            </Typography>
            <Typography variant="body" color="white">
              {termKey?.Value?.Value ?? '--'}
            </Typography>
          </Spacer>
        </Column>
        <Column xs={3} md={3} sm={3}>
          <Spacer pl="small" pt="large">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.initiated" />
            </Typography>
            <Typography variant="body" color="white">
              {process?.FormattedCreatedDate ?? '--'}
            </Typography>
          </Spacer>
          <Spacer pl="small" pt="xsmall">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.policy" />
            </Typography>
            <Typography variant="body" color="white" noWrap={true}>
              {policyKey?.Value?.Value ?? '--'}
            </Typography>
          </Spacer>
        </Column>
        <Column xs={4} md={4} sm={4}>
          <Spacer pl="small" pt="large" pr="small">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.due" />
            </Typography>
            <Typography variant="body" color="white">
              {process?.FormattedDueDate ?? '--'}
            </Typography>
          </Spacer>
          <Spacer pl="small" pt="xsmall" pr="small">
            <Typography variant="body" fontWeight="semibold" color="white">
              <FormattedMessage id="drawer.workItem.header.effectiveDate" />
            </Typography>
            <Typography variant="body" color="white">
              {endEffDateKey?.Value?.Value ?? '--'}
            </Typography>
          </Spacer>
          {reassignVisibility ? (
            <ReassignWorkItemModal
              handleClose={handleClose}
              handleToggle={handleReassignToggle}
              data={data}
              options={options}
              VimUserId={getVimUserId()?.toString()}
              setProcessId={setProcessId}
            />
          ) : null}
        </Column>
      </Row>
    </div>
  );
};

export default WorkItemHeader;
