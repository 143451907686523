import { CheckBox, Spacer } from 'alloy-foundation';
import React from 'react';
import { CellProps, DataTableColumn } from './DataTableColumn';

export class MultiSelectRowColum<Data> extends DataTableColumn<Data> {
  onChange?: (row: Data) => void;

  constructor({
    onChange,
    sortable = false,
    padding = 'none',
    dataTestId = 'multiSelect-row-header',
    // Hard coded else it is base on a flex 1 which can be greater than 68.
    width = 68,
    ...values
  }: Partial<MultiSelectRowColum<Data>> & { key: DataTableColumn<Data>['key'] }) {
    super(values);
    Object.assign(this, { onChange, sortable, padding, width, dataTestId });
  }

  header = ({ multiSelect }) => {
    return (
      <>
        <Spacer paddingLeft="medium">
          <CheckBox
            label=""
            checked={multiSelect?.isSelectAll ?? false}
            indeterminate={multiSelect?.isPartialSelection ?? false}
            onChange={multiSelect?.onSelectAll}
            data-testid="Header-Multi-Select-CheckBox"
          ></CheckBox>
        </Spacer>
      </>
    );
  };

  Cell = ({ row, rowIndex, isActive }: CellProps<Data>) => {
    return (
      <Spacer paddingLeft="medium">
        <CheckBox
          label=""
          checked={isActive ?? false}
          onChange={() => this.onChange(row)}
          data-testid={`row-${rowIndex}-multi-select-checkbox`}
        ></CheckBox>
      </Spacer>
    );
  };
}
