// Imported from the old angular project. Most aren't in used anymore but multiple copied/imported processes needed the hardcoded values from this file.
export const SmartflowConstants = {
  events: {
    clientRecordRefresh: 'Smartflow.ClientRecord.Refresh',
    clientRecordCustRefresh: 'Smartflow.ClientRecord.customerRefresh',
    agGrid: {
      sortCallbackName: 'onSortChanged',
      columnDragCallbackName: 'onColumnMoved',
    },
  },
  summary: {
    navigationModelType: 'MAH.model.navigation.global.LaunchUrl',
    events: {
      clientSelected: 'Notification.client.selected',
      clientSummaryPlrOpen: 'clientRecord.plr.open',
    },
    newActivity: {
      labels: {
        activity: 'NEW ACTIVITY...',
        suspense: 'NEW SUSPENSE...',
      },
    },
    mask: {
      emptyData: '--',
    },
    activePolicies: {
      commercial: 'commercial lines',
      labels: {
        New: 'New',
        endorse: 'Endorse',
        renew: 'Renew',
        cancel: 'Cancel',
        newActivity: 'New Activity',
        newSuspense: 'New Suspense',
        cancelViaAms360: 'Cancel via SIG',
        renewViaAms360: 'Renew via SIG',
        endorseViaAms360: 'Endorse via SIG',
        cancelWorkSmart: 'WorkSmart Cancellation',
        renewWorkSmart: 'WorkSmart Renewal',
        endorseWorkSmart: 'WorkSmart Endorsement',
        newViaAms360: 'New Business via SIG',
        policy: 'Policy',
        term: 'Term',
        status: 'Status',
        company: 'Company',
        billMethod: 'Bill Method',
        notationDesc: 'NOTATION/DESCRIPTION',
        policies: 'Policies',
        document: 'Docs',
        show: 'Show:',
        all: 'All',
        currentAndFuture: 'Current/Future',
        cancelled: 'Cancelled',
        lapsed: 'Lapsed',
        lapsingToday: 'Lapsing Today',
        nonRenewed: 'Non-Renewed',
        notTaken: 'Not taken',
        quote: 'Quote',
        renewed: 'Renewed',
        rewritten: 'Rewritten',
        past: 'Past',
        current: 'Current',
        future: 'Future',
        viewDocument: 'View Document',
        totalCost: 'Full Premiun',
      },
      noPolicyFolderLocationFound: 'Unable to identify folder location for Policy',
      noPolicyPermissions: 'Policy unavailable due to your security permissions',
      events: {
        policyResize: 'sf.activePolicyResize',
        updateChecklistTitles: 'updateChecklistTitles',
        gridEventData: 'ngGridEventData',
        gridEventSorted: 'ngGridEventSorted',
        transactNow: 'TransactNOW',
        gridEventColumns: 'ngGridEventColumns',
        gatherMenus: 'Gather.Menus.ClientPolicy',
        refreshMenus: 'workflowSettings.refresh',
        clientNameChange: 'clientNameChange',
      },
      errors: {
        buttonsNotLoaded: 'Buttons not loaded yet.',
        policies: 'Policies cannot be displayed',
        policyTransactions: 'Policy transactions cannot be displayed',
      },
      tracking: {
        policySelected: 'Table Row#Click',
        analyticsPolicyMacro: 'Policies',
      },
      gridFields: {
        policyNumber: 'AttributeFields.PolicyNumber',
        expiryDate: 'AttributeFields.ExpirationDate',
        status: 'status',
        company: 'Company',
        billMethod: 'billMethod',
        notation: 'notation',
        totalCost: 'cost',
      },
      stateKeys: {
        policyFilterKey: 'qnClientRecordPolicyFilter',
        policyColumnKey: 'qnClientRecordPolicyColumns',
      },
      templates: {
        policy: 'angular/ClientRecord/Summary/ActivePolicies/col.policyId.tpl.html',
        term: 'angular/ClientRecord/Summary/ActivePolicies/col.term.tpl.html',
        status: 'angular/ClientRecord/Summary/ActivePolicies/col.status.tpl.html',
        company: 'angular/ClientRecord/Summary/ActivePolicies/col.writingCo.tpl.html',
        billMethod: 'angular/ClientRecord/Summary/ActivePolicies/col.billMethod.tpl.html',
        totalCost: 'angular/ClientRecord/Summary/ActivePolicies/col.totalCost.tpl.html',
        notation: 'angular/ClientRecord/Summary/ActivePolicies/col.notation.tpl.html',
      },
    },

    workInProgress: {
      events: {
        refreshWorkInProgress: 'refreshWorkInProgress',
        removeWorkItem: 'removeWorkItem',
        reassignedWorkItem: 'workItemReassigned',
        priorityUpdated: 'priorityUpdated',
        openWorkItemNotification: 'open.workitem.notification',
        gridEventColumns: 'ngGridEventColumns',
        wipLoaded: 'sf.wip.loaded',
        dialog: 'openDialog',
        errorDialog: 'sf-trunk-errordialog',
        containerName: 'sf-wip-gridrow',
      },
      labels: {
        workInProgress: 'Work In Progress',
        type: 'Type',
        description: 'Description',
        policy: 'Policy',
        assignedTo: 'Assigned To',
        priority: 'Priority',
        dueDate: 'Due',
        suspense: 'SUSPENSE',
        quickNav: 'QUICKNAV',
        worksmart: 'WORKSMART',
        diary: 'DIARY',
        smartflow: 'SMARTFLOW',
        all: 'ALL',
        show: 'SHOW:',
      },
      gridFields: {
        type: 'SubType',
        description: 'Description',
        policyNumber: 'PolicyNumber',
        priorityId: 'PriorityId',
        dueDate: 'DueDate',
        assignedToName: 'AssignedToName',
        gridEventSorted: 'ngGridEventSorted',
      },
      errors: {
        workInProgress: 'Work In Progress cannot be displayed',
        policiesErrorMessage: 'An error occured loading this panel.',
        accessErrorMessage:
          "Only 'Assigned To' user is allowed to open this work item. Please contact the 'Assigned To' user or your agency's administrator.",
        accessErrorMessageTitle: 'Access Denied',
        dialogCloseText: 'CLOSE',
      },
      services: {
        suspense: 'Suspense',
      },
      tracking: {
        analyticsWIPMacro: 'WIP',
      },
      stateKeys: {
        workInProgressColumnKey: 'qnClientRecordWipColumns',
      },
      templates: {
        duedate: 'angular/ClientRecord/Summary/WorkInProgress/col.duedate.tpl.html',
        type: 'angular/ClientRecord/Summary/WorkInProgress/col.type.tpl.html',
        description: 'angular/ClientRecord/Summary/WorkInProgress/col.description.tpl.html',
        policy: 'angular/ClientRecord/Summary/WorkInProgress/col.policy.tpl.html',
        assignedto: 'angular/ClientRecord/Summary/WorkInProgress/col.assignedto.tpl.html',
        priority: 'angular/ClientRecord/Summary/WorkInProgress/col.priority.tpl.html',
      },
    },

    activities: {
      labels: {
        activities: 'Activities',
        date: 'Date',
        policy: 'Policy',
        by: 'Employee',
        activity: 'Activity',
        description: 'Description',
        activitySummary: 'Activity Summary',
        show: 'SHOW:',
        newActivity: 'NEW',
        clientId: 'clientId',
      },
      tracking: {
        activitySelected: 'Table Row#Click',
        newActivity: 'Button Group_New#Click',
        analyticsMacro: 'Activities',
        viewActivity: 'Table Row_DateLink#Click',
      },
      gridFields: {
        date: 'AttributeFields.ActivityDate',
        policyNumber: 'AttributeFields.PolicyNumber',
        by: 'AttributeFields.EmployeeName',
        activity: 'AttributeFields.Activity',
        activitySummary: 'AttributeFields.ActivitySummary',
        description: 'AttributeFields.Description',
      },
      services: {
        newActivity: 'NewActivitySuspense',
        viewActivity: 'ViewActivity',
        activity: 'Activity',
        suspense: 'Suspense',
        openSIGCustomer: 'CustomerProfile',
      },
      events: {
        gridEventData: 'ngGridEventData',
        gridEventSorted: 'ngGridEventSorted',
        gridEventColumns: 'ngGridEventColumns',
        activitiesLoaded: 'sf.activities.loaded',
        activitiesForceReload: 'notification.activities.force.reload',
      },
      errors: {
        activities: 'Activities cannot be displayed',
      },
      stateKeys: {
        activityColumnKey: 'qnClientRecordActivityColumns',
      },
    },

    header: {
      services: {
        customerDetailOpened: 'CustomerDetailOpened',
        clientRefreshed: 'ClientRefreshed',
      },
      labels: {
        ams360: 'AMS360',
        sig: 'SIG',
        accountNumber: 'Customer Number',
        notation: 'Notation',
        arBalance: 'A/R Balance',
        rep: 'Rep',
        producer: 'Producer',
        broker: 'BROKER',
        rating: 'Rating',
      },
      errors: {
        clientSummary: 'Client summary cannot be displayed.',
      },
      tracking: {
        analyticsMacro: 'Header',
        amsCustomerDetailOpened: 'AMS360 Link#Click',
        plrCustomerDetailOpened: 'Rating Link#Click',
        newActivity: 'New Activity#Click',
        clientRecordRefresh: 'Refresh#Click',
        sigCustomerDetailOpened: 'SIG Link#Click',
        newSuspense: 'New Suspense#Click',
      },
    },
    info: {
      labels: {
        primaryContact: 'PRIMARY CONTACT',
        additionalContacts: 'ADDITIONAL CONTACTS',
        dba: 'DBA',
        clientName: 'Client Name',
      },
      errors: {
        noAddress: 'No address',
        noEmail: 'No e-mail',
        noWebAddr: 'No web address',
        info: 'An Error occurred, please refresh the page and try again.',
        noDBA: 'No DBA',
        noClientName: 'No Client Name',
        noSecondaryContact: 'No Secondary Contact',
      },
      forms: {
        contactBook: 'ContactBook',
      },
    },
    services: {
      ams360: 'ams360',
      client: 'client',
      common: {
        analyticsFeature: 'Client Record',
        errors: {
          ams360Customer: 'Unable to get the AMS360 url.',
        },
      },
      data: {
        mahRecentClientSrv: 'api/gateway/ams360mah/mah/RecentClientDetail/',
      },
      policyAction: {
        continous: 'Continous',
        smartflow: 'smartflow',
        policy: 'policy',
        checklist: 'checklist',
        businessObjects: 'BusinessObjects',
        downloadEndorsement: 'DownloadEndorsement',
        nonDownloadEndorsement: 'NonDownloadEndorsement',
        cnmRenewal: 'CNMRenewal',
        cmRenewal: 'CMRenewal',
        downloadRenewal: 'DownloadRenewal',
        ciCancellation: 'CICancellation',
        npCancellation: 'NPCancellation',
        expiringPolicy: 'ExpiringPolicy',
        existingPolicy: 'ExistingPolicy',
        myActivePolicies: 'MyActivePolicies',
        reinstatementAgencyBill: 'ReinstatementAgencyBill',
        reinstatementDirectBill: 'ReinstatementDirectBill',
        clNewBusiness: 'CLNewBusiness',
        newBusiness: 'NewBusiness',
        events: {
          notificationInitiate: 'notification.process.initiate',
          downloadEndorsement: 'DownloadEndorsementInitiated',
          nonDownloadEndorsement: 'NonDownloadEndorsementInitiated',
          cnmRenewal: 'CNMRenewalInitiated',
          cmRenewal: 'CMRenewalInitiated',
          downloadRenewal: 'DownloadRenewalInitiated',
          npCancellation: 'NPCancellationInitiated',
          ciCancellation: 'CICancellationInitiated',
          reinstatementAgencyBill: 'ReinstatementAgencyBillInitiated',
          reinstatementDirectBill: 'ReinstatementDirectBillInitiated',
          submittedNewBusiness: 'CLNewBusinessInitiated',
          workqueueOpened: 'vfIconizedBar.flyout.opened.workQueueModule',
          openIconizedBar: 'vfIconizedBar.flyout.open',
          clNewBusiness: 'CLNewBusinessInitiated',
          newBusiness: 'NewBusinessInitiated',
        },
        routes: {
          workqueueModule: 'workQueueModule',
          clientDocuments: 'ClientDocuments',
        },
        tracking: {
          transactNow: 'Table Row_TnowIcon#Click',
          openExpPolicies: 'Table Row_PolicyNumber#Click',
          policyCancel: 'PolicyCancellation',
          policyEndorse: 'PolicyEndorsement',
          newActivity: 'NewActivitySuspense',
          openPolicyWindow: 'Table Row_DecPageIcon#Click',
          renewExpiryPolicy: 'PolicyRenewal',
          activity: 'Activity',
          suspense: 'Suspense',
        },
      },
    },
  },

  policyActions: {
    newPolicy: 'NewPolicy',
    renew: 'PolicyRenewal',
    cancel: 'PolicyCancellation',
    endorse: 'PolicyEndorsement',
  },
  menuLocation: {
    // bitflag
    actionMenu: 1,
    buttonMenu: 2,
    contextMenu: 4,
  },
  menuLocationAll: 7, // 1 + 2 + 4 = 7,
  expiringPolicies: {
    events: {
      gatherMenus: 'Gather.Menus.ExpiringPolicy',
    },
    expiringPolicyDataSource: 'AllExpiringPolicies',
    analyticsPanelTitle: 'Expiring Policies',
  },
  activeMenuParentIds: {
    newMenu: 'new',
    endorse: 'endorse',
    renew: 'renew',
    cancel: 'cancel',
    activity: 'activity',
    suspense: 'suspense',
  },
};
