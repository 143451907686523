import { ContentHeader, MessageBar, Spacer, VertaFormik } from 'alloy-foundation';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { NameCheckPayload } from '../../../../models/putPayloads/NameCheckPayload';
import { Version } from '../../../../models/QuickNavInstance';
import { LocalizedLeaveWithoutSaveGuard } from '../../../common/localizedLeaveWithoutSaveGuard/LocalizedLeaveWithoutSaveGuard';
import DefaultPageLayout from '../../../common/page-layout/DefaultPageLayout';
import DuplicatedModal from '../DuplicatedModal';
import QuickNavModal, { ModalProps } from '../QuickNavModal';
import {
  useCheckDuplicatedName,
  useInstance,
  usePublishDraft,
  usePublishVersion,
  useSaveDraft,
  useVersion,
} from '../useInstance';
import { useQuickNav, invalidateQuickNavTemplatesCache } from '../useQuickNav';
import InstanceEditForm from './InstanceEditForm';
import instanceValidationSchema from './InstanceValidationSchema';
import { withLayoutShell } from '../../../common/hoc/withLayoutShell';

type VersionParams = {
  busId: string;
  id: string;
};
type TemplateInfo = {
  title: string;
  type: string;
  shortTitle: string;
};
const InstanceEdit = () => {
  const { id, busId } = useParams<VersionParams>();
  const queryClient = useQueryClient();
  const quickNavQuery = useQuickNav();
  const [instanceId, setInstanceId] = useState();
  const [busProcessId, setBusProcessId] = useState<string>();
  const [preventSaveDraft, setPreventSaveDraft] = useState<boolean>();
  const [isVisible, setIsVisible] = useState<boolean>();
  const [updatedVersion, setUpdatedVersion] = useState<Version>();
  const [initialVersion, setInitialVersion] = useState<Version>();
  const [modalData, setModalData] = useState<ModalProps>(null);
  const [duplicatedModalVisible, setDuplicatedModalVisible] = useState<boolean>();
  const { formatMessage } = useIntl();
  const versionInfo = useVersion(id, {
    onSuccess(data) {
      setBusProcessId(
        data.BusinessProcess.IsVariation
          ? data.BusinessProcess.ParentId
          : data.BusinessProcessDefinitionId
      );
    },
  });
  const instancesInfo = useInstance(busProcessId, {
    enabled: busProcessId !== undefined,
  });

  useEffect(() => {
    if (versionInfo.isSuccess && instancesInfo.isSuccess) {
      const instance = instancesInfo?.data?.find((item) => item.Id.toString() === busId);
      const preventSave =
        busId !== undefined && !versionInfo?.data?.IsDraft && instance?.DraftVersionId !== 0;

      setPreventSaveDraft(preventSave);
      setIsVisible(preventSave);
    }
  }, [
    instancesInfo.data,
    versionInfo?.data?.IsDraft,
    busId,
    instancesInfo.isSuccess,
    versionInfo.isSuccess,
  ]);
  const [templateInfo, setTemplateInfo] = useState<TemplateInfo>();
  const history = useHistory();

  const saveDraftMutation = useSaveDraft({
    onSuccess() {
      invalidateQuickNavTemplatesCache(queryClient);
      history.push(`/quicknav/configure/${instanceId}`);
    },
  });

  const publishDraftMutation = usePublishDraft({
    onSuccess() {
      setUpdatedVersion(null);
      invalidateQuickNavTemplatesCache(queryClient);
      history.push(`/quicknav/configure/${instanceId}`);
    },
  });

  const publishMutation = usePublishVersion({
    onSuccess() {
      history.push(`/quicknav/configure/${instanceId}`);
      setUpdatedVersion(null);
      versionInfo.refetch();
    },
  });

  const checkDuplicateMutation = useCheckDuplicatedName({
    onSuccess(data) {
      if (data === false && updatedVersion) {
        handlePublishing();
      } else {
        setDuplicatedModalVisible(true);
      }
    },
  });

  const handlePublishingWithoutModal = () => {
    if (updatedVersion) {
      if (versionInfo?.data?.IsDraft) {
        publishDraftMutation.mutate(reorderSequenceNum(updatedVersion));
      } else {
        publishMutation.mutate({
          version: reorderSequenceNum(updatedVersion),
          isNewInstance: busId === undefined,
        });
      }
    }
  };
  const handlePublishing = () => {
    if (updatedVersion) {
      if (versionInfo?.data?.IsDraft) {
        setModalData({
          Name: updatedVersion.Title,
          Title: formatMessage({ id: 'quickNav.configuration.instances.publish.title' }),
          SubTitle: formatMessage({
            id: 'quickNav.configuration.instances.publish.subtitle.pt1',
          }),
          SubTitle2: formatMessage({
            id: 'quickNav.configuration.instances.publish.subtitle.pt2',
          }),
          confirmText: formatMessage({
            id: 'quickNav.configuration.instances.publish.button',
          }),
          onCancel: () => {
            setModalData(null);
          },
          onConfirm: () => {
            publishDraftMutation.mutate(reorderSequenceNum(updatedVersion));
          },
        });
      } else {
        setModalData({
          Name: updatedVersion.Title,
          Title: formatMessage({ id: 'quickNav.configuration.instances.publish.title' }),
          SubTitle: formatMessage({
            id: 'quickNav.configuration.instances.publish.subtitle.pt1',
          }),
          SubTitle2: formatMessage({
            id: 'quickNav.configuration.instances.publish.subtitle.pt2',
          }),
          confirmText: formatMessage({
            id: 'quickNav.configuration.instances.publish.button',
          }),
          onCancel: () => {
            setModalData(null);
          },
          onConfirm: () => {
            publishMutation.mutate({
              version: reorderSequenceNum(updatedVersion),
              isNewInstance: busId === undefined,
            });
          },
        });
      }
    }
  };

  const handlePublishWithModal = (data: Version) => {
    setModalData({
      Name: data.Title,
      Title: formatMessage({ id: 'quickNav.configuration.instances.publish.title' }),
      SubTitle: formatMessage({
        id: 'quickNav.configuration.instances.publish.subtitle.pt1',
      }),
      SubTitle2: formatMessage({
        id: 'quickNav.configuration.instances.publish.subtitle.pt2',
      }),
      confirmText: formatMessage({
        id: 'quickNav.configuration.instances.publish.button',
      }),
      onCancel: () => {
        setModalData(null);
      },
      onConfirm: () => {
        publishMutation.mutate({
          version: reorderSequenceNum(data),
          isNewInstance: busId === undefined,
        });
      },
    });
  };

  const reorderSequenceNum = (version: Version) => {
    version?.Steps.map((item, index) => (item.SequenceNumber = index + 1));
    return version;
  };
  const getUniversalType = (type: string) => {
    switch (type) {
      case 'New':
        return 'NewPolicy';
      case 'Endorse':
        return 'PolicyEndorsement';
      case 'Renew':
        return 'PolicyRenewal';
      case 'Cancel':
        return 'PolicyCancellation';
    }
  };
  useEffect(() => {
    if (quickNavQuery?.data && versionInfo?.data) {
      const template = quickNavQuery?.data.find(
        (item) => item.ShortTitle === versionInfo?.data?.ShortTitle
      );
      setTemplateInfo({
        title: template?.DisplayName,
        shortTitle: template?.ShortTitle,
        type: template?.Type,
      });
      setInstanceId(
        versionInfo?.data?.BusinessProcess?.IsVariation
          ? versionInfo?.data?.BusinessProcess?.ParentId
          : versionInfo?.data?.BusinessProcess?.Id
      );
    }
  }, [quickNavQuery?.data, versionInfo?.data]);
  useEffect(() => {
    const initial = versionInfo?.data;
    initial?.Steps.forEach((item) => (item.UrlPlaceHolder = item.Title));
    setInitialVersion(initial);
  }, [versionInfo?.data]);
  return (
    <DefaultPageLayout hideContentHeader>
      {versionInfo?.data &&
        quickNavQuery?.data &&
        instanceId &&
        templateInfo &&
        initialVersion &&
        preventSaveDraft !== undefined && (
          <>
            {preventSaveDraft && isVisible && (
              <>
                <MessageBar
                  title="Attention"
                  variant="warning"
                  hideClose={false}
                  onClose={() => setIsVisible(false)}
                >
                  {formatMessage({
                    id: 'quickNav.configuration.instances.edit.step.warning.draft',
                  })}
                </MessageBar>
                <Spacer mb="small" />
              </>
            )}
            <ContentHeader title={templateInfo?.title ?? ''}></ContentHeader>
            <VertaFormik
              initialValues={initialVersion}
              validationSchema={instanceValidationSchema(formatMessage)}
              onSubmit={(data, { resetForm, setFormikState }) => {
                if (data.IsSaveDraft) {
                  // Save as a new draft or edit draft
                  saveDraftMutation.mutate({
                    draft: reorderSequenceNum(data),
                    isNewInstance: busId === undefined,
                  });
                } else {
                  // Publishing either draft or version
                  if (
                    versionInfo?.data?.IsCurrentlyPublished &&
                    versionInfo?.data?.Title === data.Title &&
                    versionInfo?.data?.IsTemplate === false &&
                    busId !== undefined
                  ) {
                    handlePublishWithModal(data);
                    // The case when user edits the current published version
                    resetForm({
                      // Set value back to data so that user won't lose changes in steps, description,...
                      values: {
                        ...data,
                      },
                    });
                  } else {
                    // Check duplicated name before publishing
                    setUpdatedVersion(data);
                    const payload = {
                      Type: getUniversalType(templateInfo?.type),
                      ShortTitle: templateInfo?.shortTitle,
                      DisplayName: data?.Title,
                    } as NameCheckPayload;
                    checkDuplicateMutation.mutate(payload);
                    resetForm({
                      // Set value back to data so that user won't lose changes in steps, description,...
                      values: {
                        ...data,
                      },
                    });
                  }
                }
              }}
            >
              {({ dirty, isSubmitting }) => (
                <>
                  <InstanceEditForm instanceId={instanceId} preventSaveDraft={preventSaveDraft} />
                  <DuplicatedModal
                    visible={duplicatedModalVisible}
                    hide={() => setDuplicatedModalVisible(false)}
                    handlePublishing={() => {
                      setDuplicatedModalVisible(false);
                      handlePublishingWithoutModal();
                    }}
                  />
                  <LocalizedLeaveWithoutSaveGuard
                    shouldBlockNavigation={() => {
                      return dirty && saveDraftMutation.isIdle;
                    }}
                    when={dirty && !isSubmitting}
                    navigate={(path) => {
                      history.push(path);
                    }}
                  />
                </>
              )}
            </VertaFormik>
          </>
        )}
      {modalData && <QuickNavModal {...modalData}></QuickNavModal>}
    </DefaultPageLayout>
  );
};

export default withLayoutShell(InstanceEdit);
