module.exports = {
  'topnav.my_account.dashboard_settings': 'Paramètres du tableau de bord',
  'topnav.my_account.logout': 'Se déconnecter',
  'topnav.my_account.location': 'Emplacement du bureau de courtage',
  'topnav.my_account.my_account': 'Mon compte',
  'topnav.my_account.my_profile': 'Mon Profil',
  'topnav.my_account.switch_language': 'Switch Language to: English',
  'topnav.manager.title': "Paramètres d'administration",
  'topnav.manager.config': 'Configuration du gestionnaire',
  'topnav.manager.quicknav': 'Configuration QuickNav',
  'common.button.cancel': 'Annuler',
  'common.button.save': 'Enregistrer',
  'common.button.ok': 'OK',
  'common.button.new': 'Nouvelle',
  'common.button.apply': 'Appliquer',
  'common.button.back': 'Retour',
  'common.button.next': 'Suivant',
  'common.validation.invalidDate': 'Veuillez entrer une date valide',
  'common.validation.invalidEmail': 'Veuillez entrer une adresse de courriel valide',
  'common.validation.invalidPhone': 'Veuillez entrer un numéro de téléphone valide',
  'common.validation.invalidURL': 'Veuillez entrer une URL valide',
  'common.validation.required': 'Ce champ est requis',
  'common.validation.invalidPostalCodeFormat': 'Veuillez entrer un code postal valide (ANA NAN)',
  'common.due_today': "Dû aujourd'hui",
  'common.incomplete': 'Incomplet',
  'common.date_range_120': 'Dans les 120 prochains jours',
  'common.error_loading_data': "Une erreur s'est produite lors de la récupération des données",
  'common.actions.newActivity': 'Nouvelle activité',
  'common.actions.newSuspense': 'Nouvelle mise en attente',
  'common.date.nextMonth': 'Mois suivant',
  'common.date.previousMonth': 'Mois précédent',
  'common.date.placeholder': 'MM/JJ/AAAA',
  'common.active': 'Actif',
  'common.inactive': 'Inactif',
  'common.advanceSearch': 'Recherche avancée',
  'common.advanceSearch.clear': 'Effacer',
  'common.english': 'Anglais',
  'common.french': 'Français',
  'common.select': 'Sélectionner',
  'common.search.placeholder': 'Taper ici pour rechercher...',
  'common.today': "Aujourd'hui",
  'common.today.reduced': 'Auj.',
  'commnon.accessDenied': 'Accès refusé',
  'common.title': 'MonEspaceCourtier',
  'datatable.paging.next': 'Suivant',
  'datatable.paging.previous': 'Précédent',
  'datatable.rowcount.beforeText': 'Affiche présentement',
  'datatable.rowcount.afterText': 'de rangées par page',

  'customer.createCustomer.title': 'Nouveau Client',
  'customer.validationError.field1': 'Veuillez entrer un Field #1 valide',
  'customer.validationError.field2': 'Veuillez entrer un Field #2 valide',
  'customer.createSuccess': 'Client créé avec succès',
  'customer.saveSuccess': 'Client mis à jour avec succès',
  'customer.create': 'Créer',
  'customer.save': 'Sauvegarder',
  'customer.createError': 'Un problème est survenu lors de la sauvegarde du client.',
  'success.toasts.header': 'Succès',
  'error.toasts.header': 'Erreur',
  'customer.search.customerNumber': 'ID#',
  'customer.search.name': 'Nom',
  'customer.search.address': 'Adresse',
  'customer.search.postalCode': 'Code postal',
  'customer.search.contactMethod': 'Téléphone / Courriel',
  'customer.search.Active': 'Statut',
  'customer.search.selectStatus': 'Sélectionnez le(s) statut(s)',
  'customer.search.selectAccountType': 'Sélectionnez un type de compte',
  'customer.search.selectCustomerType': 'Sélectionnez un type de client',
  'customer.search.Match': 'Corresp.',
  'customer.details.title': 'Détails sur le client',
  'customer.details.businessInformation.businessUnit': 'Unité opérationnelle',
  // MISSING TRANSLATION
  'customer.details.businessInformation.title': 'Information sur le cabinet de courtage',
  'customer.details.currentAddress.title': 'Adresse courante',
  'customer.details.address.title': 'Adresse',
  'customer.details.currentAddress.postalCode.label': 'Code postal',
  'customer.details.currentAddress.city.label': 'Ville',
  'customer.details.currentAddress.province.label': 'Province',
  'customer.details.currentAddress.province.placeholder': 'Sélectionner une province...',
  'customer.details.currentAddress.addressLine.label': 'Adresse 1',
  'customer.details.currentAddress.addressLine2.label': 'Adresse 2',
  'customer.details.currentAddress.civicNumber': 'Numéro civique',
  'customer.details.currentAddress.civicNumber.message': 'Veuillez entrer le numéro civique',
  'customer.details.currentAddress.matches.range': 'Intervalle',
  'customer.details.currentAddress.matches.address': 'Adresse',
  'customer.details.currentAddress.matches.city': 'Ville',
  'customer.details.currentAddress.matches.province': 'Province',
  'customer.details.currentAddress.matches.postalCode': 'Code postal',
  'customer.details.currentAddress.matches.header':
    'Des correspondances possibles ont été trouvées.',
  'customer.details.currentAddress.matches.subheader': 'Sélectionnez une correspondance',
  'customer.details.currentAddress.matches.showmatches':
    'Afficher toutes les correspondances possibles.',
  'customer.details.businessInformation.typeOfBusiness': 'Type de compte',
  'customer.details.businessInformation.typeOfBusiness.Commercial': 'Entreprise',
  'customer.details.businessInformation.typeOfBusiness.Personal': 'Particulier',
  'customer.details.businessInformation.customerClass': 'Classe du client',
  'customer.details.businessInformation.customerClasses.1': 'Classe du client 1',
  'customer.details.businessInformation.customerClasses.2': 'Classe du client 2',
  'customer.details.businessInformation.customerClasses.3': 'Classe du client 3',
  'customer.details.businessInformation.customerClasses.4': 'Classe du client 4',
  'customer.details.businessInformation.customerClasses.placeholder': 'Sélectionner une classe...',
  'customer.details.businessInformation.division': 'Division',
  'customer.details.businessInformation.division.placeholder': 'Sélectionner une division...',
  'customer.details.businessInformation.department': 'Département',
  'customer.details.businessInformation.department.placeholder': 'Sélectionner un département...',
  'customer.details.currentAddress.matches.filter.empty': 'Aucune correspondance trouvée',
  'customer.details.currentAddress.matches.filter.placeholder': 'Tapez ici pour rechercher...',
  'customer.details.brokeragePersonnel': 'Personnel du cabinet de courtage',
  'customer.details.brokeragePersonnel.AccountExec.placeholder':
    'Sélectionner un chargé de compte...',
  'customer.details.brokeragePersonnel.AccountRep.placeholder': 'Sélectionner un représentant...',
  'customer.details.brokeragePersonnel.AccountExec1': 'Chargé de compte',
  'customer.details.brokeragePersonnel.AccountExec2': 'Chargé de compte 2',
  'customer.details.brokeragePersonnel.AccountRep1': 'Représentant',
  'customer.details.brokeragePersonnel.AccountRep2': 'Représentant 2',
  'customer.details.generalInfo': 'Informations générales',

  'topnav.actions.newCustomer': 'Nouveau Client',
  'topnav.actions.newActivity': 'Nouvelle activité',
  'topnav.actions.newSuspense': 'Nouvelle mise en attente',
  'customer.details.base.type.label': 'Type de client',
  'customer.details.base.type.placeholder': 'Sélectionner un type de client...',
  'customer.details.base.language.label': 'Langue',
  'customer.details.base.language.placeholder': 'Sélectionner une langue...',
  'customer.details.base.subbroker.label': 'Sous-agent',
  'customer.details.base.subbroker.placeholder': 'Sélectionner un sous-agent...',
  'customer.details.personal.prefix.label': 'Préfixe',
  'customer.details.personal.prefix.sc.label': 'Préfixe secondaire',
  'customer.details.personal.prefix.placeholder': 'Sélectionner un préfixe...',
  'customer.details.personal.firstname.label': 'Prénom',
  'customer.details.personal.lastname.label': 'Nom',
  'customer.details.personal.middlename.label': 'Initiale',
  'customer.details.personal.firstname.sc.label': 'Prénom secondaire',
  'customer.details.personal.lastname.sc.label': 'Nom secondaire',
  'customer.details.personal.middlename.sc.label': 'Initiale secondaire',
  'customer.details.personal.dob.label': 'Date de naissance',
  'customer.details.personal.maritalstatus.label': 'État civil',
  'customer.details.personal.maritalstatus.placeholder': 'Sélectionner un état civil...',
  'customer.details.commercial.firmname.label': "Nom de l'entreprise",
  'customer.details.commercial.firmdba.label': "Raison sociale de l'entreprise",
  'customer.details.privacyAct': 'Loi sur la protection des renseignements personnels',
  'customer.details.personalInformationConsent': 'Consentement coll. rens. pers.',
  'customer.details.consentForOtherUse': 'Consentement pour autre usage',
  'customer.details.dateOfConsent': 'Date du consentement',
  'customer.contactMethods.phoneNumber': 'Numéro de téléphone',
  'customer.contactMethods.phoneNumberExtension': 'Ext.',
  // Duplicated in English since the French header need to be plurals.
  'customer.contactMethods.phoneNumberHeader': 'Numéro de téléphone',
  'customer.contactMethods.phoneType': 'Type de téléphone',
  'customer.contactMethods.phoneType.placeholder': 'Sélectionner un type de téléphone...',
  'customer.contactMethods.CASLConsent': 'Consent. LCAP',
  'customer.contactMethods.CASLConsentDate': 'Date du consentement',
  'customer.contactMethods.addPhoneNumber': 'Ajouter nº téléphone',
  'customer.contactMethods.deletePhoneNumber': 'Supprimer',

  'customer.contactMethods.emailHeader': 'Courriel/Site internet',
  'customer.contactMethods.emailType': 'Type de courriel',
  'customer.contactMethods.addEmail': 'Ajouter un courriel',
  'customer.contactMethods.deleteEmail': 'Supprimer',
  'customer.contactMethods.emailType.placeholder': 'Sélectionner un type de courriel...',
  'customer.contactMethods.emailAddress': 'Adresse de courriel',
  'customer.contactMethods.website': 'Site internet',
  'customer.contactMethods.preferedContactMethod': 'Méthode préférée de contact',
  'customer.contactMethods.preferedContactMethod.header': 'Méthode préférée de contact',
  'customer.contactMethods.preferedContactMethod.placeholder':
    'Sélectionner une méthode préférée de contact...',
  'customer.contactMethods.notApplicable': 'N/A',

  'customer.secondaryContact.header': 'Contact secondaire',
  'customer.secondaryContact.AddButton': 'Ajouter un contact secondaire',

  'customer.summary.title': 'Sommaire',
  'customer.summary.toolTipText': "Pour éditer, cliquez l'icône du Crayon",
  'customer.summary.suspense': 'Mise en attente',

  'customer.search.title': 'Résultats',
  'customer.search.placeholder': 'Taper ici pour rechercher...',
  'customer.search.multipleResults':
    'Nous avons trouvé plusieurs résultats correspondant à "{term}"',
  'customer.search.noResults': 'Aucun résultat trouvé correspondant à "{displayTerm}"',
  'customer.search.addtiontalInfo.title': 'Information supplémentaire',
  'customer.search.addtiontalInfo.xref': 'X-référence',
  'customer.unlock': 'Ouvrir',
  'customer.lock.message.title': 'Client verrouillé',
  'customer.lock.message.subtitle': 'Ce client est présentement utilisé par',
  'customer.lock.message.name': 'Nom',
  'customer.lock.message.workstation': 'Poste de travail',
  'customer.lock.message.branch': 'Branche',
  'customer.lock.message.readonly':
    'Les renseignements de ce client sont en mode visualisation seulement.',

  'common.yes': 'Oui',
  'common.no': 'Non',

  'common.dropdown.placeholder': 'Choisir une valeur...',
  'dashboard.setting.title': "Personnaliser l'affichage",
  'dashboard.setting.subtitle':
    "Activez une section pour l'inclure dans votre tableau de bord. Vous pouvez également cliquer sur les sections et les glisser pour les mettre dans un ordre différent. Les sessions verrouillées ne peuvent être supprimées.",
  'dashboard.setting.sectionTiles': 'Tuiles de section',
  'dashboard.setting.todoList': 'Liste de tâches',
  'dashboard.setting.suspense': 'Groupes de travail',
  'dashboard.setting.expiringPolicies': 'Polices à échéance',
  'dashboard.tiles.todo_list_title': 'Liste de tâches',
  'dashboard.tiles.suspense_workgroups_title': 'Groupes de travail',
  'dashboard.tiles.WorkQueue_title': "File d'attente",
  'dashboard.tiles.expiring_policies_title': 'Polices à échéance',
  'dashboard.myWork': 'Mon travail',
  'dashboard.managerDashboard': 'Tableau de bord du gestionnaire',
  'dashboard.panel.dateRangeFilter.placeholder': 'Filtrer par plage de dates',
  'dashboard.panel.dateRangeFilter.all': 'Tous',
  'dashboard.panel.dateRangeFilter.dueToday': "Dû aujourd'hui",
  'dashboard.panel.dateRangeFilter.pastDue': 'Échu/en retard',
  'dashboard.panel.data.empty': 'Aucune donnée à afficher.',
  'dashboard.panel.custom_filter': 'Effacer le filtre personnalisé',
  'dashboard.panel.todoList.title': 'Liste de tâches',
  'dashboard.panel.todoList.complete': 'Compléter',
  'dashboard.panel.todoList.completed': 'Complété',
  'dashboard.panel.todoList.reopen': 'Reprendre',
  'dashboard.panel.todoList.incomplete': 'Incomplet',
  'dashboard.panel.todoList.toolTipText':
    'Mises en attente - Cliquez sur le calendrier ci-dessus pour la File d’attente',
  'dashboard.panel.actions.title': 'Actions',
  'dashboard.panel.actions.export': 'Exporter',
  'dashboard.panel.actions.options': 'Options',
  'dashboard.panel.actions.refresh': 'Rafraîchir',
  'dashboard.panel.todoList.columns.name.header': 'Nom',
  'dashboard.panel.todoList.columns.dueDate.header': "Date d'échéance",
  'dashboard.panel.todoList.columns.priority.header': 'Priorité',
  'dashboard.panel.todoList.columns.reason.header': 'Raison',
  'dashboard.panel.todoList.columns.status.header': 'Statut',
  'dashboard.panel.todoList.columns.policy.header': 'Police',
  'dashboard.panel.todoList.columns.description.header': 'Description',
  'dashboard.panel.todoList.options.showToDoItems': 'Afficher les tâches:',
  'dashboard.panel.todoList.options.dueDaysFromNow': "Dû juqu'à X jours à partir de maintenant",
  'dashboard.panel.todoList.options.or': 'Ou',
  'dashboard.panel.todoList.options.dueDateFrom': 'Du',
  'dashboard.panel.todoList.options.dueDateTo': 'Au',
  'dashboard.panel.todoList.options.and': 'Et',
  'dashboard.panel.todoList.options.action': 'Action',
  'dashboard.panel.todoList.options.priority': 'Priorité',
  'dashboard.panel.todoList.options.completed': 'Complété',
  'dashboard.panel.todoList.options.notCompleted': 'Non Complété',

  'dashboard.panel.expiringPoliciesList.title': 'Polices à échéance',
  'dashboard.panel.expiringPoliciesList.toolTipText':
    'Cliquez sur Actions pour les filtres avancés',
  'dashboard.panel.expiringPoliciesList.columns.customerName.header': 'Client',
  'dashboard.panel.expiringPoliciesList.columns.policyNo.header': 'Police',
  'dashboard.panel.expiringPoliciesList.columns.expirationDate.header': 'Expiration',
  'dashboard.panel.expiringPoliciesList.columns.carrierName.header': 'Compagnie',
  'dashboard.panel.expiringPoliciesList.columns.fullTermPremium.header': 'Prime plein terme',
  'dashboard.panel.expiringPoliciesList.columns.description.header': 'Description',
  'dashboard.panel.expiringPoliciesList.columns.repExec.header': 'Rep / Chargé',
  'dashboard.panel.expiringPoliciesFilter.placeholder': 'Filtrer par',
  'dashboard.panel.expiringPoliciesFilter.representative': 'Représentant',
  'dashboard.panel.expiringPoliciesFilter.executive': 'Chargé de compte',
  'dashboard.panel.expiringPoliciesList.renew': 'Renouveler',
  'dashboard.panel.expiringPoliciesList.options.showExpiringPolicies':
    'Afficher les polices à échéance',
  'dashboard.panel.expiringPoliciesList.options.preDefinedDateRange': 'Plage de dates prédéfinie',
  'dashboard.panel.expiringPoliciesList.options.expirationsBetween':
    "Avec des dates d'expiration entre",
  'dashboard.panel.expiringPoliciesList.options.or': 'Ou',
  'dashboard.panel.expiringPoliciesList.options.dueDateFrom': 'Du',
  'dashboard.panel.expiringPoliciesList.options.dueDateTo': 'Au',
  'dashboard.panel.expiringPoliciesList.options.userDefined': "Defini par l'utilisateur",

  'dashboard.panel.todoListWorkgroup.title': 'Groupes de travail',
  'dashboard.panel.todoListWorkgroup.toolTipText': 'Cliquez sur Actions pour les filtres avancés',
  'dashboard.panel.todoListWorkgroup.complete': 'Compléter',
  'dashboard.panel.todoListWorkgroup.completed': 'Complété',
  'dashboard.panel.todoListWorkgroup.reopen': 'Reprendre',
  'dashboard.panel.todoListWorkgroup.incomplete': 'Incomplet',
  'dashboard.panel.todoListWorkgroup.columns.name.header': 'Nom',
  'dashboard.panel.todoListWorkgroup.columns.dueDate.header': "Date d'échéance",
  'dashboard.panel.todoListWorkgroup.columns.priority.header': 'Priorité',
  'dashboard.panel.todoListWorkgroup.columns.to.header': 'Groupe',
  'dashboard.panel.todoListWorkgroup.columns.reason.header': 'Raison',
  'dashboard.panel.todoListWorkgroup.columns.status.header': 'Statut',
  'dashboard.panel.todoListWorkgroup.columns.policy.header': 'Police',
  'dashboard.panel.todoListWorkgroup.columns.description.header': 'Description',
  'dashboard.panel.todoListWorkgroup.options.showToDoItems': 'Afficher les tâches:',
  'dashboard.panel.todoListWorkgroup.options.dueDaysFromNow':
    "Dû juqu'à X jours à partir de maintenant",
  'dashboard.panel.todoListWorkgroup.options.or': 'Ou',
  'dashboard.panel.todoListWorkgroup.options.dueDateFrom': 'Du',
  'dashboard.panel.todoListWorkgroup.options.dueDateTo': 'Au',
  'dashboard.panel.todoListWorkgroup.options.and': 'Et',
  'dashboard.panel.todoListWorkgroup.options.action': 'Action',
  'dashboard.panel.todoListWorkgroup.options.priority': 'Priorité',
  'dashboard.panel.todoListWorkgroup.options.workgroup': 'Groupe de travail',
  'dashboard.panel.todoListWorkgroup.options.completed': 'Complété',
  'dashboard.panel.todoListWorkgroup.options.notCompleted': 'Non Complété',
  'dashboard.panel.common.changeSuspenseStatus.title': 'Confirmation de modification du statut',
  'dashboard.panel.common.changeSuspenseStatus.body':
    "Voulez-vous modifier le statut de l'élément sélectionné?",
  'dashboard.panel.common.changeSuspenseStatusError.body': 'Impossible de modifier le statut',
  'dashboard.manager.panel.title': 'Aperçu des QuickNavs',
  'dashboard.manager.panel.pastDue': 'Échu/En retard',
  'dashboard.manager.panel.currentDue': 'En cours',
  'dashboard.manager.panel.filters.status': 'Statut',
  'dashboard.manager.panel.filters.status.placeholder': 'Filtrer par statut',
  'dashboard.manager.panel.filters.assignedto': 'Assigné à',
  'dashboard.manager.panel.filters.assignedto.placeholder': 'Filtrer selon Assigné à',
  'dashboard.manager.panel.fitlers.typeofQuickNav': 'Type de QuickNav',
  'dashboard.manager.panel.filters.typeofQuickNav.placeholder': 'Filtrer par type',

  'dashboard.manager.panel.columns.assignedto': 'Assigné à',
  'dashboard.manager.panel.columns.assignto': 'Assigner à',
  'dashboard.manager.panel.columns.stepDueDate': "Date d'échéance",
  'dashboard.manager.panel.columns.currentStep': 'Étape courante',
  'dashboard.manager.panel.columns.customer': 'Client',
  'dashboard.manager.panel.columns.policyNo': '# Police',
  'dashboard.manager.panel.columns.type': 'Type',
  'dashboard.manager.panel.options.exportAll': 'Exporter tout',
  'dashboard.manager.panel.options.reassign': 'Réassigner',
  'dashboard.manager.panel.options': 'Options',

  'dashboard.manager.reassign.quicknav.title': 'Réassigner QuickNav',
  'dashboard.manager.reassign.quicknav.placeholder':
    'Tapez pour rechercher ou sélectionner dans la liste',
  'dashboard.manager.reassign.quicknav.modal.title': 'Êtes-vous certain de vouloir annuler?',
  'dashboard.manager.reassign.quicknav.modal.description':
    'Vous êtes sur le point de quitter cette page avec des modifications non enregistrées. Souhaitez-vous continuer à réassigner les items de travail ou quitter cette page et supprimer toutes les modifications non enregistrées?',
  'dashboard.manager.reassign.quicknav.modal.continue': 'Poursuivre la réassignation',
  'dashboard.manager.reassign.quicknav.modal.leave': 'Quitter et supprimer les changements',
  'dashboard.manager.reassign.quicknav.toast.success': 'Votre réassignation a réussi.',

  'drawer.recentCustomers.title': 'Clients les plus récents',
  'drawer.recentCustomers.customerId': 'No. ID du client ',
  'drawer.workItem.title': 'Item de travail',
  'drawer.workItem.header.initiated': 'Initié',
  'drawer.workItem.header.due': 'Dû',
  'drawer.workItem.header.term': 'Terme',
  'drawer.workItem.header.policy': 'Police',
  'drawer.workItem.header.effectiveDate': 'Date de transaction',
  'drawer.workItem.header.assignedTo': 'Assigné à',
  'drawer.workItem.noPermission':
    "Seul l'utilisateur 'Assigné à' a la permission d'ouvrir cet item de travail.  Veuillez contacter l'utilisateur 'Assigné à' ou l'administrateur.",
  'drawer.workItem.launchStep': "Lancer l'étape",
  'drawer.workItem.completeStep': 'Étape terminée',
  'drawer.workItem.makeStepNotApplicable': "Rendre l'étape non applicable",
  'drawer.workQueue.title': "File d'attente",
  'drawer.workQueue.back': "Retour à file d'attente",
  'drawer.workQueue.actions.reassign': 'Réassigner',
  'drawer.workQueue.actions.reassign.to': 'Assigner à: ',
  'drawer.workQueue.actions.delete': 'Supprimer',
  'drawer.workQueue.actions.delete.title': "Supprimer l'item de travail",
  'drawer.workQueue.actions.delete.subtitle':
    "Supprimer un item de travail le supprime de façon permanente de votre file d'attente.",
  'drawer.workQueue.actions.deleteManager.subtitle':
    "Supprimer un item de travail le supprime de façon permanente de la file d'attente.",
  'drawer.workQueue.actions.reassign.title': "Réassigner l'item de travail",
  'drawer.workQueue.actions.reassign.subtitle':
    "Utiliser la barre de recherche ci-dessous pour trouver la personne à qui vous désirez réassigner l'item de travail",
  'drawer.workQueue.card.customer': 'Client:',
  'drawer.workQueue.card.due': 'Échéance:',
  'drawer.workQueue.empty': "Aucune instance dans la file d'attente",
  'drawer.workQueue.search.noResults_term': 'Aucun résultat trouvé correspondant à {term}',
  'drawer.workQueue.search.noResults': 'Aucun résultat trouvé',
  'drawer.workQueue.priority.critical': 'Critique',
  'drawer.workQueue.priority.normal': 'Normal',
  'drawer.workQueue.filter.amountOfDays': 'Nombre de jours',
  'drawer.workQueue.filter.dateRange': 'Plage de dates',
  'drawer.workQueue.filter.showAll': 'Afficher tout',
  'drawer.workQueue.filter.amountOfDays.error': 'Veuillez sélectionner un nombre de jours valide',
  'drawer.workQueue.filter.dateRange.error': 'Veuillez sélectionner une plage de dates valide',
  'drawer.workQueue.filter.amountOfDays.toggleText': '{from} - {to} jours',
  'drawer.workQueue.filter.showAll.toggleText': 'Tout',
  'leaveWithoutSaveGuard.body':
    "Vous n'avez pas sauvegardé vos modifications. Si vous quittez maintenant, vous perdrez toutes vos modifications.",
  'leaveWithoutSaveGuard.cancelNavigationButtonText': 'Rester sur la page',
  'leaveWithoutSaveGuard.confirmNavigationButtonText': 'Quitter sans sauvegarder',
  'leaveWithoutSaveGuard.title': 'Êtes-vous certain de vouloir quitter?',

  'customer.details.contactInfo.header': 'Information de contact',
  'contactMethods.PersonalEmail': 'Courriel personnel',
  'contactMethods.WorkEmail': 'Courriel au travail',
  'contactMethods.Website': 'Site internet',

  'contactMethods.ResidentialPhone': 'Téléphone résidentiel',
  'contactMethods.BusinessPhone': 'Téléphone au travail',
  'contactMethods.FaxNumber': 'Fax',
  'contactMethods.CellPhone': 'Cellulaire',
  'contactMethods.EmergencyPhone': "Téléphone d'urgence",
  'contactMethods.SeasonalNumber': 'Tél. saisonnier',
  'contactMethods.Text': 'Texte',
  'contactMethods.Pager': 'Téléavertisseur',

  'activityList.Employee': 'Employé',
  'activityList.Activity': 'Activité',
  'activityList.Date': 'Date',
  'activityList.Policy': 'Police',
  'activityList.Description': 'Description',
  'activityList.ActivitySummary': "Sommaire de l'activité",
  'activityList.title': 'Activités',
  'activityList.toolTipText': "Grille basée sur l'option Ajuster l'affichage de SIG",

  'workInProgress.Header': 'Travail en cours',
  'workInProgress.toolTipText': 'QuickNavs et Mises en attente',
  'workInProgress.DueDate': "Date d'échéance",
  'workInProgress.Type': 'Type',
  'workInProgress.Description': 'Description',
  'workInProgress.Policy': 'Police',
  'workInProgress.AssignedTo': 'Assigné à',
  'workInProgress.Priority': 'Priorité',

  'customer.policies.Header': 'Polices',
  'customer.policies.toolTipText': "Grille basée sur l'option Ajuster l'affichage de SIG",
  'customer.policies.Policy': 'Police',
  'customer.policies.Term': 'Terme',
  'customer.policies.Status': 'Statut',
  'customer.policies.Company': 'Compagnie',
  'customer.policies.Premium': 'Prime Annuelle',
  'customer.policies.option.CurrentFuture': 'Courante et future',
  'customer.policies.option.Cancelled': 'Annulée',
  'customer.policies.option.All': 'Tous',
  'customer.policies.option.Lapsed': 'Échue',
  'customer.policies.filter.Endorse': 'Modifier',
  'customer.policies.filter.Renew': 'Renouveler',
  'customer.policies.filter.Cancel': 'Annuler',
  'customer.policies.filter.NewActivity': 'Nouvelle activité',
  'customer.policies.filter.NewSuspense': 'Nouvelle mise en attente',
  'customer.bottomBar.errorMessage':
    "Il y a des erreurs dans une ou plusieurs zones. Veuillez réviser chaque zone pour des détails, lorsqu'applicable.",
  'manager.configuration.title': 'Configuration du gestionnaire',
  'manager.configuration.info': 'Information sur le gestionnaire',
  'manager.configuration.info2': 'Informations sur les employés actifs',
  'manager.configuration.changemodal.title': 'Confirmer le changement de gestionnaire',
  'manager.configuration.changemodal.subtitle':
    "Vous êtes sur le point de changer le gestionnaire alors que des modifications d'assignation n'ont pas été enregistrées. Souhaitez-vous continuer à changer le gestionnaire ou retourner et compléter les assignations?",
  'manager.configuration.placerholder1': 'Tapez pour rechercher ou sélectionner dans la liste',
  'manager.configuration.placerholder2': 'Taper ici pour rechercher',
  'manager.configuration.savebutton': "Enregistrer les modifications d'affectation",
  'manager.configuration.checkbox': "Afficher uniquement les employés qui m'ont été assignés",
  'manager.configuration.subheader':
    'Sélectionnez les employés qui seront assignés au gestionnaire ci-dessus en utilisant les cases à cocher ci-dessous.',
  'manager.configuration.description':
    "Sélectionnez un gestionnaire pour voir les employés déjà assignés ou pour assigner des employés supplémentaires. Ce gestionnaire sera en mesure de voir les données métriques individuelles et celles de l'équipe dans MonEspaceCourtier.",
  'manager.configuration.info.title': "Titre de l'employé",
  'manager.configuration.info.email': 'Adresse de courriel',
  'manager.configuration.select': 'Sélectionnez un gestionnaire',
  'manager.configuration.success': "Assignation de l'employé réussie",
  'myProfile.title': 'Mon Profil',
  'myProfile.basicInfo.header': 'Information de base',
  'myProfile.brokerageInfo.header': 'Info sur le courtier',
  'myProfile.brokerageInfo.changeBrokerage.button': 'Changer de cabinet de courtage',
  'myProfile.brokerageInfo.agencyName.header': 'Nom',
  'myProfile.brokerageInfo.address.header': 'Adresse',
  'myProfile.brokerageInfo.phoneNumbers.header': 'Numéro(s) de téléphone',
  'myProfile.brokerageInfo.brokerages.header': 'Courtier(s)',
  'myProfile.brokerageInfo.changeBrokerage.header':
    'Êtes-vous certain de vouloir changer de cabinet de courtage?',
  'myProfile.brokerageInfo.changeBrokerage.subHeader':
    'Vous serez redirigé à la page de connexion où vous pourrez sélectionner un autre cabinet de courtage.',
  'myProfile.brokerageInfo.changeBrokerage.continue': 'Continuer',
  'myProfile.generalInfo.header': 'Information générale',
  'myProfile.generalInfo.displayName.header': 'Nom affiché',
  'myProfile.generalInfo.email.header': 'Courriel',
  'myProfile.generalInfo.language.header': 'Langue',
  'myProfile.updatePassword.header': 'Mise à jour du mot de passe',
  'myProfile.updatePassword.subHeader':
    'Mise à jour du mot de passe vous connectera au service de mot de passe PingID',
  'myProfile.updatePassword.linkText': 'Mise à jour du mot de passe',
  'myProfile.generalInfo.changeLanguage.header': 'Êtes vous sûr de vouloir changer de langue?',
  'myProfile.generalInfo.changeLanguage.subHeader':
    "Vous allez maintenant vivre l'expérience MonEspaceCourtier en anglais",
  'myProfile.generalInfo.changeLanguage.continue': 'Continuer en anglais',
  'myProfile.generalInfo.language.english': 'Anglais',
  'myProfile.generalInfo.language.french': 'Français',
  'myProfile.brokerageInfo.chooseBrokerage.header': 'Choisir le cabinet de courtage',

  'dashboard.manager.panel.action.open': 'Ouvrir',
  'dashboard.manager.panel.action.delete': 'Supprimer',
  'communicationService.error.title': 'Erreur de communication',
  'communicationService.error.description':
    'Impossible de démarrer le service de communication de Vertafore. Veuillez essayer de vous connecter de nouveau. Si un message vous demande de permettre/démarrer le service de communication de Vertafore, veuillez le permettre.',
  'communicationService.download.title': 'Action requise avant de poursuivre',
  'communicationService.download.description1a': 'Afin de poursuivre, veuillez ',
  'communicationService.download.description1b': 'télécharger et installer',
  'communicationService.download.description1c':
    " le service de communication Vertafore. Lorsque vous y êtes invité, suivez les instructions pour compléter l'installation. Une fois terminé, rafraichissez votre écran.",
  'communicationService.download.description2a': 'Ce service est requis pour MonEspaceCourtier.',
  'communicationService.download.description2b': 'Vous pouvez ',
  'communicationService.download.description2c': "continuer sans procéder à l'installation",
  'communicationService.download.description2d':
    " mais vous aurez un accès limité aux fonctionalités, y compris la capacité d'entrer des données sur des écrans qui s'ouvrent directement dans SIG.",
  'communicationService.download.description3':
    'Prendre note que vous devez avoir un accès comme administrateur sur votre ordinateur pour installer le service.',
  'communicationService.download.description4':
    "Si vous voyez ce message d'alerte et que le service de communication Vertafore le plus récent est déjà installé, veuillez redémarrer votre ordinateur et essayer d'accéder à MonEspaceCourtier de nouveau.",
  'communicationService.download.action': 'Télécharger et installer',
  'quickNav.configuration.header': 'Configuration QuickNav',
  'quickNav.configuration.columns.displayName.header': 'Nom de QuickNav',
  'quickNav.configuration.columns.publishedInstances.header': 'Instances publiées',
  'quickNav.configuration.columns.actions.new': 'Ajouter une nouvelle instance',
  'quickNav.configuration.columns.actions.view': 'Afficher instances',
  'quickNav.configuration.enablePopup.content':
    'Pour utiliser cette QuickNav, une instance devra être publiée manuellement.',
  'quickNav.configuration.columns.enable.header': 'Activer/Désactiver',
  'quickNav.instanceSelection.begin': 'Débuter le processus QuickNav',
  'quickNav.instanceSelection.newSig': 'Nouvelle Affaire via SIG',
  'quickNav.instanceSelection.renewSig': 'Renouveler via SIG',
  'quickNav.instanceSelection.cancelSig': 'Annuler la police via SIG',
  'quickNav.instanceSelection.endorseSig': 'Modifier via SIG',
  'quickNav.instanceSelection.title': 'QuickNav',
  'quickNav.instanceSelection.subTitle1':
    'Pour débuter, veuillez sélectionner un type de QuickNav.',
  'quickNav.instanceSelection.subTitle2':
    'Maintenant, veuillez sélectionner une instance {quickNav}.',
  'quickNav.configuration.instances.version': 'Version',
  'quickNav.configuration.instances.publisheddraft': 'Publiée / Brouillon',
  'quickNav.configuration.instances.published': 'Publiée',
  'quickNav.configuration.instances.unpublished': 'Non publiée',
  'quickNav.configuration.instances.unpublisheddraft': 'Non publiée / Brouillon',
  'quickNav.configuration.instances.draft': 'Brouillon',
  'quickNav.configuration.instances.copy': 'Copier',
  'quickNav.configuration.instances.delete.draft': 'Supprimer le brouillon',
  'quickNav.configuration.instances.publish.draft': 'Publier le brouillon',
  'quickNav.configuration.instances.delete': 'Supprimer',
  'quickNav.configuration.instances.publish': 'Publier',
  'quickNav.configuration.instances.unpublish': 'Retirer',
  'quickNav.configuration.instances.unpublish.title': 'Confirmation du retrait',
  'quickNav.configuration.instances.unpublish.subtitle.pt1':
    "La publication de cette instance sera annulée et retirée de la sélection par l'utilisateur pour tous nouveaux ",
  'quickNav.configuration.instances.unpublish.subtitle.pt2':
    ' QuickNavs. Les QuickNavs qui sont en cours ne seront pas affectés.',
  'quickNav.configuration.instances.unpublish.button': 'Retirer Maintenant',
  'quickNav.configuration.instances.publish.title': 'Confirmation de la publication',
  'quickNav.configuration.instances.publish.subtitle.pt1':
    'Cette configuration sera maintenant publiée pour tous les nouveaux ',
  'quickNav.configuration.instances.publish.subtitle.pt2':
    ' QuickNavs. Les QuickNavs qui sont en cours ne seront pas affectés.',
  'quickNav.configuration.instances.publish.button': 'Publier',
  'quickNav.configuration.instances.delete.title': "Supprimer l'instance",
  'quickNav.configuration.instances.delete.subtitle':
    "Supprimer cette instance de QuickNav la retirera de façon permanente de la liste d'instances. Vous n'aurez plus la possibilite de réviser les brouillons, et versions ou les restaurer. Elle sera également retirée de la liste des QuickNavs disponibles pour sélection par l'utilisateur. Les QuickNavs qui sont en cours ne seront pas affectés.",
  'quickNav.configuration.instances.delete.button': 'Supprimer',
  'quickNav.configuration.instances.expanded.lastupdate': 'Dernière modification',
  'quickNav.configuration.instances.expanded.restore': 'Restaurer',
  'quickNav.configuration.instances.edit.name': "Nom de l'instance",
  'quickNav.configuration.instances.edit.savedraft': 'Sauv. brouillon',
  'quickNav.configuration.instances.edit.workstep': 'étapes de travail',
  'quickNav.configuration.instances.edit.days': 'jours',
  'quickNav.configuration.instances.edit.days.tooltip':
    "Le nombre saisi représentera le nombre de jours à compter de la date de début du processus de travail. Il ne représente pas le nombre de jours pour compléter l'étape.",
  'quickNav.configuration.instances.edit.title.error':
    'Le titre doit contenir au moins 10 caractères',
  'quickNav.configuration.instances.edit.step.error':
    'Les titres des étapes doivent comporter un minimum de 10 caractères',
  'quickNav.configuration.instances.edit.step.max.error': 'Le nom ne peut excéder 160 caractères',
  'quickNav.configuration.instances.edit.step.warning.draft':
    'Un brouillon existe déjà pour cette instance. Il est impossible de sauvegarder un nouveau brouillon. Vous devez publier vos changements ou supprimer le brouillon existant.',
  'quickNav.configuration.instances.edit.duplicated':
    "Le nom de l'instance est déjà utilisé. Sélectionnez OK pour confirmer la création d'une nouvelle instance avec le même nom ou Annuler pour retourner et modifier le nom de l'instance avant de sauvegarder.",
  'quickNav.configuration.instances.edit.days.error': 'Nombre non valide',
  'quickNav.configuration.instances.edit.step': 'Étape',
  'quickNav.configuration.instances.edit.stepname': "Nom de l'étape",
  'quickNav.configuration.instances.edit.link': 'Lien',
  'quickNav.configuration.instances.edit.duedate': 'Jours depuis le début',
  'quickNav.configuration.instances.edit.active': 'Actif',
  'quickNav.configuration.instances.edit.addStep': 'Ajouter une étape',
  'quickNav.configuration.instances.edit.linkUrl': 'Lien URL',
  'errorPages.common.header': 'Veuillez patienter un instant - quelque chose ne va pas.',
  'errorPages.403.error': 'Erreur 403',
  'errorPages.403.message':
    "Malheureusement, vous n'avez pas les permissions requises pour accéder à ce produit. Veuillez vous adresser à votre Administrateur si vous désirez continuer.",
  'errorPages.403.area.message':
    "Malheureusement, vous n'avez pas les permissions requises pour accéder à cette section. Veuillez communiquer avec votre administrateur si vous avez des questions.",
  'errorPages.403.license.message':
    "Malheureusement, vous n'avez pas accès à ce produit. Veuillez communiquer avec votre administrateur si vous avez des questions.",
  'errorPages.403.sigpermission.message':
    "Malheureusement, vous n'avez pas accès à ce courtier, ou vous n'êtes pas configuré correctement dans SIG. Veuillez communiquer avec votre administrateur pour obtenir de l'aide.",
  'quicknav.keyInformation.key.policy': 'Police',
  'quicknav.keyInformation.key.term': 'Terme',
  'quicknav.keyInformation.key.endEffDate': "Date d'effet de la transaction",
  'logform.title': 'Nouvel enregistrement',
  'logform.title.update': "Mettre à jour l'enregistrement",
  'logform.linkactivity': 'Lier cette activité',
  'logform.duedate': "Date d'expiration",
  'logform.action': 'Action de la Mise en attente',
  'logform.policy': 'Police',
  'logform.transaction.date': "Date d'effet",
  'logform.activityType': "Type d'activité",
  'logform.descriptionType': 'Type de Description',
  'logform.saleStage': 'Étape',
  'logform.closeDate': 'Date de fermeture',
  'logform.premium': 'Prime',
  'logform.attachdoc': 'Joindre un document',
  'logform.uploaddoc': 'Ajouter un document',
  'logform.sharetoinsurlink': 'Partager sur InsurLink',
  'logform.summary': 'Sommaire',
  'logform.8000limit': 'Limite de caractères fixée à 8000',
  'logform.logdate': "Date de l'enregistrement",
  'logform.viewcustomer': 'Ouvrir le client',
  'logform.savelog': 'Sauv. enregist.',
  'logform.typesomething': 'Taper du texte...',
  'logform.Processed.Date': 'Date du traitement',
  'logformview.title': "Afficher l'enregistrement",
  'logformview.company': 'Compagnie',
  'logformview.times.rescheduled': 'Nombre de rappels',
  'logformview.exec': 'Chargé',
  'logformview.createdby': 'Créé par',
  'logformview.completedby': 'Complétée par',
  'logformview.assignedto': 'Mise en attente pour',
  'logformview.rep': 'Rep',
  'logformview.method': 'Méthode',
  'logformview.attachdoc': 'Joindre document',
  'logformview.uploaddocument': 'Ajouter un document',
  'logformview.sharetoinsurlink': 'Partager sur InsurLink',
  'logformview.EditSummary': 'Éditer le sommaire',
  'logformview.linked': 'Lier cette activité',
  'logformview.paperclip':
    'Cet enregistrement contient une pièce jointe.  Vous pouvez le visualiser dans SIG.',
  'logformview.system': 'Système',
  'logformview.manual': 'Manuelle',
  'logformview.duedate': "Date de création/d'expiration",
  'logform.activityCreateSuccess': "L'activité créée avec succès",
  'logform.activityCreateError': "Un problème est survenu lors de la sauvegarde de l'activité",
  'logform.suspenseCreateSuccess': 'La mise en attente créée avec succès',
  'logform.suspenseCreateError':
    'Un problème est survenu lors de la sauvegarde de la mise en attente',
  'logformviewsummary.activityextCreateSuccess': 'Détails de l’activité enregistrés avec succès',
  'logformviewsummary.activityextCreateError':
    'L’enregistrement des détails de l’activité n’a pas fonctionné',
  'logformview.suspensecreatedby': 'Générée par',
};
