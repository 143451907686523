import { TopNavButton, TopNavMenu } from 'alloy-foundation';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDrawer } from '../drawer/DrawerProvider';
import WorkQueueDrawer from '../drawer/workQueue';

const TopNavWorkQueue = () => {
  const { showDrawer } = useDrawer();
  const { formatMessage } = useIntl();

  return (
    <TopNavMenu>
      <TopNavButton
        title={formatMessage({ id: 'drawer.workQueue.title' })}
        iconName="topNavTasks"
        onClick={() => showDrawer(<WorkQueueDrawer />)}
        data-testid="work-queue"
      />
    </TopNavMenu>
  );
};

export default TopNavWorkQueue;
