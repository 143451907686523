import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { BusinessProcessTemplateRestModel } from '../../models/smartflow/BusinessProcessTemplateRestModel';
import { PublishDraftPayload } from '../../models/putPayloads/PublishDraftPayload';
import { Instance, Version } from '../../models/QuickNavInstance';
import { NameCheckPayload } from '../../models/putPayloads/NameCheckPayload';

const baseUrl = 'definition/process/';

// Please keep following the standard set by getIsCustomerAdmin.
export const getQuickNavTemplate = (): Promise<BusinessProcessTemplateRestModel[]> =>
  baseSmartflowRequests.get(baseUrl).then((response) => response);

// Please keep following the standard set by getIsCustomerAdmin.
export const updateQuickNavTemplateStatus = (id: number, status: boolean): Promise<any> =>
  baseSmartflowRequests
    .put(`${baseUrl}${id}`, status, {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    })
    .then((response) => response);

export const getQuickNavInstances = (id): Promise<Instance[]> =>
  baseSmartflowRequests.get(`${baseUrl}${id}/instance`).then((response) => response);

export const unpublishInstance = (id): Promise<any> =>
  baseSmartflowRequests
    .put(`definition/instance/unpublish/${id}`, null)
    .then((response) => response);

export const deleteInstance = (id): Promise<any> =>
  baseSmartflowRequests.delete(`definition/${id}`).then((response) => response);

export const deleteVersion = (id): Promise<any> =>
  baseSmartflowRequests.delete(`definition/version/${id}`).then((response) => response);

export const getVersionInfo = (id): Promise<Version> =>
  baseSmartflowRequests.get(`definition/version/${id}`).then((response) => response);

const generatePublishDraftPayload = (draft: Version) => {
  const putPayload = {
    Id: draft.Id,
    Title: draft.Title,
    ShortTitle: draft.ShortTitle,
    Description: draft.Description,
    PublishedDate: draft.PublishedDate,
    LastModifiedByName: draft.LastModifiedByName,
    LastModifiedDate: draft.LastModifiedDate,
    State: 2,
    ParentId: draft.ParentId,
    CopiedFromId: draft.CopiedFromId,
    IsTemplate: draft.IsTemplate,
    IsDraft: draft.IsDraft,
    Version: 0,
    RowVersion: draft.RowVersion,
    DueDateModifier: draft.DueDateModifier,
    BusinessProcessDefinitionId: draft.BusinessProcessDefinitionId,
    BusinessProcess: null,
    Steps: draft.Steps,
    IsCurrentlyPublished: draft.IsCurrentlyPublished,
    // versionCount: number,
    // isDraftVersion: boolean,
    // instanceId: number,
    // currentVersionId: number,
    // draftVersionId: number,
    // isVariation: boolean,
    // toggleInstanceSelectedState: boolean,
  };
  return putPayload as PublishDraftPayload;
};
export const publishDraft = (draft: Version): Promise<Version> => {
  const putPayload = generatePublishDraftPayload(draft);
  return baseSmartflowRequests.put('definition/version/', putPayload).then((response) => {
    return response;
  });
};
export const checkDuplicatedName = (putPayload: NameCheckPayload): Promise<boolean> => {
  return baseSmartflowRequests.put('definition/checkname/', putPayload).then((response) => {
    return response;
  });
};
export const addNewInstance = async (id): Promise<any> => {
  const version = await getVersionInfo(id);
  version.State = 2;
  version.Title = 'New Instance Testing';
  baseSmartflowRequests.post('definition/instance/', {
    Versions: [{ ...version }],
    CopiedFromId: version.BusinessProcessDefinitionId,
    Id: version.BusinessProcessDefinitionId,
    ParentId: version.BusinessProcessDefinitionId,
    IsDisable: true,
    ProgrammaticName: null,
  });
};
export const publishNewInstance = (version: Version): Promise<any> => {
  version.State = 2;
  return baseSmartflowRequests
    .post('definition/instance/', {
      Versions: [{ ...version }],
      CopiedFromId: version.BusinessProcessDefinitionId,
      Id: version.BusinessProcessDefinitionId,
      ParentId: version.BusinessProcess.IsVariation
        ? version.BusinessProcess?.ParentId
        : version.BusinessProcessDefinitionId,
      IsDisable: true,
      ProgrammaticName: null,
    })
    .then((response) => response);
};
export const publishVersion = (version: Version): Promise<Version> => {
  version.State = 2;
  return baseSmartflowRequests
    .post('definition/version/', {
      ...version,
    })
    .then((response) => response);
};
export const editDraft = (version: Version): Promise<Version> => {
  version.State = 1;
  return baseSmartflowRequests
    .put('definition/version/', {
      ...version,
    })
    .then((response) => response);
};
export const saveDraft = (version: Version): Promise<Version> => {
  version.State = 1;
  return baseSmartflowRequests
    .post('definition/version/', {
      ...version,
    })
    .then((response) => response);
};

export const saveNewDraft = (version: Version): Promise<any> => {
  version.State = 1;
  return baseSmartflowRequests
    .post('definition/instance/', {
      Versions: [{ ...version }],
      CopiedFromId: version.BusinessProcessDefinitionId,
      Id: version.BusinessProcessDefinitionId,
      ParentId: version.BusinessProcess.IsVariation
        ? version.BusinessProcess?.ParentId
        : version.BusinessProcessDefinitionId,
      IsDisable: true,
      ProgrammaticName: null,
    })
    .then((response) => response);
};
export const addNewDraft = async (id): Promise<any> => {
  const version = await getVersionInfo(id);
  version.State = 1;
  version.Title = 'New Draft Testing';
  baseSmartflowRequests.post('definition/instance/', {
    Versions: [{ ...version }],
    CopiedFromId: version.BusinessProcessDefinitionId,
    Id: version.BusinessProcessDefinitionId,
    ParentId: version.BusinessProcessDefinitionId,
    IsDisable: true,
    ProgrammaticName: null,
  });
};
