import baseRequests from '../../api/baseRequests';
import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { RecentCustomer } from '../../models/RecentCustomer';

const baseURL = 'api';

const RecentCustomerService = {
  list: (): Promise<RecentCustomer[]> =>
    baseRequests.get(`${baseURL}/recentclients/get`).then((response) => response),
  add: (custId: string) =>
    baseSmartflowRequests.post(`object/ams360/client/${custId}/recentcustomer/add`, null),
};

export default RecentCustomerService;
