import React, { useState, useEffect } from 'react';
import WorkQueueView from './WorkQueueView';
import WorkItemView from './WorkItemView';

interface Props {
  id?: string;
}

enum DrawerRenderMode {
  WorkQueue,
  WorkItem,
}

const WorkQueueDrawer = ({ id }: Props) => {
  const [processId, setProcessId] = useState(id);
  const renderMode = processId ? DrawerRenderMode.WorkItem : DrawerRenderMode.WorkQueue;

  useEffect(() => setProcessId(id), [id]);

  return (
    <>
      {renderMode === DrawerRenderMode.WorkQueue ? (
        <WorkQueueView setProcessId={setProcessId} />
      ) : (
        <WorkItemView processId={processId} setProcessId={setProcessId} />
      )}
    </>
  );
};

export default WorkQueueDrawer;
