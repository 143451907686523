import * as Yup from 'yup';
import { PssCustomer } from '../../../models/PssCustomer';
import * as fieldNames from '../fieldNames';
import { Permissions, HasPermissions, EPermissions } from '../../../models/enums/EPermissions';
const BusinessInformationSchema = (formatMessage, customer: PssCustomer) =>
  Yup.object().shape({
    [`${fieldNames.DEPARTMENT}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.DIVISION}`]: Yup.string().required(
      formatMessage({ id: 'common.validation.required' })
    ),
    [`${fieldNames.ACCOUNTEXEC1}`]: HasPermissions(
      customer.Permissions,
      Permissions.CanEditExec,
      EPermissions.EMODIFY
    )
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : Yup.string().notRequired().nullable(),
    [`${fieldNames.ACCOUNTREP1}`]: HasPermissions(
      customer.Permissions,
      Permissions.CanEditRep,
      EPermissions.EMODIFY
    )
      ? Yup.string().required(formatMessage({ id: 'common.validation.required' }))
      : Yup.string().notRequired().nullable(),
    [`${fieldNames.ACCOUNTREP2}`]: Yup.string().notRequired().nullable(),
    [`${fieldNames.ACCOUNTEXEC2}`]: Yup.string().notRequired().nullable(),
  });
export default BusinessInformationSchema;
