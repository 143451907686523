import { Modal, SectionContainer, Typography } from 'alloy-foundation';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DataTableColumn, HighlightFilterValue } from '../../../data-table/DataTableColumn';
import { useDataTable, useDataTableRows } from '../../../data-table/useDataTable';
import { Address } from '../../../../models/Address';
import LocalizedDataTable from '../../../common/localizedDataTable/LocalizedDataTable';

type Props = {
  addresses: Address[];
  onSelect: (value: Address) => void;
  onClose: () => void;
};

const PotentialMatchesModal = ({ addresses, onSelect, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const columns: DataTableColumn<Address>[] = [
    new DataTableColumn<Address>({
      key: 'Range',
      header: formatMessage({ id: 'customer.details.currentAddress.matches.range' }),
      flex: 3,
    }),
    new DataTableColumn<Address>({
      key: 'Address',
      header: formatMessage({ id: 'customer.details.currentAddress.matches.address' }),
      flex: 5,
      getValue: (row) => `${row.Address1}${row.City}`,
      Cell: function AddressCell({ row: address }) {
        return (
          <>
            <Typography variant="body">
              <HighlightFilterValue>{address.Address1}</HighlightFilterValue>
            </Typography>
            <Typography variant="body">
              <HighlightFilterValue>{address.City}</HighlightFilterValue>
            </Typography>
          </>
        );
      },
    }),
    new DataTableColumn<Address>({
      key: 'State',
      header: formatMessage({ id: 'customer.details.currentAddress.matches.province' }),
      flex: 2,
    }),
    new DataTableColumn<Address>({
      key: 'Zipcode',
      header: formatMessage({ id: 'customer.details.currentAddress.matches.postalCode' }),
      flex: 2,
    }),
  ];
  const tableState = useDataTable({
    columns,
    data: addresses,
    initialPageSize: 5,
    rowSelection: true,
  });
  const tableRows = useDataTableRows(tableState);
  const [filterValue, setFilterValue] = useState<string>('');
  return (
    <Modal large={true} onClose={onClose} fluid={true} scrollable={false} title=" ">
      <SectionContainer
        headerText={formatMessage({ id: 'customer.details.currentAddress.matches.header' })}
        subheaderText={formatMessage({ id: 'customer.details.currentAddress.matches.subheader' })}
      >
        <div style={{ height: 600 }}>
          <LocalizedDataTable
            {...tableState}
            {...tableRows}
            pageSizeOptions={[5]}
            emptyMessage={
              <Typography variant="disclaimer">
                <FormattedMessage id="customer.details.currentAddress.matches.filter.empty" />
              </Typography>
            }
            filterPlaceholder={formatMessage({
              id: 'customer.details.currentAddress.matches.filter.placeholder',
            })}
            onSelectRow={(address) => {
              if (address !== undefined) onSelect(address);
            }}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        </div>
      </SectionContainer>
    </Modal>
  );
};

export default PotentialMatchesModal;
