import type { OptionType } from 'alloy-foundation';

export interface KeyValuePair {
  Key: any;
  Value: string;
}

export function mapKeyValueToOptions(data: KeyValuePair[]): OptionType<unknown>[] {
  return data.map((arg) => {
    return { value: arg.Key, label: arg.Value };
  });
}
