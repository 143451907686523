import { Flex, Spacer, Typography } from 'alloy-foundation';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IncompleteWorkItemReduced } from '../../../models/WorkQueue';
import { DateFilter, DateFilterSettimgs } from './DateFilter';
import WorkItemCard from './WorkItemCard';

export interface WorkQueueDataProps {
  data: IncompleteWorkItemReduced[];
  filterValue: string;
  dateFilter: DateFilterSettimgs;
  onItemClick: Dispatch<SetStateAction<string>>;
}

const WorkQueueContent = ({ data, filterValue, dateFilter, onItemClick }: WorkQueueDataProps) => {
  const [filteredData, setFilteredData] = useState<IncompleteWorkItemReduced[]>([]);
  const [sortedData, setSortedData] = useState<IncompleteWorkItemReduced[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    let result = data;

    if (filterValue?.trim().length) {
      result = result.filter((item) => {
        return (
          item.CustomerName?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          item.ProcessTitle?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          item.TaskTitle?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          item.FormattedDueDate?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          (new Date(item.DueDate)?.toDateString() === new Date().toDateString() &&
            formatMessage({ id: 'common.today' })
              ?.toLowerCase()
              .includes(filterValue?.toLowerCase()))
        );
      });
    }

    // filter based on date range
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);
    let toDate = new Date();

    if (dateFilter.filter === DateFilter.DateRange) {
      fromDate = new Date(dateFilter.fromDate);
      toDate = new Date(dateFilter.toDate);
    } else if (dateFilter.filter === DateFilter.AmountOfDays) {
      fromDate.setDate(now.getDate() + Number(dateFilter.fromDays));
      toDate.setDate(now.getDate() + Number(dateFilter.toDays));
    }

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(23, 59, 59, 9999);

    result = result.filter((item) => {
      const dueDate = new Date(item.DueDate);
      return dateFilter.filter === DateFilter.ShowAll || (dueDate >= fromDate && dueDate <= toDate);
    });

    setFilteredData(result);
  }, [data, filterValue, dateFilter, formatMessage]);

  useEffect(() => {
    const sorted = [...filteredData];

    sorted.sort((itemA, itemB) => {
      const a = new Date(itemA?.DueDate)?.getTime();
      const b = new Date(itemB?.DueDate)?.getTime();

      return a - b;
    });

    setSortedData(sorted);
  }, [filteredData]);

  return (
    <>
      {sortedData?.length === 0 ? (
        <Spacer pt="small" ml="small" mr="small">
          <Flex justifyContent="center">
            <Typography color="white" variant="disclaimer" noWrap={true}>
              <FormattedMessage
                id={
                  filterValue
                    ? 'drawer.workQueue.search.noResults_term'
                    : 'drawer.workQueue.search.noResults'
                }
                values={{
                  term: filterValue,
                }}
              />
            </Typography>
          </Flex>
        </Spacer>
      ) : (
        <>
          {(sortedData ?? []).map((p, i) => (
            <WorkItemCard key={i} index={i} data={p} onItemClick={onItemClick} />
          ))}
          <Spacer pb="tiny" />
        </>
      )}
    </>
  );
};

export default WorkQueueContent;
