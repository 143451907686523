// import { AxiosResponse } from 'axios';

import baseRequests from '../../api/baseRequests';
const commSVCUrl = 'api/Message/SentMessage';

const CommunicationService = {
  showForm: (id: string, formName: string, args: Record<string, unknown>) => {
    baseRequests.post(`${commSVCUrl}`, { formName, arguments: args }).then((response) => response);
  },
};

export default CommunicationService;
