import { useMutation } from 'react-query';
import RecentCustomerService from '../../../services/recentCustomer/RecentCustomerService';

export const useRecentCustomerUpdate = (
  onSuccess: (data: any, variables: string, context: unknown) => void | undefined
) => {
  const recentCustomersMutation = useMutation((id: string) => RecentCustomerService.add(id), {
    onSuccess,
  });

  return recentCustomersMutation;
};
