import React, { useCallback, useEffect, useState } from 'react';
import { Group, Typography, Modal, ButtonGroup, Button } from 'alloy-foundation';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import { CustomerDropdowns } from '../../../models/CustomerDropdowns';
import { PssCustomer } from '../../../models/PssCustomer';
import { useBottomActionBarDispatch } from '../../common/application-layout/BottomActionBarProvider';
import BusinessInformation from './businessInfo/BusinessInformation';
import ContactInformation from './contactInformation/ContactInformation';
import GeneralInfo from './generalInfo/GeneralInfo';
import CustomerBottomBar from '../CustomerBottomBar';
import { useEditProvider } from '../../common/providers/EditProvider';
import { useVisible } from '../../common/hooks/useVisible';
import LocalizedLeaveWithoutSaveModal from '../../common/localizedLeaveWithoutSaveModal/LocalizedLeaveWithoutSaveModal';
import { useLockProvider } from '../../common/providers/LockProvider';
import CustomerLockService from '../../../services/customerLock/CustomerLockService';

interface CustomerSummaryFormProps {
  canEdit: boolean;
  canEditBusinessInfo: boolean;
  dropdowns: UseQueryResult<CustomerDropdowns, unknown>;
  customer: UseQueryResult<PssCustomer, unknown>;
}

const CustomerSummaryForm = (props: CustomerSummaryFormProps) => {
  const { formatMessage } = useIntl();
  const { handleSubmit, isSubmitting, resetForm, dirty, isValid } = useFormikContext();
  const { setBottomActionBar } = useBottomActionBarDispatch();
  const [showLock, setShowLock] = useState(false);
  const { editMode, setEditMode } = useEditProvider();
  const { currentLock, setCurrentLock } = useLockProvider();
  const {
    visible: localizedLeaveWithoutSaveGuardVisible,
    SetVisible: localizedLeaveWithoutSaveGuardSetVisible,
  } = useVisible();
  const createLock = async (custId) => {
    await CustomerLockService.post(custId).then((res) => setCurrentLock(res));
  };
  const onEdit = () => {
    if (currentLock === null) {
      createLock(props.customer.data?.CustomerId);
      setEditMode(true);
    } else if (!editMode) {
      setShowLock(true);
    }
  };
  const onDelete = useCallback(
    async (custId) => {
      setCurrentLock(null);
      return await CustomerLockService.delete(custId);
    },
    [setCurrentLock]
  );

  useEffect(() => {
    setBottomActionBar(
      editMode && (
        <CustomerBottomBar
          canSave={!isSubmitting}
          hasErrors={!isValid}
          handleCancel={() => localizedLeaveWithoutSaveGuardSetVisible(true)}
          handleSave={(formData) => {
            handleSubmit(formData);
          }}
          saveLabel={formatMessage({ id: 'customer.save' })}
        />
      )
    );
    return () => setBottomActionBar(null);
  }, [
    formatMessage,
    isSubmitting,
    setBottomActionBar,
    resetForm,
    handleSubmit,
    editMode,
    setEditMode,
    localizedLeaveWithoutSaveGuardSetVisible,
    onDelete,
    props.customer.data?.CustomerId,
    dirty,
    isValid,
  ]);
  function handleToggle() {
    setShowLock((prevState) => !prevState);
  }

  function handleClose() {
    setShowLock(false);
  }
  return (
    <div>
      {showLock && currentLock ? (
        <Modal
          small
          closable={true}
          variant="default"
          footer={
            <ButtonGroup endAlign={true}>
              <Button onClick={handleToggle} variant="primary">
                OK
              </Button>
            </ButtonGroup>
          }
          title={formatMessage({ id: 'customer.lock.message.title' })}
          onClose={handleClose}
        >
          <Typography variant="body">
            {formatMessage({ id: 'customer.lock.message.subtitle' })}
          </Typography>

          <Typography variant="body">
            {formatMessage({ id: 'customer.lock.message.name' })}: {currentLock.FirstName}{' '}
            {currentLock.LastName}
          </Typography>
          <Typography variant="body">
            {formatMessage({ id: 'customer.lock.message.workstation' })}: {currentLock.WorkStation}
          </Typography>
          <Typography variant="body">
            {formatMessage({ id: 'customer.lock.message.branch' })}: {currentLock.BranchName}
          </Typography>
          <Typography variant="body">
            {formatMessage({ id: 'customer.lock.message.readonly' })}
          </Typography>
        </Modal>
      ) : null}
      <Group spacing="large" stacked={true}>
        <GeneralInfo
          namespace=""
          customerDropdown={props.dropdowns}
          customer={props.customer}
          canEdit={props.canEdit}
          onEdit={() => onEdit()}
        />
        <BusinessInformation
          namespace="businessInformation"
          customerDropdown={props.dropdowns}
          customer={props.customer}
          canEdit={props.canEdit}
          canEditBusinessInfo={props.canEditBusinessInfo}
          onEdit={() => onEdit()}
        />
        <ContactInformation
          namespace="contactInformation"
          customerDropdown={props.dropdowns}
          customer={props.customer}
          canEdit={props.canEdit}
          onEdit={() => onEdit()}
        />
        {localizedLeaveWithoutSaveGuardVisible && (
          <LocalizedLeaveWithoutSaveModal
            onConfirmNavigationClick={() => {
              resetForm();
              localizedLeaveWithoutSaveGuardSetVisible(false);
              setEditMode(false);
              onDelete(props.customer.data?.CustomerId);
            }}
            onClose={() => localizedLeaveWithoutSaveGuardSetVisible(false)}
          />
        )}
      </Group>
    </div>
  );
};

export default CustomerSummaryForm;
