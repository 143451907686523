import React, { useState } from 'react';
import { Button, Flex, Group, Icon, LoadingIndicator, SectionContainer } from 'alloy-foundation';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import Cookies from 'js-cookie';
import { FormattedMessage, useIntl } from 'react-intl';
import { StringColumn } from '../common/custom-datatable-columns/StringColumn';
import { DataTableColumn } from '../data-table/DataTableColumn';
import { useDataTable, useDataTableRows } from '../data-table/useDataTable';
import LocalizedDataTable from '../common/localizedDataTable/LocalizedDataTable';
import { Agency } from '../../models/ProductInfo';
import stylesFn from './styles';
import { useAgenciesData } from './useAgencies';
import { useProductInfoProvider } from '../productInfo/ProductInfoProvider';

const SelectAgency = () => {
  const { css, styles } = useStyles({ stylesFn });
  const { formatMessage } = useIntl();
  const [isLoading, updateIsLoading] = useState(false);
  const columns: DataTableColumn<Agency>[] = [
    new StringColumn<Agency>({
      key: 'Name',
      header: null,
      flex: 2,
    }),
    new DataTableColumn<Agency>({
      key: 'index',
      header: '',
      align: 'right',
      sortable: false,
      Cell: function ActionCell({ row: policy }) {
        return <Icon flex={true} name="caretRight" size="16" color="blue" />;
      },
    }),
  ];

  const [currentList, setCurrentList] = useState<Agency[]>();
  const [selectedBrokerage, updateSelectedBrokerage] = useState<Agency>();
  const { selectAgency, handleError } = useProductInfoProvider();
  useAgenciesData({
    onSuccess: (data) => {
      const previousBrokerageId = Cookies.get('Vertafore_MAH_AgencyNo_Previous');
      const previousBrokerageInitalValue = data.Agencies.find(
        (b) => b.DefaultInstanceId === previousBrokerageId
      );
      updateSelectedBrokerage(previousBrokerageInitalValue);
      const list =
        data.Agencies?.filter((b) => b.DefaultInstanceId !== previousBrokerageId).sort((a1, a2) =>
          a1.Name.localeCompare(a2.Name)
        ) || [];
      if (previousBrokerageInitalValue) {
        list.unshift(previousBrokerageInitalValue);
      }
      // Set an index as it will automatically sort by the agency name or id else. Thus the previous broker will go at the bottom of the list.
      list.forEach((element, index) => {
        element.index = index;
      });
      setCurrentList(list);
    },
    onError: (err) => {
      handleError(parseInt(err['response'].data?.Message) ?? 1);
    },
  });
  const tableState = useDataTable({
    columns,
    data: currentList,
    filtering: false,
    advanceFilter: null,
    rowSelection: true,
    sorting: false,
    expandingRows: false,
    pagination: false,
    pageSizing: false,
    initialSortDirection: 'asc',
    initialSortedColumnKey: 'index',
  });
  const tableRows = useDataTableRows(tableState);

  const updateActiveBrokerage = (agencyId: string, groupId: number) => {
    selectAgency(agencyId, groupId);
  };

  return (
    <React.Fragment>
      <div {...css(styles.agencySection)}>
        {currentList && (
          <SectionContainer
            headerText={formatMessage({ id: 'myProfile.brokerageInfo.chooseBrokerage.header' })}
          >
            <Group spacing="small" stacked={true}>
              <LocalizedDataTable
                {...tableState}
                {...tableRows}
                data-testid="dashboard-panel-datatable"
                responsive={true}
                selectedRow={selectedBrokerage}
                onSelectRow={(row) => updateSelectedBrokerage(row)}
                height={currentList.length > 5 ? '265px' : 'auto'}
              />
            </Group>
            <Flex flexDirection="row-reverse">
              {isLoading ? (
                <LoadingIndicator />
              ) : (
                <Button
                  disabled={!selectedBrokerage}
                  onClick={() => {
                    updateIsLoading(true);
                    updateActiveBrokerage(
                      selectedBrokerage.DefaultInstanceId,
                      selectedBrokerage.Id
                    );
                  }}
                >
                  <FormattedMessage id="common.select" />
                </Button>
              )}
            </Flex>
          </SectionContainer>
        )}
      </div>
    </React.Fragment>
  );
};

export default SelectAgency;
