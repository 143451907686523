import { Styles } from 'react-with-styles';

const stylesFn = ({ vertaforeAlloy: { color, border } }): Styles => ({
  agencySection: {
    width: '479px',
    margin: 'auto',
    marginTop: '110px',
  },
  agencySelectionTable: {
    height: '260px',
  },
});

export default stylesFn;
