export type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, isDate: boolean) {
  if (isDate) {
    const dateA = new Date(a[orderBy] as unknown as string).getTime();
    const dateB = new Date(b[orderBy] as unknown as string).getTime();
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  isDate?: boolean
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, isDate)
    : (a, b) => -descendingComparator(a, b, orderBy, isDate);
}
