// import { Column, FormInput } from 'alloy-foundation';
import { Divider, Spacer } from 'alloy-foundation';
import React from 'react';
import { EPermissions, HasPermissions, Permissions } from '../../../../models/enums/EPermissions';
import { SigPermissions } from '../../../../models/SigPermissions';
import CustomerClasses from '../../details/businessInformation/customerClasses/CustomerClasses';
import { PrivacyAct } from '../../details/businessInformation/privacyAct/PrivacyAct';
import CustomerDetails from '../../details/customer-details/CustomerDetails';
import { SectionInfoProps } from '../SectionInfoProps';

export const GeneralInfoEditForm = ({
  props,
  parentNamespace,
  sigPermissions,
}: {
  props: SectionInfoProps;
  parentNamespace?: string;
  sigPermissions: SigPermissions;
}) => {
  return (
    <React.Fragment>
      <CustomerDetails
        dropdowns={props.customerDropdown?.data}
        customer={props.customer?.data}
        parentNamespace={parentNamespace ?? ''}
      />
      <Spacer pt="small" />
      <CustomerClasses
        dropdowns={props.customerDropdown?.data}
        showHeader={false}
        customer={props.customer?.data}
        parentNamespace={parentNamespace ?? ''}
        readOnly={
          !HasPermissions(sigPermissions, Permissions.CanEditCustomerClass, EPermissions.EMODIFY)
        }
      />
      <Divider pt="none" pb="small" ml="none" mr="none" />
      <PrivacyAct showHeader={false} parentNamespace={parentNamespace ?? ''} />
    </React.Fragment>
  );
};
