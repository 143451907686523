import React, { useEffect } from 'react';
import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import Header from '../../../common/header/Header';
import Divider from '../../../common/divider/Divider';
import * as fieldNames from '../../fieldNames';
import { PhoneNumber } from '../../../../models/PhoneNumber';
import { Emails } from '../../../../models/Emails';

const PreferedContactMethod = ({
  dropdowns,
  parentNamespace,
  hideHeader,
}: {
  dropdowns: CustomerDropdowns;
  parentNamespace?: string;
  hideHeader?: boolean;
}) => {
  const formikContext = useFormikContext();
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const { formatMessage } = useIntl();

  const phones = formikContext.getFieldProps(prefix + fieldNames.PHONES).value as PhoneNumber[];
  const phonesOptions = phones
    ?.filter(
      (phone) =>
        phone.phoneNumber &&
        phone.phoneNumber.length > 0 &&
        !phone.notApplicable &&
        phone.type &&
        phone.type.length > 0
    )
    .map((phone) => {
      return {
        value: phone.type,
        label: dropdowns.TypeOfPhoneNumbers.filter((x) => x.Key === phone.type)[0].Value,
      };
    });

  const emails = formikContext.getFieldProps(prefix + fieldNames.EMAILS).value as Emails[];
  const emailsOptions = emails
    ?.filter(
      (email) =>
        email.emailAddress &&
        email.emailAddress.length > 0 &&
        !email.notApplicable &&
        email.type &&
        email.type.length > 0
    )
    .map((email) => {
      return {
        value: email.type,
        label: dropdowns.TypeOfEmails.filter((x) => x.Key === email.type)[0].Value,
      };
    });

  const options = [...(phonesOptions ?? []), ...(emailsOptions ?? [])];

  useEffect(() => {
    const currentValue = formikContext.getFieldProps(prefix + fieldNames.PREFERED_CONTACT_METHOD)
      .value as string;
    if (
      emails.every((email) => email.type !== currentValue) &&
      phones.every((phone) => phone.type !== currentValue)
    ) {
      formikContext.setFieldValue(prefix + fieldNames.PREFERED_CONTACT_METHOD, '');
    }
    // Throwing warning for formikContext and prefix. Both shouldn't change in normal operation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails, phones]);

  return (
    <React.Fragment>
      {!hideHeader && (
        <Header
          title={formatMessage({ id: 'customer.contactMethods.preferedContactMethod.header' })}
        />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={3}>
            <FormDropdownSingleSelect
              label={formatMessage({ id: 'customer.contactMethods.preferedContactMethod' })}
              options={options}
              placeholder={formatMessage({
                id: 'customer.contactMethods.preferedContactMethod.placeholder',
              })}
              name={prefix + fieldNames.PREFERED_CONTACT_METHOD}
            />
          </Column>
        </Row>
      </Spacer>
      {!hideHeader && <Divider pt="none" pb="none" ml="none" mr="none" />}
    </React.Fragment>
  );
};

export default PreferedContactMethod;
