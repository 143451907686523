import React from 'react';
import { CustomerDropdowns } from '../../../../models/CustomerDropdowns';
import PersonalCustomerDetails from '../customer-details/PersonalCustomerDetails';
import * as fieldNames from '../../fieldNames';
import PhoneNumbersList from '../phoneNumbers/PhoneNumbersList';
import EmailsInformations from '../emailsInformation/EmailsInformations';
import PreferedContactMethod from '../preferedContactMethod/PreferedContactMethod';

const SecondaryContactForm = ({ dropdowns }: { dropdowns: CustomerDropdowns }) => {
  return (
    <React.Fragment>
      <PersonalCustomerDetails
        dropdowns={dropdowns}
        parentNamespace={fieldNames.SECONDARY_CUSTOMER_CONTACT}
        hideBaseCustomerDetails={true}
      />
      <PhoneNumbersList
        dropdowns={dropdowns}
        parentNamespace={fieldNames.SECONDARY_CUSTOMER_CONTACT}
        hideHeader={true}
        disableMandatoryContactMethod={true}
      />
      <EmailsInformations
        dropdowns={dropdowns}
        parentNamespace={fieldNames.SECONDARY_CUSTOMER_CONTACT}
        hideHeader={true}
        disableMandatoryContactMethod={true}
      />
      <PreferedContactMethod
        dropdowns={dropdowns}
        hideHeader={true}
        parentNamespace={fieldNames.SECONDARY_CUSTOMER_CONTACT}
      />
    </React.Fragment>
  );
};
export default SecondaryContactForm;
