import { Column, Group, Row, Spacer, Theme, Typography } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import { SearchResult, Xreference } from '../../models/SearchResult';
import { ExpandableRowProps } from '../data-table';
const stylesFn = ({ vertaforeAlloy: { spacing } }: Theme) => ({
  sortLabel: {
    paddingRight: spacing.tiny,
    userSelect: 'none',
  },

  searchFieldSizer: {
    minWidth: '14rem',
    maxWidth: '100%',
    paddingBottom: spacing.medium,
  },

  rightAlignedHeaderContent: {
    paddingLeft: spacing.xsmall,
  },
});
const AdditionalInformation = ({ row }: ExpandableRowProps<SearchResult>) => {
  const { theme } = useStyles({ stylesFn }) as any;
  const { spacing } = theme.vertaforeAlloy;

  const formatXReference = (xref: Xreference) => {
    let reference = '';
    if (xref.CustomerNo && xref.CustomerNo.length > 0) {
      reference += `ID#${xref.CustomerNo} `;
    }
    if (xref.FirstName && xref.FirstName.length > 0) {
      reference += `${xref.FirstName} `;
    }

    if (xref.LastName && xref.LastName.length > 0) {
      reference += xref.LastName;
    }

    return reference.trim();
  };

  return (
    <div
      data-testid="expandable-row-div"
      style={{
        // borderLeft: border.thickBlue,
        padding: spacing.large,
      }}
    >
      <Row>
        <Column>
          <Spacer mb="small">
            <Typography variant="body" fontWeight="semibold">
              <FormattedMessage id="customer.search.addtiontalInfo.title" />
            </Typography>
          </Spacer>
        </Column>
      </Row>
      <Row>
        <Column md={4}>
          <Group spacing="tiny">
            <Group spacing="xsmall" stacked={false}>
              <Typography variant="bodySm" fontWeight="semibold">
                <FormattedMessage id="customer.details.base.type.label" />
              </Typography>
              <Typography variant="bodySm">{row.ClientStatusDescription}</Typography>
            </Group>
            <Group spacing="xsmall" stacked={false}>
              <Typography variant="bodySm" fontWeight="semibold">
                <FormattedMessage id="customer.details.businessInformation.typeOfBusiness" />
              </Typography>
              <Typography variant="bodySm">{row.ClientTypeDescription}</Typography>
            </Group>

            <Group spacing="xsmall" stacked={false}>
              <Typography variant="bodySm" fontWeight="semibold" noWrap={true}>
                <FormattedMessage id="customer.search.addtiontalInfo.xref" />
              </Typography>
              <Typography variant="bodySm">
                {row.Xreference?.map((xref) => formatXReference(xref)).join(', ')}
              </Typography>
            </Group>
          </Group>
        </Column>
        <Column md={6}>
          <Group spacing="tiny">
            <Group spacing="xsmall" stacked={false}>
              <Typography variant="bodySm" fontWeight="semibold">
                <FormattedMessage id="customer.details.businessInformation.division" />
              </Typography>
              <Typography variant="bodySm">{row.Division}</Typography>
            </Group>
            <Group spacing="xsmall" stacked={false}>
              <Typography variant="bodySm" fontWeight="semibold">
                <FormattedMessage id="customer.details.businessInformation.department" />
              </Typography>
              <Typography variant="bodySm">{row.Department}</Typography>
            </Group>
          </Group>
        </Column>
      </Row>
    </div>
  );
};

export default AdditionalInformation;
