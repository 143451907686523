import { Drawer as AlloyDrawer } from 'alloy-foundation';
import React from 'react';
import useStyles from 'react-with-styles/lib/hooks/useStyles';
import stylesFn from './styles';
import { TopNavHeight } from '../topnav/TopNavConstants';

interface Props {
  children?: React.ReactNode;
}

const Drawer = ({ children }: Props) => {
  const { css, styles } = useStyles({ stylesFn });

  return (
    <AlloyDrawer
      backgroundColor="dark"
      topOffset={TopNavHeight}
      variant="persistent"
      anchor="right"
      data-testid="drawer"
    >
      <>
        <div {...css(styles.drawer)}>{children}</div>
      </>
    </AlloyDrawer>
  );
};

export default Drawer;
