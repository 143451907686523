import baseRequests from '../../api/basePSSRequests';
import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { ChecklistItemCompletionStatus, Process } from '../../models/Process';
import { IncompleteWorkItem, availableAssignees, newAssignee } from '../../models/WorkQueue';

const baseURL =
  '/user/0/workitems?filter=%7b%22general%22%3a%7b%22dueDateFilter%22%3a0%7d%7d&json=true';

const WorkQueueService = {
  GetIncompleteCount: (): Promise<string> =>
    baseRequests.get(baseURL, {}).then((response) => response.length),
  list: (): Promise<IncompleteWorkItem[]> =>
    baseRequests.get(baseURL, {}).then((response) => response),
  updatePriority: (id: string, priority: number): Promise<any> =>
    baseSmartflowRequests
      .put(`process/${id}/priority`, priority, {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((response) => response),
  getAvailableAssignee: (id: string): Promise<availableAssignees[]> =>
    baseSmartflowRequests.get(`process/${id}/availableassignees`),

  reassignItem: (id: string, newlyAssigned: newAssignee): Promise<any> =>
    baseSmartflowRequests.put(`process/${id}/assignee`, newlyAssigned).then((res) => res),
  deleteItem: (id: string): Promise<any> =>
    baseSmartflowRequests.delete(`process/${id}`).then((res) => res),
  getProcess: (id: string): Promise<Process> =>
    baseSmartflowRequests.get(`process/${id}?type=genericProcessType`).then((response) => response),
  updateTaskStatus: (id: string, status: ChecklistItemCompletionStatus): Promise<Process> =>
    baseSmartflowRequests
      .put(`task/${id}/status?json=true`, status, {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((res) => res),
};

export default WorkQueueService;
