import { HashLink, SideMenu, SideMenuListV2, SideMenuListItemV2, Spacer } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CUSTOMER_SUMMARY_SCROLL_SECTIONS } from './CustomerSummaryScrollSections';

interface Props {
  activeSection: string;
}

const CustomerSummarySideMenu = ({ activeSection }: Props) => {
  return (
    <SideMenu data-testid="customer-summary-side-menu">
      <Spacer pt="xxxlarge" />
      <Spacer pt="small" />
      <SideMenuListV2>
        <SideMenuListItemV2
          as={HashLink}
          active={activeSection === CUSTOMER_SUMMARY_SCROLL_SECTIONS.SUMMARY}
          to={`#${CUSTOMER_SUMMARY_SCROLL_SECTIONS.SUMMARY}`}
        >
          <FormattedMessage id="customer.summary.title" />
        </SideMenuListItemV2>
        <SideMenuListItemV2
          as={HashLink}
          active={activeSection === CUSTOMER_SUMMARY_SCROLL_SECTIONS.POLICIES}
          to={`#${CUSTOMER_SUMMARY_SCROLL_SECTIONS.POLICIES}`}
        >
          <FormattedMessage id="customer.policies.Header" />
        </SideMenuListItemV2>
        <SideMenuListItemV2
          as={HashLink}
          active={activeSection === CUSTOMER_SUMMARY_SCROLL_SECTIONS.WORK_IN_PROGRESS}
          to={`#${CUSTOMER_SUMMARY_SCROLL_SECTIONS.WORK_IN_PROGRESS}`}
        >
          <FormattedMessage id="workInProgress.Header" />
        </SideMenuListItemV2>
        <SideMenuListItemV2
          as={HashLink}
          active={activeSection === CUSTOMER_SUMMARY_SCROLL_SECTIONS.ACTIVITIES}
          to={`#${CUSTOMER_SUMMARY_SCROLL_SECTIONS.ACTIVITIES}`}
        >
          <FormattedMessage id="activityList.title" />
        </SideMenuListItemV2>
      </SideMenuListV2>
    </SideMenu>
  );
};

export default CustomerSummarySideMenu;
