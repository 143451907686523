import {
  TopNavButtonWithDropdown,
  TopNavDropdown,
  TopNavMenu,
  TopNavMenuLink,
} from 'alloy-foundation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import { flushSync } from 'react-dom';
import { useDrawer } from '../drawer/DrawerProvider';

const TopNavAdminSection = () => {
  const { hideDrawer } = useDrawer();
  const { formatMessage } = useIntl();

  return (
    <TopNavMenu>
      <TopNavButtonWithDropdown
        data-testid="ManagerDropdown"
        iconName="topNavSettings"
        title={formatMessage({ id: 'topnav.manager.title' })}
        aria-label="Actions"
        onClick={() => flushSync(() => hideDrawer())}
      />
      <TopNavDropdown alignment="right">
        <TopNavMenuLink data-testid="manager-config" as={RouterLink} to="/manager/configure">
          <FormattedMessage id="topnav.manager.config" />
        </TopNavMenuLink>
        <TopNavMenuLink data-testid="manager-quicknav" as={RouterLink} to="/quicknav/configure">
          <FormattedMessage id="topnav.manager.quicknav" />
        </TopNavMenuLink>
      </TopNavDropdown>
    </TopNavMenu>
  );
};

export default TopNavAdminSection;
