import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { cancellableRequest } from 'ui-auth-client';
import Configuration from '../Configuration';
const TokenExpired = 'tokenexpired';

// Certains routes were adding their name to the base URL causing issues during loading as the endpoints don't exist
axios.defaults.baseURL = Configuration.apiBaseUri;

// interceptors
axios.interceptors.request.use(
  (request) => {
    // receive the TokenExpired token when the token has expired or the user has logged out.
    request.headers.common['Requested-With'] = 'XMLHttpRequest';
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (response?.headers[TokenExpired] && response?.headers[TokenExpired] === '1')
      window.location.href = Configuration.appBaseUri;

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// responseBody
const responseBody = (response: AxiosResponse) => response.data;
// baseRequests
const baseRequests = {
  get: async (path: string, config: AxiosRequestConfig = {}, isCommSvc?: boolean) => {
    if (isCommSvc) {
      return axios.get(path, config).then(responseBody);
    }

    if (process.env.NODE_ENV === 'development') {
      config.withCredentials = true;
    }

    const url = `${Configuration.apiBaseUri}/${path}`;
    const response = await cancellableRequest<any>({
      url,
      method: 'GET',
      ...config,
    });
    return response.data;
  },
  // get: (url: string, config?: AxiosRequestConfig) => axios.get(url, config).then(responseBody),
  post: async (path: string, body: any, config: AxiosRequestConfig = {}, isCommSvc?: boolean) => {
    if (isCommSvc) {
      return axios.post(path, body, config).then(responseBody);
    }
    const url = `${Configuration.apiBaseUri}/${path}`;

    if (process.env.NODE_ENV === 'development') {
      config.withCredentials = true;
    }

    const response = await cancellableRequest<any>({
      url,
      method: 'POST',
      data: body,
      ...config,
    });
    return response.data;
  },
  put: async (url: string, body: any, config: AxiosRequestConfig<any> = {}) => {
    if (process.env.NODE_ENV === 'development') {
      config.withCredentials = true;
    }

    const response = await cancellableRequest<any>({
      url,
      method: 'PUT',
      data: body,
      ...config,
    });
    return response.data;
  },
  delete: async (url: string, config: AxiosRequestConfig<any> = {}) => {
    if (process.env.NODE_ENV === 'development') {
      config.withCredentials = true;
    }
    const response = await cancellableRequest<any>({
      url,
      method: 'DELETE',
      ...config,
    });
    return response.data;
  },
};

export default baseRequests;
