import { useMutation } from 'react-query';
import {
  ToDoListCompleteStatusCriteria,
  ToDoListCompleteStatusResult,
} from '../../../../models/ToDo';
import TodoListWorkGroupService from '../../../../services/todoListWorkgroup/TodoListWorkgroupService';

export const useToDoListWorkGroupChangeStatus = (
  onSuccess: (
    data: ToDoListCompleteStatusResult,
    variables: ToDoListCompleteStatusCriteria,
    context: unknown
  ) => void | undefined
) => {
  const suspenseMutation = useMutation(
    async (params: ToDoListCompleteStatusCriteria) =>
      await TodoListWorkGroupService.updateSuspenseStatus({
        Status: params.Status,
        SuspId: params.SuspId,
      }),
    {
      onSuccess,
    }
  );

  return suspenseMutation;
};
