import { Button, ButtonGroup, Modal } from 'alloy-foundation';
import { FormattedMessage } from 'react-intl';

export type ModalProps = {
  Name?: string;
  Title: string;
  SubTitle: string;
  SubTitle2?: string;
  confirmText: string;
  onCancel: () => void;
  onConfirm: () => void;
};
const QuickNavModal = ({
  Name,
  Title,
  SubTitle,
  SubTitle2,
  confirmText,
  onCancel,
  onConfirm,
}: ModalProps) => {
  return (
    <Modal
      variant="default"
      title={Title}
      subtitle={`${SubTitle}${Name ?? ''}${SubTitle2 ?? ''}`}
      footer={
        <ButtonGroup endAlign={true}>
          <Button onClick={onCancel}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button onClick={onConfirm} variant="primary">
            {confirmText}
          </Button>
        </ButtonGroup>
      }
      onClose={onCancel}
    ></Modal>
  );
};

export default QuickNavModal;
