import baseSmartflowRequests from '../../api/baseSmartflowRequests';
import { ActivityList } from '../../models/ActivitySummary';

const ActivityListService = {
  list: (id: string): Promise<ActivityList> =>
    baseSmartflowRequests
      .get(`/object/ams360/client/${id}/children/activity?json=true`)
      .then((response) => response.Children),
};

export default ActivityListService;
