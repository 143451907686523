import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { CustomerDropdowns } from '../../../../../models/CustomerDropdowns';
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import { PssCustomer } from '../../../../../models/PssCustomer';
import {
  CUSTOMER_CLASS1,
  CUSTOMER_CLASS2,
  CUSTOMER_CLASS3,
  CUSTOMER_CLASS4,
} from '../../../fieldNames';
import Header from '../../../../common/header/Header';

const CustomerClasses: React.FC<{
  dropdowns: CustomerDropdowns;
  customer?: PssCustomer;
  showHeader?: boolean;
  parentNamespace?: string;
  readOnly?: boolean;
}> = ({ dropdowns, showHeader = true, customer, parentNamespace, readOnly = false }) => {
  const { formatMessage } = useIntl();
  const { getFieldProps } = useFormikContext();
  const prefix = parentNamespace ? `${parentNamespace}.` : '';
  const customerClass1 = getFieldProps(prefix + CUSTOMER_CLASS1).value;
  const customerClass2 = getFieldProps(prefix + CUSTOMER_CLASS2).value;
  const customerClass3 = getFieldProps(prefix + CUSTOMER_CLASS3).value;
  const customerClass4 = getFieldProps(prefix + CUSTOMER_CLASS4).value;
  const initialValues: KeyValuePair[][] = useMemo(
    () => [
      dropdowns.CustomerClasses,
      dropdowns.CustomerClasses,
      dropdowns.CustomerClasses,
      dropdowns.CustomerClasses,
    ],
    [dropdowns.CustomerClasses]
  );

  const [customerClasses, setCustomerClass] = useState(initialValues);

  const handleCustomerClassChange = useCallback(
    (ccValue1, ccValue2, ccValue3, ccValue4) => {
      const ccTemp: KeyValuePair[][] = [];
      ccTemp[0] = initialValues[0].filter(function (value, index, arr) {
        return value.Key !== ccValue2 && value.Key !== ccValue3 && value.Key !== ccValue4;
      });
      ccTemp[1] = initialValues[1].filter(function (value, index, arr) {
        return value.Key !== ccValue1 && value.Key !== ccValue3 && value.Key !== ccValue4;
      });
      ccTemp[2] = initialValues[2].filter(function (value, index, arr) {
        return value.Key !== ccValue1 && value.Key !== ccValue2 && value.Key !== ccValue4;
      });
      ccTemp[3] = initialValues[3].filter(function (value, index, arr) {
        return value.Key !== ccValue1 && value.Key !== ccValue2 && value.Key !== ccValue3;
      });

      setCustomerClass(ccTemp);
    },
    [initialValues]
  );

  useEffect(() => {
    handleCustomerClassChange(
      customer?.CustomerClasses['CustomerClass1']?.key ?? '',
      customer?.CustomerClasses['CustomerClass2']?.key ?? '',
      customer?.CustomerClasses['CustomerClass3']?.key ?? '',
      customer?.CustomerClasses['CustomerClass4']?.key ?? ''
    );
  }, [customer?.CustomerClasses, handleCustomerClassChange]);
  return (
    <React.Fragment>
      {showHeader && (
        <Header
          title={formatMessage({ id: 'customer.details.businessInformation.customerClass' })}
        />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md="3">
            <FormDropdownSingleSelect
              required={dropdowns.MandatoryCustomerClass.includes(1) && !readOnly}
              name={prefix + CUSTOMER_CLASS1}
              label={formatMessage({
                id: 'customer.details.businessInformation.customerClasses.1',
              })}
              options={customerClasses[0].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              disabled={readOnly}
              onChange={(v) =>
                handleCustomerClassChange(
                  v?.value ?? '',
                  customerClass2,
                  customerClass3,
                  customerClass4
                )
              }
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md="3">
            <FormDropdownSingleSelect
              required={dropdowns.MandatoryCustomerClass.includes(2) && !readOnly}
              name={prefix + CUSTOMER_CLASS2}
              label={formatMessage({
                id: 'customer.details.businessInformation.customerClasses.2',
              })}
              options={customerClasses[1].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              disabled={readOnly}
              onChange={(v) =>
                handleCustomerClassChange(
                  customerClass1,
                  v?.value ?? '',
                  customerClass3,
                  customerClass4
                )
              }
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md="3">
            <FormDropdownSingleSelect
              required={dropdowns.MandatoryCustomerClass.includes(3) && !readOnly}
              name={prefix + CUSTOMER_CLASS3}
              disabled={readOnly}
              label={formatMessage({
                id: 'customer.details.businessInformation.customerClasses.3',
              })}
              options={customerClasses[2].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              onChange={(v) =>
                handleCustomerClassChange(customerClass1, customerClass2, v?.value, customerClass4)
              }
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md="3">
            <FormDropdownSingleSelect
              required={dropdowns.MandatoryCustomerClass.includes(4) && !readOnly}
              name={prefix + CUSTOMER_CLASS4}
              disabled={readOnly}
              label={formatMessage({
                id: 'customer.details.businessInformation.customerClasses.4',
              })}
              options={customerClasses[3].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              onChange={(v) =>
                handleCustomerClassChange(customerClass1, customerClass2, customerClass3, v?.value)
              }
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
        </Row>
      </Spacer>
    </React.Fragment>
  );
};

export default CustomerClasses;
