import { Button, ButtonGroup, Modal } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  onCancel: () => void;
  onChangeLanguage: () => void;
};

const ChangeLanguageModal = ({ onCancel, onChangeLanguage }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      closable={true}
      footer={
        <ButtonGroup endAlign={true}>
          <Button variant="tertiary" onClick={onCancel} data-testid="cancel">
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button onClick={onChangeLanguage} variant="primary" data-testid="continue">
            <FormattedMessage id="myProfile.generalInfo.changeLanguage.continue" />
          </Button>
        </ButtonGroup>
      }
      title={formatMessage({ id: 'myProfile.generalInfo.changeLanguage.header' })}
      subtitle={formatMessage({ id: 'myProfile.generalInfo.changeLanguage.subHeader' })}
      onClose={onCancel}
    ></Modal>
  );
};

export default ChangeLanguageModal;
