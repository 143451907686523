import { NumberColumn } from './NumberColumn';
import { Locale } from '../../localization/Locale';

export class DollarColumn<Data> extends NumberColumn<Data> {
  constructor({ ...values }: Partial<NumberColumn<Data>> & { key: NumberColumn<Data>['key'] }) {
    super(values);
    this.decimals = 0;
    this.locale === Locale.EnCa ? (this.prefix = '$') : (this.suffix = '$');
  }
}
