import { Flex, Icon, Modal, Spacer, Typography } from 'alloy-foundation';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClose: () => void;
}

const ChangeStatusErrorModal = ({ onClose }: Props) => {
  return (
    <Modal data-testid="change-status-error" closable={true} variant="error" onClose={onClose}>
      <Spacer marginBottom="small">
        <Flex alignItems="center" justifyContent="center">
          <Icon name="errorOutlined" color="error" size={64} data-testid="error-icon" />
        </Flex>
      </Spacer>
      <Spacer marginBottom="medium">
        <Flex alignItems="center" justifyContent="center">
          <Typography variant="h1" color="error">
            <FormattedMessage id="dashboard.panel.common.changeSuspenseStatusError.body" />
          </Typography>
        </Flex>
      </Spacer>
    </Modal>
  );
};

export default ChangeStatusErrorModal;
