import React, { useState, useContext } from 'react';

const TypeOfBusinessContext = React.createContext({
  typeOfBusiness: '',
  setTypeOfBusiness: (p) => p,
});

export const TypeOfBusinessProvider = ({ defaultTypeOfBusiness, children }) => {
  const [typeOfBusiness, setTypeOfBusiness] = useState(defaultTypeOfBusiness ?? 'P');

  return (
    <TypeOfBusinessContext.Provider value={{ typeOfBusiness, setTypeOfBusiness }}>
      {children}
    </TypeOfBusinessContext.Provider>
  );
};

export const useTypeOfBusiness = () => {
  const context = useContext(TypeOfBusinessContext);
  if (context === undefined) {
    throw new Error('useTypeOfBusiness cannot be called from outside of a TypeOfBusinessProvider');
  }
  return context;
};
