import { Spacer, LoadingIndicator, TopNav } from 'alloy-foundation';
import { Route, Switch, useLocation } from 'react-router';
import SelectAgency from '../selectAgency/SelectAgency';
import { UnauthorizedAccess } from '../layout/errorPages/UnauthorizedAccess';
import TopNavSelectAgency from '../topnav/TopNavSelectAgency';

export default function AppLoading() {
  const location = useLocation();

  const pathsToExclude = ['/Login/SelectAgency', '/Login/UnauthorizedAccess'];
  return (
    <>
      <TopNav variant="dark">
        <TopNavSelectAgency />
      </TopNav>
      <Spacer mb="large" />
      {!pathsToExclude.includes(location.pathname) && <LoadingIndicator size="large" />}
      <Switch>
        <Route path="/Login/SelectAgency" component={SelectAgency} />
        <Route path="/Login/UnauthorizedAccess" component={UnauthorizedAccess} />
      </Switch>
    </>
  );
}
