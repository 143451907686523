export enum QuickNavAction {
  NewPolicy = 1,
  PolicyRenewal = 2,
  PolicyCancellation = 3,
  PolicyEndorsement = 4,
}

export const GetQuickNavActionName = (action: QuickNavAction) => {
  switch (action) {
    case QuickNavAction.NewPolicy:
      return 'NewPolicy';
    case QuickNavAction.PolicyCancellation:
      return 'PolicyCancellation';
    case QuickNavAction.PolicyRenewal:
      return 'PolicyRenewal';
    case QuickNavAction.PolicyEndorsement:
      return 'PolicyEndorsement';
  }
};
