import { Column, FormDropdownSingleSelect, Row, Spacer } from 'alloy-foundation';
import React from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { CustomerDropdowns } from '../../../../../models/CustomerDropdowns';
import { NewCustomer } from '../../../../../models/NewCustomer';
import { KeyValuePair } from '../../../../../models/KeyValuePair';
import * as FieldNames from '../../../fieldNames';
import Header from '../../../../common/header/Header';
import { PssCustomer } from '../../../../../models/PssCustomer';
import {
  HasPermissions,
  Permissions,
  EPermissions,
} from '../../../../../models/enums/EPermissions';

const BrokeragePersonnel: React.FC<{
  dropdowns: CustomerDropdowns;
  showHeader?: boolean;
  parentNamespace?: string;
  customer?: PssCustomer;
}> = ({ dropdowns, showHeader = true, parentNamespace, customer }) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, getFieldProps } = useFormikContext<NewCustomer>();

  const initAccountExec: KeyValuePair[][] = [];

  const filterList = (valueList: KeyValuePair[], excludedValue) => {
    return valueList.filter(function (value, index, arr) {
      return value.Key !== excludedValue;
    });
  };

  const prefix = parentNamespace ? `${parentNamespace}.` : '';

  const accountExec1 = getFieldProps(prefix + FieldNames.ACCOUNTEXEC1).value;
  const accountExec2 = getFieldProps(prefix + FieldNames.ACCOUNTEXEC2).value;

  const accountRep1 = getFieldProps(prefix + FieldNames.ACCOUNTREP1).value;
  const accountRep2 = getFieldProps(prefix + FieldNames.ACCOUNTREP2).value;

  initAccountExec[0] = filterList(dropdowns.AccountExecs, accountExec2);
  initAccountExec[1] = filterList(dropdowns.AccountExecs, accountExec1);

  const [accountExecs, setAccountExec] = useState(initAccountExec);

  const initAccountRep: KeyValuePair[][] = [];

  initAccountRep[0] = filterList(dropdowns.AccountReps, accountRep2);
  initAccountRep[1] = filterList(dropdowns.AccountReps, accountRep1);

  const [accountReps, setAccountRep] = useState(initAccountRep);

  const handleAccountExecChange = (exec1, exec2) => {
    const ccTemp: KeyValuePair[][] = [];
    ccTemp[0] = filterList(dropdowns.AccountExecs, exec2);
    ccTemp[1] = filterList(dropdowns.AccountExecs, exec1);

    if (exec1 !== accountExec1 && dropdowns.AssociatedExecs !== null) {
      const associatedAccountExec2 = dropdowns.AssociatedExecs.find((p) => p.Key === exec1);
      if (associatedAccountExec2 && ccTemp[1].find((a) => a.Key === associatedAccountExec2.Value)) {
        setFieldValue(prefix + FieldNames.ACCOUNTEXEC2, associatedAccountExec2.Value);
        ccTemp[0] = filterList(dropdowns.AccountExecs, associatedAccountExec2.Value);
      }
    }

    setAccountExec(ccTemp);
  };

  const handleAccountRepChange = (rep1, rep2) => {
    const ccTemp: KeyValuePair[][] = [];
    ccTemp[0] = filterList(dropdowns.AccountReps, rep2);
    ccTemp[1] = filterList(dropdowns.AccountReps, rep1);

    setAccountRep(ccTemp);
  };

  return (
    <React.Fragment>
      {showHeader && (
        <Header title={formatMessage({ id: 'customer.details.brokeragePersonnel' })} />
      )}
      <Spacer mr="xlarge" mb="tiny">
        <Row>
          <Column md={3}>
            <FormDropdownSingleSelect
              data-testid="AccountExec1"
              required={true}
              name={prefix + FieldNames.ACCOUNTEXEC1}
              label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountExec1' })}
              disabled={
                parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditExec,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false
              }
              onChange={(v) => handleAccountExecChange(v?.value, accountExec2)}
              options={accountExecs[0].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={true}
              name={prefix + FieldNames.ACCOUNTREP1}
              onChange={(v) => handleAccountRepChange(v?.value, accountRep2)}
              label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountRep1' })}
              disabled={
                parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditRep,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false
              }
              options={accountReps[0].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={false}
              name={prefix + FieldNames.ACCOUNTEXEC2}
              onChange={(v) => handleAccountExecChange(accountExec1, v?.value)}
              label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountExec2' })}
              disabled={
                parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditExec,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false
              }
              options={accountExecs[1].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
          <Column md={3}>
            <FormDropdownSingleSelect
              required={false}
              name={prefix + FieldNames.ACCOUNTREP2}
              onChange={(v) => handleAccountRepChange(accountRep1, v?.value)}
              label={formatMessage({ id: 'customer.details.brokeragePersonnel.AccountRep2' })}
              disabled={
                parentNamespace
                  ? HasPermissions(
                      customer.Permissions,
                      Permissions.CanEditRep,
                      EPermissions.EMODIFY
                    )
                    ? false
                    : true
                  : false
              }
              options={accountReps[1].map((data) => {
                return { value: data.Key, label: data.Value };
              })}
              placeholder={formatMessage({ id: 'common.dropdown.placeholder' })}
            />
          </Column>
        </Row>
      </Spacer>
    </React.Fragment>
  );
};

export default BrokeragePersonnel;
