import React from 'react';
import { Modal, ButtonGroup, Button } from 'alloy-foundation';
import { useIntl } from 'react-intl';
import { IncompleteWorkItemReduced } from '../../../models/WorkQueue';
import { useDeleteItem } from './useWorkQueue';
import { WorkItemRestModel } from '../../../models/WorkItemRestModel';
interface DeleteModalProps {
  handleClose?: VoidFunction;
  handleToggle?: VoidFunction;
  data?: IncompleteWorkItemReduced | WorkItemRestModel;
  isManager?: boolean;
  updateDeletedTasks?: CallableFunction;
}
const DeleteWorkItemModal = ({
  handleClose,
  handleToggle,
  data,
  isManager = false,
  updateDeletedTasks,
}: DeleteModalProps) => {
  const { formatMessage } = useIntl();
  const { mutate } = useDeleteItem();

  const handleDelete = () => {
    const variables =
      'CustomerId' in data
        ? { Id: data.Id, CustomerId: data?.CustomerId }
        : { Id: data.Id, CustomerId: '' };

    mutate(variables);
    if (updateDeletedTasks)
      updateDeletedTasks((deltedTasks: WorkItemRestModel[]) => [...deltedTasks, data]);
    handleClose();
  };
  return (
    <Modal
      data-testid="basic-modal"
      closable={true}
      small={false}
      large={false}
      variant="default"
      fluid={false}
      footer={
        <ButtonGroup endAlign>
          <Button variant="tertiary" onClick={handleToggle}>
            {formatMessage({ id: 'common.button.cancel' })}
          </Button>
          <Button data-testid="pressDelete" onClick={handleDelete} variant="primary">
            {formatMessage({ id: 'drawer.workQueue.actions.delete' })}
          </Button>
        </ButtonGroup>
      }
      subtitle={
        isManager
          ? formatMessage({ id: 'drawer.workQueue.actions.deleteManager.subtitle' })
          : formatMessage({ id: 'drawer.workQueue.actions.delete.subtitle' })
      }
      title={formatMessage({ id: 'drawer.workQueue.actions.delete.title' })}
      onClose={handleClose}
    ></Modal>
  );
};

export default DeleteWorkItemModal;
