import { DisplayFormikState } from 'alloy-foundation';
import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useBottomActionBarDispatch } from '../common/application-layout/BottomActionBarProvider';
import BusinessInformation from './details/businessInformation/BusinessInformation';
import CurrentAddress from './details/currentAddress/CurrentAddress';
import CustomerBottomBar from './CustomerBottomBar';
import { useRouter } from '../common/hooks/useRouter';
import { CustomerDropdowns } from '../../models/CustomerDropdowns';
import CustomerDetails from './details/customer-details/CustomerDetails';
import SecondaryContact from './details/secondaryContact/SecondaryContact';
import PhoneNumbersList from './details/phoneNumbers/PhoneNumbersList';
import EmailsInformations from './details/emailsInformation/EmailsInformations';
import PreferedContactMethod from './details/preferedContactMethod/PreferedContactMethod';
import { DevOnlyComponent } from '../common/devTools';

const CreacteCustomerForm: React.FC<{ dropdowns: CustomerDropdowns }> = ({ dropdowns }) => {
  const { formatMessage } = useIntl();
  const { handleSubmit, isSubmitting } = useFormikContext();
  const { setBottomActionBar } = useBottomActionBarDispatch();
  const { history } = useRouter();

  useEffect(() => {
    setBottomActionBar(
      <CustomerBottomBar
        canSave={!isSubmitting}
        handleCancel={() => history.push('/dashboard')}
        handleSave={(formData) => handleSubmit(formData)}
        saveLabel={formatMessage({ id: 'customer.create' })}
      />
    );
    return () => setBottomActionBar(null);
  }, [formatMessage, history, isSubmitting, setBottomActionBar, handleSubmit]);

  return (
    <React.Fragment>
      <DevOnlyComponent>
        <DisplayFormikState />
      </DevOnlyComponent>
      <BusinessInformation dropdowns={dropdowns} />
      <CustomerDetails dropdowns={dropdowns} />
      <CurrentAddress dropdowns={dropdowns} />
      <PhoneNumbersList dropdowns={dropdowns} />
      <EmailsInformations dropdowns={dropdowns} />
      <PreferedContactMethod dropdowns={dropdowns} />
      <SecondaryContact dropdowns={dropdowns} />
    </React.Fragment>
  );
};

export default CreacteCustomerForm;
