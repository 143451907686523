import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import ExpiringPoliciesService from '../../../../services/expiringPolicies/ExpiringPoliciesService';
import {
  DateRange,
  ExpiringPoliciesCriteria,
  ExpiringPolicyCustomFilter,
  ExpiringPolicyItem,
} from '../../../../models/ExpiringPolicies';

export const useExpiringPoliciesData = (
  customFilter: ExpiringPolicyCustomFilter,
  fetch: number,
  onSuccess: (data: ExpiringPolicyItem[]) => void
) => {
  const expiringPoliciesData = useQuery(
    ['expiringPoliciesList', fetch, customFilter],
    () => ExpiringPoliciesService.list(mapCustomFilterToCriteria(customFilter)),
    {
      onSuccess,
    }
  );

  return expiringPoliciesData;
};

export const useExpiringPoliciesOptionsData = (onSuccess: (data: DateRange) => void) => {
  const expiringPoliciesOptionsData = useQuery(
    'expiringPoliciesOptionsData',
    () => ExpiringPoliciesService.GetOptionsData(),
    {
      onSuccess,
      refetchOnWindowFocus: false,
    }
  );

  return expiringPoliciesOptionsData;
};

const mapCustomFilterToCriteria = (
  customFilter: ExpiringPolicyCustomFilter
): ExpiringPoliciesCriteria => {
  const tmp = {
    DisplayDownloadedPolicies: true,
    IncludeBackupPolicies: false,
    Stage: null,
    Status: null,
  } as ExpiringPoliciesCriteria;

  if (customFilter?.DateFrom) {
    const date = DateTime.fromFormat(customFilter.DateFrom, 'MM/dd/yyyy');
    tmp.DateFrom = DateTime.utc(date.year, date.month, date.day).toString();
  } else
    tmp.DateFrom = DateTime.utc(
      DateTime.now().year,
      DateTime.now().month,
      DateTime.now().day
    ).toString();

  if (customFilter?.DateTo) {
    const date = DateTime.fromFormat(customFilter.DateTo, 'MM/dd/yyyy');
    tmp.DateTo = DateTime.utc(date.year, date.month, date.day)
      .plus({ days: 1 })
      .minus({ milliseconds: 3 })
      .toString();
  }
  // uses 121 days on purpose - will then remove 3 milliseconds to inckude all of the 120th day, to match tile
  else
    tmp.DateTo = DateTime.utc(DateTime.now().year, DateTime.now().month, DateTime.now().day)
      .plus({ days: 121 })
      .minus({ milliseconds: 3 })
      .toString();

  return tmp;
};
