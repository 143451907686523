import { FormDropdownSingleSelect } from 'alloy-foundation';
import { DropdownSingleSelectSearchProps } from 'alloy-foundation/es/components/Dropdowns/DropdownSingleSelectSearch';
import { useIntl } from 'react-intl';
import { FieldProps } from 'alloy-foundation/es/forms/hooks/useFormField';

/** `CheckBox` automatically connected to the parent `Form`.  */
export default function FormYesNoDropdown(
  props: FieldProps<string, Omit<DropdownSingleSelectSearchProps<string>, 'options'>>
) {
  const { formatMessage } = useIntl();
  const consentOptions = [
    { value: 'Y', label: formatMessage({ id: 'common.yes' }) },
    { value: 'N', label: formatMessage({ id: 'common.no' }) },
  ];

  const dropdownProps = { ...props, ...{ options: consentOptions } } as FieldProps<
    string,
    DropdownSingleSelectSearchProps<string>
  >;

  return <FormDropdownSingleSelect {...dropdownProps} />;
}
