import baseRequests from '../../api/baseRequests';
import { StateCollection } from '../../models/ApplicationState/StateCollection';
import { ProductInfo } from '../../models/ProductInfo';

declare global {
  interface Window {
    productInfo: any;
  }
}

const baseURL = 'api/ProductInfo';
// Do not add to this file. Please update userInfoService.ts
const productInfoService = {
  retrieveProductInfo: (): Promise<ProductInfo> =>
    baseRequests.get(`${baseURL}/GetProductInfo`).then((response) => response),
  retrieveAgencies: (): Promise<ProductInfo> =>
    baseRequests.get(`${baseURL}/GetAgencies`).then((response) => response),
  getStateCollection: (): any => {
    const productInfo = window.productInfo as ProductInfo;

    try {
      return JSON.parse(productInfo.StateCollection);
    } catch (e) {
      return { empty: true };
    }
  },
  getState: (): StateCollection => {
    const stateCollection = window.stateCollection;

    try {
      return JSON.parse(stateCollection);
    } catch (e) {
      return null;
    }
  },
};

export default productInfoService;
