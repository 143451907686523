import { ContentHeader, Spacer } from 'alloy-foundation';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Manager } from '../../../models/Manager';
import { LocalizedLeaveWithoutSaveGuard } from '../../common/localizedLeaveWithoutSaveGuard/LocalizedLeaveWithoutSaveGuard';
import { EmployeeAssignmentStep } from './EmployeeAssignmentStep';
import DefaultPageLayout from '../../common/page-layout/DefaultPageLayout';
import { ManagerSelectionStep } from './ManagerSelectionStep';
import { useManagerData } from './useManagerData';
import { withLayoutShell } from '../../common/hoc/withLayoutShell';

const ManagerConfiguration = () => {
  const { formatMessage } = useIntl();
  const managerList = useManagerData(['allactivevssoemployees']);
  const [selectedManager, setSelectedManager] = useState<Manager>();
  const history = useHistory();
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const onSelectionChange = () => {
    setIsDirty(true);
  };

  const onSelectedManagerChanged = (manager: Manager) => {
    setSelectedManager(manager);
    setIsDirty(false);
  };

  const onSave = () => {
    setIsDirty(false);
  };

  return (
    <>
      <DefaultPageLayout hideContentHeader={true}>
        <ContentHeader
          title={formatMessage({ id: 'manager.configuration.title' })}
          description={formatMessage({ id: 'manager.configuration.description' })}
        />

        <ManagerSelectionStep
          managerList={managerList}
          selectedManager={selectedManager}
          setSelectedManager={onSelectedManagerChanged}
          isDirty={isDirty}
        ></ManagerSelectionStep>
        <Spacer marginBottom="medium" />
        {selectedManager && (
          <EmployeeAssignmentStep
            selectedManager={selectedManager}
            setSelectedManager={setSelectedManager}
            vssoEmpList={managerList}
            onChange={onSelectionChange}
            onSave={onSave}
          />
        )}
        <LocalizedLeaveWithoutSaveGuard
          shouldBlockNavigation={() => isDirty}
          navigate={(path) => {
            history.push(path);
          }}
        ></LocalizedLeaveWithoutSaveGuard>
      </DefaultPageLayout>
    </>
  );
};
export default withLayoutShell(ManagerConfiguration);
