import { Styles } from 'react-with-styles';

const stylesFn = (): Styles => ({
  header: {
    height: '90px',
  },
  container: {
    height: 'calc(100vh - 9.6rem)',
  },
});

export default stylesFn;
