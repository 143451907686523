import baseRequests from '../../api/baseRequests';
import { SearchResult } from '../../models/SearchResult';
import CookiesService from '../cookies/CookiesService';

// base url
const baseURL = '/api/globalsearch/searchterms?';
let productGuids = '&';

// build cookies params
const cookies = CookiesService.getCookiesNamesByValue('True');
cookies.forEach(function (cookieName) {
  productGuids += `productGuids%5B%5D=${cookieName}&`;
});
productGuids = productGuids.slice(0, -1);

// exports
const GlobalSearchService = {
  searchByAll: (searchText: string): Promise<SearchResult[]> =>
    baseRequests
      .get(
        `${baseURL}searchBy=Client+Name&customerName=on&customerNumber=on&customerEmail=on&customerPhone=on&policyNumber=on&xReference=on&customerActive=on&customerInactive=on` +
          `&searchText=${searchText}${productGuids}`
      )
      .then((response) => response),
};

export default GlobalSearchService;
