import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from 'alloy-foundation';
import { useMutation } from 'react-query';
import { useRouter } from '../common/hooks/useRouter';
import CreateCustomer from './CreateCustomer';
import customerServices from '../../services/customer/Customers';
import { withLayoutShell } from '../common/hoc/withLayoutShell';

const CreateCustomerContainer = () => {
  const { addErrorToast, addSuccessToast } = useAppContext();
  const { history } = useRouter();

  const createCustomer = async (formData) => {
    return await customerServices.create(formData);
  };

  const mutation = useMutation(createCustomer, {
    onError: (error, variables, context) => {
      // An error happened!
      addErrorToast(<FormattedMessage id="customer.createError" />, {
        title: <FormattedMessage id="error.toasts.header" />,
        withBottomActionBar: true,
      });
    },
    onSuccess: (data, variables, context) => {
      history.push(`/customers/${data}`);

      addSuccessToast(<FormattedMessage id="customer.createSuccess" />, {
        title: <FormattedMessage id="success.toasts.header" />,
        withBottomActionBar: true,
      });
    },
  });

  return (
    // applying the following css (position: 'relative') fixes the issue caused by using SingleLineFormListArray
    // which breaks the layout.  ScrollWrapper should only be used with ScrollSection.
    <div style={{ position: 'relative' }}>
      <CreateCustomer createCustomer={mutation.mutateAsync} />
    </div>
  );
};

export default withLayoutShell(CreateCustomerContainer);
