import { FormDatePickerInput } from 'alloy-foundation';
import { FieldProps } from 'alloy-foundation/es/forms/hooks/useFormField';
import { DatePickerInputProps } from 'alloy-foundation/es/components/DatePickerInput';
import { Locale } from 'alloy-foundation/es/types';
import { useIntl } from 'react-intl';
import { useLocaleContext } from '../../localization/LocaleContext';

const LocalizedFormDatePickerInput = (props: FieldProps<string | Date, DatePickerInputProps>) => {
  const { formatMessage } = useIntl();
  const { locale } = useLocaleContext();
  const alloyLocale = (locale ?? 'en').substring(0, 2) as Locale;

  return (
    <FormDatePickerInput
      locale={alloyLocale}
      placeholder={formatMessage({ id: 'common.date.placeholder' })}
      datePickerProps={{
        labels: {
          previousMonth: formatMessage({ id: 'common.date.previousMonth' }),
          nextMonth: formatMessage({ id: 'common.date.nextMonth' }),
        },
      }}
      {...props}
    />
  );
};

export default LocalizedFormDatePickerInput;
