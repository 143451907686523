import React from 'react';
import { useIntl } from 'react-intl';
import Card from '../common/card/Card';
import BusinessInformationViewOnly from './BusinessInformationViewOnly';
import { BusinessInformationEditForm } from './BusinessInformationEditForm';
import { SectionInfoProps } from '../SectionInfoProps';

const BusinessInformation = (props: SectionInfoProps) => {
  const { formatMessage } = useIntl();
  return (
    <Card
      title={formatMessage({ id: 'customer.details.businessInformation.title' })}
      canEdit={props.canEdit && props.canEditBusinessInfo}
      onEdit={props.onEdit}
      isClosed={false}
      namespace="businessInformation"
      viewControl={<BusinessInformationViewOnly customer={props.customer} />}
      formControl={<BusinessInformationEditForm {...props}></BusinessInformationEditForm>}
    ></Card>
  );
};

export default BusinessInformation;
