import React, { useState, createContext, useMemo, useContext } from 'react';

const BottomActionBarState = createContext({ bottomActionBar: null });
const BottomActionBarDispatch = createContext({ setBottomActionBar: (p) => p });
const BottomActionBarProvider = ({ children }) => {
  const [bottomActionBar, setBottomActionBar] = useState(null);

  const memoizedState = useMemo(() => ({ bottomActionBar }), [bottomActionBar]);
  const memoizedDispatch = useMemo(() => ({ setBottomActionBar }), []);

  return (
    <BottomActionBarState.Provider value={memoizedState}>
      <BottomActionBarDispatch.Provider value={memoizedDispatch}>
        {children}
      </BottomActionBarDispatch.Provider>
    </BottomActionBarState.Provider>
  );
};

const useBottomActionBarState = () => {
  const context = useContext(BottomActionBarState);
  if (context === undefined) {
    throw new Error(
      'useBottomActionBarState cannot be called from outside of a BottomActionBarProvider'
    );
  }
  return context;
};

const useBottomActionBarDispatch = () => {
  const context = useContext(BottomActionBarDispatch);
  if (context === undefined) {
    throw new Error(
      'useBottomActionBarDispatch cannot be called from outside of a BottomActionBarProvider'
    );
  }
  return context;
};

const useBottomActionBar = () => {
  return [useBottomActionBarState(), useBottomActionBarDispatch()];
};

export { useBottomActionBarState, useBottomActionBarDispatch, useBottomActionBar };
export default BottomActionBarProvider;
